import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TextField from 'components/TextField';
import Button from 'components/new/Button';
import BubblesTop from '../assets/bubblestop.svg';
import BubblesBottom from '../assets/bubblesbottom.svg';
import { notification } from 'antd';
import { signupNewsletter } from 'services/newslettersignup/newslettersignup.actions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { isEmailValid } from 'common/helpers/validators';
import './subscribe.css';

class Subscribe extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: {
        value: "",
        error: false,
        errorMessage: "Please enter your email."
      }
    }
  }

  componentDidUpdate(prevProps) {
    console.log(prevProps, "prevProps")
    console.log(this.props, "this.props")
    if (this.props.isNewsletterSignupSuccessful) {
      notification.success({
        message: "Thank you for subscribing!"
      });
    }

    if (this.props.error && this.props.errorMessage.length > 0 && !this.state.email.error) {
      this.setState({
        email: {
          ...this.state.email,
          error: true,
          errorMessage: this.props.errorMessage
        }
      });

    }

/*
    if (!this.props.isNewsletterSignupSuccessful) {
      notification.success({
        message: "You have already subscribed."
      });
    }*/
  }

  isEmailValid() {
    return isEmailValid(this.state.email.value);
  }

  onChange(event) {
    this.setState({ [event.target.name]: {
      value: event.target.value,
      error: this.state[event.target.name].error
      }
    })
  }

  onClickSubmitEmail() {
    if (!this.isEmailValid()) {
      this.setState({
        email: {
          error: true,
          value: this.state.email.value,
          errorMessage: "Please enter a valid e-mail address"
        }
      });
      return;
    }
    this.props.signupNewsletter(this.state.email.value);
  }

  render() {
    let { email } = this.state;

    return (
      <div className="d-flex align-items-center justify-content-center subscribe-container" style={{borderRadius: '9px'}}>
        <div className="d-flex align-items-center justify-content-center flex-column">
          <div style={{marginBottom: '15px'}} className="header-2-gray">Subscribe to Our Blog</div>
          <div style={{marginBottom: '35px'}} className="body-copy-gray">Stay up to date with the latest events, marketing, and sponsorship tips and news.</div>
          <div style={{marginBottom: '35px'}}>
            <TextField
              fontStyle="primary"
              name="email"
              errorMessage={email.errorMessage}
              value={email.value}
              error={email.error}
              onChange={this.onChange.bind(this)}
              label="Enter email address"
              size="full" />
            </div>
            <Button label="SUBSCRIBE VIA EMAIL" size="md" type="senary" onClick={this.onClickSubmitEmail.bind(this)}  />
        </div>
        <img className="subscribe-bubbles-top" src={BubblesTop} />
        <img className="subscribe-bubbles-bottom" src={BubblesBottom} />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isNewsletterSignupSuccessful: state.newslettersignup.isNewsletterSignupSuccessful,
    errorMessage: state.newslettersignup.errorMessage,
    error: state.newslettersignup.error
  };
};

const mapDispatchToProps = dispatcher => (
    bindActionCreators({
        signupNewsletter,
    }, dispatcher)
);

export default connect(mapStateToProps, mapDispatchToProps)(Subscribe);

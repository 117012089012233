import React, { Component } from 'react';
import Detail from './Detail';
import Tab from 'common/Tab';
import TabGroup from 'common/TabGroup';
import Accordion from 'components/Accordion';
import EventGallery from 'screens/event/components/eventgallery.component';
import EventSidePanel from 'screens/event/components/eventsidepanel.component';
import EventAudienceTab from 'screens/event/components/eventaudiencetab.component';
import EventOverviewTab from 'screens/event/components/eventoverviewtab.component';
import { bindActionCreators } from 'redux';
import { generateCurrencyFormat } from 'common/helpers/currency';
import { getEvent } from 'services/events/events.actions';
import { connect } from 'react-redux';
import './view.css';

class ViewEvent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabs: {
        overview: {
          active: true,
          label: "Overview"
        },
        audience: {
          active: false,
          label: "Audience"
        },
        sponsorships: {
          active: false,
          label: "Sponsorships"
        }
      },
      selectedPackage: null
    }
  }

  setCustomizeModalVisible(isVisible) {
    this.setState({
      isCustomizePackageModalVisible: isVisible
    })
  }

  componentWillMount() {
    const { id } = this.props.match.params;
    this.props.getEvent(null, id);
  }

  activate(current) {
    let { tabs } = this.state;
    let update = {...tabs};

    for (let tab in tabs) {
      if (current === tab) {
        update[tab].active = true;
      } else {
        update[tab].active = false;
      }
    }

    this.setState({
      tabs: {...update}
    });
  }

  getImages(assets) {
    let { event } = this.props;

    if (!event) {
      return;
    }

    assets = event.assets;

    let images = assets.filter((asset) => {
      return asset.asset_type_id == 1
    }).map(({url}) => {
      return {
        src: url
      }
    });
    return images;
  }

  getLogo(assets) {
    let { event } = this.props;

    if (!event) {
      return;
    }

    assets = event.assets;

    let images = assets.filter((asset) => {
      return asset.asset_type_id == 3
    }).map(({url}) => {
      return {
        src: url
      }
    });
    return images;
  }

  setAudienceTab() {
    if (!this.state.tabs.audience.active) return (null);
    let { audienceAttributes, eventGender, eventAttendances } = this.props.event;
    return (
      <EventAudienceTab
        expected_attendees={this.props.event.event.expected_attendees}
        audience_attributes={audienceAttributes}
        eventGender={eventGender}
        eventAttendances={eventAttendances}
      />
    )
  }

  setOverviewTab(description) {
    if (!this.state.tabs.overview.active) return (null);

    return (
      <EventOverviewTab assets={this.props.event.assets} event={this.props.event.event} />
    )
  }

  setPackagesTab(packages) {
    if (!this.state.tabs.sponsorships.active) return (null);
    if (packages === null) return (null);
    let panels = [];

    packages.map((item, index) => {
      let { name, cost, limit, placements, description } = item;
      console.log(item);
      let content = () => {
        return (
          <div key={index} className="event-details-packages-content">
            <Detail label="Description" value={description} />
            {placements ? <Detail label="Package Contents" value={(placements.length === 0 ? "None" : placements.map((placement) => placement.placement.placement).join(", "))} /> : null}
            <Detail label="# of Available Packages" value={limit} />
          </div>
        )
      }

      let panel = {
        label: (
          <div className="d-flex flex-row">
            <div>
              <div className='body-copy-bold-brand'>{name}</div>
              <div className="body-copy-tertiary">{generateCurrencyFormat(cost)}</div>
            </div>
          </div>
        ),
        content
      }

      panels.push(panel);
    });

    return (
      <div className="event-details-panel">
        <Accordion panels={panels} />
      </div>
    )
  }

  buyPackage(selectedPackage) {
    this.setState({
      selectedPackage,
      isCustomizePackageModalVisible: true
    })
  }

  getTabs() {
    let buffer = [];
    let index = 0;
    console.log()
    for (let tab in this.state.tabs) {
      console.log(tab);
      let { label, active, hidden } = this.state.tabs[tab];

      buffer.push(
        <Tab
          type="bold"
          isCompleteVisible={false}
          key={index}
          hidden={hidden}
          label={label}
          selected={active}
          onClick={this.activate.bind(this, tab)} />
        )
      index++;
    }
    return buffer;
  }

  render() {
    if (!this.props.event.event.id) return null;
    let { event, audienceAttributes, packages, assets, organization, eventGenders, eventAttendances } = this.props.event;
    let { name, startdate, enddate, tagline, website, location } = event;
    let { tabs } = this.state;

    if (!location) return null;
    let {formatted_address, description} = location;
    let images = this.getImages(assets);
    let logos = this.getLogo(assets);

    return (
      <div style={{paddingBottom: '50px', paddingTop: '25px'}}>
        <div className="d-flex flex-md-row flex-column">
          <EventSidePanel
            location={location}
            logos={logos}
            website={website}
            tagline={tagline}
            name={name}
            startdate={startdate}
            enddate={enddate}
            assets={assets}
            venuename={location.name}
            venueaddress={location.formatted_address}
            organization={organization} />
          <div className="event-main-panel col-md-8 col-12 event-details-main flex-shrink-0 d-flex flex-column">
            <EventGallery images={images}/>
            <div className="d-flex flex-column event-tab-group">
              <div>
                <TabGroup>
                  {this.getTabs()}
                </TabGroup>
              </div>
              <div style={{height: '100%'}}>
                { this.setOverviewTab() }
                { this.setPackagesTab(packages) }
                { this.setAudienceTab()}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    event: state.events.currentEvent,
    items: state.cart.items,
    user: state.users.user
  };
};


const mapDispatchToProps = dispatcher => (
    bindActionCreators({
      getEvent
    }, dispatcher)
);

export default connect(mapStateToProps, mapDispatchToProps)(ViewEvent);

import React, { Component } from 'react';
import Modal from 'components/Modal';
import EventPackageForm from 'screens/event/components/eventpackagesform.component';
import ButtonGroup from 'components/ButtonGroup';
import './editeventpackagemodal.css';

class EditEventPackageModal extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  render() {
    let { addPackage, onClickClose, isEditModalOpen, placements, packages, removePackage, savedPackages, searchPlacements, postPackages, putPackages, setPlacements, event} = this.props;
    return (
      <Modal
        size="large"
        onClickClose={onClickClose.bind(this)}
        visible={isEditModalOpen}
        appendFooter={() => {
          return (
            <ButtonGroup
              labelPrimary="SAVE"
              labelSecondary="CANCEL"
              onClickPrimary={(() => {})}
              onClickSecondary={(() => {})} />
            )
        }}>
        <div className="edit-modal-wrapper d-flex justify-content-center">
          <div className="edit-modal-container">
            <EventPackageForm
              event={event}
              placements={placements}
              packages={packages}
              removePackage={removePackage.bind(this)}
              savedPackages={savedPackages}
              addPackage={addPackage}
              searchPlacements={searchPlacements.bind(this)}
              postPackages={postPackages.bind(this)}
              putPackages={putPackages.bind(this)}
              setPlacements={setPlacements.bind(this)}
            />
          </div>
        </div>
      </Modal>
    );
  }
}

export default EditEventPackageModal;

import { ActionTypes } from './newslettersignup.actions';

const initialState = {
    isNewsletterSignupSuccessful: false,
    errorMessage: "",
    email: ""
};

const NewsletterSignupReducer = (state = initialState, action) => {
    switch (action.type) {
      case ActionTypes.SIGNUP_NEWSLETTER:
        return { ...state };
      case ActionTypes.SIGNUP_NEWSLETTER_SUCCESS:
        return { ...state, isNewsletterSignupSuccessful: true, email: action.data.email, error: false};
      case ActionTypes.SIGNUP_NEWSLETTER_FAILURE:
        return { ...state, isNewsletterSignupSuccessful: false, errorMessage: action.errorMessage, error: true};
      default:
          return state;
    }
};

export default NewsletterSignupReducer;

import React, { Component } from 'react';
import Wrapper from 'components/Wrapper';
import Tab from 'common/Tab';
import TabGroup from 'common/TabGroup';
import { Routes } from 'constants/Routes';
import { withRouter, Redirect } from "react-router-dom";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Document from 'assets/icons/document@3x.png';
import User from 'assets/user@3x.png';
import Clipboard from 'assets/icons/clipboard@3x.png';
import Tag from 'assets/tag@3x.png';
import './createportfolio.css';

const {
  PORTFOLIO_OBJECTIVES,
  PORTFOLIO_SPONSORSET,
  PORTFOLIO_AUDIENCE,
  PORTFOLIO_PREFERENCES,
  PORTFOLIO_PLACEMENTS,
  PORTFOLIO_REVIEW
} = Routes;
/*
<TabGroup>
  <Tab
    icon={Document}
    label="Objective"
    history={history}
    route={PORTFOLIO_OBJECTIVES}
    onClick={this.onClickTab.bind(this, PORTFOLIO_OBJECTIVES)}/>
  <Tab icon={User} label="Audience" history={history} route={PORTFOLIO_AUDIENCE} onClick={this.onClickTab.bind(this, PORTFOLIO_AUDIENCE)}/>
  <Tab icon={Tag} label="Placements" history={history} route={PORTFOLIO_PREFERENCES} onClick={this.onClickTab.bind(this, PORTFOLIO_PREFERENCES)}/>
  <Tab icon={Clipboard} history={history} label="Review" route={PORTFOLIO_REVIEW} onClick={this.onClickTab.bind(this, PORTFOLIO_REVIEW)}/>
</TabGroup>*/
const tabs = [
  { alt: "Document", icon: Document, label: "Objective", route: PORTFOLIO_OBJECTIVES },
  { alt: "User", icon: User, label: "Audience", route: PORTFOLIO_AUDIENCE },
  { alt: "Tag", icon: Tag, label: "Preferences", route: PORTFOLIO_PREFERENCES },
  { alt: "Clipboard", icon: Clipboard, label: "Review", route: PORTFOLIO_REVIEW }
]

class CreatePortfolio extends Component {
  onClickTab(route) {
    if (!this.validate(route)) return;
    this.props.history.push(route);
  }

  validate(route) {
    let { pathname } = this.props.history.location;
    let { isPortfolioPreferencesPageValid, isPortfolioObjectivePageValid, isPortfolioAudiencePageValid } = this.props;

    if (pathname === PORTFOLIO_OBJECTIVES && !isPortfolioObjectivePageValid) {
      return false;
    }

    if (pathname === PORTFOLIO_AUDIENCE && !isPortfolioAudiencePageValid && (route === PORTFOLIO_PREFERENCES || route === PORTFOLIO_REVIEW)) {
      return false;
    }

    if (pathname === PORTFOLIO_PREFERENCES && !isPortfolioPreferencesPageValid && (route === PORTFOLIO_PREFERENCES)) {
      return false;
    }

    if (pathname !== PORTFOLIO_REVIEW
      && !isPortfolioObjectivePageValid
      && !isPortfolioAudiencePageValid
      && !isPortfolioPreferencesPageValid) {
      return false;
    }

    return true;
  }

  render() {
    let { history, isPortfolioObjectivePageValid, isPortfolioAudiencePageValid, isPortfolioPreferencesPageValid } = this.props;
    let { pathname } = history.location;
    
    if (pathname === PORTFOLIO_REVIEW
      && (!isPortfolioObjectivePageValid
      || !isPortfolioAudiencePageValid
      || !isPortfolioPreferencesPageValid)) {
        return <Redirect to={PORTFOLIO_OBJECTIVES} />;
    }

    return (
        <div className="container" style={{paddingTop: '30px'}}>
          <TabGroup>
            {
              tabs.map(({alt, icon, label, route}, index)=>{
                let isComplete = false;
                let active = true;

                if (PORTFOLIO_OBJECTIVES === route) {
                  isComplete = isPortfolioObjectivePageValid;
                }

                if (PORTFOLIO_AUDIENCE === route) {
                  isComplete = isPortfolioAudiencePageValid;
                  if (!isPortfolioObjectivePageValid) {
                    active = false;
                  }
                }

                if (PORTFOLIO_PREFERENCES === route) {
                  isComplete = isPortfolioPreferencesPageValid;
                }

                return (
                  <Tab
                    isComplete={isComplete}
                    active={active}
                    alt={alt}
                    key={index}
                    icon={icon}
                    label={label}
                    history={history}
                    route={route}
                    onClick={this.onClickTab.bind(this, route)} />
                  )
              })
            }
          </TabGroup>
          <Wrapper>
            {this.props.children}
          </Wrapper>
        </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isPortfolioSponsorsetPageValid: state.portfolios.isPortfolioSponsorsetPageValid,
    isPortfolioPreferencesPageValid: state.portfolios.isPortfolioPreferencesPageValid,
    isPortfolioObjectivePageValid: state.portfolios.isPortfolioObjectivePageValid,
    isPortfolioAudiencePageValid: state.portfolios.isPortfolioAudiencePageValid
  };
};

const mapDispatchToProps = dispatcher => (
    bindActionCreators({

    }, dispatcher)
);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CreatePortfolio));

import React, { Component } from 'react';
import Paragraph from 'components/blog/paragraph.component';
import './block.css';

class Block extends Component {
  render(){
    let { label } = this.props;
    return (
      <div>
        <label className="block-label header-2-tertiary">{label}</label>
        <Paragraph>{this.props.children}</Paragraph>
      </div>
    )
  }
}

export default Block;

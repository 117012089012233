import React, { Component } from 'react';
import User from 'assets/user.svg';
import Button from 'components/new/Button';
import SubscribeModal from 'common/modals/subscribe.modal';
import { Routes } from 'constants/Routes';
import './postauthor.css';

class PostAuthor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false
    }
  }

  static defaultProps = {
    style: {}
  }

  onClickGoToSocialUrl(url) {
    window.open(url, "_blank",)
  }

  goToRoute(route) {
    if (route === 'subscribe') {
      this.setState({
        isModalOpen: true
      })
      return;
    }

    this.props.history.push(route);
  }

  onClickClose() {
    this.setState({
      isModalOpen: false
    })
  }

  render(){
    let { author, authorphotourl, authorsocialname, authorsocialurl, img, style, ctalabel, ctadescription, ctalink } = this.props;

    let user = User;

    if (authorphotourl) {
      user = authorphotourl;
    }

    let imgStyle = {
      backgroundImage: `url(${user})`
    }

    return (
      <div className="container">
        <div className="postauthor-container d-flex flex-column flex-sm-row align-items-center row" style={style}>
          <div className="col-lg-5 col-12">
            <div className="d-flex flex-row align-items-center">
              <span style={{marginRight: '20px'}}>
                {/*<img src={User} />*/}
                <div style={imgStyle} className="postauthor-img"></div>
              </span>
              <span>
                <div className="body-copy-tertiary">Written by {author}</div>
                <div onClick={this.onClickGoToSocialUrl.bind(this, authorsocialurl)} className="postauthor-socialurl body-copy-tertiary">@{authorsocialname}</div>
              </span>
            </div>
          </div>
          <div className="col-lg-7 col-12 d-flex flex-lg-row flex-column postauthor-cta">
            <div className="postauthor-cta-description body-copy-tertiary d-flex align-items-center">{ctadescription}</div>
            <div className="d-flex align-items-center justify-content-center">
              <Button className="button-text-medium create-event-button" label={ctalabel} size="sm" type="primary" onClick={this.goToRoute.bind(this, ctalink)} />
            </div>
          </div>
        </div>
        <SubscribeModal isModalOpen={this.state.isModalOpen} onClickClose={this.onClickClose.bind(this)} />
      </div>
    )
  }
}

export default PostAuthor;

import React, { Component } from 'react';
import Benefit from './components/Benefit';
import './Benefits.css'

import presentation from './assets/presentation.png';
import group_chat from './assets/group_chat.png';

class Benefits extends Component {
  render() {
    return (
      <div className="benefits-container">
        <Benefit
          src={group_chat}
          alt="Illustration of 3 people using electronic devices sitting amongst a collection of browser windows"
          label="Provide a Next Level Experience"
          descriptionTop="Invite attendees to your event space"
          descriptionBottom="Enable meaningful connections amongst attendees"
          reverse={true} />
        <Benefit
          src={presentation}
          alt="Illustration of a person giving a presenation, pointing at a bar chart with a line graph"
          label="Fulfill Your Event Aspirations"
          descriptionTop="Never miss event success again"
          descriptionBottom="Invite collaborators to work towards goals with you" />
      </div>

    );
  }
}

export default Benefits;

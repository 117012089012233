import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './Divider.css';

class Divider extends Component {
  static defaultProps = {
    type: "gradient",
    style: {},
    className: ""
  }

  getDividerType() {
    let { type, className } = this.props;

    if (this.props.type === 'brand secondary') return `restage-divider-brand-secondary ${className}`.trim();
    if (this.props.type === 'brand') return `restage-divider-brand ${className}`.trim();
    if (this.props.type === 'gradient') return `restage-divider-gradient ${className}`.trim();



    return "restage-divider-gradient";
  }
  render() {
    return (
      <div style={this.props.style} className={`${this.getDividerType()} restage-divider`} />
    );
  }
}

export default Divider;

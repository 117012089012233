import React, { Component } from 'react';
import PropTypes from 'prop-types';
import loader from 'assets/icons/loader.svg';

class Loader extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div style={{height: '100%'}} className="d-flex align-items-center justify-content-center">
        <img alt="Loading GIF" style={{height: '200px'}} src={loader} />
      </div>
    );
  }
}

export default Loader;

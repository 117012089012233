import React, { Component } from 'react';
import Wrapper from 'components/Wrapper';
import Eyebrow from 'components/Eyebrow';
import Detail from 'common/Detail';
import Button from 'components/new/Button';
import TextField from 'components/TextField';
import FormGroup from 'components/FormGroup';
import { Table } from 'antd';
import { getEvent } from 'services/events/events.actions';
import { generateCurrencyFormat } from 'common/helpers/currency';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

const columns = [
  {
    title: 'Sponsor Instance ID',
    dataIndex: 'event_sponsor_id',
    key: 'event_sponsor_id'
  },
  {
    title: 'Event ID',
    dataIndex: 'event_id',
    key: 'event_id'
  },
  {
    title: 'Sponsor Name',
    dataIndex: 'sponsor_name',
    key: 'sponsor_name'
  },
  {
    title: 'Sponsor URL',
    dataIndex: 'sponsor_url',
    key: 'sponsor_url'
  }
];

class EventSponsors extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: []
    }
  }

  componentWillMount() {
    fetch("https://api.restagex.com/event-sponsor/all", {
      method: "GET",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json"
      },
      redirect: "follow",
      referrer: "no-referrer"
    }).then((response) => {
      return response.json();
    }).then((data) => {
      let instances = []
      data.map((item, index) => {
        instances.push({
          event_sponsor_id: item.id,
          event_id: item.event_id,
          sponsor_name: item.name,
          sponsor_url: <a href={item.sponsor_url} key={index} target="_blank" rel="noopener noreferrer">{item.sponsor_url}</a>
        })
      });
      this.setState({
        data: instances
      })
    });
  }

  _handleOnChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  render() {
    let { data } = this.state;

    return (
      <Wrapper className="container">
      <div className="d-flex flex-row justify-content-between">
        <Detail justification="start" header="Event Sponsors" subHeader="Event Sponsors" />
      </div>
        <div style={{marginTop: '25px'}}>
          <Eyebrow label="Event Sponsors" />
          <Table dataSource={data} columns={columns} />
        </div>
      </Wrapper>
    );
  }
}

const mapStateToProps = (state) => {
  return {

  };
};

const mapDispatchToProps = dispatcher => (
    bindActionCreators({

    }, dispatcher)
);

export default connect(mapStateToProps, mapDispatchToProps)(EventSponsors);

import { put, call, all, takeLatest } from 'redux-saga/effects';
import { ActionTypes } from './packages.actions';
import { post, putRequest } from '../Api'
const basePath = "event-packages";

export const postPackages = function* (action) {
  const { payload: { packages } } = action;
  try {
    const response = yield call(post, basePath, packages);
    const data = yield response.json();
    //const json = yield response.json();
    yield put({ type: ActionTypes.POST_PACKAGES_SUCCESS, packages: packages, savedPackages: data });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: ActionTypes.POST_PACKAGES_FAILURE, error });
  }
}

export const putPackages = function* (action) {
  const { payload: { packages } } = action;
  try {
    const response = yield call(putRequest, basePath, packages);
    //const json = yield response.json();
    yield put({ type: ActionTypes.PUT_PACKAGES_SUCCESS, packages: packages, savedPackages: response.data });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: ActionTypes.PUT_PACKAGES_FAILURE, error });
  }
}

function* packages() {
  yield all([
    takeLatest(ActionTypes.POST_PACKAGES, postPackages),
    takeLatest(ActionTypes.PUT_PACKAGES, putPackages)
  ]);
}

export default packages;

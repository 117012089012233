import { ActionTypes } from './sponsornewslettersignup.actions';

const initialState = {
    demoSignupErrorMessage: "",
    demoSignupError: false,
    newsletterSignupErrorMessage: "",
    newsletterSignupError: false,
    isNewsletterSignupSuccessful: false,
    isDemoSignupSuccessful: false,
    email: ""
};

const SponsorNewsLetterSignupReducer = (state = initialState, action) => {
    switch (action.type) {
      case ActionTypes.NEWSLETTER_SIGNUP_SUCCESS:
        return { ...state, isNewsletterSignupSuccessful: true};
      case ActionTypes.DEMO_SIGNUP_SUCCESS:
          return { ...state, isDemoSignupSuccessful: true, error: false, errorMessage: ""};
      case ActionTypes.DEMO_SIGNUP_FAILURE:
        return { ...state,
          demoSignupErrorMessage: action.errorMessage,
          demoSignupError: true
        };
      case ActionTypes.NEWSLETTER_SIGNUP_FAILURE:
        return { ...state,
          newsletterSignupErrorMessage: action.errorMessage,
          newsletterSignupError: true
        };
      case ActionTypes.SIGNUP_RESET:
        return { ...state,
          isNewsletterSignupSuccessful: false,
          isDemoSignupSuccessful: false,
          demoSignupError: false,
          demoSignupErrorMessage: "",
          newsletterSignupError: false,
          newsletterSignupErrorMessage: ""
        };
      case ActionTypes.SET_EMAIL:
        return { ...state,
          email: action.payload.email
        };
      default:
          return state;
    }
};

export default SponsorNewsLetterSignupReducer;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Select as AntdSelect} from 'antd';
import './Select.css';
const { Option } = AntdSelect;

class Select extends Component {
  static defaultProps = {
    errorStyle: {},
    style: {},
    size: "full",
    type: ""
  }

  onChange(value, event) {
    if (this.props.onChange !== null) {
      this.props.onChange(value, event.props.children);
    }
  }

  getLabel() {
    let { type } = this.props;

    if (type === 'purple') return "body-copy-bold-brand"

    return "body-copy-bold-tertiary";
  }

  getType() {
    let { type } = this.props;

    if (type === 'purple') return "restage-select restage-select-purple body-copy-bold-purple"

    return "restage-select body-copy-tertiary";
  }

  render() {
    let { label, placeholder, options, errorStyle, error, errorMessage } = this.props;

    let className = [];

    className = (this.props.size === "full" ? className.concat(["textfield-full"]) : className);
    className = (this.props.size === "md" ? className.concat(["textfield-md"]) : className);
    className = (this.props.size === "sm" ? className.concat(["textfield-sm"]) : className);
    className = (this.props.size === "half" ? className.concat(["textfield-half"]) : className);

    return (
      <div style={this.props.style} className={className.join(" ")}>
        <label className={this.getLabel()}>{label}</label>
        <AntdSelect className={this.getType()} placeholder={placeholder} onChange={this.onChange.bind(this)}>
          {
            options.map((option, index)=>{
                let { value, label } = option;
                return (
                  <Option key={index} value={value}>{label}</Option>
                )
              })
            }
        </AntdSelect>
        <div className={error ? "textfield-error-container" : "textfield-hidden" }>
          <div style={errorStyle} className="body-copy-error-xs-small">{errorMessage}</div>
        </div>
      </div>
    );
  }
}

export default Select;

import firebase from '../firebase/Firebase';

class Auth {
  constructor() {
    this.authenticated = false;
  }

  async login(email, password, cb) {
    this.authenticated = true;
    let response = await firebase.signInWithEmailAndPassword(email, password);
    cb(response);
  }

  async logout(cb) {
    await firebase.signOut();
    this.authenticated = false;
    cb();
  }

  isAuthenticated() {
    return this.authenticated;
  }
}

export default new Auth();

import React, { Component } from 'react';
import Wrapper from 'components/Wrapper';
import Tab from 'common/Tab';
import TabGroup from 'common/TabGroup';
import { Routes } from 'constants/Routes';
import { postEvents } from 'services/events/events.actions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter, Redirect } from "react-router-dom";
import Document from 'assets/icons/document@3x.png';
import User from 'assets/user@3x.png';
import Clipboard from 'assets/icons/clipboard@3x.png';
import Tag from 'assets/tag@3x.png';
import Check from 'assets/icons/check.svg';
import './createevent.css';

const {
  EVENT_DETAILS,
  EVENT_AUDIENCE,
  EVENT_PACKAGES,
  EVENT_REVIEW
} = Routes;

const tabs = [
  { alt: "Document", icon: Document, label: "Details", route: EVENT_DETAILS },
  { alt: "User", icon: User, label: "Audience", route: EVENT_AUDIENCE },
  { alt: "Tag", icon: Tag, label: "Packages", route: EVENT_PACKAGES },
  { alt: "Clipboard", icon: Clipboard, label: "Review", route: EVENT_REVIEW }
]

class CreateEvent extends Component {
  onClickTab(route) {
    if (!this.validate(route)) return;
    this.props.history.push(route);
  }

  validate(route) {
    let { pathname } = this.props.history.location;
    let { isEventDetailsPageValid, isEventAudiencePageValid, isEventPackagesPageValid } = this.props;

    if (pathname === EVENT_DETAILS && !isEventDetailsPageValid) {
      return false;
    }

    if (pathname === EVENT_AUDIENCE && !isEventAudiencePageValid && (route === EVENT_PACKAGES || route === EVENT_REVIEW)) {
      return false;
    }

    if (pathname === EVENT_PACKAGES && !isEventPackagesPageValid && (route === EVENT_REVIEW)) {
      return false;
    }

    if (pathname !== EVENT_REVIEW
      && !isEventDetailsPageValid
      && !isEventPackagesPageValid
      && !isEventAudiencePageValid) {
      return false;
    }

    return true;
  }

  render() {
    let {
      history,
      isEventDetailsPageValid,
      isEventAudiencePageValid,
      isEventPackagesPageValid
    } = this.props;

    let { pathname } = history.location;

    if (pathname === EVENT_REVIEW
      && (!isEventDetailsPageValid
      || !isEventPackagesPageValid
      || !isEventAudiencePageValid)) {
        return <Redirect to={EVENT_DETAILS} />;
    }

    return (
        <div className="container" style={{paddingTop: '30px'}}>
          <TabGroup>
            {
              tabs.map(({alt, icon, label, route}, index)=>{
                let isComplete = false;
                let active = true;

                if (EVENT_DETAILS === route) {
                  isComplete = isEventDetailsPageValid;
                }

                if (EVENT_AUDIENCE === route) {
                  isComplete = isEventAudiencePageValid;
                  if (!isEventDetailsPageValid) {
                    active = false;
                  }
                }

                if (EVENT_PACKAGES === route) {
                  isComplete = isEventPackagesPageValid;
                }

                return (
                  <Tab
                    isComplete={isComplete}
                    active={active}
                    alt={alt}
                    key={index}
                    icon={icon}
                    label={label}
                    history={history}
                    route={route}
                    onClick={this.onClickTab.bind(this, route)} />
                  )
              })
            }
          </TabGroup>
          <Wrapper style={{position: 'relative'}}>
            {this.props.children}
          </Wrapper>
        </div>
    );
  }
}


export default CreateEvent;

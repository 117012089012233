import React, { Component } from 'react';
import { AssetTypes } from 'constants/AssetTypes';
import Document from 'assets/icons/document-purple.svg';
import './eventoverviewtab.css';


class EventOverviewTab extends Component {
  getAssetByAssetTypeId(assets, asset_type_id) {
    let items = assets.filter((asset) => {
      return asset.asset_type_id === asset_type_id
    }).map(({id, description, url}) => {
      return {
        id,
        description,
        src: url
      }
    });
    return items;
  }

  renderDocuments() {
    let prospectus = this.getAssetByAssetTypeId(this.props.assets, AssetTypes.prospectus);
    let decks = this.getAssetByAssetTypeId(this.props.assets, AssetTypes.pitchDeck);

    if (prospectus.length === 0 && decks.length === 0) {
      return (null);
    }
    let prospectusElement = null;
    let pitchDeckElement = null;

    if (prospectus.length > 0) {
      console.log(prospectus)
      prospectusElement =  (
        <a style={{cursor: "pointer", width: '100%'}} target="_blank" href={prospectus[0].src} rel="noopener noreferrer" className="d-flex flex-row align-items-center event-overview-document-row">
          <img src={Document} style={{height: '40px', marginRight: '35px'}} />
          <div>
            <div style={{marginBottom: '5px'}} className="body-copy-bold-tertiary">Conference Prospectus</div>
            <div className="body-copy-tertiary">{prospectus[0].description}</div>
          </div>
        </a>
      )
    }

    if (decks.length > 0) {
      pitchDeckElement =  (
        <a style={{cursor: "pointer", width: '100%'}} target="_blank" href={decks[0].src} rel="noopener noreferrer" className="d-flex flex-row align-items-center event-overview-document-row">
          <img src={Document} style={{height: '40px', marginRight: '35px'}} />
          <div>
            <div style={{marginBottom: '5px'}} className="body-copy-bold-tertiary">Pitch deck</div>
            <div className="body-copy-tertiary">{decks[0].description}</div>
          </div>
        </a>
      )
    }

    return (
      <div>
        <div className="body-copy-bold-tertiary" style={{marginBottom: '8px'}}>Documents</div>
        <div className="event-overview-documents">
          {prospectusElement}
          {pitchDeckElement}
        </div>
      </div>
    )
  }

  renderOverview(label, text) {
    if (text) {
      return(
        <div>
            <div style={{marginBottom: '10px'}} className="header-3-tertiary">{label}</div>
            <div className="body-copy-tertiary">{text}</div>
        </div>
      )
    }
    return null
  }

  render() {
    let { audience_description, organization_description, description } = this.props.event;
    let video = this.getAssetByAssetTypeId(this.props.assets, AssetTypes.video);

    let videoElement = null;

    if (video.length > 0) {
      videoElement = (
        <div className="d-flex justify-content-center event-video-container">
          <video height="auto" width="100%" controls>
            <source src={video[0].src} type="video/mp4" />
          </video>
        </div>
      )
    }

    return (
      <div className="event-details-panel event-overview-tab">
        {this.renderOverview("About Event", description)}
        {videoElement}
        {this.renderOverview("About Organization", organization_description)}
        {this.renderOverview("About Audience", audience_description)}
        {this.renderDocuments()}
      </div>
    )
  }
}

export default EventOverviewTab;

import React, { Component } from 'react';
import { Routes } from 'constants/Routes';
import Img from '../assets/fill.png';
import './pagesegment.css';

class LearnMorePageSegment extends Component {
  static defaultProps = {
    orientation: '',
    imageClassName: ''
  }

  getRowClass() {
    let classNames = ["d-flex flex-column"]

    if (this.props.orientation === 'reverse') {
      classNames.push("flex-lg-row-reverse");
      classNames.push("pagesegment-row-reverse")
    } else {
      classNames.push("flex-lg-row");
      classNames.push("pagesegment-row");
    }
    return classNames.join(" ");
  }

  getBackgroundClass() {
    let { background } = this.props;
    let classNames = ["pagesegment-container"];

    if (background === "purple") {
      classNames.push("pagesegment-container-purple");
    } else if (background === 'pink') {
      classNames.push("pagesegment-container-pink");
    } else {
      classNames.push("pagesegment-container-white");
    }

    return classNames.join(" ")
  }

  getHeaderClass() {
    let classNames = ["pagesegment-header"];
    let { background } = this.props;

    if (background === "purple") {
      classNames.push("header-2-orange")
    } else {
      classNames.push("header-2-brand")
    }

    return classNames.join(" ");
  }


  getDescriptionClass() {
    let classNames = ["pagesegment-description"];
    let { background } = this.props;

    if (background === "purple") {
      classNames.push("body-copy-white")
    } else {
      classNames.push("body-copy-tertiary")
    }

    return classNames.join(" ");
  }

  render(){
    let { header, description, img, imageClassName } = this.props;

    return(
      <div className={this.getBackgroundClass()}>
        <div className="container">
          <div className={this.getRowClass()}>
            <div className="col-lg-6 col-12 d-flex flex-column align-items-start justify-content-center">
              <div className={this.getHeaderClass()}>{header}</div>
              <div className={this.getDescriptionClass()}>{description}</div>
            </div>
            <div className="col-lg-6 col-12 d-flex align-items-start justify-content-center">
              <img className={`${imageClassName} pagesegment-image`} src={img} />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default LearnMorePageSegment;

import Datetime from 'react-datetime'
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import './TextField.css';
import './Calendar.css';

const dateFormat = 'MM/DD/YYYY';

class Calendar extends Component {
  static propTypes = {
    label: PropTypes.string
  }

  static defaultProps = {
    value: null,
    error: false,
    size: "md",
    fontStyle: 'primary',
    timeFormat: true,
    placeholder: "",
    isValidDate: (current) => {
       return current.isAfter(Datetime.moment());
    }
  }

  constructor(props) {
    super(props);
    this.state = {
      value: props.value,
      active: false,
      date: null,
      focused: false
    };
  }

  handleChange(event) {
    if (this.props.handleChange != null) {
      this.props.handleChange(event.target.value);
    }
  }

  getLabel() {
    let className = "";

    if (this.props.label == null || this.props.label == "") {
      return;
    }

    if (this.props.fontStyle === "primary") {
      className = "body-copy-primary";
    } else if (this.props.fontStyle === "secondary") {
      className = "body-copy-secondary";
    } else if (this.props.fontStyle === "tertiary") {
      className = "body-copy-bold-tertiary";//"body-copy-tertiary";
    }

    return (
      <label className={className}>{this.props.label}</label>
    );
  }

  getBase() {
    let { timeFormat, value, onChange, size, placeholder, isValidDate } = this.props;
    let className = ["textfield"];

    className = (size === "full" ? className.concat(["textfield-full"]) : className);
    className = (size === "md" ? className.concat(["textfield-md"]) : className);
    className = (size === "half" ? className.concat(["textfield-half"]) : className);

    className = className.join(" ")

    return (<Datetime
      isValidDate={isValidDate}
      timeFormat={true}
      dateFormat="YYYY-MM-DD"
      placeholder={placeholder}
      value={(value ? moment(value, dateFormat) : null)}
      onChange={onChange.bind(this)}
      inputProps={{ className , readOnly: true, placeholder: `${placeholder}`}} />);
  }

  render() {
    let { size, error, errorMessage } = this.props;

    let containerClassName = [];
    containerClassName = (size === "full" ? containerClassName.concat(["textfield-container-full"]) : containerClassName);
    containerClassName = (size === "md" ? containerClassName.concat(["textfield-container-md"]) : containerClassName);
    containerClassName = (size === "half" ? containerClassName.concat(["textfield-container-half"]) : containerClassName);

    return (
      <div className={containerClassName.join(" ")}>
        {this.getLabel()}
        {this.getBase()}
        <div className={error ? "textfield-error-container" : "textfield-hidden" }>
          <div className="body-copy-error-xs-small">{errorMessage}</div>
        </div>
      </div>
    );
  }
}

export default Calendar;

/*

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DatePicker } from 'antd';
import moment from 'moment';
import './TextField.css';
import './Calendar.css';

const dateFormat = 'MM/DD/YYYY';

class Calendar extends Component {
  static propTypes = {
    label: PropTypes.string
  }

  static defaultProps = {
    value: new Date(),
    error: false,
    size: "md",
    fontStyle: 'primary',
    timeFormat: true,
    disabledDate: () => true,
    required: false,
    optional: false
  }

  constructor(props) {
    super(props);
    this.state = {
      value: props.value,
      active: false,
      date: null,
      focused: false
    };
  }

  handleChange(event) {
    if (this.props.handleChange != null) {
      this.props.handleChange(event.target.value);
    }
  }

  getLabel() {
    let className = "";

    if (this.props.label == null || this.props.label == "") {
      return;
    }

    if (this.props.fontStyle === "primary") {
      className = "body-copy-primary";
    } else if (this.props.fontStyle === "secondary") {
      className = "body-copy-secondary";
    } else if (this.props.fontStyle === "tertiary") {
      className = "body-copy-bold-tertiary";//"body-copy-tertiary";
    }

    return (
      <label className={className}>{this.props.required ? "*" : ""}{this.props.label}{this.props.optional ? " (Optional)" : ""}</label>
    );
  }

  getBase() {
    let { timeFormat, value, onChange, size } = this.props;
    let className = [];

    className = (size === "full" ? className.concat(["textfield-full"]) : className);
    className = (size === "md" ? className.concat(["textfield-md"]) : className);
    className = (size === "half" ? className.concat(["textfield-half"]) : className);
    className = className.join(" ")
    return (<DatePicker
      disabledDate={this.props.disabledDate.bind(this)}
      className={className}
      format={dateFormat}
      defaultValue={(value ? moment(value, dateFormat) : null)}
      value={(value ? moment(value, dateFormat) : null)}
      onChange={onChange.bind(this)} />);
  }

  render() {
    let { size, error, errorMessage } = this.props;

    let containerClassName = [];
    containerClassName = (size === "full" ? containerClassName.concat(["textfield-container-full"]) : containerClassName);
    containerClassName = (size === "md" ? containerClassName.concat(["textfield-container-md"]) : containerClassName);
    containerClassName = (size === "half" ? containerClassName.concat(["textfield-container-half"]) : containerClassName);

    return (
      <div className={containerClassName.join(" ")}>
        {this.getLabel()}
        {this.getBase()}
        <div>
          <div className={error ? "textfield-error-container" : "textfield-hidden" }>
            <div className="body-copy-error-xs-small">{errorMessage}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default Calendar;*/

import React, { Component } from 'react';
import LearnMorePageSegment from './components/pagesegment.component';
import Hero from './components/hero.component';
import HeroWithImage from './components/herowithimage.component';
import Footer from './components/footer.component';
import CreateImg from './assets/create.svg';
import MailImg from './assets/mail.svg';
import ContactImg from './assets/contact.svg';
import ConnectImg from './assets/connect.svg';
import DotsRightImg from './assets/dots-right.svg';
import DotsLeftImg from './assets/dots-left.svg';
import Button from 'components/new/Button';
import { Routes } from 'constants/Routes';
import './eventorganizer.css';

class EventOrganizer extends Component {
  goToCreateAnEvent() {
    //TODO: If user is logged in, go to create an event flow.
    this.props.history.push(Routes.ORGANIZER_ONBOARDING_INTRO)
  }
  /*
    Story of what is the problem that we solve and what are the benefits to solving that problem.

    Pick three problems:

    -Getting sponsorship leads
    -Get more revenue

    Trying to get their attendance up
    Webcast all of their conferences so now fewer people are attending in person.



  */
  openCalendly() {
    window.open("https://www.calendly.com/kiamber", "_blank",)
  }

  render(){
    return(
      <div>
        <HeroWithImage
          img={ContactImg}
          header="Get introduced to sponsors"
          description="Create a streamlined event sponsorships process that works for your events. Find new event partners with customization, curation, and ease."
          onCTA={this.goToCreateAnEvent.bind(this)}
          ctaLabel="Create An Event">
            <img className="eventorganizer-dots-right" src={DotsRightImg} />
            <img className="eventorganizer-dots-left" src={DotsLeftImg} />
          </HeroWithImage>
          {/*<div className="learnmore-eventorganizer-header">
            <div className="container header-2-purple">Here's How It Works</div>
          </div>*/}
          <div style={{position: 'relative', zIndex: '1'}} className="learnmore-eventorganizer-create-container pagesegment-container-white d-flex flex-column flex-lg-row">
            <div className="container d-flex justify-content-center align-items-center">
              <div className="pagesegment-row d-flex">
                <div className="col-lg-6 col-12">
                  <div className="pagesegment-header header-2-brand">Create Event Sponsorships</div>
                  <div className="pagesegment-description body-copy-tertiary">Get started quickly with an event page - Share event details, audience info, and sponsorship opportunities with sponsors. Restage event pages supports traditional and custom sponsorships packages as well as á la carte placements.</div>
                </div>
              </div>
            </div>
            <div>
              <img className='eventorganizer-create-img' src={CreateImg} />
            </div>
          </div>
        <div className="eventorganizer-band d-flex align-items-center justify-content-center flex-column">
          <div style={{textAlign: 'center'}} className="container header-2-white">Set up a new channel for getting sponsors.</div>
          <div style={{textAlign: 'center'}} className="container header-2-white">Streamline for sponsorship sales and easily connect with sponsors.</div>
        </div>
        <LearnMorePageSegment
          img={ConnectImg}
          orientation="reverse"
          header="Receive Offers"
          description="No more cold outreach to hundreds of leads for sponsorships. Using our negotiations tool, qualified sponsors come to you. Get messages from sponsors interested in working with you. Sponsors can submit requests to purchase specific packages." />
        <LearnMorePageSegment
          img={MailImg}
          header="Connect with Sponsors"
          description="See which sponsors are interested in your packages and easily respond to requests. We will introduce you to interested sponsors. Quickly set up meetings with leads to discuss the next steps."/>
        <Footer
          background="purple">
          <div className="d-flex flex-row resources-ctas">
            <div className="d-flex align-items-center"><Button style={{marginRight: '15px'}} label="CREATE EVENT" size="sm" type="primary" onClick={this.goToCreateAnEvent.bind(this)}  /></div>
            <div className="d-flex align-items-center"><Button label="BOOK A DEMO" size="sm" type="tertiary" onClick={this.openCalendly.bind(this)}  /></div>
          </div>
        </Footer>
      </div>
    )
  }
}

export default EventOrganizer;

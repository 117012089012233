import React, { Component } from 'react';
import Form from 'components/Form';
import List from 'common/list.component';
import Button from 'components/new/Button';
import Eyebrow from 'components/Eyebrow';
import Detail from 'common/Detail';
import ButtonGroup from 'components/ButtonGroup';
import Row from 'common/Row.component';
import Divider from 'common/Divider.component';
import Modal from 'components/Modal';
import Image from 'components/Image';
import Review from 'assets/review.svg';
import { Routes } from 'constants/Routes';
import { generateCurrencyFormat } from 'common/helpers/currency';
import { generateDateTimeFormat } from 'common/helpers/date';
import './eventreview.css';

const KEYS = {
  "Age Range": "Age Ranges",
  "Location": "Locations",
  "Occupation": "Occupations",
  "Industry": "Industries",
  "Job Function": "Job Function",
  "Job Seniority": "Seniority Level"
}

class EventReview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalVisible: false
    }
  }

  componentDidMount() {
    if (!this.props.isEventDetailsPageValid || !this.props.isEventAudiencePageValid || !this.props.isEventPackagesPageValid) {
      this.props.history.push(Routes.EVENT_DETAILS);
    }
  }

  //Same code in event.screen.js
  setPackagesUI(packages) {
    if (packages.length === 0) {
      return (
        <div>
          <div className="body-copy-tertiary">No packages</div>
        </div>
      )
    }

    return (
      <div className="eventpackages-sidepanel-container eventpage-body-packages">
        {
          packages.map((item, index) => {
            let { name, cost, limit, placements, description } = item;
            console.log(placements)
            return (
              <div key={index}>
                <div className="d-flex flex-column">
                  <Row style={{marginBottom: '15px'}} label="Package Name" value={name} />
                  <Row label="Limit" value={limit} />
                  <Row label="Price" value={generateCurrencyFormat(cost)} />
                  <Row label="Package Description" value={description || ""} />
                  <Row label="Placements" value={placements.join(", ")} />
                </div>
              </div>
            )
          })
        }
      </div>
    )
  }

  setAudienceProfile(audienceAttributes) {
    let buffer = [];
    let items = [];
    let index = 0;
    let label = "";
    let map = new Map();
    let newAudience = new Map();

    audienceAttributes.map((item) => {
      if (!newAudience.has(item.type)) {
        newAudience.set(item.type, []);
      }
      newAudience.get(item.type).push(item);
    })

    newAudience.forEach((value, key) => {
      items = value.map((attribute) => attribute.value);
      map.set(key, items)
    })

    map.forEach((value, key) => {
      label = key;

      if (KEYS[key]) {
          label = KEYS[key];
      }

      buffer.push(<Row key={key} label={key} value={value.join(", ")} />)
    })

    return buffer
  }

  goToRoute(route) {
      this.props.history.push(route);
  }

  onClickSubmit() {
    this.props.publish(this.props.event.event.id);
    //this.goToRoute(Routes.ORGANIZER_HOME);
    this.setState({
      modalVisible: true
    })
  }

  setModalVisible(visible) {
    this.setState({
      modalVisible: visible
    })
  }

  closeModal() {
    this.props.history.push(Routes.ORGANIZER_HOME);
    this.setState({modalVisible: false});
  }

  render() {
    let {
      modalVisible
    } = this.state;

    let { event, attributes, packages } = this.props;
    let { startdate, enddate, name, description, location } = event.event;

    console.log()
    return (
      <div>
        <div>
          <Detail
            justification="start"
            style={{marginBottom: '25px', textAlign: 'Start'}}
            description="Sponsors with smiliar audience profiles wil be notified of your event."
            header="Review"
            subHeader="Step 4 of 4" />
          <Divider type="brand secondary" />
          <div className="eventreview-details">
            <div className="d-flex flex-md-row flex-column justify-content-between review-row">
              <div>
                <label className="body-copy-bold-orange">Details</label>
                <div className="body-copy-tertiary">
                  <Row label="Name" value={name}/>
                  <Row label="Location" value={location.formatted_address}/>
                  <Row label="Description" value={description}/>
                  <Row label="Start Date:" value={generateDateTimeFormat(new Date(startdate))}/>
                  <Row label="End Date" value= {generateDateTimeFormat(new Date(enddate))} />
                </div>
              </div>
              <div>
                <Button label="Edit" type="quarternary" size="xs" onClick={this.goToRoute.bind(this, Routes.EVENT_DETAILS)} />
              </div>
            </div>
          </div>
          <Divider type="brand secondary" />
          <div className="eventreview-details">
            <div className="d-flex flex-md-row flex-column justify-content-between review-row">
              <div>
                <label className="body-copy-bold-orange">Audience</label>
                {this.setAudienceProfile(attributes)}
              </div>
              <div>
                <Button label="Edit" size="xs" type="quarternary" onClick={this.goToRoute.bind(this, Routes.EVENT_AUDIENCE)} />
              </div>
            </div>
          </div>
          <Divider type="brand secondary" />
          <div className="eventreview-details">
            <div className="d-flex flex-md-row flex-column justify-content-between review-row">
              <div>
                <label className="body-copy-bold-orange">Packages</label>
                {this.setPackagesUI(packages)}
              </div>
              <div>
                <Button label="Edit" size="xs" type="quarternary" onClick={this.goToRoute.bind(this, Routes.EVENT_PACKAGES)} />
              </div>
            </div>
          </div>
        </div>
        <Divider type="brand secondary" />
        <div style={{marginTop: '50px'}}>
          <div style={{float: 'right', marginBottom: '10px'}} className="body-copy-tertiary">Step 4 of 4</div>
          <ButtonGroup
            labelPrimary="Submit"
            labelSecondary="Back"
            onClickPrimary={this.onClickSubmit.bind(this)}
            onClickSecondary={this.goToRoute.bind(this, Routes.EVENT_PACKAGES)} />
        </div>
        <Modal
          onClickClose={this.closeModal.bind(this)}
          visible={modalVisible}>
          <div className="d-flex align-items-center flex-column" style={{padding: '24px', backgroundColor: 'white'}}>
            <Image className="eventreview-image" src={Review} />
            <Detail style={{marginBottom: '50px'}}header="Your event is under review" subHeader="Please allow up to 2 business days for a response from us." />
            <Button size="md" label="MY EVENTS" type="tertiary" onClick={this.closeModal.bind(this)}/>
          </div>
        </Modal>
      </div>
    );
  }
}

export default EventReview;

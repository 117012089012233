export const ActionTypes = {
    GET_BLOG_POSTS: 'GET_BLOG_POSTS',
    GET_BLOG_POSTS_SUCCESS: 'GET_BLOG_POSTS_SUCCESS',
    GET_BLOG_POSTS_FAILURE: 'GET_BLOG_POSTS_FAILURE',
    GET_BLOG_POST: 'GET_BLOG_POSTS',
    GET_BLOG_POST_SUCCESS: 'GET_BLOG_POST_SUCCESS',
    GET_BLOG_POST_FAILURE: 'GET_BLOG_POST_FAILURE'
};

export const getBlogPosts = () => {
  return {
    type: ActionTypes.GET_BLOG_POSTS,
  }
};

export const getBlogPost = (id) => {
  return {
    type: ActionTypes.GET_BLOG_POST,
    payload: {
      id
    }
  }
};

import { ActionTypes } from './sponsorshiprequests.actions';

const initialState = {
    errorMessage: "",
    requests: []
};

const SponsorshipRequestsReducer = (state = initialState, action) => {
    switch (action.type) {
      case ActionTypes.POST_SPONSORSHIP_REQUEST_SUCCESS:
        return { ...state};
      case ActionTypes.POST_SPONSORSHIP_REQUEST_FAILURE:
        return { ...state};
      case ActionTypes.POST_SPONSORSHIP_REQUESTS_SUCCESS:
        return { ...state};
      case ActionTypes.POST_SPONSORSHIP_REQUESTS_FAILURE:
          return { ...state};
      default:
          return state;
    }
};

export default SponsorshipRequestsReducer;

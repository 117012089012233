import { put, all, takeLatest } from 'redux-saga/effects';
import { ActionTypes } from './tap.actions';

export const addAttribute = function* (action) {
  const { payload: { attribute } } = action;
  try {
    yield put({ type: ActionTypes.ADD_ATTRIBUTE_SUCCESS, attribute });
  } catch (error) {}
}

export const removeAttribute = function* (action) {
  const { payload: { attribute } } = action;

  try {
    yield put({ type: ActionTypes.REMOVE_ATTRIBUTE_SUCCESS, attribute });
  } catch (error) {}
}

function* tap() {
  yield all([
    takeLatest(ActionTypes.ADD_ATTRIBUTE, addAttribute),
    takeLatest(ActionTypes.REMOVE_ATTRIBUTE, removeAttribute)
  ]);
}

export default tap;

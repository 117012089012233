import React, { Component } from 'react';
import AudienceProfileAttributes from 'common/audienceprofile-attributes.component';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import './header.css';

class Header extends Component {
  render() {
    return (
      <div className="search-header">
        <AudienceProfileAttributes />
      </div>
    );
  }
}

export default Header;

import React, { Component } from 'react';
import Modal from 'components/Modal';
import AudienceProfile from 'common/audienceprofile.component';
import Detail from 'common/Detail';
import AudienceProfileAttributes from 'common/audienceprofile-attributes.component';
import ButtonGroup from 'components/ButtonGroup';
import TextField from 'components/TextField';
import FormGroup from 'components/FormGroup';
import Select from 'components/Select';
import Button from 'components/new/Button';
import Divider from 'common/Divider.component';
import Tab from 'common/Tab';
import Close from 'assets/icons/Close';
import TabGroup from 'common/TabGroup';
import { generatePercentFormat } from 'common/helpers/percent';
import { generateNumberFormat } from 'common/helpers/number';
import { isNumeric, isFilled } from 'common/helpers/validators';
import './editeventaudiencemodal.css';

const getYears = () => {
  let years = [];
  for (let i = 2019; i > 1979; i--) {
    years.push({value: i, label: i});
  }
  return years;
}

class EditEventAudienceModal extends Component {
  constructor(props) {
    super(props)
    console.log(props)
    let female = 0, non_binary = 0, male = 0;

    if (props.eventGender !== null) {
      female = props.eventGender.female;
      non_binary = props.eventGender.non_binary;
      male = props.eventGender.male;
    }

    let eventAttendances = [];
    if (props.eventAttendances !== null) {
      eventAttendances = props.eventAttendances;
    }

    this.state = {
      attendeeIndex: 0,
      eventAttendances,
      expected_attendees: {
        value: props.expected_attendees || "",
        error: false
      },
      year: {
        value: "",
        error: false,
        errorMessage: "Year cannot be empty."
      },
      attendees: {
        value: "",
        error: false
      },
      non_binary: {
        value: non_binary,
        quantity: non_binary,
        error: false
      },
      female: {
        value: female,
        quantity: female,
        error: false
      },
      male: {
        value: male,
        quantity: male,
        error: false
      },
      total: {
        errorMessage: "Total of all percentages cannot exceed 100%. Please adjust.",
        value: (male + female + non_binary)/100,
        quantity: (male + female + non_binary)/100,
        error: false
      },
      tabs: {
        general: {
          active: true,
          label: "General"
        },
        audienceprofile: {
          active: false,
          label: "Audience Profile"
        }
      }
    }
  }

  activate(current) {
    let { tabs } = this.state;
    let update = {...tabs};

    for (let tab in tabs) {
      if (current === tab) {
        update[tab].active = true;
      } else {
        update[tab].active = false;
      }
    }

    this.setState({
      tabs: {...update}
    });
  }

  reset() {
    this.setState({
      attendeeIndex: this.state.attendeeIndex + 1,
      year: {
        value: "",
        error: false,
        errorMessage: "Year cannot be empty."
      },
      attendees: {
        value: "",
        error: false
      },
    });
  }

  getTabs() {
    let buffer = [];
    let index = 0;

    for (let tab in this.state.tabs) {

      let { label, active, hidden } = this.state.tabs[tab];

      buffer.push(
        <Tab
          type="bold"
          isCompleteVisible={false}
          key={index}
          hidden={hidden}
          label={label}
          selected={active}
          onClick={this.activate.bind(this, tab)} />
        )
      index++;
    }
    return buffer;
  }

  validate() {
    let fields = new Set();
    if (this.state.total.error) {
      return;
    }
    /*fields.add(this.isFieldValid(isNumeric, "year"));
    fields.add(this.isFieldValid(isNumeric, "attendees"));*/

    return !fields.has(false);
  }

  isFieldValid(validator, key) {
    console.log("this.state[key].value", this.state[key].value)
    let isValid = validator(this.state[key].value);

    this.setState({
      [key]: {
        value: this.state[key].value,
        error: !isValid,
        errorMessage: this.state.errorMessage
      }
    });

    return isValid;
  }

  setAudienceProfileTab() {
    if (!this.state.tabs.audienceprofile.active) return (null);
    console.log("sadf", this.props)
    return (
      <div>
        <AudienceProfileAttributes style={{marginBottom: '25px'}}/>
        <AudienceProfile audience_profile_name={this.props.audience_profile_name} />
      </div>
    )
  }

  onChange(event) {
    console.log(event)
    this.setState({ [event.target.name]: {
      value: event.target.value,
      error: this.state[event.target.name].error
      }
    })
  }

  onChangePercentage(event) {
    let { value } = event.target
    let quantity = 0;

    let OK = /[0-9+$]/.test(value)

    if (!OK && value.length > 0) return;

    if (value.length > 0) {
      value = parseInt(value);
      quantity = value;
    } else {
      value = "";
      quantity = 0;
    }



    this.setState({ [event.target.name]: {
      value,
      quantity,
      error: this.state[event.target.name].error
      }
    })
  }

  calculateTotal() {
    return parseInt(this.state.non_binary.quantity) +  parseInt(this.state.female.quantity) +  parseInt(this.state.male.quantity);
  }

  onBlurUpdateTotal(event) {
    let total = this.calculateTotal()/100;
    let error = false;
    if (total > 1) {
      error = true;
    }

    this.setState({
      total: {
        ...this.state.total,
        value: total,
        error
      }
    })
  }

  onChangeSelect(id, value) {
    this.setState({
      year: {
        value: id,
        error: false,
        errorMessage: "Year cannot be empty."
      }
    },()=>{
      console.log(this.state)
    })
  }

  validateAddYear() {
    let fields = new Set();

    fields.add(this.isFieldValid(isFilled, "year"));
    fields.add(this.isFieldValid(isNumeric, "attendees"));

    return !fields.has(false);
  }

  onClickAddYear() {
    console.log(this.state)
    if (!this.validateAddYear()) return;

    let check = this.state.eventAttendances.filter((item) => item.year === this.state.year.value);
    if (check.length > 0) {
      this.setState({
        year: {
          ...this.state.year,
          errorMessage: "Year has already been added.",
          error: true
        }
      });
      return;
    };

    let eventAttendances = [
      ...this.state.eventAttendances,
      {
        attendeeIndex: this.state.attendeeIndex,
        year: this.state.year.value,
        attendance: this.state.attendees.value
      }
    ];

    this.setState({
      eventAttendances
    })
  }

  onClickRemoveYear(index) {
    let eventAttendances = this.state.eventAttendances.filter((item) => item.attendeeIndex !== index);
    this.setState({
      eventAttendances
    })
  }

  getAttendeesUI() {
    let { eventAttendances }  = this.state;
    if (eventAttendances.length === 0) {
      return (
        <div className="body-copy-tertiary justify-content-between d-flex align-items-center" style={{paddingLeft: '7px', paddingRight: '7px', paddingTop: '10px', paddingBottom: '10px'}}>
          No placements added
        </div>
      )
    } else {
      return (
          <div className="eventpackages-placements">
            {
              eventAttendances.sort((a, b) => (a.year < b.year) ? 1 : -1).map((item, index)=>{
                item.attendeeIndex = index;
                return(
                  <div key={index} className="body-copy-tertiary justify-content-between d-flex align-items-center" style={{paddingLeft: '7px', paddingRight: '7px', paddingTop: '10px', paddingBottom: '10px'}}>
                    <div className="col-4">{item.year}</div>
                    <div style={{textAlign: 'center'}} className="col-4">{generateNumberFormat(item.attendance)}</div>
                    <div className="d-flex col-4 justify-content-end"><Close onClick={this.onClickRemoveYear.bind(this, item.attendeeIndex)} /></div>
                  </div>
                )
              })
            }
          </div>
      )
    }
  }

  setGeneralTab() {
    if (!this.state.tabs.general.active) return (null);
    let { expected_attendees, year, attendees, male, female, non_binary, total, category } = this.state;

    return (
      <div className="edit-audience-modal-general-tab">
        <TextField
          fontStyle="tertiary"
          name="expected_attendees"
          placeholder="Enter the amount of expected attendees"
          value={expected_attendees.value}
          errorMessage="Enter the amount of expected attendees."
          error={expected_attendees.error}
          onChange={this.onChange.bind(this)}
          label="Expected attendees"
          size="md" />
        <div className="edit-audience-modal-general-tab">
          <div>
            <Detail style={{marginBottom: '25px'}} type="tertiary" justification="start" header="Previous Years Attendance" subHeader="Enter details about your previous years attendee numbers. The stats will be featured in your audience profile for sponsors to see." />
            <FormGroup style={{marginBottom: '25px'}}>
              <Select
                label="Event Year"
                error={year.error}
                errorMessage={year.errorMessage}
                onChange={this.onChangeSelect.bind(this)}
                placeholder="Select the event year"
                options={getYears()} />
              <TextField
                fontStyle="tertiary"
                name="attendees"
                placeholder="Enter the number of attendees"
                value={attendees.value}
                errorMessage="Enter the number of attendees."
                error={attendees.error}
                onChange={this.onChange.bind(this)}
                label="Number of attendees"
                size="full" />
              <div className="edit-attendee-button d-flex align-items-end">
                <Button
                  size="full"
                  label="Add"
                  type="tertiary"
                  onClick={this.onClickAddYear.bind(this)} />
                </div>
              </FormGroup>
              <div style={{marginBottom: '25px', padding: '0px'}} className="col-lg-6 col-12">
                <div className="d-flex justify-content-between body-copy-bold-tertiary" style={{backgroundColor: '#E3DBE6', paddingLeft: '7px', paddingRight: '7px', paddingTop: '4px', paddingBottom: '4px'}}>
                  <div className="col-4">Year</div>
                  <div style={{textAlign: 'center'}} className="col-4">Attendees</div>
                  <div className="col-4"></div>
                </div>
                {this.getAttendeesUI()}
              </div>
            </div>
          </div>
          <Divider style={{marginTop: '25px', marginBottom: '25px'}} type="brand" />
          <div>
            <Detail style={{marginBottom: '25px'}} type="tertiary" justification="start" header="Gender Breakdown" subHeader="Enter details about the gender diversity of your event. This could be helpful to sponsors looking to reach a specific group." />
            <FormGroup>
              <TextField
                fontStyle="tertiary"
                name="female"
                placeholder="% of female attendees"
                value={female.value}
                errorMessage="Enter the percentage of female attendees."
                error={female.error}
                onChange={this.onChangePercentage.bind(this)}
                onBlur={this.onBlurUpdateTotal.bind(this)}
                label="Female"
                size="full" />
              <TextField
                fontStyle="tertiary"
                name="male"
                placeholder="% of male attendees"
                value={male.value}
                errorMessage="Enter the number of attendees."
                error={male.error}
                onChange={this.onChangePercentage.bind(this)}
                onBlur={this.onBlurUpdateTotal.bind(this)}
                label="Male"
                size="full" />
              <TextField
                fontStyle="tertiary"
                name="non_binary"
                placeholder="% of non-binary attendees"
                value={non_binary.value}
                errorMessage="Enter the percentage of non-binary attendees."
                error={non_binary.error}
                onChange={this.onChangePercentage.bind(this)}
                onBlur={this.onBlurUpdateTotal.bind(this)}
                label="Non-binary"
                size="full" />
              <div className="d-flex align-items-center">
                <Detail justification="start" header="Total" subHeader={generatePercentFormat(total.value)} type="body-copy-bold-tertiary"/>
              </div>
            </FormGroup>
            {total.error ? <div className="body-copy-error-wrapper d-flex justify-content-start"><span style={{position: 'absolute'}} className="body-copy-error-xs-small body-cooy-error-container">{total.errorMessage}</span></div> : null}
          </div>
      </div>
    )
  }

  onClickSaveEvent() {
    if (!this.validate()) return;
    let { female, male, non_binary, eventAttendances } = this.state;
    eventAttendances  = eventAttendances.map((item)=> {
      delete item.attendeeIndex;
      return item;
    });
    let event = {
      ...this.props.event,
      expected_attendees: this.state.expected_attendees.value
    };
    this.props.putEvent(event);
    this.props.putEventGenders({
      event_id: event.id,
      female: female.value,
      male: male.value,
      non_binary: non_binary.value
    })
    this.props.putEventAttendances({
      event_id: event.id,
      event_attendances: eventAttendances
    });
    this.props.onClickSaveEventAudience();
    this.reset();
  }

  render() {
    /*size="large"*/
    return (
      <Modal
        appendFooter={() => {
          return(
            <ButtonGroup
              labelPrimary="SAVE"
              labelSecondary="CANCEL"
              onClickPrimary={this.onClickSaveEvent.bind(this)}
              onClickSecondary={this.props.onClickClose.bind(this)} />
          )
        }}

        onClickClose={this.props.onClickClose.bind(this)}
        visible={this.props.isEditModalOpen}>
          <div className="edit-modal-wrapper d-flex justify-content-center">
            <div className="edit-modal-container">
              <Detail justification="start" style={{marginBottom: '25px', textAlign: 'Start'}} header="Audience Info" subHeader="Update your event audience information." />
              <div>
                <TabGroup>
                  {this.getTabs()}
                </TabGroup>
              </div>
              <div>
                { this.setAudienceProfileTab() }
                { this.setGeneralTab() }
              </div>
            </div>
          </div>
      </Modal>
    );
  }
}

export default EditEventAudienceModal;

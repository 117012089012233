import { put, all, takeLatest } from 'redux-saga/effects';
import { ActionTypes } from './firebase.actions';

export const setFirebase = function* (action) {
  const { payload: { firebase } } = action;
  try {
    yield put({ type: ActionTypes.SET_FIREBASE_SUCCESS, data: firebase });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: ActionTypes.SET_FIREBASE_FAILURE, error });
  }
}

function* firebase() {
  yield all([
    takeLatest(ActionTypes.SET_FIREBASE, setFirebase)
  ]);
}

export default firebase;

export const ActionTypes = {
    NEWSLETTER_SIGNUP: 'NEWSLETTER_SIGNUP',
    NEWSLETTER_SIGNUP_SUCCESS: 'NEWSLETTER_SIGNUP_SUCCESS',
    NEWSLETTER_SIGNUP_FAILURE: 'NEWSLETTER_SIGNUP_FAILURE',

    SIGNUP_RESET: 'SIGNUP_RESET',

    DEMO_SIGNUP: 'DEMO_SIGNUP',
    DEMO_SIGNUP_SUCCESS: 'DEMO_SIGNUP_SUCCESS',
    DEMO_SIGNUP_FAILURE: 'DEMO_SIGNUP_FAILURE',
    SET_EMAIL: 'SET_EMAIL'
};

export const signUpNewsletter = (data) => ({
    type: ActionTypes.NEWSLETTER_SIGNUP,
    payload: {
      data
    }
});

export const signUpDemo = (email) => ({
    type: ActionTypes.DEMO_SIGNUP,
    payload: {
      email
    }
});

export const reset = () => ({
    type: ActionTypes.SIGNUP_RESET
})

export const setEmail = (email) => ({
    type: ActionTypes.SET_EMAIL,
    payload: {
      email
    }
})

import { put, call, all, takeLatest } from 'redux-saga/effects';
import { ActionTypes } from './eventattendances.actions';
import { putRequest } from '../Api'
const basePath = "event-attendances";

export const putEventAttendances = function* (action) {
  const { payload: { data } } = action;
  try {
    const response = yield call(putRequest, basePath, data);
    yield put({ type: ActionTypes.PUT_EVENT_ATTENDANCES_SUCCESS, eventattendances: response.data });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: ActionTypes.PUT_EVENT_ATTENDANCES_FAILURE, error });
  }
}

function* eventattendances() {
  yield all([
    takeLatest(ActionTypes.PUT_EVENT_ATTENDANCES, putEventAttendances)
  ]);
}

export default eventattendances;

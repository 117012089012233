import { ActionTypes } from './portfolios.actions';

const initialState = {
    errorMessage: "",
    portfolios: [],
    currentPortfolio: null,
    isPortfolioSponsorsetPageValid: false,
    isPortfolioObjectivePageValid: false,
    isPortfolioAudiencePageValid: false,
    isPortfolioPreferencesPageValid: false,
    lower_bound: null,
    upper_bound: null,
    portfolio_name: ""
};

const PortfoliosReducer = (state = initialState, action) => {
    switch (action.type) {
      case ActionTypes.GET_PORTFOLIO_SUCCESS:
        return { ...state, currentPortfolio: action.data};
      case ActionTypes.SET_PORTFOLIO_NAME:
        return { ...state, portfolio_name: action.payload.name};
      case ActionTypes.GET_PORTFOLIOS_SUCCESS:
        return { ...state, portfolios: action.data};
      case ActionTypes.POST_PORTFOLIO_SUCCESS:
        return { ...state, currentPortfolio: action.data };
      case ActionTypes.SET_CURRENT_PORTFOLIO_SUCCESS:
        return { ...state, currentPortfolio: action.data };
      case ActionTypes.GET_PORTFOLIO_SUCCESS:
        return { ...state, currentPortfolio: {...action.data}};
      case ActionTypes.SET_PORTFOLIO_SPONSORSET_PAGE_STATE:
          return { ...state,isPortfolioSponsorsetPageValid: action.payload.isValid };
      case ActionTypes.SET_PORTFOLIO_OBJECTIVE_PAGE_STATE:
          return { ...state, isPortfolioObjectivePageValid: action.payload.isValid };
      case ActionTypes.SET_PORTFOLIO_AUDIENCE_PAGE_STATE:
          return { ...state, isPortfolioAudiencePageValid: action.payload.isValid };
      case ActionTypes.SET_PORTFOLIO_PREFERENCES_PAGE_STATE:
          return { ...state, isPortfolioPreferencesPageValid: action.payload.isValid };
      case ActionTypes.SET_BUDGET:
          return { ...state, lower_bound: action.payload.lowerBound, upper_bound: action.payload.upperBound };
      default:
          return state;
    }
};

export default PortfoliosReducer;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import EventPoster from 'assets/placeholder.jpg'
import './eventtile-prototype.css';

class EventTilePrototype extends Component {
  static propTypes = {
    label: PropTypes.string
  }

  static defaultProps = {

  }

  onClick() {
    if (this.props.onClick !== null) {
      this.props.onClick();
    }
  }

  render() {
    let { name, formatted_address, startdate, enddate, poster } = this.props;
    let style = {
      backgroundImage:  `url(${EventPoster})`
    }

    if (poster) {
      style = {
        backgroundImage:  `url(${poster.url})`
      }
    }

    return (
      <div onClick={this.onClick.bind(this)} className="d-flex flex-column eventtile-prototype-container flex-sm-row">
        <div className="col-sm-4 col-md-4  eventtile-prototype-poster-container">
          <div className="eventtile-prototype-poster" style={style} />
        </div>
        <div className="eventtile-prototype-footer col-sm-8 col-md-8 flex-column justify-content-between d-flex">
          <div>
            <div className="body-copy-tertiary">{name}</div>
            <div className="body-copy-tertiary-small">{formatted_address}</div>
            <div className="d-flex">
              <div className="body-copy-tertiary-small">{startdate}</div>
              <div className="body-copy-tertiary-small">&nbsp; to &nbsp;</div>
              <div className="body-copy-tertiary-small">{enddate}</div>
            </div>
          </div>
          <div className="d-flex">
            <div className="body-copy-tertiary-small">Score</div>
          </div>
        </div>
      </div>
    );
  }
}

export default EventTilePrototype;

import { ActionTypes } from './eventassets.actions';

const initialState = {
    errorMessage: "",
    assets: [],
    assetsAdded: [],
    assetsRemoved: []
};

const EventAssetsReducer = (state = initialState, action) => {
    switch (action.type) {
      case ActionTypes.POST_EVENT_ASSETS_SUCCESS:

        return { ...state, assetsAdded: [...state.assetsAdded, action.response.name], assetsRemoved: [] };
      case ActionTypes.ADD_EVENT_ASSET:
        let contains = state.assets.filter((asset) => action.payload.asset.get("filename") === asset.get("filename"));
        let assets = [];

        if (contains.length > 0) {
          assets = [...state.assets];
        } else {
          assets = [...state.assets, action.payload.asset]
        }
        return {
          ...state,
          assets
        };
      case ActionTypes.REMOVE_EVENT_ASSET:
        let newAssets = state.assets.filter((asset) => !(action.payload.asset.get("filename") === asset.get("filename")));
        return {
          ...state,
          assets: [...newAssets]
        };
      case ActionTypes.DELETE_EVENT_ASSET_SUCCESS:
        return {
          ...state,
          assetsRemoved: [...state.assetsRemoved, action.id],
          assetsAdded: []
        };
      default:
          return state;
    }
};

export default EventAssetsReducer;

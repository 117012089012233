import { put, call, all, takeLatest } from 'redux-saga/effects';
import { ActionTypes } from './eventaudienceprofile.actions';
import { post,putRequest } from '../Api'

const basePath = "event-audience-profiles"

export const postEventAudienceProfile = function* (action) {
  const { payload: { event_audience_profile } } = action;

  try {
    const response = yield call(post, basePath, event_audience_profile);
    const data = yield response.json();
    yield put({ type: ActionTypes.POST_EVENT_AUDIENCE_PROFILE_SUCCESS,  event_audience_profile: data });
  } catch (error) {
    yield put({ type: ActionTypes.POST_EVENT_AUDIENCE_PROFILE_FAILURE, error });
  }
}

export const putEventAudienceProfile = function* (action) {
  const { payload: { event_audience_profile } } = action;

  try {
    const response = yield call(putRequest, basePath, event_audience_profile);
    yield put({ type: ActionTypes.PUT_EVENT_AUDIENCE_PROFILE_SUCCESS, event_audience_profile: response.data });
  } catch (error) {
    yield put({ type: ActionTypes.PUT_EVENT_AUDIENCE_PROFILE_FAILURE, error });
  }
}

function* eventaudienceprofile() {
  yield all([
    takeLatest(ActionTypes.POST_EVENT_AUDIENCE_PROFILE, postEventAudienceProfile),
    takeLatest(ActionTypes.PUT_EVENT_AUDIENCE_PROFILE, putEventAudienceProfile)
  ]);
}

export default eventaudienceprofile;

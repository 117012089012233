import { ActionTypes } from './portfolioobjectives.actions';

const initialState = {
    objective: ""
};

const PortfolioObjectivesReducer = (state = initialState, action) => {
    switch (action.type) {
      case ActionTypes.SET_PORTFOLIO_OBJECTIVE:
        let { objective } = action.payload;

        return { ...state, objective };
      default:
        return state;
    }
};

export default PortfolioObjectivesReducer;

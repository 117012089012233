import React, { Component } from 'react';
import Autocomplete from 'components/Autocomplete';
import { getSuggestions, setSuggestions } from 'services/places/places.actions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import './Places.css';

class Places extends Component {
  static defaultProps = {
    fontStyle: "primary",
    placeholder: "",
    required: false,
    optional: false,
    isAddressAllowed: true,
    isGeopoliticalRegionAllowed: true,
    name: ""
  }

  onChangeAutocomplete(search) {
    let data = {
      query: search
    };

    this.props.getSuggestions(data, this.props.isAddressAllowed, this.props.isGeopoliticalRegionAllowed);
  }

  onMouseDownAutocompleteOption(value) {

    if (this.props.onOptionSelection !== null) {
      let selected = this.props.suggestions.filter((result)=>result.description === value);
      this.props.onOptionSelection(selected);
    }

    this.props.setSuggestions([]);
  }

  onBlurAutocomplete() {
    this.props.setSuggestions([]);
  }

  render() {
    let {
      placeholder,
      suggestions,
      fontStyle,
      label,
      name,
      required,
      optional,
      error,
      errorMessage,
      validator,
      value } = this.props;
    let results = suggestions.map((result) => result.description);

    return (
      <Autocomplete
        name={name}
        leftRounded={false}
        fontStyle={fontStyle}
        error={error}
        defaultVaule={value}
        value={value}
        resetOnSelect={true}
        errorMessage={errorMessage}
        validator={validator}
        onChange={this.onChangeAutocomplete.bind(this)}
        results={results}
        placeholder={placeholder}
        onMouseDownOption={this.onMouseDownAutocompleteOption.bind(this)}
        onBlur={this.onBlurAutocomplete.bind(this)}
        label={label}
        required={required}
        optional={optional}
        size="full"/>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    suggestions: state.places.suggestions
  };
};

const mapDispatchToProps = dispatcher => (
    bindActionCreators({
        getSuggestions,
        setSuggestions
    }, dispatcher)
);

export default connect(mapStateToProps, mapDispatchToProps)(Places);

export const ActionTypes = {
    POST_EVENT_PACKAGE_OFFER: 'POST_EVENT_PACKAGE_OFFER',
    POST_EVENT_PACKAGE_OFFER_SUCCESS: 'POST_EVENT_PACKAGE_OFFER_SUCCESS',
    POST_EVENT_PACKAGE_OFFER_FAILURE: 'POST_EVENT_PACKAGE_OFFER_FAILURE'
  }

export const postEventPackageOffer = (event_package_offer) => ({
    type: ActionTypes.POST_EVENT_PACKAGE_OFFER,
    payload: {
      event_package_offer
    }
});

export const ActionTypes = {
    SHARE_EVENT: 'SHARE_EVENT',
    SHARE_EVENT_SUCCESS: 'SHARE_EVENT_SUCCESS',
    SHARE_EVENT_FAILURE: 'SHARE_EVENT_FAILURE',
};

export const shareEvent = (data) => ({
    type: ActionTypes.SHARE_EVENT,
    payload: {
      data
    }
});

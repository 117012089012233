import React, { Component } from 'react';
import Signup from '../components/Signup';
import Button from 'components/new/Button';
import logo from './assets/logo.svg';
import sidedots from './assets/dots-right.svg';
import profile from './assets/profile.svg';
import './Hero.css';

class Hero extends Component {
  openCalendly() {
    window.open("https://www.calendly.com/kiamber", "_blank",)
  }

  render() {
    return (
      <div className="hero-container">
        <div className="container hero-content">
          <div className="hero-main d-flex align-items-center justify-content-center">
            <div className="d-flex flex-lg-row flex-column">
              <div className="col-lg-6 col-12 d-flex flex-column justify-content-center hero-cta-container">
                {/*<img alt="" className="hero-img" src={logo} />*/}
                <div className="hero-text">
                  <div className="header-2-secondary hero-label-position">Create event sponsorships and connect with sponsors.</div>
                  <div className="header-3-white">
                    Restage is a new platform for selling event sponsorships. We help event organizers and sponsors make new connections and add capacity to sponsorship teams.
                  </div>
                </div>
                <div>
                  <Button label="BOOK A DEMO" size="sm" type="tertiary" onClick={this.openCalendly.bind(this)}  />
                </div>
                {/*<div>
                  <Signup  history={this.props.history} />
                </div>*/}
              </div>
              <div className="col-lg-6 col-12 hero-rocket-container">
                <img className="hero-profile" src={profile} alt="Person sitting on rock looking at three displays, each display with a photo and descriptions."/>
              </div>
            </div>
          </div>
        </div>
        <img alt="" className="hero-sidedots" src={sidedots} />
      </div>
    );
  }
}

export default Hero;

export const ActionTypes = {
    POST_SPONSORSHIP: 'POST_SPONSORSHIP',
    POST_SPONSORSHIP_SUCCESS: 'POST_SPONSORSHIP_SUCCESS',
    POST_SPONSORSHIP_FAILURE: 'POST_SPONSORSHIP_FAILURE'
};

export const postSponsorship = (data) => {
  return {
    type: ActionTypes.POST_SPONSORSHIP,
    payload: {
      data
    }
  }
};

export const ActionTypes = {
    SIGNUP: 'SIGNUP',
    SIGNUP_SUCCESS: 'SIGNUP_SUCCESS',
    SIGNUP_FAILURE: 'SIGNUP_FAILURE',
    CREATE_USER: 'CREATE_USER',
    CREATE_USER_SUCCESS: 'CREATE_USER_SUCCESS',
    CREATE_USER_FAILURE: 'CREATE_USER_FAILURE',
    CREATE_USER_ACCOUNT: 'CREATE_USER_ACCOUNT',
    CREATE_USER_ACCOUNT_SUCCESS: 'CREATE_USER_ACCOUNT_SUCCESS',
    CREATE_USER_ACCOUNT_FAILURE: 'CREATE_USER_ACCOUNT_FAILURE',
};

export const signUp = (user) => {
  return {
    type: ActionTypes.SIGNUP,
    payload: {
      user
    }
  }
};

export const createAccount = (user) => {
  return {
    type: ActionTypes.CREATE_USER_ACCOUNT,
    payload: {
      user
    }
  }
};

export const createUser = (user) => {
  return {
    type: ActionTypes.CREATE_USER,
    payload: {
      user
    }
  }
};

import { put, call, all, takeLatest } from 'redux-saga/effects';
import { ActionTypes } from './sponsornewslettersignup.actions';
import { post } from '../Api'

const basePath = "signup"

export const signUpNewsletter = function* (action) {
  let { data } = action.payload;
  try {
    const response = yield call(post, basePath + "/demo", data);
    if (response.status >= 200 && response.status < 300) {
      const json = yield response.json();
      yield put({ type: ActionTypes.NEWSLETTER_SIGNUP_SUCCESS, data: json });
    } else {
      throw response;
    }
  } catch (error) {
    // dispatch a failure action to the store with the error
    const json  = yield error.json();
    yield put({ type: ActionTypes.NEWSLETTER_SIGNUP_FAILURE, error: true, errorMessage: json.message });
  }
}

export const signUpDemo = function* (action) {
  let { email } = action.payload;
  try {
    const response = yield call(post, basePath + "/demo", { email });
    if (response.status >= 200 && response.status < 300) {
      const json = yield response.json();
      yield put({ type: ActionTypes.DEMO_SIGNUP_SUCCESS, data: json });
    } else {
      throw response;
    }
  } catch (error) {
    const json  = yield error.json();
    yield put({ type: ActionTypes.DEMO_SIGNUP_FAILURE, error: true, errorMessage: json.message });
  }
}

function* sponsornewslettersignup() {
  yield all([
    takeLatest(ActionTypes.NEWSLETTER_SIGNUP, signUpNewsletter),
    takeLatest(ActionTypes.DEMO_SIGNUP, signUpDemo),
  ]);
}

export default sponsornewslettersignup;

import { put, call, all, takeLatest } from 'redux-saga/effects';
import { ActionTypes } from './shares.actions';
import { post } from '../Api'

const basePath = "share"

export const shareEvent = function* (action) {
  let { data } = action.payload;
  try {
    const response = yield call(post, basePath, data);
    const json = yield response.json();

    yield put({ type: ActionTypes.SHARE_EVENT_SUCCESS, response: json });
  } catch (error) {
    yield put({ type: ActionTypes.SHARE_EVENT_FAILURE });
  }
}

function* shares() {
  yield all([
    takeLatest(ActionTypes.SHARE_EVENT, shareEvent)
  ]);
}

export default shares;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './old/NavBar.css';

class NavBarLink extends Component {
  static defaultProps = {
    type: ""
  }
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  onClick() {
    if (this.props.onClick) {
      this.props.onClick();
    }
  }

  setLinkStyle() {
    if (this.props.type === 'purple') {
      return "body-copy-secondary";
    }
    return "body-copy-primary";
  }

  render() {

    return (
      <div style={{width: 'max-content'}} onClick={this.onClick.bind(this)} className="restage-navbar-link  d-flex align-items-center">
        <a className={`${this.setLinkStyle()} restage-link`} href={this.props.route}>{this.props.label}</a>
      </div>
    );
  }
}

export default NavBarLink;

export const ActionTypes = {
    POST_MESSAGE: 'POST_MESSAGE',
    POST_MESSAGE_SUCCESS: 'POST_MESSAGE_SUCCESS',
    POST_MESSAGE_FAILURE: 'POST_MESSAGE_FAILURE',
    GET_MESSAGES: 'GET_MESSAGES',
    GET_MESSAGES_SUCCESS: 'GET_MESSAGES_SUCCESS',
    GET_MESSAGES_FAILURE: 'GET_MESSAGES_FAILURE'
  }

export const postMessage = (message, room_id, sender_user_id) => ({
    type: ActionTypes.MESSAGE,
    payload: {
      message,
      room_id,
      sender_user_id
    }
});

export const getMessages = (room_id) => ({
  type: ActionTypes.MESSAGE,
  payload: {
    room_id
  }
})

import React, { Component } from 'react';
import ButtonGroup from 'components/ButtonGroup';
import Form from 'components/Form';
import Button from 'components/new/Button';
import FormGroup from 'components/FormGroup';
import TextField from 'components/TextField';
import Detail from 'common/Detail';
import AutocompleteEntryGroup from 'common/AutocompleteEntryGroup';
import CurrencyFormat from 'react-currency-format';
import Close from 'assets/icons/Close';
import EventPackagesFormSidePanel from 'screens/event/components/eventpackagesformsidepanel.component';
import { Routes } from 'constants/Routes';
import { isFilled, min } from 'common/helpers/validators';
import { notification } from 'antd';
import './eventpackagesform.css';

class EventPackagesForm extends Component {
  static defaultProps = {

  }

  constructor(props) {
    super(props);
    this.state = {
      packageIndex: 0,
      placements:[],
      name: {
        value: "",
        error: false
      },
      cost: {
        value: "",
        quantity: "",
        error: false,
        errorMessage: "Please enter a valid cost."
      },
      limit: {
        value: "",
        error: false
      },
      description: {
        value: "",
        error: false
      },
      placement: {
        value: "",
        error: false
      },
    }
  }

  reset() {
    this.setState({
      packageIndex: this.state.packageIndex + 1,
      placements:[],
      name: {
        value: "",
        error: false
      },
      cost: {
        value: "",
        quantity: "",
        error: false,
        errorMessage: "Please enter a valid cost."
      },
      limit: {
        value: "",
        error: false
      },
      description: {
        value: "",
        error: false
      },
      placement: {
        value: "",
        error: false
      },
    });
  }

  onClickEditPackage(item) {
    this.onClickRemovePackage(item);
    let { name, limit, description, cost } = item;
    this.setState({
      placements: item.placements,
      name: {
        value: name,
        error: false
      },
      cost: {
        value: cost,
        quantity: cost,
        error: false,
        errorMessage: "Please enter a valid cost."
      },
      limit: {
        value: limit,
        error: false
      },
      description: {
        value: description,
        error: false
      },
      placement: {
        value: "",
        error: false
      },
    });
  }

  addSidePanel() {
    return (
      <EventPackagesFormSidePanel
        onClickEditPackage={this.onClickEditPackage.bind(this)}
        onClickRemovePackage={this.onClickRemovePackage.bind(this)}
        packages={this.props.packages}
        savedPackages={this.props.savedPackages}
      />);
  }

  onClickRemovePackage(remove) {
    this.props.removePackage(remove);
  }

  goToReview() {
    if (this.props.packages.length === 0) {
      this.setState({
        error: true,
        errorMessage: "Please enter at least one package."
      })
      return;
    }

    if (this.props.savedPackages.length === 0) {
      this.props.postPackages(this.props.packages);
    } else {
      this.props.putPackages(this.props.packages);
    }
  }

  goToAudience() {
    this.props.history.push(Routes.EVENT_AUDIENCE);
  }

  /***********/
  onChangeAutocomplete(search) {
    this.setState({
      placement: {
        value: search.trim(),
        error: false
      }
    });
    this.props.searchPlacements(search.trim());
  }

  onMouseDownAutocompleteOption(value) {
    this.setState({
      placement: {
        value: value.trim(),
        error: false,
      }
    }, ()=>{
      this.props.setPlacements([]);
      this.onClickAddPlacement();
    });

  }

  onBlurAutocomplete() {
    this.props.setPlacements([]);
  }

  onChange(event) {
    this.setState({ [event.target.name]: {
      value: event.target.value,
      error: this.state[event.target.name].error,
      errorMessage: this.state[event.target.name].errorMessage
      }
    })
  }

  onValueChange(values) {
    const {formattedValue, value} = values;
    this.setState({ cost: {
      ...this.state.cost,
      value: formattedValue,
      quantity: value,
      }
    }, ()=> {
      this.setState({ cost: {
        ...this.state.cost,
        error: !this.isCostMin(min, 5000, "cost", "Package value must be at least $5,000."),
        errorMessage: this.state["cost"].errorMessage
        }
      });
    });
  }

  validate() {
    let fields = new Set();

    fields.add(this.isFieldValid(isFilled, "name"));
    fields.add(this.isCostMin(min, 5000, "cost", "Package value must be at least $5,000."));
    fields.add(this.isFieldValid(isFilled, "limit"));

    if (this.state.placements.length === 0) {
      fields.add(false);
    }

    return !fields.has(false);
  }

  isFieldValid(validator, key) {
    let isValid = validator(this.state[key].value);

    this.setState({
      [key]: {
        value: this.state[key].value,
        error: !isValid,
        errorMessage: this.state.errorMessage
      }
    });

    return isValid;
  }

  isCostMin(validator, value, key, errorMessage) {
    this.setState({
      [key]: {
        quantity: this.state[key].quantity,
        value: this.state[key].value,
        error: !validator(this.state[key].quantity, value),
        errorMessage: errorMessage
      }
    });

    return validator(this.state[key].quantity, value);
  }

  onClickAddPackage() {
    if (!this.validate()) return null;

    let {
      placements,
      name,
      description,
      cost,
      limit
    } = this.state;

    let newpackage = {
      packageIndex: this.state.packageIndex,
      placements,
      name: name.value,
      description: description.value,
      limit: limit.value,
      cost: cost.quantity,
      event_id: this.props.event.id,

    }

    this.props.addPackage(newpackage);
    this.reset();
  }

  onClickAddPlacement() {
    let { placement, placements } = this.state;

    let newPlacements = placements.filter((item) => item === placement.value);

    if (newPlacements.length > 0) {
      notification.info({
        description: `This placement has already been added.`
      });
      return;
    }

    if (!placement.value) {
      this.setState({
        placement: {
          value: "",
          error: true
        }
      });
      return;
    }
    placements.push(placement.value);

    this.setState({
      placements,
      placement: {
        value: null,
        error: false
      }
    }, () => {
      this.props.setPlacements([]);
    });
  }

  onClickRemovePlacement(placement) {
    let { placements } = this.state;

    let newPlacements = placements.filter((item)=> item !== placement.trim());

    this.setState({
      placements: newPlacements,
      placement: {
        value: "",
        error: false
      }
    }, () => {
      this.props.setPlacements([]);
    });
  }

  getPlacementsUI(placements) {
    if (placements.length === 0) {
      return (
        <div className="body-copy-tertiary justify-content-between d-flex align-items-center" style={{paddingLeft: '7px', paddingRight: '7px', paddingTop: '10px', paddingBottom: '10px'}}>
          No placements added
        </div>
      )
    } else {
      return (
          <div className="eventpackages-placements">
            {
              placements.map((placement, index)=>{
                return(
                  <div key={index} className="body-copy-tertiary justify-content-between d-flex align-items-center" style={{paddingLeft: '7px', paddingRight: '7px', paddingTop: '10px', paddingBottom: '10px'}}>
                    <div>{placement}</div>
                    <div className="d-flex"><Close onClick={this.onClickRemovePlacement.bind(this, placement)} /></div>
                  </div>
                )
              })
            }
          </div>
      )
    }
  }

  render() {
    let { placements, name, cost, description, limit, placement } = this.state;

    let {
      error,
      errorMessage
    } = this.state;

    let results = this.props.placements.map((result)=>{
      if (result.placement !== null) {
        return result.placement;
      }
    });

    return (
      <div>
        <Form addSidePanel={this.addSidePanel.bind(this)}>
          <div>
            <Detail justification="start" style={{marginBottom: '25px', textAlign: 'Start'}} header="Sponsorships" subHeader="Manage your sponsorships" />
            <div className="eventpackages-container" style={{marginBottom: '50px'}}>
              <FormGroup>
                <TextField
                  fontStyle="tertiary"
                  size="full"
                  name="name"
                  value={name.value}
                  errorMessage="Package must have a name."
                  error={name.error}
                  label="Package Name"
                  placeholder='Package Name'
                  onChange={this.onChange.bind(this)} />
              </FormGroup>
              <FormGroup>
                <CurrencyFormat
                  thousandSeparator={true}
                  prefix='$'
                  customInput={TextField}
                  fontStyle="tertiary"
                  size="full"
                  name="cost"
                  value={cost.quantity}
                  error={cost.error}
                  errorMessage={cost.errorMessage}
                  label="Package Value - Minimum $5,000"
                  placeholder='Value'
                  onValueChange={this.onValueChange.bind(this)} />
                <TextField
                  fontStyle="tertiary"
                  size="full"
                  name="limit"
                  errorMessage="Please specify a package limit."
                  value={limit.value}
                  error={limit.error}
                  label="Limit"
                  placeholder='# Of Avaliable Placements'
                  onChange={this.onChange.bind(this)} />
              </FormGroup>
              <TextField
                fontStyle="tertiary"
                name="description"
                errorMessage="Please add a package description."
                value={description.value}
                error={description.error}
                isTextArea={true}
                optional={true}
                onChange={this.onChange.bind(this)}
                label="Package Description"
                size="full" />
              <AutocompleteEntryGroup
                fontStyle="tertiary"
                label="Placement"
                placeholder="Enter placement"
                name="placement"
                value={placement.value}
                error={placement.error}
                errorMessage="Please enter at least one placement."
                onClickAddEntry={this.onClickAddPlacement.bind(this)}
                onChange={this.onChangeAutocomplete.bind(this)}
                results={results}
                onMouseDownOption={this.onMouseDownAutocompleteOption.bind(this)}
                onBlur={this.onBlurAutocomplete.bind(this)}
                size="full"/>
            </div>
            <div>
              <div className="eventpackages" style={{marginBottom: '25px'}}>
                <div style={{padding: '0px'}} className="col-sm-6 col-12">
                  <div className="d-flex justify-content-between" style={{backgroundColor: '#E3DBE6', paddingLeft: '7px', paddingRight: '7px', paddingTop: '4px', paddingBottom: '4px'}}>
                    <div className="body-copy-bold-tertiary">Placements</div>
                  </div>
                  {this.getPlacementsUI(placements)}
                </div>
              </div>
              <Button label="Add Package" type="tertiary" onClick={this.onClickAddPackage.bind(this)} />
            </div>
          </div>
        </Form>
        <div style={{position: "relative"}}>
          {error ? <div className="body-copy-error-wrapper"><span className="signup-error-message body-copy-error-small body-copy-error-container">{errorMessage}</span></div> : null}
        </div>
      </div>

    );
  }
}

export default EventPackagesForm;

export const ActionTypes = {
    GET_PORTFOLIOS: 'GET_PORTFOLIOS',
    GET_PORTFOLIOS_SUCCESS: 'GET_PORTFOLIOS_SUCCESS',
    GET_PORTFOLIOS_FAILURE: 'GET_PORTFOLIOS_FAILURE',
    GET_PORTFOLIO: 'GET_PORTFOLIO',
    GET_PORTFOLIO_SUCCESS: 'GET_PORTFOLIO_SUCCESS',
    GET_PORTFOLIO_FAILURE: 'GET_PORTFOLIO_FAILURE',
    POST_PORTFOLIO: 'POST_PORTFOLIO',
    POST_PORTFOLIO_SUCCESS: 'POST_PORTFOLIO_SUCCESS',
    POST_PORTFOLIO_FAILURE: 'POST_PORTFOLIO_FAILURE',
    SET_CURRENT_PORTFOLIO: 'SET_CURRENT_PORTFOLIO',
    SET_CURRENT_PORTFOLIO_SUCCESS: 'SET_CURRENT_PORTFOLIO_SUCCESS',
    SET_CURRENT_PORTFOLIO_FAILURE: 'SET_CURRENT_PORTFOLIO_FAILURE',
    SET_PORTFOLIO_SPONSORSET_PAGE_STATE: 'SET_PORTFOLIO_SPONSORSET_PAGE_STATE',
    SET_PORTFOLIO_OBJECTIVE_PAGE_STATE: 'SET_PORTFOLIO_OBJECTIVE_PAGE_STATE',
    SET_PORTFOLIO_AUDIENCE_PAGE_STATE: 'SET_PORTFOLIO_AUDIENCE_PAGE_STATE',
    SET_PORTFOLIO_PREFERENCES_PAGE_STATE: 'SET_PORTFOLIO_PREFERENCES_PAGE_STATE',
    PUBLISH_PORTFOLIO: 'PUBLISH_PORTFOLIO',
    PUBLISH_PORTFOLIO_SUCCESS: 'PUBLISH_PORTFOLIO_SUCCESS',
    PUBLISH_PORTFOLIO_FAILURE: 'PUBLISH_PORTFOLIO_FAILURE',
    SET_PORTFOLIO_NAME: 'SET_PORTFOLIO_NAME',
    SET_BUDGET: 'SET_BUDGET'
};

export const setPortfolioName = (name) => ({
    type: ActionTypes.SET_PORTFOLIO_NAME,
    payload: {
      name
    }
});

export const getPortfolios = (user_id, portfolio_id) => ({
    type: ActionTypes.GET_PORTFOLIOS,
    payload: {
      user_id,
      portfolio_id
    }
});

export const getPortfolio = (id) => ({
    type: ActionTypes.GET_PORTFOLIO,
    payload: {
      portfolio_id: id
    }
});

export const postPortfolio = (portfolio) => ({
    type: ActionTypes.POST_PORTFOLIO,
    payload: {
      portfolio
    }
});

export const publishPortfolio = (portfolio_id) => ({
  type: ActionTypes.PUBLISH_PORTFOLIO,
  payload: {
    portfolio_id
  }
})

export const setCurrentPortfolio = (currentPortfolio) => {
  return {
      type: ActionTypes.SET_CURRENT_PORTFOLIO,
      payload: {
        currentPortfolio
      }
  };
}

export const setPortfolioSponsorsetPage = (isValid) => ({
  type: ActionTypes.SET_PORTFOLIO_SPONSORSET_PAGE_STATE,
  payload: {
    isValid
  }
})

export const setPortfolioObjectivePage = (isValid) => ({
  type: ActionTypes.SET_PORTFOLIO_OBJECTIVE_PAGE_STATE,
  payload: {
    isValid
  }
})


export const setPortfolioAudiencePage = (isValid) => ({
  type: ActionTypes.SET_PORTFOLIO_AUDIENCE_PAGE_STATE,
  payload: {
    isValid
  }
})

export const setPortfolioPreferencesPage = (isValid) => ({
  type: ActionTypes.SET_PORTFOLIO_PREFERENCES_PAGE_STATE,
  payload: {
    isValid
  }
})

export const setBudget = (lowerBound, upperBound) => ({
  type: ActionTypes.SET_BUDGET,
  payload: {
    lowerBound,
    upperBound
  }
})

import React, { Component } from 'react';
import './option.css';

class Option extends Component {
  getClassName() {
    let { active } = this.props;
    if (active) {
      return "body-copy-orange objectives-option-container";
    }
    return "body-copy-tertiary objectives-option-container"
  }

  render() {
    let { option, onClick } = this.props;
    return (
      <div onClick={onClick.bind(this, option)} className={this.getClassName()}>
        {option}
      </div>
    );
  }
}

export default Option;

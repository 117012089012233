import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './Image.css';

class Image extends Component {
  static propTypes = {
    src: PropTypes.string.isRequired
  }

  static defaultProps = {
      visible: false,
      className: ''
  }

  render() {
    return (
      <div className={this.props.className}>
        <img className="image" src={this.props.src} alt={this.props.alt}/>
      </div>
    );
  }
}

export default Image;

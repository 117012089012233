import { ActionTypes } from './placementtypes.actions';

const initialState = {
    errorMessage: "",
    types: []
};

const PlacementTypesReducer = (state = initialState, action) => {
    switch (action.type) {
      case ActionTypes.GET_PLACEMENT_TYPES_SUCCESS:
        return { ...state, types: action.types};
      default:
        return state;
    }
};

export default PlacementTypesReducer;

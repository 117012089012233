import React, { Component } from 'react';
import './Section.css';

class Section extends Component {
  render() {
    return (
      <div className="how-section-container">
        <div className="how-section-header body-copy-bold">
          {this.props.header}
        </div>
        <div style={{marginBottom: '20px'}} className="d-flex justify-content-center">
          <div className="how-section-divider d-flex align-items-center justify-content-center">
            <div className="how-section-icon-container d-flex align-items-center justify-content-center">
              <img className="how-section-icon" src={this.props.src} />
            </div>
          </div>
        </div>
        <div className="body-copy-tertiary">
          {this.props.text}
        </div>
      </div>
    );
  }
}

export default Section;

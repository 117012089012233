import { ActionTypes } from './tap.actions';

const initialState = {
    errorMessage: "",
    attributes: []
};

const TapReducer = (state = initialState, action) => {
    switch (action.type) {
      case ActionTypes.ADD_ATTRIBUTE_SUCCESS:
        let contains = state.attributes.filter(({ type, value }) => action.attribute.type === type && action.attribute.value === value);
        let attributes = [];

        if (contains.length > 0) {
          attributes = [...state.attributes];
        } else {
          attributes = [...state.attributes, action.attribute]
        }
        return {
          ...state,
          attributes
        };
      case ActionTypes.REMOVE_ATTRIBUTE_SUCCESS:

        let newAttributes = state.attributes.filter(({ type, value }) => !(action.attribute.type === type && action.attribute.value === value));

        return {
          ...state,
          attributes: [...newAttributes]
        };
      default:
          return state;
    }
};

export default TapReducer;

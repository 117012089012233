import React, { Component } from 'react';
import './card.css';

class Card extends Component {
  render() {
    let { img, label, description } = this.props;
    return (
      <div className="card-container d-flex flex-column align-items-start">
        <div><img className="card-img" src={img}/></div>
        <div>
          <div className="card-label body-copy-bold-purple">{label}</div>
          <div className="card-description body-copy-tertiary">{description}</div>
        </div>
      </div>
    );
  }
}

export default Card;

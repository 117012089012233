import { call, put, all, takeLatest } from 'redux-saga/effects';
import { post } from '../Api'
import { ActionTypes } from './portfoliodesiredplacements.actions';

const basePath = "portfolio-desired-placements";

export const postPortfolioDesiredPlacements = function* (action) {
  const { payload: { placements } } = action;
  try {
    const response = yield call(post, basePath, placements);
    yield put({ type: ActionTypes.POST_PORTFOLIO_DESIRED_PLACEMENTS_SUCCESS, response });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: ActionTypes.POST_PORTFOLIO_DESIRED_PLACEMENTS_FAILURE, error });
  }
}

export const addPlacement = function* (action) {
  const { payload: { placement } } = action;
  try {
    yield put({ type: ActionTypes.ADD_PLACEMENT_SUCCESS, placement });
  } catch (error) {}
}

export const removePlacement = function* (action) {
  const { payload: { placement } } = action;
  try {
    yield put({ type: ActionTypes.REMOVE_PLACEMENT_SUCCESS, placement });
  } catch (error) {}
}

function* portfoliodesiredplacements() {
  yield all([
    takeLatest(ActionTypes.POST_PORTFOLIO_DESIRED_PLACEMENTS, postPortfolioDesiredPlacements),
    takeLatest(ActionTypes.ADD_PLACEMENT, addPlacement),
    takeLatest(ActionTypes.REMOVE_PLACEMENT, removePlacement)
  ]);
}

export default portfoliodesiredplacements;

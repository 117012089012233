import { ActionTypes } from './packages.actions';

const initialState = {
    errorMessage: "",
    packages: [],
    savedPackages: [],
    isEventPackagesPageValid: false,
};

const PackagesReducer = (state = initialState, action) => {
    switch (action.type) {
      case ActionTypes.POST_PACKAGES_SUCCESS:
        return { ...state, packages: [...action.packages], savedPackages: [...action.savedPackages]};
      case ActionTypes.PUT_PACKAGES_SUCCESS:
        return { ...state, packages: [...action.packages], savedPackages: [...action.savedPackages]};
      case ActionTypes.ADD_PACKAGE:
        let packages = [...state.packages];
        console.log("packages", packages, action)
        packages.push(action.payload.item);
        return { ...state, packages };
      case ActionTypes.CLEAR_PACKAGES:
        packages = [];
        return { ...state, packages };
      case ActionTypes.REMOVE_PACKAGE:
        let newPackages = state.packages.filter((item) => item.packageIndex !== action.payload.item.packageIndex);
        return { ...state, packages: [...newPackages] };
      case ActionTypes.SET_EVENT_PACKAGES_PAGE_STATE:
        return { ...state,isEventPackagesPageValid: action.payload.isValid };
      default:
          return state;
    }
};

export default PackagesReducer;

export const ActionTypes = {
    SEND_NEGOTIATIONS_EMAIL: 'SEND_NEGOTIATIONS_EMAIL',
    SEND_NEGOTIATIONS_EMAIL_SUCCESS: 'SEND_NEGOTIATIONS_EMAIL_SUCCESS',
    SEND_NEGOTIATIONS_EMAIL_FAILURE: 'SEND_NEGOTIATIONS_EMAIL_FAILURE'
};

export const sendNegotiationsEmail = (eventPackageOfferId, message) => ({
    type: ActionTypes.SEND_NEGOTIATIONS_EMAIL,
    payload: {
      eventPackageOfferId,
      message
    }
});

import { put, call, all, takeLatest } from 'redux-saga/effects';
import { ActionTypes } from './portfolioobjectives.actions';
import { post } from '../Api'

const basePath = "portfolio-objectives"

export const postPortfolioObjective = function* (action) {
  const { payload: { portfolio_id, objective, portfolio_name } } = action;
  try {
    const response = yield call(post, basePath, {
      portfolio_id,
      objective,
      portfolio_name
    });
    const data = yield response.json();

    yield put({ type: ActionTypes.POST_PORTFOLIO_OBJECTIVE_SUCCESS, data });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: ActionTypes.POST_PORTFOLIO_OBJECTIVE_FAILURE, error });
  }
}
function* portfolioobjectives() {
  yield all([
    takeLatest(ActionTypes.POST_PORTFOLIO_OBJECTIVE, postPortfolioObjective),
  ]);
}

export default portfolioobjectives;

import React, { Component } from 'react';
import TextField from 'components/TextField';
import Button from 'components/new/Button';
import Wrapper from 'components/Wrapper';
import { sendForgotPasswordEmail } from 'services/forgotpassword/forgotpassword.actions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import './forgotpassword.css';

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: ''
    };
  }

  componentDidUpdate(prevProps) {
    //if (this.props.)
  }

  _handleOnChange(event){
    this.setState({
      [event.target.name]: event.target.value
    })
  }

  _handleOnPressReset() {
    this.props.sendForgotPasswordEmail(this.state.email);
  }

  render() {
    return (
      <Wrapper className="signup-container container d-flex align-items-center justify-content-center flex-column">
          <div style={{marginBottom: '65px'}} className="header-1-tertiary">Forgot Password</div>
          <TextField
            name="email"
            placeholder='Email'
            value={this.state.email}
            onChange={this._handleOnChange.bind(this)} />
          <div style={{marginTop: '50px'}}>
            <Button label="Submit" size="lg" onClick={this._handleOnPressReset.bind(this)} />
          </div>
          {this.props.error ? <div style={{alignSelf: 'stretch', position: "relative"}}><span style={{textAlign: 'center', position: "absolute", color: 'red'}}>{this.props.errorMessage}</span></div> : null}
        </Wrapper>
    )
  }
}

const mapDispatchToProps = dispatcher => (
    bindActionCreators({
        sendForgotPasswordEmail
    }, dispatcher)
);

const mapStateToProps = (state) => {
  return {
    session: state.forgotpassword.session,
    errorMessage: state.signUp.errorMessage,
    error: state.signUp.error
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TextField from 'components/TextField';
import Pencil from 'assets/pencil.svg';
import './EditTextField.css';

class EditTextField extends Component {
  static defaultProps = {
    style: {},
    isEditable: false,
    placeholder: "Click the pencil to edit",
    isTextArea: false,
    isLink: false,
    fontStyle: "tertiary"
  }

  constructor(props) {
    super(props);
    this.state = {
      value: props.value,
      error: false
    }
  }

  onClick() {
    this.setState({
      isEditable: !this.state.isEditable
    }, () => {
      if (!this.state.isEditable) {
        this.props.onShowTextField()
      }
    })
  }

  getBase() {
    if (this.state.isEditable) {
      return (<TextField
          name={this.props.name}
          value={this.props.value}
          error={this.props.error}
          errorMessage={this.props.errorMessage}
          placeholder={this.props.placeholder}
          isTextArea={this.props.isTextArea}
          onChange={this.props.onChange.bind(this)}
          size="full" />)
    } else {
      if (this.props.isLink && this.props.value !== null && this.props.value.length > 0 ) {
        return (
          <div style={{wordBreak: "break-word", width: '100%'}}className="body-copy-tertiary">
            <a className="body-copy-tertiary-link" href={this.props.value} target="_blank" rel="noopener noreferrer">{this.props.value}</a>
          </div>
        )
      }

      return (<div className="body-copy-tertiary" style={{width: '100%'}}>{(this.props.value !== null && this.props.value.length > 0 ? this.props.value : this.props.placeholder)} </div>);
    }
  }

  render() {
    let altDescription = "";

    if (this.props.isEditable) {
      altDescription = `Click to save ${this.props.label}.`;
    } else {
      altDescription = `A pencil, that when clicked, will expose the ${this.props.label} text field to allow the ${this.props.label} be edited.`;
    }
    return (
      <div className="d-flex flex-row" style={{width: '100%'}}>
        <div style={{width: '100%'}}>
          <div style={{marginBottom: '8px'}} className="header-3-tertiary">{this.props.label}</div>
          {this.getBase()}
        </div>
        <div style={{marginLeft: '5px', cursor: 'pointer'}}>
          <img alt={altDescription} onClick={this.onClick.bind(this)} src={Pencil} />
        </div>
      </div>
    );
  }
}

export default EditTextField;

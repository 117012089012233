import React, { Component } from 'react';
import Detail from './Detail';
import Modal from 'components/Modal';
import Divider from 'common/Divider.component';
import TextField from 'components/TextField';
import ButtonGroup from 'components/ButtonGroup';
import Button from 'components/new/Button';
import Close from 'assets/icons/Close';
import { notification } from 'antd';
import { isEmailValid } from 'common/helpers/validators';
import copy from 'copy-to-clipboard';
import './shareeventmodal.css';

class ShareEventModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: {
        value: ""
      },
      numberOfEmailFields: 1,
      textFields: {
        email0: {
          index: 0,
          value: "",
          error: false
        }
      }
    }
  }

  onChangeTextField(event) {
    let { name, value } = event.target;

    this.setState({
      textFields: {
        ...this.state.textFields,
        [name]: {
          ...this.state.textFields[name],
          value,
          error: this.state.textFields[name].error
        }
      }
    });
  }

  onChange(event) {
    let { name, value } = event.target;

    this.setState({
      [name]: {
      value,
      error: this.state[name].error
      }
    })
  }

  removeEmailField(emailFieldName) {
    let newTextFields = {...this.state.textFields};
    delete newTextFields[emailFieldName];

    this.setState({
      textFields: {...newTextFields}
    })
  }

  addEmailField() {
    let { textFields, numberOfEmailFields } = this.state;

    let fieldName = `email${numberOfEmailFields}`;

    let newTextFields = {
      ...textFields,
      [fieldName]: {
        index: numberOfEmailFields,
        value: "",
        error: false
      }
    };

    this.setState({
      textFields: {...newTextFields},
      numberOfEmailFields: numberOfEmailFields + 1
    });
  }

  validate() {
    let fields = new Set();
    let { textFields } = this.state;
    let newTextFields = {};
    let emails = [];

    for (let key in textFields) {
      let field = textFields[key];

      if (field.value.length === 0) continue;

      let isValid = isEmailValid(field.value);

      emails.push(field.value);

      newTextFields = {
        ...newTextFields,
        [key]: {
          ...field,
          error: !isValid
        }
      };
      fields.add(isValid);
    }

    console.log(newTextFields)

    this.setState({
      textFields: {
        ...newTextFields
      }
    }, () => console.log(this.state.textFields));

    return {
      isValid: !fields.has(false),
      emails
    }
  }

  onSubmitShareEvent() {
    let { event, shareEvent } = this.props;

    let validity = this.validate();

    if (!validity.isValid) {
      return;
    }

    shareEvent({
      event_id: event.id,
      emails: validity.emails,
      message: this.state.message.value
    })
  }

  getTextFields() {
    let { textFields } = this.state;
    let buffer = []
    let states = [];
    let that = this;
    for (let key in textFields) {
      let fields = textFields[key];
      let { value, error, index } = fields;

      let fieldName = `email${index}`

      let close = <Close style={{marginLeft: '15px'}} onClick={this.removeEmailField.bind(this, fieldName)} />

      if (fieldName === 'email0') {
        close = null;
      }

      buffer.push(
        <div className="d-flex align-items-center">
          <TextField
            key={index}
            fontStyle="tertiary"
            name={fieldName}
            error={error}
            errorMessage="Please enter a valid e-mail."
            value={value}
            placeholder="Enter an e-mail address"
            onChange={that.onChangeTextField.bind(this)}
            size="lg" />
          {close}
        </div>
      )
    }

    return buffer;
  }

  getShareableLink() {
    this.props.shareEvent({event_id: this.props.event.id, emails: []})
  }

  copyUrlToClipboard() {
    copy(this.props.inviteUrl);
    notification.success({
      message: "Copied to clipboard."
    });
  }


  render() {
    let { message } = this.state;
    let { onClickClose, visible } = this.props;

    return (
      <Modal
        className=""
        onClickClose={onClickClose.bind(this)}
        appendFooter={
          ()=>{
            return (
              <ButtonGroup
                labelPrimary="SEND"
                labelSecondary="CANCEL"
                onClickPrimary={this.onSubmitShareEvent.bind(this)}
                onClickSecondary={onClickClose.bind(this)} />
            )
          }
        }
        visible={visible}>
        <div className="share-event-modal-container">
          <div className="body-copy-tertiary" style={{textAlign: 'start', marginBottom: '15px'}}>
            <div style={{marginBottom: '10px'}} className="header-2-brand">Share this event</div>
            <div className="body-copy-tertiary">
              Interested in sponsoring this event?
              Share it with your coworkers and collaborators before sending a package request to the event organizer. 
              Enter their email addresses below or use a shareable link.
            </div>
          </div>
          <Divider type="brand" className="share-event-modal-divider-1" />
          <div>
            <div style={{marginBottom: '20px'}} className="body-copy-bold-tertiary">Invite Team Members</div>
            <div className="shared-emails-container">
              {this.getTextFields()}
              <Button
                label="ADD ANOTHER EMAIL"
                type="tertiary"
                onClick={this.addEmailField.bind(this)} />
            </div>
          </div>
          <Divider type="brand" className="share-event-modal-divider-2" />
          <TextField
            fontStyle="tertiary"
            name="message"
            value={message.value}
            errorMessage="Description cannot be empty."
            isTextArea={true}
            onChange={this.onChange.bind(this)}
            placeholder="Leave a message for the recipient(s)"
            label="Leave a message"
            size="full" />
          <div className="share-link body-copy-secondary" onClick={this.getShareableLink.bind(this)}>Use shareable link</div>
          {this.props.inviteUrl && this.props.messages.length === 0 && this.props.warnings.length === 0?
            <div className="d-flex flex-column">
              <div className="shared-link body-copy-tertiary">{this.props.inviteUrl}</div>
              <div>
                <Button
                  size="md"
                  style={{marginTop: '15px'}}
                  label="COPY TO CLIPBOARD"
                  type="tertiary"
                  onClick={this.copyUrlToClipboard.bind(this)} />
              </div>
            </div> : null}
        </div>
      </Modal>
    );
  }
}

export default ShareEventModal;

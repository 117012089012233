import { put, call, all, takeLatest } from 'redux-saga/effects';
import { ActionTypes } from './placements.actions';
import { getDropwizard, post } from '../Api'
const basePath = "placements";

export const searchPlacements = function* (action) {
  const { payload: { query, type } } = action;
  try {
    let params = [];
    if (type) {
      params.push(`type=${type}`);
    }
    params.push(`query=${query}`);
    const response = yield call(getDropwizard, basePath, `/search?${params.join("&")}`);
    yield put({ type: ActionTypes.SEARCH_PLACEMENTS_SUCCESS, data: response.data });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: ActionTypes.SEARCH_PLACEMENTS_FAILURE, error });
  }
}

export const setPlacements = function* (action) {
  const { payload: { placements } } = action;
  try {
    yield put({ type: ActionTypes.SEARCH_PLACEMENTS_SUCCESS, data: placements });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: ActionTypes.SEARCH_PLACEMENTS_FAILURE, error });
  }
}

export const getPlacements = function* (action) {
  try {
    const response = yield call(getDropwizard, basePath);
    yield put({ type: ActionTypes.GET_PLACEMENTS_SUCCESS, types: response.data });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: ActionTypes.GET_PLACEMENTS_FAILURE, error });
  }
}

function* placements() {
  yield all([
    takeLatest(ActionTypes.SEARCH_PLACEMENTS, searchPlacements),
    takeLatest(ActionTypes.SET_PLACEMENTS, setPlacements),
    takeLatest(ActionTypes.GET_PLACEMENTS, getPlacements)
  ]);
}

export default placements;

import { put, call, all, takeLatest } from 'redux-saga/effects';
import { ActionTypes } from './events.actions';
import { ActionTypes as EventAssetsActionTypes } from 'services/eventassets/eventassets.actions';
import { ActionTypes as PackageActionTypes } from 'services/packages/packages.actions';
import { get, post, getDropwizard, putRequest } from '../Api'
import firebase from '../../firebase/Firebase';
const basePath = "events"

export const getEvent = function* (action) {
  let { event_id, user_id } = action.payload;
  try {
    let query = "?"

    let params = [];

    if (user_id) {
      params.push("user_id=" + user_id);
    }

    if (event_id) {
      params.push("event_id=" + event_id);
    }

    if (params.length > 0) {
      query = query + params.join("&");
    } else {
      query = ""
    }

    const response = yield call(getDropwizard, basePath, query);
    yield put({ type: PackageActionTypes.CLEAR_PACKAGES});
    for (let index in response.data.packages) {
       yield put({ type: PackageActionTypes.ADD_PACKAGE, payload: {item: response.data.packages[index]}  });
    }

    yield put({ type: ActionTypes.GET_EVENT_SUCCESS, data: response.data });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: ActionTypes.GET_EVENT_FAILURE, error });
  }
}

export const getEventWithToken = function* (action) {
  let { event_id, token } = action.payload;
  try {
    const response = yield call(getDropwizard, `${basePath}/${event_id}/preview?token=${token}`);

    yield put({ type: ActionTypes.GET_EVENT_WITH_TOKEN_SUCCESS, data: response.data });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: ActionTypes.GET_EVENT_WITH_TOKEN_FAILURE, error });
  }
}

export const getEvents = function* (action) {
  let { user_id } = action.payload;
  try {
    let query = "?"

    let params = [];

    if (user_id && user_id !== undefined) {
      params.push("user_id=" + user_id);
    }

    if (params.length > 0) {
      query = query + params.join("&");
    } else {
      query = ""
    }

    const response = yield call(getDropwizard, basePath, query);

    yield put({ type: ActionTypes.GET_EVENTS_SUCCESS, data: response.data });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: ActionTypes.GET_EVENTS_FAILURE, error });
  }
}

export const searchEvents = function* (action) {
  let { filters } = action.payload;

  try {

    const response = yield call(post, basePath+ "/search", filters);
    const json = yield response.json();
    yield put({ type: ActionTypes.SEARCH_EVENTS_SUCCESS, data: json });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: ActionTypes.SEARCH_EVENTS_FAILURE, error });
  }
}

export const postEvent = function* (action) {
  const { payload: { data } } = action;
  try {
    //data.user_id = firebase.auth.currentUser.uid
    let event_data = {...data};
    delete event_data.assets;

    const response = yield call(post, basePath, event_data);
    const json = yield response.json();
    yield getEvents({ type: ActionTypes.GET_EVENTS, payload: {user_id: data.user_id}  });

    if (data.assets) {
      for (let index in data.assets) {
        data.assets[index].append("event_id", json.id)
        yield put({ type: EventAssetsActionTypes.POST_EVENT_ASSETS, payload: {event_assets: data.assets[index]} });
      }
    }

    yield put({ type: ActionTypes.POST_EVENTS_SUCCESS, json });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: ActionTypes.POST_EVENTS_FAILURE, error });
  }
}

export const update = function* (action) {
  const { payload: { data } } = action;
  try {
    //data.user_id = firebase.auth.currentUser.uid
    let event_data = {...data};
    delete event_data.assets;

    const response = yield call(post, basePath, event_data);
    const json = yield response.json();
    yield getEvents({ type: ActionTypes.GET_EVENTS, payload: {user_id: data.user_id}  });

    if (data.assets) {
      for (let index in data.assets) {
        data.assets[index].append("event_id", json.id)
        yield put({ type: EventAssetsActionTypes.POST_EVENT_ASSETS, payload: {event_assets: data.assets[index]} });
      }
    }

    yield put({ type: ActionTypes.POST_EVENTS_SUCCESS, json });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: ActionTypes.POST_EVENTS_FAILURE, error });
  }
}

export const putEvent = function* (action) {
  try {
    const response = yield call(putRequest, `${basePath}`, action.payload.event);
    yield put({ type: ActionTypes.PUT_EVENT_SUCCESS, response: response.data });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: ActionTypes.PUT_EVENT_FAILURE, error });
  }
}

export const postEventDecision = function* (action) {
  const { payload: { event_id, decision } } = action;

  try {
    const response = yield call(post, `${basePath}/${event_id}/decision`, { event_id, decision });
    //const json = yield response.json();

    yield put({ type: ActionTypes.POST_EVENT_DECISION_SUCCESS, payload: { event_id, decision } });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: ActionTypes.POST_EVENT_DECISION_FAILURE, error });
  }
}

export const publish = function* (action) {
  let email = firebase.auth.currentUser.email;

  const { payload: { id } } = action;
  try {
    const response = yield call(post, basePath + "/" + id +"/publish", { email } );
    console.log(response, "response")
    yield put({ type: ActionTypes.PUBLISH_EVENT_SUCCESS });
    yield put({ type: ActionTypes.GET_EVENT, payload: {event_id: id} });
  } catch (error) {
    yield put({ type: ActionTypes.PUBLISH_EVENT_FAILURE });
  }
}

function* events() {
  yield all([
    takeLatest(ActionTypes.GET_EVENT, getEvent),
    takeLatest(ActionTypes.GET_EVENT_WITH_TOKEN, getEventWithToken),
    takeLatest(ActionTypes.GET_EVENTS, getEvents),
    takeLatest(ActionTypes.SEARCH_EVENTS, searchEvents),
    takeLatest(ActionTypes.POST_EVENTS, postEvent),
    takeLatest(ActionTypes.PUBLISH_EVENT, publish),
    takeLatest(ActionTypes.POST_EVENT_DECISION, postEventDecision),
    takeLatest(ActionTypes.PUT_EVENT, putEvent),
  ]);
}

export default events;

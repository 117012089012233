import React, { Component } from 'react';
import Button from 'components/new/Button';
import upload from 'assets/icons/purple/share@2x.png';
import Dropzone from 'react-dropzone'

import './Upload.css';

class Upload extends Component {
  static defaultProps = {
    multiple: false,
    style: {},
    accept: "*"
  }

  constructor(props) {
    super(props);
    this.fileInput = React.createRef();
  }

  onClickUpload(multiple) {
    this.fileInput.current.click();
  }

  /*_handleOnSubmit(event) {
    let { onSubmit } = this.props;

    if (onSubmit) {
      let { files } = this.fileInput.current;
      onSubmit(event, files);
    }
  }*/

  _handleOnSubmit(files) {
    let { onSubmit } = this.props;
    if (onSubmit) {
      onSubmit(null, files);
    }
  }

  render() {
    /*      <div>
            <Button icon={upload} type="quarternary" label={this.props.label} onClick={this.onClickUpload.bind(this, false)}/>
            <input name="upload" onChange={this._handleOnSubmit.bind(this)} ref={this.fileInput} className="upload-inputfile" type="file" multiple={(this.props.multiple ? true : false)}/>
          </div>*/
    let { error, errorMessage } = this.props;
    return (
      <div style={this.props.style}>
        <label style={{marginBottom: '8px'}} className="body-copy-bold-tertiary">{this.props.label}</label>
        <Dropzone accept={this.props.accept} multiple={(this.props.multiple ? true : false)} onDrop={this._handleOnSubmit.bind(this)}>
          {({getRootProps, getInputProps}) => (
            <section className="upload-container" >
              <div style={{height: '100%'}} className="d-flex align-items-center justify-content-center" {...getRootProps()}>
                <input {...getInputProps()} />
                <div>
                  <div style={{marginBottom: '10px'}} className="d-flex flex-row">
                    <img style={{height: '25px', marginRight: '10px'}} src={upload}/><div className="body-copy-secondary">Upload File</div>
                  </div>
                  <div className="body-copy-xs-small-brand-secoundary-3">*Max file size is 100 MB</div>
                </div>
              </div>
            </section>
          )}
        </Dropzone>
        <div className={error ? "textfield-error-container" : "textfield-hidden" }>
          <div className="body-copy-error-xs-small">{errorMessage}</div>
        </div>
      </div>

    );
  }
}

export default Upload;

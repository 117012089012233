import React, { Component } from 'react';
import Detail from 'common/Detail';
import Divider from 'common/Divider.component';
import Wrapper from 'components/Wrapper';
import Button from 'components/new/Button';
import Mailbox from 'assets/mailbox.svg';
import NotFound from 'assets/notfound.svg';
import queryString from 'query-string'
import { resend } from 'services/verify/verify.actions';
import { Routes } from 'constants/Routes';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import './verify.css';

class Resend extends Component {
  componentWillMount() {
    const values = queryString.parse(this.props.location.search);
    const token = values.token;
    const email = values.email;
    this.props.resend(token, email);
    //console.log(token, email);
  }

  componentDidUpdate(prevProps) {
    if (this.props.success !== prevProps.success) {
      console.log("SUCCESS");
    }
  }

  goToPath(path) {
    this.props.history.push(path);
  }

  render() {
    if (this.props.resendsuccess === null) return null;
    let route = Routes.LOG_IN;
    let img = Mailbox;
    let header = "We have sent another verification e-mail.";
    let label = "LOG IN";

    if (!this.props.resendsuccess) {
      img = NotFound;
      header = "Account or token not found or has expired";
    }

    return (
      <Wrapper className="container d-flex flex-column flex-fill">
        <div className="d-flex flex-column">
          <Detail justification="start" style={{textAlign: 'Start', marginBottom: '25px'}} header={header} />
          <Divider  style={{marginBottom: '25px'}}/>
        </div>
        <div style={{marginBottom: '30px'}} className="d-flex align-items-center justify-content-center flex-fill">
          <img src={img} style={{width: '100%', maxWidth: '300px'}}/>
        </div>
        <div className="d-flex justify-content-center">
          <Button
            size="md"
            type="primary"
            label={label}
            onClick={this.goToPath.bind(this, route)} />
        </div>
      </Wrapper>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    resendsuccess: state.verify.resendsuccess,
    error: state.verify.error
  };
};

const mapDispatchToProps = dispatcher => (
    bindActionCreators({
      resend
    }, dispatcher)
);

export default connect(mapStateToProps, mapDispatchToProps)(Resend);

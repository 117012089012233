import { put, call, all, takeLatest } from 'redux-saga/effects';
import { ActionTypes } from './packagetypes.actions';
import { getDropwizard } from '../Api'

const basePath = "package-types";

export const getPackageTypes = function* (action) {
  try {
    const response = yield call(getDropwizard, basePath);
    yield put({ type: ActionTypes.GET_PACKAGE_TYPES_SUCCESS, types: response.data });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: ActionTypes.GET_PACKAGE_TYPES_FAILURE, error });
  }
}

function* packagetypes() {
  yield all([
    takeLatest(ActionTypes.GET_PACKAGE_TYPES, getPackageTypes)
  ]);
}

export default packagetypes;

import { ActionTypes } from './placements.actions';

const initialState = {
    errorMessage: "",
    placements: []
};

const OccupationsReducer = (state = initialState, action) => {
    switch (action.type) {
      case ActionTypes.SEARCH_PLACEMENTS_SUCCESS:
        return { ...state, placements: action.data};
      case ActionTypes.SET_PLACEMENTS_SUCCESS:
        return { ...state, placements: action.data};
      case ActionTypes.GET_PLACEMENTS_SUCCESS:
        return { ...state, types: action.types};
      default:
        return state;
    }
};

export default OccupationsReducer;

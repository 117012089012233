export const ActionTypes = {
    POST_AVAILABLE_PLACEMENTS: 'POST_AVAILABLE_PLACEMENTS',
    POST_AVAILABLE_PLACEMENTS_SUCCESS: 'POST_AVAILABLE_PLACEMENTS_SUCCESS',
    POST_AVAILABLE_PLACEMENTS_FAILURE: 'POST_AVAILABLE_PLACEMENTS_FAILURE'
};

export const postAvailablePlacements = (requests) => {
  return {
    type: ActionTypes.POST_AVAILABLE_PLACEMENTS,
    payload: {
      requests
    }
  }
};

import { ActionTypes } from './signup.actions';

const initialState = {
    errorMessage: "",
    error: false,
    success: false,
    email: "",
    token: ""
};

const SignUpReducer = (state = initialState, action) => {
    switch (action.type) {
      case ActionTypes.SIGNUP_SUCCESS:
        return { ...state, error: false };
      case ActionTypes.SIGNUP_FAILURE:
        return { ...state, errorMessage: action.errorMessage, error: true};
      case ActionTypes.CREATE_USER:
          return { ...state, error: false };
      case ActionTypes.CREATE_USER_SUCCESS:
        return { ...state, errorMessage: action.errorMessage, success: true, error: false, email: action.email, token: action.token};
      case ActionTypes.CREATE_USER_FAILURE:
        return { ...state, errorMessage: action.errorMessage, error: true};
      case ActionTypes.CREATE_USER_ACCOUNT_FAILURE:
        return { ...state, errorMessage: action.errorMessage, error: true};
      case ActionTypes.CREATE_USER_ACCOUNT_SUCCESS:
        return { ...state, errorMessage: action.errorMessage, success: true, error: false };
      default:
          return state;
    }
};

export default SignUpReducer;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import EventPoster from 'assets/placeholder.jpg'
import Button from 'components/new/Button';
import './eventtile.css';

class EventTile extends Component {
  static propTypes = {
    label: PropTypes.string
  }

  static defaultProps = {

  }

  onClick() {
    if (this.props.onClick !== null) {
      this.props.onClick();
    }
  }

  setFooterUI () {
    return (
      <div className="eventtile-footer body-copy-tertiary-small d-flex justify-content-center">
        {/*<Button label="EDIT EVENT" size="xs" style="quintary" onClick={this.onClick.bind(this)}  />*/}
        <Button label="DASHBOARD" size="xs" type="quintary" onClick={this.onClick.bind(this)}  />
      </div>
    )
  }

  render() {
    let { name, formatted_address, startdate, enddate, poster, score, description } = this.props;
    let style = {
      backgroundImage:  `url(${EventPoster})`
    }

    if (poster) {
      style = {
        backgroundImage:  `url(${poster.url})`
      }
    }

    if  (description && description.length > 50) {
      description = `${description.substring(0,50)}...`;
    }

    return (
      <div onClick={this.onClick.bind(this)} className="d-flex flex-column justify-content-between eventtile-container">
        <div className="d-flex flex-column flex-shrink-0">
          <div className="eventtile-poster-container">
            <div className="eventtile-poster" style={style} />
          </div>
          <div className="eventtile-content d-flex flex-column">
            <div>
              <div style={{display: 'none'}} className="body-copy-quarternary">{score}% Match</div>
              <div style={{marginBottom: '15px'}} className="header-2-tertiary">{name}</div>
              <div style={{marginBottom: '15px'}} className="body-copy-tertiary">{description}</div>
            </div>
            <div>
              <div className="body-copy-tertiary-small">Location: {formatted_address}</div>
              <div className="d-flex">
                <div className="body-copy-tertiary-small">Dates:&nbsp;</div>
                <div className="body-copy-tertiary-small">{startdate}</div>
                <div className="body-copy-tertiary-small">&nbsp; to &nbsp;</div>
                <div className="body-copy-tertiary-small">{enddate}</div>
              </div>
            </div>
          </div>
        </div>
        {this.setFooterUI()}
      </div>
    );
  }
}

export default EventTile;

import React, { Component } from 'react';
import './Checkbox.css';

//Add keypress
class Checkbox extends Component {
  static defaultProps = {
    checked: false,
    style: {}
  }

  onClick() {
    if (this.props.onClick != null) {
      this.props.onClick();
    }

    this.setState({
      check: !this.props.checked
    })
  }

  setDescription() {
    if (this.props.description) {
      return (<div className="body-copy-tertiary-small">{this.props.description}</div>)
    }
    return (null)
  }

  render() {
    let className = ["d-flex", "flex-column", "align-items-start", "checkbox-container"];
    if (this.props.className) {
      className.push(this.props.className)
    };

    return (
      <div style={this.props.style} className={className.join(" ")}>

          <div style={{width: '100%'}} className='d-flex flex-row align-items-center'>
            <div  style={{width: '100%'}} className="d-flex flex-row">
              <div>
                <div role="checkbox" tabIndex="0" aria-checked={this.props.checked} name={this.props.value} value={this.props.value} onClick={this.onClick.bind(this)} className="checkbox-outer d-flex align-items-center justify-content-center">
                  <div className={(this.props.checked ? "checkbox-inner checkbox-open" : "checkbox-inner")} />
                </div>
              </div>
              <div onClick={this.onClick.bind(this)} style={{display: 'inline-block', width: '100%'}} className={`checkbox-label ${this.props.description ? 'body-copy-tertiary' : 'body-copy-tertiary-small'}`}>{this.props.label}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default Checkbox;

import React, { Component } from 'react';
import Form from 'components/Form';
import Eyebrow from 'components/Eyebrow';
import List from 'common/list.component';
import Detail from 'common/Detail';
import Divider from 'common/Divider.component';
import Button from 'components/new/Button';
import ButtonGroup from 'components/ButtonGroup';
import Row from 'common/Row.component';
import Modal from 'components/Modal';
import Image from 'components/Image';
import SelectedOptionsImg from 'assets/selected_options.svg';
import { Routes } from 'constants/Routes';
import { generateDateTimeFormat } from 'common/helpers/date';
import { generateCurrencyFormat } from 'common/helpers/currency';
import { getPortfolio, publishPortfolio } from 'services/portfolios/portfolios.actions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import './portfolioreview.css';

const {
  PORTFOLIO_SPONSORSET,
  PORTFOLIO_OBJECTIVES,
  PORTFOLIO_AUDIENCE,
  PORTFOLIO_PREFERENCES,
  PORTFOLIO_PLACEMENTS,
  SPONSOR_HOME
} = Routes;

const KEYS = {
  "Age Range": "Age Ranges",
  "Location": "Locations",
  "Occupation": "Occupations",
  "Industry": "Industries",
  "Job Function": "Job Function",
  "Job Seniority": "Seniority Level"
}

//Very similar to portfolio.screen.js
class PortfolioReview extends Component {
  static defaultProps = {

  }

  constructor(props) {
    super(props);
    this.state = {
      modalVisible: false
    }
  }

  componentDidMount() {
    let { isPortfolioObjectivePageValid, isPortfolioAudiencePageValid, isPortfolioPreferencesPageValid, history } = this.props;
    if (!isPortfolioObjectivePageValid || !isPortfolioAudiencePageValid || !isPortfolioPreferencesPageValid) {
      history.push(PORTFOLIO_OBJECTIVES);
      return;
    }
    this.props.getPortfolio(this.props.portfolio.id);
  }

  //Similar code in eventreview.screen.js, event.screen.js
  setAudienceProfile(audienceAttributes) {
    let buffer = [];
    let items = [];
    let index = 0;
    let label = "";
    let map = new Map();
    let newAudience = new Map();

    audienceAttributes.map((item) => {
      if (!newAudience.has(item.type)) {
        newAudience.set(item.type, []);
      }
      newAudience.get(item.type).push(item);
    })

    newAudience.forEach((value, key) => {
      items = value.map((attribute) => attribute.value);
      map.set(key, items)
    })

    map.forEach((value, key) => {
      label = key;

      if (KEYS[key]) {
          label = KEYS[key];
      }

      buffer.push(<Row key={key} label={key} value={value.join(", ")} />)
    })

    return buffer
  }

  getPlacementPreferences(preferences){
    return preferences.map((item)=> <div>{`${item.type}: ${item.placement}`}</div>)
  }

  goToRoute(route) {
      this.props.history.push(route);
  }

  onClickGoBack() {
    this.props.history.push(PORTFOLIO_PREFERENCES);
  }

  onClickSubmit() {
    this.props.publishPortfolio(this.props.portfolio.id);
    this.setModalVisible(true);
  //  this.goToRoute(SPONSOR_HOME);
    //this.props.publishPortfolio(this.props.portfolio.portfolio.id);
  }

  setModalVisible(visible) {
    this.setState({
      modalVisible: visible
    })
  }

  closeModal() {
    this.goToRoute(SPONSOR_HOME);
    this.setState({modalVisible: false});
  }

  render() {

    if (!this.props.portfolio) {
      return (null);
    }

    let { modalVisible } = this.state;
    let { portfolio, portfolio_audience_profile, objective, lower_bound, upper_bound, preferences } = this.props;
    let { name } = portfolio;

    return (
      <div>
        <div>
          <Detail
            justification="start"
            style={{marginBottom: '25px', textAlign: 'Start'}}
            description="Please review. Finish creating your portfolio to begin receiving recommendations for event sponsorships."
            header="Review"
            subHeader="Step 4 of 4" />
          <Divider type="brand secondary"/>
          <div className="portfolioreview-details">
            <div className="d-flex flex-md-row flex-column justify-content-between review-row">
              <div>
                <label className="body-copy-bold-orange">Objectives</label>
                <div className="body-copy-tertiary">
                  <Row label="Name" value={name} />
                  <Row label="Objective" value={objective} />
                </div>
              </div>
              <div>
                <Button label="Edit" type="quarternary" size="xs" onClick={this.goToRoute.bind(this, PORTFOLIO_OBJECTIVES)} />
              </div>
            </div>
          </div>
          <Divider type="brand secondary" />
          <div className="portfolioreview-details">
            <div className="d-flex flex-md-row flex-column justify-content-between review-row">
              <div>
                <label className="body-copy-bold-orange">Audience</label>
                {this.setAudienceProfile(portfolio_audience_profile.audience_profile.audience_attributes)}
              </div>
              <div>
                <Button label="Edit" size="xs" type="quarternary" onClick={this.goToRoute.bind(this, PORTFOLIO_AUDIENCE)} />
              </div>
            </div>
          </div>
          <Divider  type="brand secondary"/>
          <div className="portfolioreview-details">
            <div className="d-flex flex-md-row flex-column justify-content-between review-row">
              <div>
                <label className="body-copy-bold-orange">Placement Preferences</label>
                <div className="body-copy-tertiary">
                  <Row label="Preferences" value={this.getPlacementPreferences(preferences)} />
                  <Row label="Budget" value={`${generateCurrencyFormat(lower_bound)} - ${generateCurrencyFormat(upper_bound)}`} />
                </div>
              </div>
              <div>
                <Button label="Edit" type="quarternary" size="xs" onClick={this.goToRoute.bind(this, PORTFOLIO_PREFERENCES)} />
              </div>
            </div>
          </div>
          <Divider  type="brand secondary"/>
        </div>
        <div style={{marginTop: '50px'}}>
          <div style={{float: 'right', marginBottom: '10px'}} className="body-copy-tertiary">Step 4 of 4</div>
          <ButtonGroup
            labelPrimary="Submit"
            labelSecondary="Back"
            onClickPrimary={this.onClickSubmit.bind(this)}
            onClickSecondary={this.onClickGoBack.bind(this)} />
        </div>
        <Modal
          onClickClose={this.closeModal.bind(this)}
          visible={modalVisible}>
          <div className="d-flex align-items-center flex-column" style={{padding: '24px', backgroundColor: 'white'}}>
            <Image className="portfolioreview-image" src={SelectedOptionsImg} />
            <Detail style={{marginBottom: '50px'}} header="Thank you for creating a portfolio" subHeader="Next steps - Check your email soon. We’ll send you event sponsorship recommendations using the information you’ve provided." />
            <Button size="md" label="MY PORTFOLIOS" type="tertiary" onClick={this.closeModal.bind(this)}/>
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {

  return {
    lower_bound: state.portfolios.lower_bound,
    upper_bound: state.portfolios.upper_bound,
    portfolio: state.portfolios.currentPortfolio,
    objective: state.portfolioobjectives.objective,
    portfolio_audience_profile: state.portfolioaudienceprofile.portfolio_audience_profile,
    preferences: state.sponsorpreferences.preferences,
    isPortfolioObjectivePageValid: state.portfolios.isPortfolioObjectivePageValid,
    isPortfolioAudiencePageValid: state.portfolios.isPortfolioAudiencePageValid,
    isPortfolioPreferencesPageValid: state.portfolios.isPortfolioPreferencesPageValid
  };
};

const mapDispatchToProps = dispatcher => (
    bindActionCreators({
      getPortfolio,
      publishPortfolio
    }, dispatcher)
);

export default connect(mapStateToProps, mapDispatchToProps)(PortfolioReview);

import React, { Component } from 'react';
import Detail from 'common/Detail';
import Button from 'components/new/Button';
import Modal from 'components/Modal';
import Upload from 'components/Upload';
import ButtonGroup from 'components/ButtonGroup';
import Places from 'common/Places';
import TextField from 'components/TextField';
import FormGroup from 'components/FormGroup';
import Calendar from 'components/Calendar';
import moment from 'moment';
import './editeventoverviewmodal.css';

class EditEventOverviewModal extends Component {
  constructor(props) {
    super(props);
    let { event, location } = props;

    this.state = {
      description: {
        value: event.description,
        error: false
      },
      audience_description: {
        value: event.audience_description || "",
        error: false
      },
      organization_description: {
        value: event.organization_description || "",
        error: false
      },
      tagline: {
        value: event.tagline || "",
        error: false
      },
      name: {
        value: event.name || "",
        error: false
      },
      website: {
        value: event.website || "",
        error: false
      },
      place_id: {
        value: location.place_id,
        error: false
      },
      place_name: {
        value: location.description
      },
      startdate: {
        value: event.startdate,
        error: false
      },
      enddate: {
        value: event.enddate,
        error: false
      }
    }
  }

  onChange(event) {
    this.setState({ [event.target.name]: {
      value: event.target.value,
      error: this.state[event.target.name].error
      }
    })
  }

  onAutocompleteOptionSelection(selected) {
    let place = selected[0];
    this.setState({
      place_id: {
        value: place.placeId,
        error: false
      },
      place_name: {
        value: place.description
      }
    });
  }

  onChangeDate(key, value) {
    this.setState({
      [key]: {
        value,
        error: false
      },
    }, () => {
      if (key === "startdate" && this.state.enddate.value !== null && this.state.startdate.value > this.state.enddate.value) {
        this.setState({
          enddate: {
            value: this.state.startdate.value,
            error: false
          }
        })
      }
    });
  }

  onClickSave() {
    let event = {
      ...this.props.event,
      name: this.state.name.value,
      place_id: this.state.place_id.value,
      startdate: this.state.startdate.value,
      enddate: this.state.enddate.value,
      place_name: this.state.place_name.value,
      tagline: this.state.tagline.value,
      website: this.buildUrl(this.state.website.value.toLowerCase())
    };

    this.props.putEvent(event);
  }

  buildUrl(url) {
    if (!url.includes("http")) {
      return `http://${url}`;
    }
    return url;
  }

  disabledDate(current) {
    return current && current < moment().endOf('day');
  }

  render() {
    let { location, logos} = this.props;
    let { website, tagline, name, place_id, place_name, startdate, enddate } = this.state;

    let place_description = (location.description ? location.description : `${location.name}, ${location.formatted_address}`)

    let editLogoElement;

    if (logos.length > 0) {
      editLogoElement = (
        <div>
          <div className="body-copy-bold-tertiary" style={{marginBottom: '8px'}}>Event Logo</div>
          <img alt="Event or company logo" style={{width: '80%', maxWidth: '355px', marginBottom: '15px'}} src={logos[0].src} />
          <div>
            <Button
              label="Remove"
              type="tertiary"
              onClick={this.props.removeAsset.bind(this, logos[0].id)} />
          </div>
        </div>
      )
    } else {
      editLogoElement = (
        <Upload multiple={false} label="Event Logo" accept="image/*" onSubmit={this.props.onClickSubmitLogo.bind(this)} />
      )
    }

  /*  size="large"*/
    return (
      <Modal

        appendFooter={
          ()=>{
            return (
              <ButtonGroup
                labelPrimary="SAVE"
                labelSecondary="CANCEL"
                onClickPrimary={this.onClickSave.bind(this)}
                onClickSecondary={this.props.onClickClose.bind(this)} />
            )
          }
        }
        onClickClose={this.props.onClickClose.bind(this)}
        visible={this.props.isEditModalOpen}>
          <div className="edit-modal-wrapper d-flex justify-content-center">
            <div className="edit-modal-container">
              <Detail justification="start" style={{marginBottom: '25px', textAlign: 'Start'}} header="Company Info" subHeader="Update your event name, tagline, and other information about your event." />
              {editLogoElement}
              <TextField
                fontStyle="tertiary"
                name="name"
                placeholder="Enter the event name"
                value={name.value}
                errorMessage="Event name cannot be empty."
                error={name.error}
                onChange={this.onChange.bind(this)}
                label="Event Name"
                size="full" />
              <FormGroup>
                <Calendar
                  timeFormat={false}
                  fontStyle="tertiary"
                  value={(startdate.value ? new Date(startdate.value) : null)}
                  disabledDate={this.disabledDate.bind(this)}
                  placeholder="Start date and time"
                  errorMessage="Start date cannot be empty."
                  error={startdate.error}
                  onChange={this.onChangeDate.bind(this, "startdate")}
                  label="Start Date"
                  size="full"/>
                <Calendar
                  timeFormat={false}
                  fontStyle="tertiary"
                  placeholder="End date and time"
                  isValidDate={(current) => current.isAfter(this.state.startdate.value || new Date())}
                  value={(enddate.value ? new Date(enddate.value) : null)}
                  disabledDate={this.disabledDate.bind(this)}
                  errorMessage="End date cannot be empty."
                  error={enddate.error}
                  onChange={this.onChangeDate.bind(this, "enddate")}
                  label="End Date"
                  size="full"/>
              </FormGroup>
              <TextField
                fontStyle="tertiary"
                name="tagline"
                placeholder="Enter a one sentence event description"
                value={tagline.value}
                errorMessage="Event name cannot be empty."
                error={tagline.error}
                onChange={this.onChange.bind(this)}
                label="Event Tagline"
                size="full" />
              <TextField
                fontStyle="tertiary"
                name="website"
                placeholder="Enter the event website"
                value={website.value}
                errorMessage="Please enter a valid URL."
                error={website.error}
                onChange={this.onChange.bind(this)}
                label="Event Website"
                size="full" />
              <Places
                isGeopoliticalRegionAllowed={false}
                fontStyle="tertiary"
                label="Venue"
                value={place_name.value ? place_name.value : place_description}
                placeholder="Event venue"
                errorMessage="Please enter the venue of the event."
                error={place_id.error}
                onOptionSelection={this.onAutocompleteOptionSelection.bind(this)} />
            </div>
          </div>
      </Modal>
    );
  }
}

export default EditEventOverviewModal;

import React, { Component } from 'react';
import './Benefit.css'

class Benefit extends Component {

  render() {
    let { label, descriptionTop, descriptionBottom, src, alt } = this.props;

    let className = ["d-flex", "flex-column", "align-items-center"];

    className = (this.props.reverse === true ? className.concat(['flex-md-row-reverse']) : className.concat(['flex-md-row']));

    return (
      <div className="benefit-container">
        <div className="container">
          <div className={className.join(" ")}>
            <div className="col-md-6 col-12 d-flex flex-column">
              <div className="benefit-label benefit-label-position">{label}</div>
              <div className="benefit-description">{descriptionTop}</div>
              <div className="benefit-description">{descriptionBottom}</div>
            </div>
            <div className="col-md-6 col-12">
              <div className="benefit-image-container">
                <img alt={alt} className="benefit-image" src={src}/>
              </div>
            </div>
          </div>
        </div>
      </div>

    );
  }
}

export default Benefit;

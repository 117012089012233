import React, { Component } from 'react';

class Header extends Component {
  getClassName(level) {
    if (level === 2) {
      return 'header-2-tertiary';
    }

    if (level === 4) {
      return 'header-4-tertiary';
    }
  }
  render() {
    let { level, content, href } = this.props
    if (href) {
      return <div id={href} className={this.getClassName(level)}>{content}</div>;
    }
    return (
      <div className={this.getClassName(level)}>{content}</div>
    );
  }
}

export default Header;

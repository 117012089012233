import { ActionTypes } from './eventaudienceprofile.actions';

const initialState = {
    errorMessage: "",
    isEventAudiencePageValid: false,
    event_audience_profile: null
};

const EventAudienceProfileReducer = (state = initialState, action) => {
    switch (action.type) {
      case ActionTypes.POST_EVENT_AUDIENCE_PROFILE_SUCCESS:
        return { ...state, event_audience_profile: action.event_audience_profile };
      case ActionTypes.PUT_EVENT_AUDIENCE_PROFILE_SUCCESS:
        return { ...state, event_audience_profile: action.event_audience_profile };
      case ActionTypes.SET_EVENT_AUDIENCE_PAGE_STATE:
        return { ...state,isEventAudiencePageValid: action.payload.isValid };
      default:
          return state;
    }
};

export default EventAudienceProfileReducer;

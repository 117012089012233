import React, { Component } from 'react';
import Section from './components/Section';
import logo from './assets/logo.svg';
import dollarflow from './assets/dollarflow.png';
import heart from './assets/heart.png';
import presentation from './assets/presentation.png';
import grid from './assets/grid.png';
import dot from './assets/dot.png';
import rocket from './assets/rocket.svg';
import chart from './assets/chart.svg';
import links from './assets/links.svg';
import freckles from './assets/freckles.png';
import bag from './assets/bag.svg';
import './Organizers.css'

class Organizers extends Component {

  render() {
    return (
      <div className="home-organizers-dots">
        <div className="container">
          <div className="d-flex flex-sm-row align-items-center flex-column home-organizers-cards flex-wrap">
            <Section
              header="Create Sponsorship Opportunities"
              src={rocket}
              alt="Icon of light bulb."
              text="Create event pages quickly through our standardized form and easily add details later" />
`            <Section
              header="Connect with Partners"
              alt="Icon of an empty price tag"
              src={chart}
              text="Start a conversation with recommended partners and track their status" />`
            <Section
              header="Target Custom Audiences"
              alt="Icon of nodes linked to one another."
              src={links}
              text="Set preferences and create profiles to define target cities, industries, and more" />
            <Section
              header="Sell Sponsorships Online"
              alt="Icon of an empty price tag"
              src={bag}
              text="Get closer to a done deal with a platform that helps you at each step" />
          </div>
          <div className="home-organizers-how col-md-7 col-12">
            <div style={{marginBottom: '30px'}} className="header-2-darkpurple">Here’s How It Works</div>
            <div className="body-copy-white">We’re a marketplace to help sponsors and event organizers find the right partners using recommendations and audience targeting. </div>
          </div>
        </div>
        <img className="organizers-floating-img" src={presentation} />
        <img className="organizers-floating-grid" src={grid} />
        <img className="organizers-floating-freckles" src={freckles} />
        <img className="organizers-floating-dot" src={dot} />
      </div>
    );
  }
}

export default Organizers;

import React, { Component } from 'react';
import How from './sections/how/How';
import Sponsors from './sections/sponsors/Sponsors';
import Organizers from './sections/organizers/Organizers';
import Services from './sections/services/Services';
import Footer from '../learnmore/components/footer.component';
import Benefits from './sections/benefits/Benefits';
import Hero from './sections/hero/Hero';
import Button from 'components/new/Button';
import Arrow from './arrow.png';
import dots from './assets/dots.svg';
import { Routes } from 'constants/Routes';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import './Home.css';

class Home extends Component {
  openCalendly() {
    window.open("https://www.calendly.com/kiamber", "_blank",)
  }

  goToCreateAnEvent() {
    this.props.history.push(Routes.ORGANIZER_ONBOARDING_INTRO)
  }

  render() {
    return (
      <div>
        <Hero history={this.props.history} firebase={this.props.firebase} />
        <How history={this.props.history} />
        <Sponsors history={this.props.history}/>
        <div className="banner-container">
          <CarouselProvider
             naturalSlideWidth={100}
             naturalSlideHeight={10}
             totalSlides={2}
             isPlaying={true}
             interval={5000}
             className="d-flex flex-row"
           >
            <Slider className="banner-slider-container" style={{width: '100%'}}>
                <Slide index={0}>
                  <div className="banner-slider-slide d-flex flex-column align-items-sm-center align-items-start">
                    <div style={{marginBottom: '15px'}} className="header-2-quarternary">Fulfill Your Event Aspirations</div>
                    <div className="body-copy-tertiary">Never miss event success again. Find partners that fit your brand and target audience.</div>
                  </div>
                </Slide>
                <Slide index={1}>
                   <div className="banner-slider-slide d-flex flex-column align-items-sm-center align-items-start">
                     <div style={{marginBottom: '15px'}} className="header-2-quarternary">Save Time with Introductions</div>
                     <div className="body-copy-tertiary">Let us help you navigate the massive and ever-changing sponsorships landscape.</div>
                   </div>
                 </Slide>
              </Slider>
              <div style={{position: 'absolute', right: '0px', height: '100%', right: '10%'}} className="d-flex align-items-center">
                <div style={{marginLeft: '30px'}}>
                    <img style={{width: '29px'}} src={Arrow} />
                </div>
              </div>
           </CarouselProvider>
        </div>
        <Organizers />
        <div className="container" style={{position: 'relative'}}>
          <img className="home-dots" src={dots} />
          <div className="home-wrapping-up-block container d-flex align-items-end flex-column">
            <div className="header-2-darkpurple">Expanding your sponsorships network is <s className="home-strikethrough-container d-inline-flex align-items-center"><s className="home-strikethrough"/>hard</s></div>
            <div className="header-2-orange">Well not anymore...</div>
          </div>
        </div>
        <Footer
          background="purple">
          <div className="d-flex flex-sm-row resources-ctas flex-column">
            <div className="d-flex align-items-center resources-create-cta"><Button label="CREATE EVENT" size="sm" type="primary" onClick={this.goToCreateAnEvent.bind(this)}  /></div>
            <div className="d-flex align-items-center"><Button style={{marginRight: '15px'}} label="BOOK A DEMO" size="sm" type="tertiary" onClick={this.openCalendly.bind(this)}  /></div>
          </div>
        </Footer>
      </div>
    );
  }
}

export default Home;

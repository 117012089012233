import React, { Component } from 'react';
import TextField from 'components/TextField';
import Detail from 'common/Detail';
import Image from 'components/Image';
import Button from 'components/new/Button';
import CreateAccountForm from 'common/forms/createaccount.form';
import LoginForm from 'common/forms/login.form';
import ButtonGroup from 'components/ButtonGroup';
import Modal from 'components/Modal';
import Mailbox from 'assets/mailbox.svg';
import Team from 'assets/team.svg';
import Divider from 'common/Divider.component';
import { notification } from 'antd';
import { withRouter } from "react-router-dom";
import { isFilled, isEmailValid, isPasswordValid } from 'common/helpers/validators';
import { Routes } from 'constants/Routes';
import './createaccount.css';

const {
  LOG_IN
} = Routes;

class CreateAccountModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      loginFormVisible: false,
      firstname: {
        value: "",
        error: false
      },
      lastname: {
        value: "",
        error: false
      },
      email: {
        value: "",
        error: false
      },
      password: {
        value: "",
        error: false
      },
      company: {
        value: "",
        error: false
      },
      role: {
        value: "",
        error: false
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    /*
    if (this.props.error && (!prevProps.error || !this.state.error)) {
      this.setState({

      })
      notification.info({
        message: this.props.errorMessage
      });
      return;
    }

    */

    if (this.props.error && !prevProps.error) {
          console.log("uh ha ha",this.props.error, !this.props.success )
      notification.info({
        message: this.props.errorMessage
      });
      return;
    }

    if (!prevProps.resendsuccess && this.props.resendsuccess === true && !this.state.loginFormVisible && this.props.visible && !this.state.resendNotificationShowing) {
      notification.success({
        message: `Another verification e-mail has been sent to ${this.props.email}`
      });
    }

    if (this.props.loginSuccess && !prevProps.loginSuccess && prevState.loginFormVisible) {
      this.props.close();
      notification.success({
        message: "Welcome to Restage"
      });
    }
  }

  validateCreateAccount() {
    let fields = new Set();

    fields.add(this.isFieldValid(isFilled, "firstname"));
    fields.add(this.isFieldValid(isFilled, "lastname"));
    fields.add(this.isFieldValid(isEmailValid, "email"));
    fields.add(this.isFieldValid(isFilled, "company"));
    fields.add(this.isFieldValid(isPasswordValid, "password"));

    return !fields.has(false);
  }

  onSubmit() {
    if (!this.validateCreateAccount()) return null;

    let {
      firstname,
      lastname,
      email,
      password,
      company,
      role
    } = this.state;

    let data = {
      firstName: firstname.value,
      lastName: lastname.value,
      email: email.value,
      password: password.value,
      organizationName: company.value,
      role: role.value
    }

    this.props.onSubmit(data);
  }

  onClickResendEmail() {
    let { token, email } = this.props;

    this.props.resend(token, email);
  }

  onClickLogin() {
    if (this.state.loginFormVisible) {
      this.login()
      return;
    }

    this.setState({
      loginFormVisible: true
    })
  }

  login() {
    if (!this.validateLogin()) return;
    let { email, password } = this.state;

    this.props.userLogin(email.value, password.value)
  }

  validateLogin() {
    let fields = new Set();
    fields.add(this.isFieldValid(isEmailValid, "email"));
    fields.add(this.isFieldValid(isPasswordValid, "password"));
    return !fields.has(false);
  }

  isFieldValid(validator, key) {
    let isValid = validator(this.state[key].value);

    this.setState({
      [key]: {
        value: this.state[key].value,
        error: !isValid
      }
    });

    return isValid;
  }

  onChangeRole(value) {
    this.setState({ "role": {
      value,
      error: false
      }
    })
  }

  onChange(event) {
    this.setState({ [event.target.name]: {
      value: event.target.value,
      error: this.state[event.target.name].error
      }
    })
  }

  goTo(route) {
    this.props.history.push(route);
  }

  render() {
    let {
      firstname,
      lastname,
      email,
      company,
      role,
      password,
      modalVisible,
      loginFormVisible
    } = this.state;

    let {
      visible,
      success,
      loginError,
      loginErrorMessage
    } = this.props;

    let formClassName = ["container createaccount-modal-form-wrapper", "createaccount-modal-item-visible"]
    let successClassName = ["createaccount-modal-container", "createaccount-modal-item-visible", "d-flex", "align-items-center", "justify-content-center"]
    let loginFormClassName = ["createaccount-modal-item-visible"]
    let modalSize = "";
    let centered = false;

    if (success && !loginFormVisible) {
      modalSize = "large";
      successClassName = successClassName.filter((item) => item !== "createaccount-modal-item-visible")
    } else if (loginFormVisible) {
      loginFormClassName = loginFormClassName.filter((item) => item !== "createaccount-modal-item-visible");
      centered = true;
    } else {
      formClassName = formClassName.filter((item) => item !== "createaccount-modal-item-visible")
    }

    return (
      <Modal
        centered={centered}
        size={modalSize}
        appendFooter={() => {
          if (!success) {
            return(
              <ButtonGroup
                labelPrimary="CREATE"
                labelSecondary="CANCEL"
                onClickPrimary={this.onSubmit.bind(this)}
                onClickSecondary={this.props.close.bind(this)} />
            )
          }

          return(
            <ButtonGroup
              overridePrimaryType="tertiary"
              labelPrimary="LOG IN"
              labelSecondary="CANCEL"
              onClickPrimary={this.onClickLogin.bind(this)}
              onClickSecondary={this.props.close.bind(this)} />
          )
        }}
        closeColor="#BDBDBD"
        onClickClose={this.props.close.bind(this)}
        visible={visible}>
        <div className={formClassName.join(" ")}>
          <div className="d-flex align-items-center flex-column" style={{paddingTop: '30px', paddingBottom: '10px'}}>
            <div style={{marginBottom: '25px', textAlign: 'center'}} className="header-2-mid-purple">Create an account with Restage</div>
            <div style={{textAlign: 'center', maxWidth: '720px'}} className="body-copy-tertiary">
              Join the Restage network to connect with event creators and brands.
              We make it easy for you to find events that match your brand’s sponsorship objectives.
              We event help you set the first meeting! <br/><br/>Already have an account?&nbsp;<span onClick={this.goTo.bind(this, LOG_IN)} className="body-copy-tertiary-link restage-link">Log In.</span>
            </div>
          </div>
          <Divider style={{marginBottom: '15px'}} type="brand" />
          <div className="createaccount-modal-container">
            <CreateAccountForm
              role={role}
              onChangeRole={this.onChangeRole.bind(this)}
              onChange={this.onChange.bind(this)}
              firstname={firstname}
              lastname={lastname}
              email={email}
              company={company}
              password={password}
            />
          </div>
        </div>
        <div className={successClassName.join(" ")}>
          <div className="d-flex align-items-center flex-column" style={{padding: '24px', backgroundColor: 'white'}}>
            <Image className="createaccount-modal-confirmation-image" src={Mailbox} />
            <Detail style={{marginBottom: '50px'}}header="Verify your email to start using Restage.">
              Check your email to get started. Click <span onClick={this.onClickResendEmail.bind(this)} className="body-copy-tertiary-link">here</span> if you need to send the email again.
             </Detail>
          </div>
        </div>
        <div style={{paddingTop: '65px'}} className={loginFormClassName.join(" ")}>
          <LoginForm
            email={email}
            password={password}
            onChange={this.onChange.bind(this)}
            error={loginError}
            errorMessage={loginErrorMessage} />
          </div>
      </Modal>
    );
  }
}

export default withRouter(CreateAccountModal);

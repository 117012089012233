import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DownArrowPurple from 'assets/down-arrow-purple.svg';
import DownArrowWhite from 'assets/down-arrow-white.svg';
import './expand.css';
class Expand extends Component {
  static defaultProps = {
    expandChildrenContainerClassName: ""
  }

  /*getDropdown() {
    let className = `${this.props.expandChildrenContainerClassName} expand-children-container`;

    if (this.props.isOpen) {
      className = `${this.props.expandChildrenContainerClassName} expand-children-container expand-children-container-open`;
    }
    return (
      <div className={className} >
          {this.props.children}
      </div>
    )
  }*/

  getDropdown() {
    if (this.props.isOpen) {
      let className = `${this.props.expandChildrenContainerClassName} expand-children-container`;
      return (
        <div className={className} >
          {this.props.children}
          </div>
        )
    }
    return (
      null
    )
  }

  getLabel() {
    let className = "body-copy-secondary";
    if (this.props.isOpen) {
      className = "body-copy-white";
    }
    return (
      <div style={{marginRight: '15px'}} className={className}>{this.props.label}</div>
    )
  }

  getArrow() {
    if (this.props.isOpen) {
      return (<img src={DownArrowWhite} />)
    }
    return (<img src={DownArrowPurple} />)
  }

  getContainerStyle() {
    if (this.props.isOpen) {
      return {backgroundColor: "#680894"};
    }
    return {backgroundColor: "transparent"};
  }

  render() {
    return (
      <div style={{position: 'relative'}}>
        <div style={this.getContainerStyle()} onClick={this.props.onToggle.bind(this)} className="expand-container">
          <div className="d-flex flex-row">
            {this.getLabel()}
            {this.getArrow()}
          </div>
        </div>
        <div>
          {this.getDropdown()}
        </div>
      </div>
    );
  }
}

export default Expand;

import React, { Component } from 'react';
import TextField from 'components/TextField';
import Detail from 'common/Detail';
import Image from 'components/Image';
import Button from 'components/new/Button';
import Divider from 'common/Divider.component';
import Modal from 'components/Modal';
import Mailbox from 'assets/mailbox.svg';
import Select from 'components/Select';
import Team from 'assets/team.svg';
import { createUser } from 'services/signup/signup.actions';
import { resend } from 'services/verify/verify.actions';
import { isFilled, isEmailValid, isPasswordValid } from 'common/helpers/validators';
import { Routes } from 'constants/Routes';
import './createaccount.css';

const ROLES = [
  {
    value: "Event Organizer",
    label: "Event Organizer"
  },
  {
    value: "Sponsor",
    label: "Sponsor"
  }
]

class CreateAccountForm extends Component {
  render() {
    let {firstname,
      lastname,
      email,
      company,
      password,
      modalVisible,
      role,
      onChange,
      onChangeRole
    } = this.props;

    return (
      <div className="createaccount-form-container">
        <TextField
          fontStyle="tertiary"
          name="firstname"
          value={firstname.value}
          placeholder="Enter your first name"
          errorMessage="First name cannot be empty."
          error={firstname.error}
          onChange={onChange.bind(this)}
          label="First Name"
          size="full" />
        <TextField
          fontStyle="tertiary"
          name="lastname"
          error={lastname.error}
          errorMessage="Last name cannot be empty."
          placeholder="Enter your last name"
          value={lastname.value}
          onChange={onChange.bind(this)}
          label="Last Name"
          size="full" />
        <TextField
          fontStyle="tertiary"
          name="email"
          error={email.error}
          errorMessage="Please enter a valid e-mail."
          value={email.value}
          placeholder="Enter your work e-mail address"
          onChange={onChange.bind(this)}
          label="Work Email Address"
          size="full" />
        <TextField
          fontStyle="tertiary"
          name="password"
          type="password"
          error={password.error}
          errorMessage="Password must be at least 6 characters."
          placeholder="Enter your password"
          value={password.value}
          onChange={onChange.bind(this)}
          label="Password"
          size="full" />
        <TextField
          fontStyle="tertiary"
          name="company"
          error={company.error}
          errorMessage="Company name cannot be empty."
          placeholder="Enter your company name"
          value={company.value}
          onChange={onChange.bind(this)}
          label="Company Name"
          size="full" />
        <Select
          options={ROLES}
          name="role"
          error={role.error}
          errorMessage="Please select a role"
          placeholder="Select a role"
          value={role.value}
          onChange={onChangeRole.bind(this)}
          label="Role"
          size="full" />
      </div>
    );
  }
}

export default CreateAccountForm;

import { ActionTypes } from './portfoliodesiredplacements.actions';

const initialState = {
    errorMessage: "",
    placements: []
};

const PortfolioDesiredPlacementsReducer = (state = initialState, action) => {
    switch (action.type) {
      case ActionTypes.ADD_PLACEMENT_SUCCESS:
        let contains = state.placements.filter((placement) => placement === action.placement);
        let placements = [];

        if (contains.length > 0) {
          placements = [...state.placements];
        } else {
          placements = [...state.placements, action.placement]
        }
        return {
          ...state,
          placements
        };
      case ActionTypes.REMOVE_PLACEMENT_SUCCESS:

        let newPlacements = state.placements.filter((placement) => !(placement === action.placement));

        return {
          ...state,
          placements: [...newPlacements]
        };
      default:
          return state;
    }
};

export default PortfolioDesiredPlacementsReducer;

import React, { Component } from 'react';
import Wrapper from 'components/Wrapper';
import Eyebrow from 'components/Eyebrow';
import Detail from 'common/Detail';
import Button from 'components/new/Button';
import TextField from 'components/TextField';
import FormGroup from 'components/FormGroup';
import { Table } from 'antd';
import { getEvent } from 'services/events/events.actions';
import { generateCurrencyFormat } from 'common/helpers/currency';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

const columns = [
  {
    title: 'Sponsor Name',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: 'Count',
    dataIndex: 'count',
    key: 'ranking'
  },
  {
    title: 'Sponsor URL',
    dataIndex: 'url',
    key: 'url'
  },
  {
    title: 'Events',
    dataIndex: 'events',
    key: 'events'
  }
];

class SponsorRankings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: []
    }
  }

  componentWillMount() {

    fetch("https://api.restagex.com/sponsor-rankings", {
      //fetch("http://localhost:9000/sponsor-rankings", {
      method: "GET",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json"
      },
      redirect: "follow",
      referrer: "no-referrer"
    }).then((response) => {
      return response.json();
    }).then((data) => {
      let results = []
      data.map((item, index) => {

        let { name, url, events, count } = item;
        results.push({
          name,
          count,
          url: <a href={url} target="_blank" key={index} rel="noopener noreferrer">{url}</a>,
          events: this.getEventsDiv(events)
        })
      });
      this.setState({
        data: results
      })
    });
  }

  getEventsDiv(events) {
    return (
      <div>
        {
          events.map((event, index) => {
            return (
              <div className="d-flex flex-row" key={index}>
                <div className="col-6">{event.name}</div>
                <div className="col-6">
                  <a href={event.url} target="_blank" rel="noopener noreferrer">{event.url}</a>
                </div>
              </div>
            )
          })
        }
      </div>
    )
  }

  _handleOnChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  render() {
    let { data } = this.state;

    return (
      <Wrapper className="container">
      <div className="d-flex flex-row justify-content-between">
        <Detail justification="start" header="Sponsor Rankings" subHeader="Sponsor Rankings" />
      </div>
        <div style={{marginTop: '25px'}}>
          <Eyebrow label="Sponsor Rankings" />
          <Table dataSource={data} columns={columns} />
        </div>
      </Wrapper>
    );
  }
}

const mapStateToProps = (state) => {
  return {

  };
};

const mapDispatchToProps = dispatcher => (
    bindActionCreators({

    }, dispatcher)
);

export default connect(mapStateToProps, mapDispatchToProps)(SponsorRankings);

import React, { Component } from 'react';
import LearnMorePageSegment from './components/pagesegment.component';
import Hero from './components/hero.component';
import HeroWithImage from './components/herowithimage.component';
import Footer from './components/footer.component';
import AudienceImg from './assets/audience.svg';
import SponsorshipPreferencesImg from './assets/sponsorshippreferences.svg';
import GetEventRecommendationsImg from './assets/geteventrecommendations.svg';
import RequestSponsorshipPackagesImg from './assets/requestsponsorshippackages.svg';
import ConnectImg from './assets/connect.svg';
import DotsRightImg from './assets/dots-right.svg';
import DotsLeftImg from './assets/dots-left.svg';
import Button from 'components/new/Button';
import { Routes } from 'constants/Routes';
import './sponsor.css';

class Sponsor extends Component {
  goToCreateAnEvent() {
    //TODO: If user is logged in, go to create an event flow.
    this.props.history.push(Routes.ORGANIZER_ONBOARDING_INTRO)
  }

  openCalendly() {
    window.open("https://www.calendly.com/kiamber", "_blank",)
  }

  render(){
    return(
      <div>
        <HeroWithImage
          img={AudienceImg}
          header="Target specific event audiences"
          description="Tired of low-quality inbound sponsorship requests? Get event recommendations using audience targeting."
          onCTA={this.openCalendly.bind(this)}
          buttonType="tertiary"
          ctaLabel="Book A Demo">
            <img className="sponsor-dots-right" src={DotsRightImg} />
            <img className="sponsor-dots-left" src={DotsLeftImg} />
          </HeroWithImage>
          {/*<div className="learnmore-eventorganizer-header">
            <div className="container header-2-purple">Here's How It Works</div>
          </div>*/}
          <div style={{position: 'relative', zIndex: '1'}} className="learnmore-sponsor-create-container pagesegment-container-white d-flex flex-column flex-lg-row">
            <div className="container d-flex justify-content-center align-items-center">
              <div className="pagesegment-row d-flex">
                <div className="col-lg-6 col-12">
                  <div className="pagesegment-header header-2-brand">Set Sponsorship Preferences</div>
                  <div className="pagesegment-description body-copy-tertiary">How are you finding event sponsorships when the sponsorships landscape is vast and ever-changing? Restage lets you set your sponsorship objectives, create audiences, and find events that fit your brand.</div>
                </div>
              </div>
            </div>
            <div>
              <img className='sponsor-preferences-img' src={SponsorshipPreferencesImg} />
            </div>
          </div>
        <div className="sponsor-band d-flex align-items-center justify-content-center flex-column">
          <div style={{textAlign: 'center'}} className="container header-2-white">Set up a new process for purchasing sponsorships.</div>
          <div style={{textAlign: 'center'}} className="container header-2-white">Customize sponsorship packages and streamline your proposal conversations.</div>
        </div>
        <div className="sponsor-pagesegments-container">
          <LearnMorePageSegment
            img={GetEventRecommendationsImg}
            orientation="reverse"
            header="Get Event Recommendations"
            description="What do you do when inbound leads aren't matching your current sponsorship objectives? Our recommendations engine helps you find events by showing you events that meet your preference." />
          <LearnMorePageSegment
            img={RequestSponsorshipPackagesImg}
            header="Request Sponsorship Packages"
            description="Send event organizers a request to let them know you’re interested in their sponsorships. Customize packages before sending your request. Restage helps you quickly set up meetings to discuss the next steps."/>
        </div>
        <Footer
          topheader="Want to learn more?"
          bottomheader="Get in touch."
          background="pink">
          <div className="d-flex flex-row align-items-center resources-ctas">
            <div>
              <Button label="BOOK A DEMO" size="sm" type="tertiary" onClick={this.openCalendly.bind(this)}  />
            </div>
          </div>
        </Footer>
      </div>
    )
  }
}

export default Sponsor;

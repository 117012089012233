import React, { Component } from 'react';
import './Detail.css'

class Detail extends Component {
  static defaultProps = {
    descriptionStyle: {},
    color: 'white'
  }
  render() {
    let classNameDetail = ["detail-detail detail-detail-position"];
    let classNameDescription = ["detail-description"];

    classNameDetail = (this.props.justification === 'start' ? classNameDetail.concat(["detail-start"]) : classNameDetail);
    classNameDetail = (this.props.justification === 'end' ? classNameDetail.concat(["detail-end"]) : classNameDetail);

    classNameDescription = (this.props.justification === 'start' ? classNameDescription.concat(["detail-start"]) : classNameDescription);
    classNameDescription = (this.props.justification === 'end' ? classNameDescription.concat(["detail-end"]) : classNameDescription);

    classNameDetail = (this.props.color === 'white' ? classNameDetail.concat(["detail-white"]) : classNameDetail);
    classNameDetail = (this.props.color === 'black' ? classNameDetail.concat(["detail-black"]) : classNameDetail);

    classNameDescription = (this.props.color === 'white' ? classNameDescription.concat(["detail-white"]) : classNameDescription);
    classNameDescription = (this.props.color === 'black' ? classNameDescription.concat(["detail-black"]) : classNameDescription);

    classNameDetail = (this.props.toggle === 'BTW' ? classNameDetail.concat(["detail-btw"]) : classNameDetail);
    classNameDetail = (this.props.toggle === 'WTB' ? classNameDetail.concat(["detail-wtb"]) : classNameDetail);

    classNameDescription = (this.props.toggle === 'BTW' ? classNameDescription.concat(["detail-btw"]) : classNameDescription);
    classNameDescription = (this.props.toggle === 'WTB' ? classNameDescription.concat(["detail-wtb"]) : classNameDescription)

    return (
      <div className="d-flex flex-column">
        <div className={classNameDetail.join(" ")}>{this.props.detail}</div>
        <div style={this.props.descriptionStyle} className={classNameDescription.join(" ")}>{this.props.description}</div>
      </div>
    );
  }
}

export default Detail;

import { put, call, all, takeLatest } from 'redux-saga/effects';
import { ActionTypes } from './forgotpassword.actions';
import { forgotPassword } from './forgotpassword.api';

export const sendForgotPasswordEmail = function* (action) {
  try {
    const { payload: { email } } = action;
    yield call(forgotPassword, email);
    yield put({ type: ActionTypes.FORGOT_PASSWORD_SUCCESS, success: true });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: ActionTypes.FORGOT_PASSWORD_FAILURE, error });
  }
}

function* forgotpassword() {
  yield all([
    takeLatest(ActionTypes.FORGOT_PASSWORD, sendForgotPasswordEmail)
  ]);
}

export default forgotpassword;

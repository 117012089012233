import React, { Component } from 'react';
import Checkbox from 'components/Checkbox';
import CheckboxWrapper from 'components/CheckboxWrapper';
import ButtonGroup from 'components/ButtonGroup';
import Divider from 'common/Divider.component';
import Autocomplete from 'components/Autocomplete';
import Form from 'components/Form';
import RadioGroup from 'components/RadioGroup';
import Detail from 'common/Detail';
import TextField from 'components/TextField';
import { Routes } from 'constants/Routes';
import { generateCurrencyFormat } from 'common/helpers/currency';
import { addPlacement, removePlacement, postPortfolioDesiredPlacements } from 'services/portfoliodesiredplacements/portfoliodesiredplacements.actions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import './portfolioplacements.css';

class PortfolioPlacements extends Component {
  static defaultProps = {

  }

  setPlacementChecked(option) {
    let filteredArray = this.props.portfoliodesiredplacements.filter((placement) => placement === option);
    if (filteredArray.length > 0) {
      return true;
    }
    return false;
  }

  onClickPlacement(option) {
    let filteredArray = this.props.portfoliodesiredplacements.filter((placement) => {
      return placement === option;
    });

    if (filteredArray.length === 0) {
      this.props.addPlacement(option);
    } else {
      this.props.removePlacement(option);
    }
  }

  setPlacementOptionsUI() {

  }

  setPlacementsUI() {
    let placements = [
      "Publicity in your online marketing",
      "Advertising in event collateral",
      "Publicity on event website",
      "Logo on cocktail napkins",
      "Logo on lanyard",
      "Logo on flyer",
      "Half page ad in program book",
      "Logo inclusion on ads",
      "Sign by food station",
      "Thank you on social media" //Instagram posts? Tweet? Facebook?
    ];

    return (
      <div>
      <CheckboxWrapper>
        {
          placements.map((option, index) => {
            return (
              <Checkbox className="col-sm-6 col-12" key={index} checked={this.setPlacementChecked(option)} label={option} onClick={this.onClickPlacement.bind(this, option)} value={option} />
            )
          })
        }
      </CheckboxWrapper>
      </div>
    );
  }



  goToPortfolioReview() {
    let { id } = this.props.portfolio;
    let placements = {
      portfolio_id: id,
      desired_placements:  this.props.portfoliodesiredplacements
    }

    this.props.postPortfolioDesiredPlacements(placements)
    this.props.history.push(Routes.PORTFOLIO_REVIEW);
  }

  goToPortfolioAudienceProfile() {
    this.props.history.push(Routes.PORTFOLIO_AUDIENCE);
  }

  selectedDesiredPlacementType() {
    let placementtypes = [
      {"id": 1, "type": "Digital Placements", "description": "Posts on social media, logo on conference website, digital ads, etc."},
      {"id": 2, "type": "Physical Placements", "description": "Physical advertisements, logo on conference flyers, logo on stage banner, etc."},
      {"id": 3, "type": "Presentational Placements", "description": "Opportunity to speak to audience, video message for conference website visitors"}
    ];

    let items = placementtypes.map((item)=>{
      return {
        id: item.id,
        type: item.type,
        description: item.description
      }
    });

    return  (
      <div style={{marginTop: '50px', marginBottom: '50px'}}>
        <RadioGroup items={items} onSelected={(()=>{})}/>
      </div>
    );
  }

  selectedDesiredPlacementTypeCheckbox() {
    let placementtypes = [
      {"id": 1, "type": "Digital (i.e. Posts on social media, logo on conference website, digital ads, etc.) ", "description": "Posts on social media, logo on conference website, digital ads, etc."},
      {"id": 2, "type": "Physical (i.e. Physical advertisements, logo on conference flyers, logo on stage banner, etc.)", "description": "Physical advertisements, logo on conference flyers, logo on stage banner, etc."},
      {"id": 3, "type": "Presentational (i.e. Opportunity to speak to audience, video message for conference website visitors)", "description": "Opportunity to speak to audience, video message for conference website visiters"}
    ];

    return  (
      <div>
        <label className="body-copy-bold-tertiary">Placement Types</label>
        <div className="portfolio-placement-types" style={{marginTop: '20px', marginBottom: '50px'}}>
          {
            placementtypes.map((item, index)=>{
              return (
                <Checkbox
                  key={index}
                  label={item.type}
                  description={item.description}
                  checked={this.setPlacementChecked(item.id)}
                  onClick={this.onClickPlacement.bind(this, item.id)} />
              )
            })
          }
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="container">
        <div style={{marginBottom: '50px'}}>
          <Detail justification="start" style={{marginBottom: '25px', textAlign: 'Start'}} header="Desired Placements" subHeader="Choose the types of marketing content you would be interested in  purchasing for events." />
          {/*this.setPlacementsUI()*/}
          {/*this.selectedDesiredPlacementType()*/}
          {this.selectedDesiredPlacementTypeCheckbox()}
        </div>
        <div style={{marginTop: '50px'}}>
          <div style={{float: 'right', marginBottom: '10px'}} className="body-copy-tertiary">Step 3 of 4</div>
          <ButtonGroup
            labelPrimary="Review"
            labelSecondary="Back"
            onClickPrimary={this.goToPortfolioReview.bind(this)}
            onClickSecondary={this.goToPortfolioAudienceProfile.bind(this)} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    portfoliodesiredplacements: state.portfoliodesiredplacements.placements,
    portfolio: state.portfolios.currentPortfolio
  };
};

const mapDispatchToProps = dispatcher => (
    bindActionCreators({
        addPlacement,
        removePlacement,
        postPortfolioDesiredPlacements
    }, dispatcher)
);

export default connect(mapStateToProps, mapDispatchToProps)(PortfolioPlacements);

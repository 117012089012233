import React, { Component } from 'react';
import Option from './option.component';
import './objectivesgroup.css';

class ObjectivesGroup extends Component {
  render() {
    let { header, objectives, className, onClick } = this.props;
    return (
      <div className={(className ? className: "" )}>
        <div className="body-copy-bold-tertiary objectivesgroup-header">{header}</div>
        <div>
          {
            objectives.map((objective, index) => {
              return <Option active={objective.active} key={index} option={objective.objective} onClick={onClick.bind(this)} />
            })
          }
        </div>
      </div>
    );
  }
}

export default ObjectivesGroup;

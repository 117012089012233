import { put, call, all, takeLatest } from 'redux-saga/effects';
import { ActionTypes } from './sponsorships.actions';
import { post } from '../Api'

const basePath = "sponsorships"

export const postSponsorship = function* (action) {
  const { payload: { data } } = action;
  try {
    const response = yield call(post, basePath, data);
    const json = response.json();
    yield put({ type: ActionTypes.POST_SPONSORSHIP_SUCCESS, json });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: ActionTypes.POST_SPONSORSHIP_FAILURE, error });
  }
}

function* sponsorships() {
  yield all([
    takeLatest(ActionTypes.POST_SPONSORSHIP, postSponsorship)
  ]);
}

export default sponsorships;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Pill from './Pill';
import './PillWrapper.css';

class PillWrapper extends Component {
  static defaultProps = {
    defaultLabel: 'No Filters Added',
    style: {}
  }

  constructor(props) {
    super(props);
  }

  render() {
    let className=["pill-wrapper", "d-flex", "flex-wrap"];

    className = (this.props.className !== null ? className.concat(this.props.className) : className);

    return (
      <div style={this.props.style} className={className.join(" ")}>
        {(this.props.children.length === 0 ? <Pill isCloseVisible={false} label={this.props.defaultLabel} /> : this.props.children)}
      </div>
    );
  }
}

export default PillWrapper;

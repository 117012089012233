export const ActionTypes = {
    FORGOT_PASSWORD: 'FORGOT_PASSWORD',
    FORGOT_PASSWORD_SUCCESS: 'FORGOT_PASSWORD_SUCCESS',
    FORGOT_PASSWORD_FAILURE: 'FORGOT_PASSWORD_FAILURE'
};

export const sendForgotPasswordEmail = (email) => ({
    type: ActionTypes.FORGOT_PASSWORD,
    payload: {
      email
    }
});

import React, { Component } from 'react';
import Pill from 'components/Pill';
import PillWrapper from 'components/PillWrapper'
import { removeAudienceProfileAttribute } from 'services/audienceprofile/audienceprofile.actions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import './audienceprofile-attributes.css';

class AudienceProfileAttributes extends Component {
  static defaultProps = {
    label: "Audience Attributes",
    style: {}
  }
  setPills() {
    if (this.props.attributes.length === 0) return (<Pill
      isCloseVisible={false}
      label="No attributes added" />);

    return this.props.attributes.map((attribute, index)=>
      <Pill
        key={index}
        label={`${attribute.type}: ${attribute.value}`}
        onClickRemove={this.props.removeAudienceProfileAttribute.bind(this, attribute)}/>)
  }

  render() {
    return (
      <div style={{...this.props.style}} className="audienceprofile-attributes-wrapper">
        <div className="body-copy-bold-tertiary" style={{marginBottom: '15px'}}>{this.props.label}</div>
        <PillWrapper className="audienceprofile-attributes-container" defaultLabel="Add Filters">
          {this.setPills()}
        </PillWrapper>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    attributes: state.audienceprofile.attributes
  };
};

const mapDispatchToProps = dispatcher => (
    bindActionCreators({
        removeAudienceProfileAttribute,
    }, dispatcher)
);

export default connect(mapStateToProps, mapDispatchToProps)(AudienceProfileAttributes);

import firebase from '../firebase/Firebase';
import axios from 'axios';

const baseGet = (api) => async (path, query = "") => {
  const token = await firebase.auth.currentUser.getIdToken();
  return fetch(api + path + query, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + token
    },
    redirect: "follow",
    referrer: "no-referrer"
  });
}

export const basePost = (api) => async (path, data, isFile = false) => {
  const token = await firebase.auth.currentUser.getIdToken();

  let headers = {
    "Authorization": "Bearer " + token
  }

  let body;

  if (!isFile) {
    headers["Content-Type"] = "application/json";
    body = JSON.stringify(data);
  } else {
    body = data;
  }

  return fetch(api + path, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers,
    redirect: "follow",
    referrer: "no-referrer",
    body,
  });
}

export const basePut = (api) => async (path, data, isFile = false) => {
  const token = await firebase.auth.currentUser.getIdToken();

  let headers = {
    "Authorization": "Bearer " + token,
    'Content-Type': 'application/json'
  }

  let body = data;

  return axios.put(api + path, body, {
    headers
  });
}

export const get = baseGet(process.env.REACT_APP_API);
export const putRequest = basePut(process.env.REACT_APP_DROPWIZARD_API);

export const getDropwizard = async (path, query = "") => {
  let headers = {
    'Content-Type': 'application/json'
  }

  if (firebase.auth.currentUser !== null) {
    const token = await firebase.auth.currentUser.getIdToken();
    headers["Authorization"] = "Bearer " + token;
  }

  return axios.get(process.env.REACT_APP_DROPWIZARD_API + path + query, {
    headers
  });
}


export const post = async (path, data, isFile = false) => {
  let headers = {}

  if (firebase.auth.currentUser !== null) {
    const token = await firebase.auth.currentUser.getIdToken();
    headers["Authorization"] = "Bearer " + token;
  }

  let body;

  if (isFile) {
    //headers["Content-Type"] = "multipart/form-data";
    //headers["Content-Type"] = "application/json";
    body = data;

  } else {
    headers["Content-Type"] = "application/json";
    body = JSON.stringify(data);
  }

  return fetch(process.env.REACT_APP_DROPWIZARD_API + path, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers,
    redirect: "follow",
    referrer: "no-referrer",
    body,
  });
}

export const deleteRequest = async (path) => {
  const token = await firebase.auth.currentUser.getIdToken();

  let headers = {
    "Authorization": "Bearer " + token,
    'Content-Type': 'application/json'
  }

  return axios.delete(process.env.REACT_APP_DROPWIZARD_API + path, {
    headers
  });
}

import React, { Component } from 'react';
import SwipeableViews from 'react-swipeable-views';
import CaretRight from 'assets/caret-right.svg';
import CaretLeft from 'assets/caret-left.svg';
import './Gallery.css';

class Gallery extends Component {
  static defaultProps = {
    type: 'background'
  }

  constructor() {
    super();
    this.state = {
      currentIndex: 0
    }
  }

  onChangeIndex(index) {
    this.setState({
      currentIndex: index
    })
  }

  setGalleryClass(index) {
    let { currentIndex } = this.state;
    if (index === currentIndex) {
      return "gallery-dot gallery-dot-active";
    }
    return "gallery-dot";
  }

  onClickLeft() {
    let { currentIndex } = this.state;

    if (currentIndex === 0) return;

    this.setState({
      currentIndex: currentIndex - 1
    })
  }


  onClickRight(event) {
    event.stopPropagation();
    let { currentIndex } = this.state;
    let { pictures } = this.props;

    if (currentIndex === pictures.length - 1) return;

    this.setState({
      currentIndex: currentIndex + 1
    })
  }

  onClickGallery() {
    let { onClick } = this.props;
    if (onClick) {
      onClick();
    }
  }

  getImage(styles, src) {
    if (this.props.type === 'image') {
      return (<img className="gallery-image" src={src}/>)
    }
    return (<div style={styles} className="gallery-background-image" />);
  }

  render() {

    let { currentIndex } = this.state;
    let { pictures, style, className } = this.props;
    return (
      <div style={(style ? style : {})} className={`gallery-container ${(className ? className : "")}`} onClick={this.onClickGallery.bind(this)}>
          <div className="gallery-button-left d-flex align-items-center justify-content-center">
            <button onClick={this.onClickLeft.bind(this)} className="gallery-button" disabled={(currentIndex === 0)} />
            <img className="gallery-caret" src={CaretLeft} />
          </div>
          <div className="gallery-button-right d-flex align-items-center justify-content-center">
            <button onClick={this.onClickRight.bind(this)} className="gallery-button" disabled={(currentIndex === pictures.length - 1)}/>
            <img className="gallery-caret" src={CaretRight} />
          </div>
        <SwipeableViews className="gallery-swipeableviews" onChangeIndex={this.onChangeIndex.bind(this)} index={currentIndex} enableMouseEvents>
          {
            pictures.map((picture, index) => {
              let styles = {
                backgroundImage: `url(${picture.src})`
              }

              return (
                <div className="gallery-image-container d-flex align-items-start" key={index}>
                  {this.getImage(styles, picture.src)}
                </div>
              )
            })
          }
        </SwipeableViews>
        <div className="gallery-dots d-flex flex-row justify-content-center align-items-center">
          {
            pictures.map((picture, index) => <div key={index} className={this.setGalleryClass(index)} />)
          }
        </div>
      </div>
    )
  }
}

export default Gallery;

import { put, call, all, takeLatest } from 'redux-saga/effects';
import { ActionTypes } from './blog.actions';
import { post, getDropwizard } from '../Api'
import Parser from 'rss-parser';

const basePath = "blog/posts"

export const getBlogPosts = function* (action) {
  try {
    const response = yield call(getDropwizard, basePath)
    yield put({ type: ActionTypes.GET_BLOG_POSTS_SUCCESS, json: response.data });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: ActionTypes.GET_BLOG_POSTS_FAILURE, error });
  }
}

export const getBlogPost = function* (action) {
  try {
    const { payload: { id } } = action;
    const response = yield call(getDropwizard, basePath + "/" + id)
    yield put({ type: ActionTypes.GET_BLOG_POST_SUCCESS, json: response.data });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: ActionTypes.GET_BLOG_POST_FAILURE, error });
  }
}

function* blog() {
  yield all([
    takeLatest(ActionTypes.GET_BLOG_POSTS, getBlogPosts),
    takeLatest(ActionTypes.GET_BLOG_POST, getBlogPost)
  ]);
}

export default blog;

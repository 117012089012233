import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ButtonGroup from 'components/ButtonGroup';
import Divider from 'common/Divider.component';
import PillsContainer from 'common/pillscontainer.component';
import Form from 'components/Form';
import Detail from 'common/Detail';
import Select from 'components/Select';
import CompoundSlider from 'components/compoundslider';
import AutocompleteEntryGroup from 'common/AutocompleteEntryGroup';
import Autocomplete from 'components/Autocomplete';
import Button from 'components/new/Button';
import { searchPlacements, setPlacements } from 'services/placements/placements.actions';
import { postPortfolioPlacementPreferences, putPortfolioPlacementPreferences } from 'services/portfolioplacementpreferences/portfolioplacementpreferences.actions';
import { getPlacementTypes } from 'services/placementtypes/placementtypes.actions';
import { setPortfolioPreferencesPage, setBudget } from 'services/portfolios/portfolios.actions';
import { addSponsorPreference, removeSponsorPreference } from 'services/sponsorpreferences/sponsorpreferences.actions';
import { Routes } from 'constants/Routes';
import './preferences.css';

const {
  PORTFOLIO_OBJECTIVES,
  PORTFOLIO_REVIEW,
  PORTFOLIO_AUDIENCE
} = Routes;

const ROLES = [
  {
    value: "Event Organizer",
    label: "Event Organizer"
  },
  {
    value: "Sponsor",
    label: "Sponsor"
  }
]

class Preferences extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: {
        value: "",
        error: false
      },
      placement: {
        value: "",
        error: false
      },
      lowerBound: 5000,
      upperBound: 15000,
      error: false
    }
  }

  componentDidMount() {
    if (!this.props.isPortfolioObjectivePageValid || !this.props.isPortfolioAudiencePageValid) {
      this.props.history.push(PORTFOLIO_OBJECTIVES);
      return;
    }
    this.props.setBudget(this.state.lowerBound, this.state.upperBound)
    this.props.getPlacementTypes();
  }

  addSidePanel() {
    let { removeSponsorPreference, preferences } = this.props;
    let pills = preferences.map((item)=>`${item.type}: ${item.placement}`)
    return (
      <div style={{height: '100%'}}>
        <PillsContainer
          label="Preferences"
          values={pills}
          emptyStateMessage="No sponsor preferences specified"
          onClickRemove={removeSponsorPreference.bind(this)}
          style={{height: '100%'}} />
      </div>
    )
  }

  validate() {

    if (this.props.preferences.length === 0) {
      this.setState({
        error: true
      })
      return false;
    }
    this.setState({
      error: false
    })
    return true;
  }

  goToPortfolioReview() {
    if (!this.validate()) return;
    let data = {
      portfolio_id: this.props.portfolio.id,
      preferences: this.props.preferences,
      lower_bound: this.state.lowerBound,
      upper_bound: this.state.upperBound
    }

    if (this.props.portfolioplacementpreferences == null) {
      this.props.postPortfolioPlacementPreferences(data);
    } else {
      this.props.putPortfolioPlacementPreferences(data);
    }

    this.props.setPortfolioPreferencesPage(true);
    this.props.history.push(PORTFOLIO_REVIEW);
  }

  goToPortfolioAudienceProfile() {
    this.props.history.push(PORTFOLIO_AUDIENCE);
  }

  onChangeSelect(name, value) {
    this.setState({ [name] : {
      value,
      error: false
      }
    })
  }

  onChangeCompoundSlider(values) {
    let lower = values[0];
    let upper = values[1];
    this.setState({
      lowerBound: lower,
      upperBound: upper
    });
    this.props.setBudget(lower, upper)
  }

  getPlacementTypes() {
    let options = this.props.placementtypes.map((item) => {
      let { type } = item;
      return {value: type, label: type};
    });
    return options;
  }

  onChangeAutocomplete(search) {
    this.setState({
      placement: {
        value: search.trim(),
        error: false
      }
    });
    this.props.searchPlacements(search.trim(), this.state.type.value);
  }

  onMouseDownAutocompleteOption(value) {
    this.setState({
      placement: {
        value: value.trim(),
        error: false,
      }
    }, ()=>{
      this.props.setPlacements([]);
      this.onClickAddPlacement();
    });
  }

  onBlurAutocomplete() {
    this.props.setPlacements([]);
  }

  onClickAddPlacement() {
    let { placement } = this.state;

    let item = {
      placement: placement.value,
      type: this.state.type.value
    }

    this.props.addSponsorPreference(item);

    if (!placement.value) {
      this.setState({
        placement: {
          value: "",
          error: true
        }
      });
      return;
    }

    this.setState({
      placement: {
        value: "",
        error: false
      }
    }, () => {
      this.props.setPlacements([]);
    });
  }

  render() {
    let { type, lowerBound, upperBound, placement, name } = this.state;
    let results = this.props.placements.map((result)=>{
      if (result.placement !== null) {
        return result.placement;
      }
    });

    return (
      <div>
        <Form addSidePanel={this.addSidePanel.bind(this)}>
          <Detail justification="start" style={{marginBottom: '25px', textAlign: 'Start'}}
            header="Placement Preferences"
            subHeader="Have a brand presence at the right events with the right people. Select types of sponsorship opportunities that best fits your objectives." />
          <div className="sponsor-preferences-container" style={{marginBottom: '50px'}}>
            <Select
              options={this.getPlacementTypes()}
              name="type"
              error={type.error}
              errorMessage="Please select a sponsorship type"
              placeholder="Sponsorship Type"
              value={type.value}
              onChange={this.onChangeSelect.bind(this, "type")}
              label="Sponsorship Type"
            size="full" />
            <AutocompleteEntryGroup
              fontStyle="tertiary"
              label="Placement Type"
              placeholder="Enter a preferred placement"
              name="placement"
              errorMessage="Placement cannot be an empty string."
              buttonLabel="Add"
              value={placement.value}
              error={placement.error}
              onClickAddEntry={this.onClickAddPlacement.bind(this)}
              onChange={this.onChangeAutocomplete.bind(this)}
              results={results}
              onMouseDownOption={this.onMouseDownAutocompleteOption.bind(this)}
              onBlur={this.onBlurAutocomplete.bind(this)}
              size="full"/>
            <div>
            <Divider style={{marginBottom: '25px'}} type="brand"/>
            <div className="d-flex align-items-center">
              <CompoundSlider
                onChange={this.onChangeCompoundSlider.bind(this)}
                domain={[0, 30000]}
                defaultValues={[lowerBound, upperBound]}
                label="Sponsorship Package Price Range"
              />
              <div className="body-copy-tertiary sponsor-preferences-upperbound">$30K+</div>
            </div>
            </div>
          </div>
          {this.state.error ? <div style={{alignSelf: 'stretch', position: "relative"}}><span className="body-copy-error-xs-small" style={{textAlign: 'center', position: "absolute", color: 'red'}}>Please select at least one placement preference.</span></div> : null}
        </Form>
        <div style={{marginTop: '50px'}}>
          <div style={{float: 'right', marginBottom: '10px'}} className="body-copy-tertiary">Step 3 of 4</div>
          <ButtonGroup
            labelPrimary="Next"
            labelSecondary="Back"
            onClickPrimary={this.goToPortfolioReview.bind(this)}
            onClickSecondary={this.goToPortfolioAudienceProfile.bind(this)} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    placements: state.placements.placements,
    placementtypes: state.placementtypes.types,
    preferences: state.sponsorpreferences.preferences,
    portfolio: state.portfolios.currentPortfolio,
    portfolioplacementpreferences: state.portfolioplacementpreferences.preferences,
    isPortfolioObjectivePageValid: state.portfolios.isPortfolioObjectivePageValid,
    isPortfolioAudiencePageValid: state.portfolios.isPortfolioAudiencePageValid
  };
};

const mapDispatchToProps = dispatcher => (
    bindActionCreators({
      getPlacementTypes,
      addSponsorPreference,
      setPlacements,
      setBudget,
      searchPlacements,
      removeSponsorPreference,
      postPortfolioPlacementPreferences,
      putPortfolioPlacementPreferences,
      setPortfolioPreferencesPage
    }, dispatcher)
);

export default connect(mapStateToProps, mapDispatchToProps)(Preferences);

import { put, call, all, takeLatest } from 'redux-saga/effects';
import { ActionTypes } from "./negotiations.actions";
import { post } from '../Api'

const basePath = "negotiations"

export const postNegotiationsRequest = function* (action) {
  const { payload: { eventPackageOfferId, message } } = action;

  try {
    const response = yield call(post, `${basePath}/${eventPackageOfferId}`, { message });
    yield put({ type: ActionTypes.SEND_NEGOTIATIONS_EMAIL_SUCCESS,  eventPackageOfferId });
  } catch (error) {
    yield put({ type: ActionTypes.SEND_NEGOTIATIONS_EMAIL_FAILURE, error });
  }
}

export default function* negotiations() {
    yield all([
        takeLatest(ActionTypes.SEND_NEGOTIATIONS_EMAIL, postNegotiationsRequest),
    ]);
}

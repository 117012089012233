export const ActionTypes = {
    POST_SPONSORSHIP_REQUEST: 'POST_SPONSORSHIP_REQUEST',
    POST_SPONSORSHIP_REQUEST_SUCCESS: 'POST_SPONSORSHIP_REQUEST_SUCCESS',
    POST_SPONSORSHIP_REQUEST_FAILURE: 'POST_SPONSORSHIP_REQUEST_FAILURE',
    POST_SPONSORSHIP_REQUESTS: 'POST_SPONSORSHIP_REQUESTS',
    POST_SPONSORSHIP_REQUESTS_SUCCESS: 'POST_SPONSORSHIP_REQUEST_SUCCESS',
    POST_SPONSORSHIP_REQUESTS_FAILURE: 'POST_SPONSORSHIP_REQUEST_FAILURE'
};

export const postSponsorshipRequests = (requests) => {
  return {
    type: ActionTypes.POST_SPONSORSHIP_REQUESTS,
    payload: {
      requests
    }
  }
};

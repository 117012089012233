import React, { Component } from 'react';
import ButtonGroup from 'components/ButtonGroup';
import TextField from 'components/TextField';
import FormGroup from 'components/FormGroup';
import Places from 'common/Places';
import Detail from 'common/Detail';
import Form from 'components/Form';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import './profile.css';

//Example: https://www.aana.com/docs/default-source/meeting/2019-annual-congress-exhibitor-prospectus.pdf?sfvrsn=bb6c56b1_4
class Profile extends Component {
  onChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  _handleOnAutocompleteOptionSelection(selected) {
    this.setState({
      place_id: selected[0].place_id
    });
  }


  render() {
    return (
      <Form className="profile-container">
        <Detail justification="start" style={{marginBottom: '25px', textAlign: 'Start'}} header="Profile" subHeader="Update Your Profile Information" />
        <FormGroup>
          <TextField
            fontStyle="tertiary"
            name="Organization"
            onChange={this.onChange.bind(this)}
            label="Organization"
            size="full" />
          </FormGroup>
        <FormGroup>
          <Places
            fontStyle="tertiary"
            label="Address"
            placeholder="Location"
            onOptionSelection={this._handleOnAutocompleteOptionSelection.bind(this)} />
          <TextField
            fontStyle="tertiary"
            name="Address 2"
            onChange={this.onChange.bind(this)}
            label="Address 2"
            size="full" />
        </FormGroup>
        <FormGroup>
          <TextField
            fontStyle="tertiary"
            name="Contact Name"
            onChange={this.onChange.bind(this)}
            label="Contact Name"
            size="full" />
          <TextField
            fontStyle="tertiary"
            name="Contact Title"
            onChange={this.onChange.bind(this)}
            label="Contact Title"
            size="full" />
        </FormGroup>
        <FormGroup>
          <TextField
            fontStyle="tertiary"
            name="Contact E-mail"
            onChange={this.onChange.bind(this)}
            label="Contact E-mail"
            size="full" />
          <TextField
            fontStyle="tertiary"
            name="Contact Phone"
            onChange={this.onChange.bind(this)}
            label="Contact Phone"
            size="full" />
        </FormGroup>
        <ButtonGroup
          labelPrimary="Submit"
          labelSecondary="Back to home"
          onClickPrimary={(()=>{})}
          onClickSecondary={(()=>{})} />
      </Form>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    events: state.events.events,
    user: state.users.currentUser
  };
};

const mapDispatchToProps = dispatcher => (
    bindActionCreators({

    }, dispatcher)
);

export default connect(mapStateToProps, mapDispatchToProps)(Profile);

import React, { Component } from 'react';
import TextField from 'components/TextField';
import Button from 'components/new/Button';
import Wrapper from 'components/Wrapper';
import ButtonGroup from 'components/ButtonGroup';
import FormGroup from 'components/FormGroup';
import Calendar from 'components/Calendar';
import Divider from 'common/Divider.component';
import { generateCurrencyFormat } from 'common/helpers/currency';
import { generateDateTimeFormat } from 'common/helpers/date';
import { Routes } from 'constants/Routes';
import { postSponsorship } from 'services/sponsorships/sponsorships.actions';
import upload from 'assets/icons/upload.svg';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import './requestsponsorship.css';

class RequestSponsorship extends Component {
  static results = []

  constructor(props) {
    super(props);
    this.state = {
      requests: '',
      name: '',
      type: '',
      budget:'',
      guidelines: '',
      event_id: 1,
      requests: []
    }

    this.fileInput = React.createRef();
  }

  isValid() {
  if (
        (this.state.requests != "" && this.state.requests != null) &&
        (this.state.guidelines != "" && this.state.guidelines != null)
      ) {
      return true;
    }
    return false;
  }

  _handleOnChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  _handleClickSubmitSponsorshipRequests() {
    if (!this.isValid()) {
      return;
    }

    let data = {
      requests: this.state.requests,
      guidelines: this.state.guidelines,
      event_id: this.props.currentEvent.id
    }

    this.props.postSponsorship(data);
    this.props.history.push(Routes.EVENT_PLACEMENTS);
  }

  _handleOnChangeDueDate(value) {
    this.setState({
      due_date: new Date(value.format("YYYY-MM-DDTHH:mm:ssZ"))
    });
  }

  onClickUpload() {
    this.fileInput.current.click();
  }

  handleSubmit(event) {
    event.preventDefault();

    const data = new FormData();

    data.append('file', this.fileInput.current.files[0]);
    data.append('filename', this.fileInput.current.value);

    fetch(process.env.REACT_APP_API + "/sponsorships/test", {
      method: 'POST',
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      headers: {

      },
      redirect: "follow",
      referrer: "no-referrer",
      body: data
    }).then((response) => {

    });
  }

  goToEventDetails() {
    this.props.history.push(Routes.EVENT_DETAILS);
  }

  setSponsorshipRequestsUI() {
    if (this.state.requests.length === 0) {
      return (
        null
      )
    } else {
      return (
        <div>
          <div style={{marginBottom: '50px', textAlign: 'center'}} className="header-1-tertiary">Sponsorship Requests:</div>
          {
            this.state.requests.map((request, index)=>{
              console.log(request);
              return (
                <div className="d-flex flex-wrap body-copy-tertiary" key={index}>
                  <div className="col-md-2 col-6 d-flex align-items-center">{request.name}</div>
                  <div className="col-md-2 col-6 d-flex align-items-center">{generateCurrencyFormat(request.budget)}</div>
                  <div className="col-md-2 col-6 d-flex align-items-center">{request.type}</div>
                  <div className="col-md-2 col-6 d-flex align-items-center">{generateDateTimeFormat(request.due_date)}</div>
                  <div className="col-md-4 col-12 d-flex align-items-center justify-content-end"><Button label="Remove" onClick={(()=>{}).bind(this)}/></div>
                </div>
              )
              return (null)
            })
          }
        </div>
      );
    }
  }

  addSponsorshipRequest() {
    let {
      budget,
      type,
      name,
      due_date
    } = this.state;

    let request = {
      budget,
      type,
      name,
      due_date
    };

    let requests = this.state.requests;
    requests.push(request);
    this.setState({
      requests
    })
  }

  render() {
    return (
      <Wrapper className="container requestsponsorship-container flex-column d-flex">
        <div>
          <div style={{marginBottom: '50px', textAlign: 'center'}} className="header-1-tertiary">Sponsorship Requests (Add at least 1):</div>
          <div className="sponsorship-request-form">
            <FormGroup>
              <TextField
                size="full"
                fontStyle="tertiary"
                name="name"
                placeholder='Request Name'
                label="Request Name"
                onChange={this._handleOnChange.bind(this)} />
              <TextField
                size="full"
                fontStyle="tertiary"
                name="budget"
                placeholder='Desired Budget'
                label="Desired Budget"
                onChange={this._handleOnChange.bind(this)} />
            </FormGroup>
            <FormGroup>
              <TextField
                size="full"
                fontStyle="tertiary"
                name="type"
                placeholder='Type'
                label="Type"
                onChange={this._handleOnChange.bind(this)} />
              <Calendar
                  fontStyle="tertiary"
                  onChange={this._handleOnChangeDueDate.bind(this)}
                  size="full"
                  timeFormat={false}
                  label="Need By Date"/>
            </FormGroup>
            <Button label="Add a sponsorship request" onClick={this.addSponsorshipRequest.bind(this)}/>
          </div>
        </div>
        {this.setSponsorshipRequestsUI()}
        <Divider />
        <TextField
          name="guidelines"
          fontStyle="tertiary"
          isTextArea={true}
          onChange={this._handleOnChange.bind(this)}
          label="Guidelines for sponsor participation"
          size="full" />
        <input name="upload" onChange={this.handleSubmit.bind(this)} ref={this.fileInput} className="inputfile" type="file" />
        <div>
          <Button icon={upload} label="Upload sponsor pitch deck" onClick={this.onClickUpload.bind(this)}/>
        </div>
        <div>
          <ButtonGroup
            labelPrimary="Next: Set your available placements"
            labelSecondary="Back to event details"
            onClickPrimary={this._handleClickSubmitSponsorshipRequests.bind(this)}
            onClickSecondary={this.goToEventDetails.bind(this)} />
        </div>
      </Wrapper>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.users.currentUser,
    requests: state.sponsorshiprequests.requests,
    currentEvent: state.events.currentEvent
  };
};

const mapDispatchToProps = dispatcher => (
    bindActionCreators({
        postSponsorship
    }, dispatcher)
);

export default connect(mapStateToProps, mapDispatchToProps)(RequestSponsorship);

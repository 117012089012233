import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AudienceProfile from 'common/audienceprofile.component';
import AudienceProfileAttributes from 'common/audienceprofile-attributes.component';
import ButtonGroup from 'components/ButtonGroup';
import Form from 'components/Form';
import Detail from 'common/Detail';
import { isFilled } from 'common/helpers/validators';
import { Routes } from 'constants/Routes';
import { getSuggestions, setSuggestions } from 'services/places/places.actions';
import { postPortfolioAudienceProfile, putPortfolioAudienceProfile } from 'services/portfolioaudienceprofile/portfolioaudienceprofile.actions';
import { setPortfolioAudiencePage } from 'services/portfolios/portfolios.actions';
import './portfolioaudience.css';

const {
  PORTFOLIO_OBJECTIVES,
  PORTFOLIO_PREFERENCES
} = Routes

class PortfolioAudience extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isNameFilled: true,
      isAttributeFilled: true
    }
  }

  componentDidMount() {
    if (!this.props.isPortfolioObjectivePageValid) {
      this.props.history.push(PORTFOLIO_OBJECTIVES);
    }
  }

  addSidePanel() {
    return (
      <div style={{height: '100%'}}>
        <AudienceProfileAttributes />
      </div>
    )
  }

  goToObjectives() {
    this.props.history.push(PORTFOLIO_OBJECTIVES);
  }

  goToSponsorPreferences() {
    let { postPortfolioAudienceProfile, history, setPortfolioAudiencePage, portfolio_audience_profile, putPortfolioAudienceProfile } = this.props;
    if (!this.validate()) return;

    console.log("portfolio_audience_profile", portfolio_audience_profile);
    if (portfolio_audience_profile === null) {
      postPortfolioAudienceProfile(this.getPortfolioAudienceProfileData());
    } else {
        console.log("uh ha ha")
      putPortfolioAudienceProfile(this.getPortfolioAudienceProfileData());
    }
    setPortfolioAudiencePage(true);
    history.push(PORTFOLIO_PREFERENCES);
  }

  getPortfolioAudienceProfileData() {
    let pap = {};

    pap.name = this.props.name;
    pap.portfolio_id = this.props.currentPortfolio.id;
    pap.audience_attributes = this.props.attributes;

    return pap;
  }

  validate() {
    let fields = new Set();

    let isNameFilled = isFilled(this.props.name);
    let isAttributeFilled = (this.props.attributes.length > 0 ? true : false);

    if (!isNameFilled || !isAttributeFilled) {
      this.setState({
        isNameFilled,
        isAttributeFilled
      });
      return;
    } else {
      this.setState({
        isNameFilled,
        isAttributeFilled: true
      });
    }

    return !fields.has(false);
  }

  render() {
    let { isNameFilled } = this.state;
    return (
      <div>
        <Form addSidePanel={this.addSidePanel.bind(this)}>
          <Detail justification="start" style={{marginBottom: '25px', textAlign: 'Start'}} header="Audience" subHeader="Define your target audience for the event. The audience attributes selected will be shown in the right panel" />
          <div style={{marginBottom: '50px'}}>
            <AudienceProfile
              isNameFilled={isNameFilled}
            />
          </div>
        </Form>
        {!this.state.isAttributeFilled ? <div style={{alignSelf: 'stretch', position: "relative"}}><span className="body-copy-error-xs-small" style={{textAlign: 'center', position: "absolute", color: 'red'}}>Please specifiy at least one attribute.</span></div> : null}
        <div style={{marginTop: '50px'}}>
          <div style={{float: 'right', marginBottom: '10px'}} className="body-copy-tertiary">Step 2 of 4</div>
          <ButtonGroup
            labelPrimary="Next"
            labelSecondary="Back"
            onClickPrimary={this.goToSponsorPreferences.bind(this)}
            onClickSecondary={this.goToObjectives.bind(this)} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    suggestions: state.places.suggestions,
    attributes: state.audienceprofile.attributes,
    name: state.audienceprofile.name,
    currentPortfolio: state.portfolios.currentPortfolio,
    isPortfolioObjectivePageValid: state.portfolios.isPortfolioObjectivePageValid,
    portfolio_audience_profile: state.portfolioaudienceprofile.portfolio_audience_profile
  };
};

const mapDispatchToProps = dispatcher => (
    bindActionCreators({
        getSuggestions,
        setSuggestions,
        postPortfolioAudienceProfile,
        putPortfolioAudienceProfile,
        setPortfolioAudiencePage
    }, dispatcher)
);

export default connect(mapStateToProps, mapDispatchToProps)(PortfolioAudience);

import React, { Component } from 'react';
import { Routes } from 'constants/Routes';
import Button from 'components/new/Button';
import ButtonGroup from 'components/ButtonGroup';
import Divider from 'common/Divider.component';
import Footer from '../../components/footer.component';
import Portfolio from 'assets/portfolio@2x.png';
import Wrapper from 'components/Wrapper';
import Placement from '../../components/placement.component';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getPortfolios } from 'services/portfolios/portfolios.actions';
import '../event.css';

import ImageGallery from 'react-image-gallery';

const images = [
  {
    original: 'http://lorempixel.com/1000/600/nature/1/',
    thumbnail: 'http://lorempixel.com/250/150/nature/1/',
  },
  {
    original: 'http://lorempixel.com/1000/600/nature/2/',
    thumbnail: 'http://lorempixel.com/250/150/nature/2/'
  },
  {
    original: 'http://lorempixel.com/1000/600/nature/3/',
    thumbnail: 'http://lorempixel.com/250/150/nature/3/'
  }
]

class Event extends Component {
  static defaultProps = {
    events: []
  }

  constructor(props) {
    super(props);

  }

  setPlacementsUI() {

    let placements = [
      {"type": "Publicity in your online marketing", "amount": 10, "remaining": 4, "value": 300},
      {"type": "Logo placement on lanyard", "amount": 1, "remaining": 4, "value": 300},
      {"type": "Logo placement on flyer", "amount": 10, "remaining": 4, "value": 300},
      {"type": "Logo placement on event website", "amount": 5, "remaining": 4, "value": 300}
    ];

    return  (
      <div>
        {
          placements.map((placement) => {
            return <Placement type={placement.type} remaining={placement.remaining} value={placement.value}/>
          })
        }
      </div>
    );

  }

  setSponsorshipOffersUI() {

  }

  render() {
    //console.log(this.props.currentEvent);
    //let { location, event, sponsorships } = this.props.currentEvent;
    let { location, event, sponsorships } = {"event":{"datetime":"2019-08-14T07:00:00.000Z","name":"Main St. Festival","user_id":"MyceyCYaiVMjO1ZyRPR2RGX5atd2","locationId":"uW0rgD7tdyRGf40MagKE","description":"This is an event for local businesses to introduce themselves to the new incoming undergraduate and graduate classes as well as new faculty of Dartmouth College. Vendors will be on the green, and attendees will be able to buy food and other goods."},"location":{"administrative_area_level_1":"New Hampshire","country":"United States","administrative_area_level_2":"Grafton County","formatted_address":"Hanover, NH, USA","locality":"Hanover","place_id":"ChIJ5cyscCK3tEwRT_DsE-vcGn8"}};
    let { formatted_address } = location;
    let { name, description } = event;

    return (
      <div>
        <Wrapper className="container event-container">
          <div className="d-flex flex-lg-row flex-column event-header">
            <div className="d-flex col-lg-5 col-12 align-items-center">
              <ImageGallery
                showBullets={true}
                showThumbnails={false}
                items={images} />
            </div>
            <div className="d-flex col-lg-7 col-12 flex-column event-data">
              <div className="header-1-tertiary">{name}</div>
              <div className="body-copy-tertiary">{formatted_address}</div>
              <div className="body-copy-tertiary event-description">{description}</div>
              <Button label="Sponsor" type="primary" onClick={(()=>{}).bind(this)}/>
            </div>
          </div>
          <div>
            <div>
              <Divider />
              <label className="body-copy-secondary">Audience Profile</label>
              <div></div>
            </div>
            <div>
              <Divider />
              <label className="body-copy-secondary">Needs</label>
              <div></div>
            </div>
            <div>
              <Divider />
              <label className="body-copy-secondary">Placements</label>
              <div>
                {this.setPlacementsUI()}
              </div>
            </div>
            <div>
              <Divider />
              <label className="body-copy-secondary">Sponsorship offers</label>
              <div>
                {this.setSponsorshipOffersUI()}
              </div>
            </div>
          </div>
        </Wrapper>
        <Footer history={this.props.history} />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentEvent: state.events.currentEvent
  };
};


const mapDispatchToProps = null;

export default connect(mapStateToProps, mapDispatchToProps)(Event);

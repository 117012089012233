import React, { Component } from 'react';
import { Switch, BrowserRouter as Router, Route, withRouter } from "react-router-dom";
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import CreateEvent from '../screens/event/create/createevent.screen';
import RequestSponsorship from '../screens/event/create/sponsorship/requestsponsorship.screen';
import EventDetails from '../screens/event/create/details/eventdetails.screen';
import EventAudience from '../screens/event/create/audience/eventaudience.screen';
import EventPlacements from '../screens/event/create/placements/eventplacements.screen';
import EventPackages from '../screens/event/create/packages/eventpackages.screen';
import EventReview from '../screens/event/create/review/eventreview.screen';
import ProtectedRoute from 'common/ProtectedRoute.component';
import { getSuggestions, setSuggestions } from 'services/places/places.actions';
import { getEvent, putEvent, postEvent, setEventDetailsPage, publish } from 'services/events/events.actions';
import { postEventAssets, addEventAsset } from 'services/eventassets/eventassets.actions';
import { postEventAudienceProfile, putEventAudienceProfile, setEventAudiencePage } from 'services/eventaudienceprofile/eventaudienceprofile.actions';
import { searchPlacements, setPlacements } from 'services/placements/placements.actions';
import { postPackages, putPackages, addPackage, removePackage, setEventPackagesPage } from 'services/packages/packages.actions';
import { isNumeric } from 'common/helpers/validators';
import { Routes } from 'constants/Routes';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

const routes = [
  { path: Routes.EVENT_DETAILS, Component: EventDetails },
  { path: Routes.EVENT_PACKAGES, Component: EventPackages },
  { path: Routes.EVENT_PLACEMENTS, Component: EventPlacements },
  { path: Routes.EVENT_AUDIENCE, Component: EventAudience },
  { path: Routes.EVENT_REVIEW, Component: EventReview }
]

class CreateEventRoutes extends Component {
  componentDidMount() {
    const { id } = this.props.match.params;

    if (id !== undefined && !isNumeric(id)) {
      this.props.getEvent(id)
      return;
    }
  }

  componentDidUpdate(prevProps) {

  }

  render() {
    let {
      isEventPackagesPageValid,
      isEventDetailsPageValid,
      isEventAudiencePageValid,

      suggestions,
      getSuggestions,
      setSuggestions,

      assets,
      attributes,
      event,
      user,
      addEventAsset,
      postEvent,
      putEvent,
      postEventAssets,
      setEventDetailsPage,

      postEventAudienceProfile,
      putEventAudienceProfile,
      event_audience_profile,
      name,
      setEventAudiencePage,

      available,
      placements,
      packages,
      savedPackages,
      searchPlacements,
      setPlacements,
      postPackages,
      putPackages,
      addPackage,
      removePackage,
      setEventPackagesPage,

      publish
    } = this.props;

    return (
        <Route path={Routes.EVENTS_CREATE} children={({match, location})=>{
          if (match !== null && !match.isExact) {
            return (
              <CreateEvent
                isEventPackagesPageValid={isEventPackagesPageValid}
                isEventDetailsPageValid={isEventDetailsPageValid}
                isEventAudiencePageValid={isEventAudiencePageValid}
                event={this.props.event}
                history={this.props.history}>
                <TransitionGroup>
                  <CSSTransition
                    key={location.key}
                    timeout={600}
                    classNames='restage-route-transition'>
                    <Switch>
                      <ProtectedRoute
                        event={event}
                        assets={assets}
                        user={user}
                        postEvent={postEvent.bind(this)}
                        putEvent={putEvent.bind(this)}
                        postEventAssets={postEventAssets.bind(this)}
                        addEventAsset={addEventAsset.bind(this)}
                        setEventDetailsPage={setEventDetailsPage.bind(this)}
                        key={Routes.EVENT_DETAILS} exact path={Routes.EVENT_DETAILS} component={EventDetails}/>
                      <ProtectedRoute
                        isEventDetailsPageValid={isEventDetailsPageValid}
                        event={event}
                        attributes={attributes}
                        name={name}
                        event_audience_profile={event_audience_profile}
                        putEventAudienceProfile={putEventAudienceProfile.bind(this)}
                        postEventAudienceProfile={postEventAudienceProfile.bind(this)}
                        setEventAudiencePage={setEventAudiencePage.bind(this)}
                        key={Routes.EVENT_AUDIENCE} exact path={Routes.EVENT_AUDIENCE} component={EventAudience}/>
                      <ProtectedRoute
                        savedPackages={savedPackages}
                        isEventDetailsPageValid={isEventDetailsPageValid}
                        isEventAudiencePageValid={isEventAudiencePageValid}
                        searchPlacements={searchPlacements.bind(this)}
                        setPlacements={setPlacements.bind(this)}
                        postPackages={postPackages.bind(this)}
                        putPackages={putPackages.bind(this)}
                        addPackage={addPackage.bind(this)}
                        removePackage={removePackage.bind(this)}
                        available={available}
                        placements={placements}
                        packages={packages}
                        event={event}
                        setEventPackagesPage={setEventPackagesPage.bind(this)}
                        key={Routes.EVENT_PACKAGES} exact path={Routes.EVENT_PACKAGES} component={EventPackages}/>
                      <ProtectedRoute
                        isEventDetailsPageValid={isEventDetailsPageValid}
                        isEventAudiencePageValid={isEventAudiencePageValid}
                        isEventPackagesPageValid={isEventPackagesPageValid}
                        packages={packages}
                        attributes={attributes}
                        event={event}
                        publish={publish.bind(this)}
                        key={Routes.EVENT_REVIEW} exact path={Routes.EVENT_REVIEW} component={EventReview}/>
                    </Switch>
                  </CSSTransition>
                </TransitionGroup>
              </CreateEvent>
            );
          } else {
            return null;
          }
        }}
        />
      )
  }
}

const mapStateToProps = (state) => {
  return {
    suggestions: state.places.suggestions,
    event_audience_profile: state.eventaudienceprofile.event_audience_profile,
    assets: state.eventassets.assets,
    attributes: state.audienceprofile.attributes,
    event: state.events.currentEvent,
    name: state.audienceprofile.name,
    user: state.users.currentUser,
    available: state.availableplacements.available,
    placements: state.placements.placements,
    packages: state.packages.packages,
    savedPackages: state.packages.savedPackages,
    isEventPackagesPageValid: state.packages.isEventPackagesPageValid,
    isEventDetailsPageValid: state.events.isEventDetailsPageValid,
    isEventAudiencePageValid: state.eventaudienceprofile.isEventAudiencePageValid
  };
};

const mapDispatchToProps = dispatcher => (
    bindActionCreators({
      getSuggestions,
      setSuggestions,
      addEventAsset,
      getEvent,
      putEvent,
      postEvent,
      postEventAssets,
      postEventAudienceProfile,
      putEventAudienceProfile,
      publish,
      setEventAudiencePage,
      setEventDetailsPage,
      searchPlacements,
      setPlacements,
      postPackages,
      putPackages,
      addPackage,
      removePackage,
      setEventPackagesPage
    }, dispatcher)
);


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CreateEventRoutes));

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { postPortfolio, setPortfolioSponsorsetPage } from 'services/portfolios/portfolios.actions';
import TextField from 'components/TextField';
import Form from 'components/Form';
import ButtonGroup from 'components/ButtonGroup';
import Calendar from 'components/Calendar';
import FormGroup from 'components/FormGroup';
import Detail from 'common/Detail';
import { isFilled } from 'common/helpers/validators';
import { Routes } from 'constants/Routes';
import moment from 'moment';
import './portfoliosponsorset.css';

class PortfolioSponsorSet extends Component {
  static defaultProps = {
    events: []
  }

  constructor(props) {
    super(props);
    this.state = {
      name: {
        value: props.name || null,
        error: false
      },
      startdate: {
        value: props.startdate || null,
        error: false
      },
      enddate: {
        value: props.enddate || null,
        error: false
      },
      duedate: {
        value: props.duedate || null,
        error: false
      },
      objectives: {
        value: props.objectives || "",
        error: false
      },
      budget: {
        value: props.budget || "",
        error: false
      }
    }
    console.log(this.state);
  }

  validate() {
    let isFormValid = false;
    let fields = new Set();

    //fields.add(this.isFieldValid(isFilled, "name"));
    fields.add(this.isFieldValid(isFilled, "objectives"));
    //fields.add(this.isFieldValid(isFilled, "startdate"));
    //fields.add(this.isFieldValid(isFilled, "enddate"));
    fields.add(this.isFieldValid(isFilled, "duedate"));
    fields.add(this.isFieldValid(isFilled, "budget"));

    return !fields.has(false);
  }

  isFieldValid(validator, key) {
    let isValid = validator(this.state[key].value);

    this.setState({
      [key]: {
        value: this.state[key].value,
        error: !isValid
      }
    });

    return isValid;
  }

  onChange(event) {
    this.setState({ [event.target.name]: {
      value: event.target.value,
      error: this.state[event.target.name].error
      }
    })
  }

  goToSponsorHome() {
    this.props.history.push(Routes.SPONSOR_HOME);
  }

  goToPortfolioAudienceProfile() {
    this.props.history.push(Routes.PORTFOLIO_AUDIENCE);
  }

  postPortfolio() {
    let isValid = this.validate();
    this.props.setPortfolioSponsorsetPage(isValid);
    if (!isValid) return;

    let portfolio = {
      name: "Name" || this.state.name.value,
      budget: this.state.budget.value,
      due_date: this.state.duedate.value,
      user_id: this.props.user.id
    }

    this.props.postPortfolio(portfolio)
    this.goToPortfolioAudienceProfile();
  }

  onChangeDate(key, value) {
    this.setState({
      [key]: {
        value,
        error: false
      },
    });
  }

  disabledDate(current) {
    return current && current < moment().endOf('day');
  }

  render() {
    let { objectives, name, budget, startdate, enddate, duedate } = this.state;

    return (
      <div>
        <div>
          <Detail justification="start" style={{marginBottom: '25px', textAlign: 'Start'}} header="Details" subHeader="Create your Sponsor Set" />
          <div style={{marginBottom: '50px'}} className="portfoliosponsorset-container">
            <FormGroup>
              {/*<TextField
                size="full"
                fontStyle="tertiary"
                name="name"
                value={name.value}
                error={name.error}
                placeholder='Portfolio Name'
                label="Portfolio Name"
                onChange={this.onChange.bind(this)} />*/}
            <Calendar
                fontStyle="tertiary"
                onChange={this.onChangeDate.bind(this, "duedate")}
                disabledDate={this.disabledDate.bind(this)}
                size="full"
                value={(duedate.value ? new Date(duedate.value) : null)}
                error={duedate.error}
                errorMessage="Cannot be empty."
                placeholder="Need sponsorships by?"
                timeFormat={false}
                label="Need sponsorships by?"/>
              <TextField
                size="full"
                fontStyle="tertiary"
                name="budget"
                placeholder='Budget'
                label="Budget"
                value={budget.value}
                error={budget.error}
                errorMessage="Budget cannot be empty."
                onChange={this.onChange.bind(this)} />
              </FormGroup>
            {/*<FormGroup>
              <Calendar
                  fontStyle="tertiary"
                  onChange={this.onChangeDate.bind(this, "startdate")}
                  disabledDate={this.disabledDate.bind(this)}
                  size="full"
                  value={(startdate.value ? new Date(startdate.value) : null)}
                  error={startdate.error}
                  timeFormat={false}
                  errorMessage="Start date cannot be empty."
                  label="Start Date"/>
               <Calendar
                  fontStyle="tertiary"
                  timeFormat={false}
                  value={(enddate.value ? new Date(enddate.value) : null)}
                  disabledDate={this.disabledDate.bind(this)}
                  error={enddate.error}
                  onChange={this.onChangeDate.bind(this, "enddate")}
                  size="full"
                  errorMessage="End date cannot be empty."
                  label="End Date"/>
            </FormGroup>*/}
            <TextField
              fontStyle="tertiary"
              name="objectives"
              value={objectives.value}
              errorMessage="Brand objectives cannot be empty."
              error={objectives.error}
              isTextArea={true}
              onChange={this.onChange.bind(this)}
              label="Brand Objectives"
              placeholder="Brand objectives"
              size="full" />
          </div>
        </div>
        <div style={{marginTop: '50px'}}>
          <div style={{float: 'right', marginBottom: '10px'}} className="body-copy-tertiary">Step 1 of 4</div>
          <ButtonGroup
            labelPrimary="Next"
            labelSecondary="Back"
            onClickPrimary={this.postPortfolio.bind(this)}
            onClickSecondary={this.goToSponsorHome.bind(this)} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.users.user
  };
};

const mapDispatchToProps = dispatcher => (
    bindActionCreators({
        postPortfolio,
        setPortfolioSponsorsetPage
    }, dispatcher)
);

export default connect(mapStateToProps, mapDispatchToProps)(PortfolioSponsorSet);

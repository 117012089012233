export const ActionTypes = {
    SIGNUP_NEWSLETTER: 'SIGNUP_NEWSLETTER',
    SIGNUP_NEWSLETTER_SUCCESS: 'SIGNUP_NEWSLETTER_SUCCESS',
    SIGNUP_NEWSLETTER_FAILURE: 'SIGNUP_NEWSLETTER_FAILURE'
};

export const signupNewsletter = (email) => ({
    type: ActionTypes.SIGNUP_NEWSLETTER,
    payload: {
      email
    }
});

import { put, call, all, takeLatest } from 'redux-saga/effects';
import { ActionTypes } from './jobfunctions.actions';
import { getDropwizard } from '../Api'

const basePath = "job-functions";

export const getJobFunctions = function* (action) {
  try {
    const response = yield call(getDropwizard, basePath);
    yield put({ type: ActionTypes.GET_JOB_FUNCTIONS_SUCCESS, functions: response.data });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: ActionTypes.GET_JOB_FUNCTIONS_FAILURE, error });
  }
}

function* jobfunctions() {
  yield all([
    takeLatest(ActionTypes.GET_JOB_FUNCTIONS, getJobFunctions)
  ]);
}

export default jobfunctions;

export const ActionTypes = {
    SET_ACCOUNT_MODAL_VISIBILITY: 'SET_ACCOUNT_MODAL_VISIBILITY'
};

export const setAccountModalVisibility = (visibility) => ({
    type: ActionTypes.SET_ACCOUNT_MODAL_VISIBILITY,
    payload: {
      visibility
    }
});

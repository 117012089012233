export const ActionTypes = {
    POST_EVENT_SPONSORSHIP_OFFER: 'POST_EVENT_SPONSORSHIP_OFFER',
    POST_EVENT_SPONSORSHIP_OFFER_SUCCESS: 'POST_EVENT_SPONSORSHIP_OFFER_SUCCESS',
    POST_EVENT_SPONSORSHIP_OFFER_FAILURE: 'POST_EVENT_SPONSORSHIP_OFFER_FAILURE',
    GET_EVENT_SPONSORSHIP_OFFER: 'GET_EVENT_SPONSORSHIP_OFFER',
    GET_EVENT_SPONSORSHIP_OFFER_SUCCESS: 'GET_EVENT_SPONSORSHIP_OFFER_SUCCESS',
    GET_EVENT_SPONSORSHIP_OFFER_FAILURE: 'GET_EVENT_SPONSORSHIP_OFFER_FAILURE',
};

export const postEventSponsorshipOffer = (eventsponsorshipoffer) => ({
    type: ActionTypes.POST_EVENT_SPONSORSHIP_OFFER,
    payload: {
      eventsponsorshipoffer
    }
});

export const getEventSponsorshipOffer = (event_id) => ({
    type: ActionTypes.GET_EVENT_SPONSORSHIP_OFFER,
    payload: {
      event_id
    }
});

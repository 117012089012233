export const ActionTypes = {
    POST_EVENT_ATTENDANCES: 'POST_EVENT_PLACEMENTS',
    POST_EVENT_ATTENDANCES_SUCCESS: 'POST_EVENT_ATTENDANCES_SUCCESS',
    POST_EVENT_ATTENDANCES_FAILURE: 'POST_EVENT_ATTENDANCES_FAILURE',
    PUT_EVENT_ATTENDANCES: 'PUT_EVENT_ATTENDANCES',
    PUT_EVENT_ATTENDANCES_SUCCESS: 'PUT_EVENT_ATTENDANCES_SUCCESS',
    PUT_EVENT_ATTENDANCES_FAILURE: 'PUT_EVENT_ATTENDANCES_FAILURE'
};

export const postEventAttendances = (data) => ({
    type: ActionTypes.POST_EVENT_ATTENDANCES,
    payload: {
      data
    }
});

export const putEventAttendances = (data) => ({
    type: ActionTypes.PUT_EVENT_ATTENDANCES,
    payload: {
      data
    }
});

import React, { Component } from 'react';
import Check from 'assets/icons/check.svg';
import CheckComplete from 'assets/icons/check_complete_full.svg';
import Image from 'components/Image';
import PropTypes from 'prop-types';

import './Tab.css';

class Tab extends Component {
  static defaultProps = {
    selected: false,
    route: "",
    isComplete: false,
    isCompleteVisible: true,
    size: "large",
    alt: "",
    type: "regular",
    hidden: false,
    active: true
  }

  onClick() {
    if (this.props.onClick) {
      this.props.onClick();
    }
  }

  setCheckmark() {
    let check;

    if (!this.props.isCompleteVisible) {
      return (null);
    }

    if (this.props.isComplete) {
      check = CheckComplete;
    } else {
      check = Check;
    }
    return (
      <Image className="tab-checkmark" alt="Checkmark" src={check}/>
    )
  }

  setIcon() {
    let { icon, alt } = this.props;
    if (icon) {
      return (
        <img alt={alt} className="tab-icon" src={icon}/>
      )
    }
    return (null)
  }

  render() {
    if (this.props.hidden) return (null);

    let pathname;
    let { history, route, selected, alt, icon, label, size, isComplete } = this.props;

    if (history) {
      pathname = history.location.pathname;
    }

    let fontClass = "body-copy-tertiary";
    let fontClassActive = "body-copy-orange";
    let fontClassSmall = "body-copy-tertiary-small";
    let fontClassSmallActive = "body-copy-orange";

    if (this.props.type === 'bold') {
      fontClass = "body-copy-bold-tertiary";
      fontClassActive = "body-copy-bold-orange";
      fontClassSmall = "body-copy-bold-tertiary-small";
      fontClassSmallActive = "body-copy-bold-orange";
    }

    let labelClassName = "";

    let classNames = [];
    let active = ["d-flex", "flex-row", "align-items-center", "tab-container", "active"];
    let inactive = ["d-flex", "flex-row", "align-items-center", "tab-container", "inactive"];

    if (isComplete || (pathname === route || selected)) {
      labelClassName = (size === "large" ? `tab-label ${fontClassActive}` : `tab-label ${fontClassSmallActive}`);
    } else {
      labelClassName = (size === "large" ? `tab-label ${fontClass}` : `tab-label ${fontClassSmall}`);
    }

    if (pathname === route || selected) {
      classNames = active;
    } else {
      classNames = inactive
    }
    let style ={};

    if (!this.props.active) {
      style = {
        cursor: "pointer !important"
      }
    }

    return (
      <div style={style} onClick={this.onClick.bind(this)} className={classNames.join(" ")}>
        <div className={labelClassName}>{label}</div>
        {this.setCheckmark()}
      </div>
    );
  }
}

export default Tab;

import React, { Component } from 'react';
import { notification } from 'antd';
import Modal from 'components/Modal';
import Gallery from 'components/Gallery.component';
import './eventgallery.css';

class EventGallery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isGalleryModalOpen: false
    }
  }

  setModalVisible(isVisible) {
    this.setState({
      isGalleryModalOpen: isVisible
    })
  }

  render() {
    let { images } = this.props;

    return (
      <div>
        <Gallery onClick={this.setModalVisible.bind(this, true, "isGalleryModalOpen")} className="event-gallery" pictures={images}/>
        <Modal
          className="event-gallery-modal-wrapper"
          backgroundCloseColor="gray"
          closeColor="#FFFFFF"
          size="large"
          center={true}
          onClickClose={this.setModalVisible.bind(this, false, "isGalleryModalOpen")}
          visible={this.state.isGalleryModalOpen}>
            <Gallery type="image" className="event-gallery-modal" pictures={images} />
        </Modal>
      </div>
    );
  }
}

export default EventGallery;

import React, { Component } from 'react';
import './tableofcontents.css';

class TableOfContents extends Component {
  render(){
    let { header, contents, goToPath } = this.props;
    return (
      <div className="tableofcontents-container">
        <div className="body-copy-bold-purple tableofcontents-header">{header}</div>
        <div>
          {
            contents.map((item, index)=>{
              let { href, label } = item;

              return (
                <div className="tableofcontents-item d-flex">
                  <div className="body-copy-bold-orange tableofcontents-index">{index+1}</div>
                  <a href={`#${href}`}className="body-copy-quarternary tableofcontents-link">{label}</a>
                </div>
              )
            })
          }
        </div>
      </div>
    )
  }
}

export default TableOfContents;

import { ActionTypes } from './messages.actions';

const initialState = {
    errorMessage: ""
};

const MessageReducer = (state = initialState, action) => {
    switch (action.type) {
      case ActionTypes.MESSAGE_SUCCESS:
        return { ...state };
      default:
          return state;
    }
};

export default MessageReducer;

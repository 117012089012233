import React, { Component } from 'react';
import Card from './components/card.component';
import dots from './assets/dots-left.svg';
import clipboard from './assets/clipboard.svg';
import bullhorn from './assets/bullhorn.svg';
import sheet from './assets/document.svg';
import mail from './assets/mail.svg';
import sliders from './assets/sliders.svg';
import './How.css'

class How extends Component {
  render() {
    return (
      <div className="home-eo-how-wrapper">
        <div className="container">
          <div className="row">
            <div className="offset-sm-2 offset-md-2" />
            <div className="col-md-9 col-12 how-eo-cards d-flex flex-wrap">
              <Card
                img={bullhorn}
                label="Create a new channel for sponsorships"
                description="List your event details and audience info to get package request from sponsors sent to your inbox." />
              <Card
                img={sheet}
                label="Build comprehensive event pages"
                description="Create event pages designed with the partnership in mind. Showcase audience engagement and sponsorships." />
              <Card
                img={clipboard}
                label="Customize sponsorship packages"
                description="Preset packages start conversations. Take conversations further by modifying packages with custom placements." />
              <Card
                img={sliders}
                label="Get curated event recommendations"
                description="Use  your sponsorship objectives and target audience to uncover the events relevant to your company." />
            </div>
          </div>
          {/*<div className="home-eo-how-container">
            <div className="header-2-tertiary">Event Organizer</div>
            <div>
              <div className="body-copy-bold-purple">Sponsorship sales made easy</div>
              <div className="body-copy-tertiary">We've streamlined proposals and negotiations to make it easy for you to connect and transact with new partners.</div>
            </div>
          </div>*/}
        </div>
        <img className="home-eo-how-dots" src={dots} />
      </div>
    );
  }
}

export default How;

import { ActionTypes } from './eventsponsors.actions';

const initialState = {
    errorMessage: "",
    eventSponsors: [],
};

const EventSponsorsReducer = (state = initialState, action) => {
    switch (action.type) {
      case ActionTypes.GET_EVENT_SPONSORS_SUCCESS:
        return { ...state};
      case ActionTypes.GET_EVENT_SPONSORS_FAILURE:
        return { ...state};
      default:
          return state;
    }
};

export default EventSponsorsReducer;

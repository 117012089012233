import { put, call, all, takeLatest } from 'redux-saga/effects';
import { ActionTypes } from './eventsponsors.actions';
import { get } from '../Api'

const basePath = "event_sponsors";

export const getEventSponsors = function* (action) {
  try {
    const response = yield call(get, basePath);
    const json = yield response.json();
    yield put({ type: ActionTypes.GET_EVENT_SPONSORS_SUCCESS, data: json });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: ActionTypes.GET_EVENT_SPONSORS_FAILURE, error });
  }
}

function* eventsponsors() {
  yield all([
    takeLatest(ActionTypes.GET_EVENT_SPONSORS, getEventSponsors)
  ]);
}

export default eventsponsors;

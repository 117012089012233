export const ActionTypes = {
    PUT_EVENT_GENDERS: 'PUT_EVENT_GENDERS',
    PUT_EVENT_GENDERS_SUCCESS: 'PUT_EVENT_GENDERS_SUCCESS',
    PUT_EVENT_GENDERS_FAILURE: 'PUT_EVENT_GENDERS_FAILURE'
};

export const putEventGenders = (data) => ({
    type: ActionTypes.PUT_EVENT_GENDERS,
    payload: {
      data
    }
});

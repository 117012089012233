import { put, call, all, takeLatest } from 'redux-saga/effects';
import { ActionTypes } from './packagecategories.actions';
import { getDropwizard } from '../Api'

const basePath = "package-categories";

export const getPackageCategories = function* (action) {
  try {
    const response = yield call(getDropwizard, basePath);
    yield put({ type: ActionTypes.GET_PACKAGE_CATEGORIES_SUCCESS, categories: response.data });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: ActionTypes.GET_PACKAGE_CATEGORIES_FAILURE, error });
  }
}

function* packagecategories() {
  yield all([
    takeLatest(ActionTypes.GET_PACKAGE_CATEGORIES, getPackageCategories)
  ]);
}

export default packagecategories;

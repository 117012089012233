import { ActionTypes } from './eventattendances.actions';

const initialState = {
    eventattendances: null
};

const EventAttendancesReducer = (state = initialState, action) => {
    switch (action.type) {
      case ActionTypes.PUT_EVENT_ATTENDANCES_SUCCESS:
        return { ...state, eventattendances: action.eventattendances };
      default:
          return state;
    }
};

export default EventAttendancesReducer;

import { put, call, all, takeLatest } from 'redux-saga/effects';
import { ActionTypes } from './occupations.actions';
import { post } from '../Api'
const basePath = "occupations";

export const searchOccupations = function* (action) {
  const { payload: { query } } = action;
  try {
    const response = yield call(post, basePath + "/search", { query });
    const json = yield response.json();
    yield put({ type: ActionTypes.SEARCH_OCCUPATIONS_SUCCESS, data: json });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: ActionTypes.SEARCH_OCCUPATIONS_FAILURE, error });
  }
}

export const setOccupations = function* (action) {
  const { payload: { occupations } } = action;
  try {
    yield put({ type: ActionTypes.SEARCH_OCCUPATIONS_SUCCESS, data: occupations });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: ActionTypes.SEARCH_OCCUPATIONS_FAILURE, error });
  }
}

function* occupations() {
  yield all([
    takeLatest(ActionTypes.SEARCH_OCCUPATIONS, searchOccupations),
    takeLatest(ActionTypes.SET_OCCUPATIONS, setOccupations)
  ]);
}

export default occupations;

import { put, call, all, takeLatest } from 'redux-saga/effects';
import { ActionTypes } from './places.actions';
import { post } from '../Api'

const basePath = "places"

export const getSuggestions = function* (action) {
  const { payload: { query, isAddressAllowed, isGeopoliticalRegionAllowed } } = action;
  try {
    const response = yield call(post, basePath + "/autocomplete", { query: query.query, addressAllowed: isAddressAllowed, geopoliticalRegionAllowed: isGeopoliticalRegionAllowed });
    const data = yield response.json();
    yield put({ type: ActionTypes.GET_SUGGESTIONS_SUCCESS, data });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: ActionTypes.GET_SUGGESTIONS_FAILURE, error });
  }
}

function* places() {
  yield all([
    takeLatest(ActionTypes.GET_SUGGESTIONS, getSuggestions)
  ]);
}

export default places;

import { put, call, all, takeLatest } from 'redux-saga/effects';
import { ActionTypes } from './portfolios.actions';
import { getDropwizard, post } from '../Api'

const basePath = "portfolios"

export const getPortfolio = function* (action) {
  let { portfolio_id } = action.payload;
  try {
    let query = "?"

    let params = [];

    /*if (user_id) {
      params.push("user_id=" + user_id);
    }*/

    if (portfolio_id) {
      params.push("portfolio_id=" + portfolio_id);
    }

    if (params.length > 0) {
      query = query + params.join("&");
    } else {
      query = ""
    }

    const response = yield call(getDropwizard, basePath, query);

    yield put({ type: ActionTypes.GET_PORTFOLIO_SUCCESS, data: response.data });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: ActionTypes.GET_PORTFOLIO_FAILURE, error });
  }
}

export const getPortfolios = function* (action) {
  let { portfolio_id, user_id } = action.payload;
  try {
    let query = "?"

    let params = [];


    if (portfolio_id) {
      params.push("portfolio_id=" + portfolio_id);
    }

    if (params.length > 0) {
      query = query + params.join("&");
    } else {
      query = ""
    }

    const response = yield call(getDropwizard, basePath, query);
    yield put({ type: ActionTypes.GET_PORTFOLIOS_SUCCESS, data: response.data });
  } catch (error) {
    yield put({ type: ActionTypes.GET_PORTFOLIOS_FAILURE, error });
    // dispatch a failure action to the store with the error
  }
}

export const publishPortfolio = function* (action) {
  const { payload: { portfolio_id } } = action;
  try {
    const response = yield call(getDropwizard, `${basePath}/${portfolio_id}/publish`);
    const data = yield response.json();
    yield put({ type: ActionTypes.PUBLISH_PORTFOLIO_SUCCESS, data: response.data });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: ActionTypes.PUBLISH_PORTFOLIO_FAILURE, error });
  }
}

export const postPortfolio = function* (action) {
  const { payload: { portfolio } } = action;
  try {
    const response = yield call(post, basePath, portfolio);
    const data = yield response.json();

    yield put({ type: ActionTypes.POST_PORTFOLIO_SUCCESS, data });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: ActionTypes.POST_PORTFOLIO_FAILURE, error });
  }
}

export const setCurrentPortfolio = function* (action) {
  const { payload: { currentPortfolio  } } = action;
  try {
    yield put({ type: ActionTypes.SET_CURRENT_PORTFOLIO_SUCCESS, data: currentPortfolio });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: ActionTypes.SET_CURRENT_PORTFOLIO_FAILURE, error });
  }
}

function* portfolios() {
  yield all([
    takeLatest(ActionTypes.GET_PORTFOLIOS, getPortfolios),
    takeLatest(ActionTypes.GET_PORTFOLIO, getPortfolio),
    takeLatest(ActionTypes.POST_PORTFOLIO, postPortfolio),
    takeLatest(ActionTypes.SET_CURRENT_PORTFOLIO, setCurrentPortfolio),
    takeLatest(ActionTypes.PUBLISH_PORTFOLIO, publishPortfolio),
  ]);
}

export default portfolios;

import React, { Component } from 'react';
import TextField from 'components/TextField';
import Button from 'components/new/Button';
import Wrapper from 'components/Wrapper';
import SignUpFacebookBase from './SignUpFacebookBase';
import { signUp } from 'services/signup/signup.actions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import './signup.css';

class SignUpScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subscribed: true,
      errorMessage: '',
      error: false,
      firstName: '',
      lastName: '',
      email: '',
      password: ''
    };
  }

  _handleOnPressSignUp() {
    let user = {
      subscribed: this.state.subscribed,
      email: this.state.email,
      emailVerified: false,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      disabled: false
    };

    this.props.signUp(this.state.email, this.state.password, this.state.firstName, this.state.lastName, user, this.props.history);
  }

  _handleOnPressLogIn() {

  }

  onChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  render() {
    return (
      <Wrapper className="signup-container container d-flex align-items-center justify-content-center flex-column">
        <div style={{marginBottom: '65px'}} className="header-1-tertiary">Sign Up</div>
        <div className='signup-form d-flex align-items-center flex-column'>
          <TextField
            name="firstName"
            placeholder='First Name'
            value={this.state.firstName}
            onChange={this.onChange.bind(this)} />
          <TextField
            name="lastName"
            placeholder='Last Name'
            value={this.state.lastName}
            onChange={this.onChange.bind(this)} />
          <TextField
            name="email"
            placeholder='E-mail'
            value={this.state.email}
            onChange={this.onChange.bind(this)} />
          <TextField
            name="password"
            placeholder='Password'
            type="password"
            value={this.state.password}
            onChange={this.onChange.bind(this)} />
          <Button
            label="Sign Up"
            type="tertiary"
            size="lg"
            onClick={this._handleOnPressSignUp.bind(this)} />
          {this.props.error ? <div className="d-flex justify-content-center" style={{width: '100%', position: "relative"}}><span style={{textAlign: 'center', position: "absolute", color: 'red'}}>{this.props.errorMessage}</span></div> : null}
          <div style={{display: 'none'}}>
            <SignUpFacebookBase firebase={this.props.firebase} />
          </div>
        </div>

      </Wrapper>
    )
  }
}

const mapDispatchToProps = dispatcher => (
    bindActionCreators({
        signUp
    }, dispatcher)
);

const mapStateToProps = (state) => {
  return {
    errorMessage: state.signUp.errorMessage,
    error: state.signUp.error
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUpScreen);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './Autocomplete.css';
import './TextField.css';
import { AutoComplete } from 'antd';

class Autocomplete extends Component {
  static propTypes = {
    label: PropTypes.string,
    validator: PropTypes.string
  }

  static defaultProps = {
    value: "",
    error: false,
    size: "md",
    fontStyle: "tertiary",
    resetOnSelect: false,
    required: false,
    style: {}
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevProps.value !== this.props.value){
      //Perform some operation here
      this.setState({value: this.props.value});
    }
  }

  constructor(props) {
    super(props);
    this.state = {
      value: props.value,
      active: false
    };
  }

  onChange(value) {
    this.setState({
      value: value
    });
    if (this.props.onChange != null) {
      if (value.length > 2) {
        this.props.onChange(value);
      }
    }
  }

  onBlur(event) {
    if (this.props.onBlur != null) {
      this.props.onBlur();
    }
  }

  onMouseDownOption(value) {
    let { onMouseDownOption, resetOnSelect } = this.props;
    if (onMouseDownOption != null) {
      onMouseDownOption(value);
      if (resetOnSelect) value = ""
      this.setState({
        value
      });
    }
  }

  getLabel() {
    let { label, fontStyle, required, optional } = this.props;
    let className = "";

    if (label == null || label == "") {
      return;
    }

    if (fontStyle === "primary") {
      className = "body-copy-bold-primary";
    } else if (fontStyle === "secondary") {
      className = "body-copy-bold-secondary";
    } else if (fontStyle === "tertiary") {
      className = "body-copy-bold-tertiary";//"body-copy-tertiary";
    }

    return (
      <label className={className}>{required ? "*" : ""}{label}{optional ? " (Optional)" : ""}</label>
    );
  }

  onClickAddEntry() {
    if (this.props.onClickAddEntry) {

    }
  }

  getOptions() {
    return (
      this.props.results.map((result, index)=>{
        return (<div
          key={index}
          onMouseDown={this._handleMouseDownOption.bind(this)}
          className="autocomplete-option d-flex align-items-center">{result}</div>);
      })
    )
  }

  onBlur() {
    let { validator } = this.props;

    if (!validator) {
      return;
    }

    if (validator(this.state.value)) {
      this.setState({
        error: true
      })
    } else {
      if (this.props.error) {
        this.setState({
          error: false
        })
      }
    }

  }

  render() {
    let className = ["textfield"];
    let { leftRounded, size, results, style, placeholder } = this.props;
    let { errorMessage, error } = this.props;

    className = (leftRounded !== null && leftRounded === true ? className.concat(["textfield-left-rounded"]) : className);
    className = (size === "full" ? className.concat(["textfield-full"]) : className);
    className = (size === "md" ? className.concat(["textfield-md"]) : className);

    let autocompleteOptionsClassName = ["autocomplete-options"];

    autocompleteOptionsClassName = (results.length === 0 ?
      autocompleteOptionsClassName.concat("autocomplete-hidden") : autocompleteOptionsClassName.filter(className => className !== 'autocomplete-hidden'));

    let containerClassName = ["autocomplete-container d-flex flex-column"];
    containerClassName = (size === "full" ? containerClassName.concat(["textfield-container-full"]) : containerClassName);
    containerClassName = (size === "md" ? containerClassName.concat(["textfield-container-md"]) : containerClassName);

    let additionalstyle = style;
    return (
      <div className={containerClassName.join(" ")}>
        <div style={{position: 'relative'}} className="d-flex flex-column">
          {this.getLabel()}
          <AutoComplete
            onBlur={this.onBlur.bind(this)}
            className="autocomplete-antd-styles"
            value={this.state.value}
            onChange={this.onChange.bind(this)}
            onSelect={this.onMouseDownOption.bind(this)}
            dataSource={results}
            placeholder={placeholder} />
        </div>
        <div>
          <div className={error ? "textfield-error-container" : "textfield-hidden" }>
            <div className="body-copy-error-xs-small">{errorMessage}</div>
          </div>
        </div>
      </div>

    );
  }
}

export default Autocomplete;

import React, { Component } from 'react';
import Button from 'components/new/Button';
import FormGroup from 'components/FormGroup';
import './ButtonGroup.css';

class ButtonGroup extends Component {
  static defaultProps = {
    reverse: false,
    responsiveJustification: 'vertical',
    style: {},
    overridePrimaryType: "",
    overrideSecondaryType: "",

  }

  onClickPrimary() {
    if (this.props.onClickPrimary !== null) {
      this.props.onClickPrimary();
    }
  }

  onClickSecondary() {
    if (this.props.onClickSecondary !== null) {
      this.props.onClickSecondary();
    }
  }

  render() {
    let {
      reverse,
      overridePrimaryType,
      overrideSecondaryType,
      responsiveJustification,
      style,
      labelPrimary,
      labelSecondary
    } = this.props;

    let primary = "primary";
    let secondary = "secondary";

    if (overridePrimaryType) {
      primary = overridePrimaryType;
    }

    let leftType = primary;
    let rightType= secondary;

    if (reverse) {
      leftType = secondary;
      rightType = primary;
    }

    let className = "flex-md-row d-flex justify-content-end flex-column restage-button-group";
    if (responsiveJustification === 'horizontal') {
      className = "flex-row d-flex justify-content-end restage-button-group-horizontal";
    }

    return (
      <div style={style} className={className}>
        <Button label={labelSecondary} type={rightType} onClick={this.onClickSecondary.bind(this)} />
        <Button label={labelPrimary} type={leftType} onClick={this.onClickPrimary.bind(this)} />
      </div>
    );
  }
}

export default ButtonGroup;

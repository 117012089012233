export const ActionTypes = {
    FACEBOOK_LOGIN: 'FACEBOOK_LOGIN',
    FACEBOOK_LOGIN_SUCCESS: 'FACEBOOK_LOGIN_SUCCESS',
    FACEBOOK_LOGIN_FAILURE: 'FACEBOOK_LOGIN_FAILURE',

    USER_LOGIN: 'USER_LOGIN',
    USER_LOGIN_SUCCESS: 'USER_LOGIN_SUCCESS',
    USER_LOGIN_FAILURE: 'USER_LOGIN_FAILURE',

    USER_LOGOUT: 'USER_LOGOUT',
    USER_LOGOUT_SUCCESS: 'USER_LOGOUT_SUCCESS',
    USER_LOGOUT_FAILURE: 'USER_LOGOUT_FAILURE',
};

export const userLogin = (email, password) => ({
    type: ActionTypes.USER_LOGIN,
    payload: {
        email,
        password
    },
});

export const userLogout = () => ({
  type: ActionTypes.USER_LOGOUT
})

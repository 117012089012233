import { ActionTypes } from './jobseniorities.actions';

const initialState = {
    errorMessage: "",
    seniorities: []
};

const JobSenioritiesReducer = (state = initialState, action) => {
    switch (action.type) {
      case ActionTypes.GET_JOB_SENIORITIES_SUCCESS:
        return { ...state, seniorities: action.seniorities};
      default:
          return state;
    }
};

export default JobSenioritiesReducer;

import { put, call, all, takeLatest } from 'redux-saga/effects';
import { ActionTypes } from './eventassets.actions';
import { post, deleteRequest } from '../Api'

const basePath = "event-assets"

export const postEventAssets = function* (action) {
  const { payload: { event_assets } } = action;
  try {
    const response = yield call(post, basePath, event_assets, true);
    const data = yield response.json();
    yield put({ type: ActionTypes.POST_EVENT_ASSETS_SUCCESS, response: data });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: ActionTypes.POST_EVENT_ASSETS_FAILURE, error });
  }
}

/*export const postEventAsset = function* (action) {
  const { payload: { event_assets } } = action;
  try {
    const response = yield call(post, basePath, event_assets, true);
    yield put({ type: ActionTypes.POST_EVENT_ASSET_SUCCESS, response });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: ActionTypes.POST_EVENT_ASSET_FAILURE, error });
  }
}*/

export const deleteEventAsset = function* (action) {
  const { payload: { id } } = action;
  try {
    const response = yield call(deleteRequest, `${basePath}/${id}`);
    yield put({ type: ActionTypes.DELETE_EVENT_ASSET_SUCCESS, id });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: ActionTypes.DELETE_EVENT_ASSET_FAILURE, error });
  }
}

function* eventassets() {
  yield all([
    takeLatest(ActionTypes.POST_EVENT_ASSETS, postEventAssets),
    //takeLatest(ActionTypes.POST_EVENT_ASSET, postEventAsset),
    takeLatest(ActionTypes.DELETE_EVENT_ASSET, deleteEventAsset),
  ]);
}

export default eventassets;

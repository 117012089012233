import React from 'react';

function GetStartedPanel(props) {
  let { title, items } = props;
  return (
    <div className="eventshome-emptystate d-flex flex-column">
      <div style={{width: '100%'}} className="d-flex justify-content-center">
        <div className="eventshome-emptystate-header header-2-orange">{title}</div>
      </div>
      <div className="eventshome-emptystate-instructions">
        {
          items.map((item, index) => {
            return (
              <div className="d-flex flex-row">
                <div className="eventshome-emptystate-label body-copy-bold-tertiary">{index + 1}</div>
                <div className="body-copy-tertiary">{item}</div>
              </div>
            )
          })
        }
      </div>
    </div>
  )
}

export default GetStartedPanel;

import { ActionTypes } from './users.actions';

const initialState = {
    errorMessage: "",
    users: [],
    currentUser: null,
    user: null
};

const UsersReducer = (state = initialState, action) => {
    switch (action.type) {
      case ActionTypes.POST_USERS_SUCCESS:
        return { ...state, users: action.data};
      case ActionTypes.SET_CURRENT_USER:
          return { ...state, currentUser: action.payload, user: action.payload};
      case ActionTypes.GET_USER_SUCCESS:
          return { ...state, currentUser: action.payload, user: action.payload};
      case ActionTypes.GET_USER_PROFILE_SUCCESS:
        return {
          ...state,
        }
      case ActionTypes.GET_USER_PROFILE_FAILURE:
        return {
          ...state
        };
      case ActionTypes.POST_USER_PROFILE_SUCCESS:
        return {
          ...state,
        }
      case ActionTypes.POST_USER_PROFILE_FAILURE:
        return {
          ...state
        };
      default:
          return state;
    }
};

export default UsersReducer;

import React, { Component } from 'react';
import './paragraph.css';

class Paragraph extends Component {
  static defaultProps = {
    className: ""
  }
  render() {
    return (
      <div className={`${this.props.className} body-copy-tertiary`}>
        {this.props.children}
      </div>
    );
  }
}

export default Paragraph;

import { ActionTypes } from './accountmodal.actions';

const initialState = {
  visibility: false
};

const AccountModalReducer = (state = initialState, action) => {
    switch (action.type) {
      case ActionTypes.SET_ACCOUNT_MODAL_VISIBILITY:
        let { visibility } = action.payload;
        return { visibility };
      default:
          return state;
    }
};

export default AccountModalReducer;

import React, { Component } from 'react';
import Paragraph from 'components/blog/paragraph.component';
import Header from 'components/blog/header.component';
import List from 'common/list.component';
import Footer from '../learnmore/components/footer.component';
import Button from 'components/new/Button';
import PostAuthor from './components/postauthor.component';
import TableOfContents from './components/tableofcontents.component';
import PostMediaSticky from './components/postmediasticky.component';
import PostMediaMobile from './components/postmediamobile.component';
import Pill from 'components/Pill';
import { StickyContainer, Sticky } from 'react-sticky';
import { Helmet } from 'react-helmet';
import { Routes } from 'constants/Routes';
import { getBlogPost } from 'services/blog/blog.actions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import './post.css';

class Post extends Component {
  componentDidMount() {
    const { id } = this.props.match.params;
    this.props.getBlogPost(id);
  }

  goToPath(path) {
    this.props.history.push(path);
  }

  build() {
    let buffer = [];
    let hero = null;
    let header = null;
    let {
      title,
      content,
      author,
      authorsocialname,
      authorsocialurl,
      authorphotourl,
      ctalabel,
      ctadescription,
      ctalink,
      tableofcontents
    } = this.props.post;

    content.map((item, index)=>{
      if (index === 0) {
        if (item.type === "IMAGE") {
          let description = item.description ? <div style={{marginTop: '5px'}} className="body-copy-bold-tertiary-xs-small">{item.description}</div> : null
          hero = (
            <div key={`post - ${index}`} className="post-hero d-flex align-items-center flex-column">
              <div>
                <img alt={item.description} style={{width: '100%'}} src={item.content} />
              </div>
              <div style={{paddingLeft: '15px', paddingRight: '15px'}}>{description}</div>
            </div>
          )
        }
      } else if (index === 1) {
        if (item.type === "HEADING") {
          /*buffer.push(
            <div key={`post - ${index}`}>

              <div style={{marginBottom: '20px'}}><span className="body-copy-bold-orange">{this.props.post.category}</span>{` | ${Math.ceil(this.props.post.wordcount/200)} min read`}</div>
              <PostAuthor
                author={author}
                authorphotourl={authorphotourl}
                authorsocialname={authorsocialname}
                authorsocialurl={authorsocialurl}
                style={{marginTop: '20px'}} />
            </div>
          );*/
          /*buffer.push(
            <div>
              <Header content={item.content} level={item.level} />
              <div>
                <div>Written by {this.props.post.author}</div>
                <div>{this.props.post.date}</div>
              </div>
            </div>
          );*/
        }
      } else {
        if (item.type === "PARAGRAPH") {
          if (item.content === '[TOC]') {
            buffer.push(<TableOfContents
              key="table-of-contents"
              header={title}
              contents={tableofcontents}
              goToPath={this.goToPath.bind(this)}
            />);
          } else {
            buffer.push(<Paragraph>{item.content}</Paragraph>);
          }
        } else if (item.type === "IMAGE") {

          let description = item.description ? <div key={`post - ${index}`} key={`post - ${index}`}style={{marginTop: '5px'}} className="body-copy-bold-tertiary-xs-small">{item.description}</div> : null
          buffer.push(
            <div key={`post - ${index}`} className="d-flex align-items-center justify-content-center flex-column">
              <div>
                <img alt={item.description} style={{width: '100%'}} src={item.content} />
              </div>
              {description}
            </div>
          )
        } else if (item.type === "HEADING") {
          let href = "";
          let content = item.content;
          let parts = item.content.split("#");
          if (parts.length > 1) {
            href = parts[1];
            content = parts[0];
          }
          buffer.push(<Header key={`post - ${index}`} href={href} content={content.trim()} level={item.level} />);
        } else if (item.type === "LIST") {
          buffer.push(<List key={`post - ${index}`} expand={true} justification="vertical" items={item.list} />);
        } else if (item.type === 'GROUP') {
          let group = []
          for (let index in item.group) {
            let block = item.group[index]
            if (block.type === "PARAGRAPH") {
              if (block.properties && block.properties.bold) {
                group.push(<span key={`post - ${index}`} className="body-copy-bold-tertiary">{block.content}</span>);
              } else {
                group.push(block.content);
              }
            } else if (block.type === "LINK") {
              group.push(<a key={`post - ${index}`} target="_blank" rel="noopener noreferrer" href={block.url}>{block.content}</a>)
            }
          }
          buffer.push(<Paragraph key={`post - ${index}`}>{group}</Paragraph>)
        } else if (item.type === 'QUOTE') {
          let group = []
          for (let index in item.group) {
            let block = item.group[index]
            if (block.type === "PARAGRAPH") {
              if (block.properties && block.properties.bold) {
                group.push(<span key={`post - ${index}`} className="body-copy-bold-tertiary">{block.content}</span>);
              } else {
                group.push(block.content);
              }
            } else if (block.type === "LINK") {
              group.push(<a key={`post - ${index}`} target="_blank" rel="noopener noreferrer" href={block.url}>{block.content}</a>)
            }
          }
          buffer.push(<Paragraph className="post-blockquote" key={`post - ${index}`}>{group}</Paragraph>)
        }
      }
    });
    return(
      <div className="post-wrapper">
        {hero}
        <div className="container">
          <div className="d-flex flex-column align-items-center">
            <div className="post-authorwrapper">
              <div style={{marginBottom: '20px'}}><span className="body-copy-bold-orange">{this.props.post.category}</span>{` | ${Math.ceil(this.props.post.wordcount/200)} min read`}</div>
              <PostAuthor
                history={this.props.history}
                author={author}
                ctalabel={ctalabel}
                ctadescription={ctadescription}
                ctalink={ctalink}
                authorphotourl={authorphotourl}
                authorsocialname={authorsocialname}
                authorsocialurl={authorsocialurl}
                style={{marginTop: '20px'}} />
            </div>
          </div>
        </div>
        <StickyContainer>
          <Sticky>{({ style }) => <PostMediaSticky className="post-stickycontainer" style={style}/>}</Sticky>
          <div className="post-container d-flex flex-column justify-content-center">
            {buffer}
          </div>
          <div className="container d-flex justify-content-center">
            <div className="post-about-restage-container">
              <div className="post-about-restage-header body-copy-bold-tertiary">About Restage</div>
              <div className="body-copy-tertiary">
                Restage is a marketplace for event sponsorships.
                We help event organizers and sponsors find the right partners for events using recommendations and audience targeting.
                Are you an event organizer? List your event on Restage and start getting new sponsor leads for your next event.
              </div>
            </div>
          </div>
        </StickyContainer>
        {/*<div style={{position: 'relative'}}>
          <div style={{position: 'fixed', top: '0px'}}>Bloop</div>
          <div className="post-container d-flex flex-column justify-content-center">
            {buffer}
          </div>
        </div>*/}
          <PostMediaMobile />
        <Footer
          background="purple"
          description="Streamline your event sponsorship sales"
          ctaLabel="Create Event">
          <div className="d-flex flex-sm-row resources-ctas flex-column">
            <div className="d-flex align-items-center resources-create-cta"><Button label="CREATE EVENT" size="md" type="primary" onClick={this.goToPath.bind(this, Routes.ORGANIZER_ONBOARDING_INTRO)}  /></div>
            <div className="d-flex align-items-center"><a style={{textAlign: 'center'}} className="button-md new-button button-tertiary body-copy-white link-undecorated" target="_blank" rel="noopener noreferrer" href="https://calendly.com/kiamber">BOOK A DEMO</a></div>
          </div>
        </Footer>
        <Helmet>
          <title>{ title }</title>
        </Helmet>
      </div>
    )
  }

  render(){
    if (!this.props.post) return null;
    return this.build();
  }
}

const mapStateToProps = (state) => {
  return {
    post: state.blog.post
  };
};

const mapDispatchToProps = dispatcher => (
    bindActionCreators({
      getBlogPost
    }, dispatcher)
);

export default connect(mapStateToProps, mapDispatchToProps)(Post);

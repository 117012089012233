import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './Eyebrow.css';

class Eyebrow extends Component {
  static propTypes = {
    label: PropTypes.string.isRequired
  }

  render() {
    return (
      <div className="d-flex">
        <div className="body-copy-bold-tertiary">{this.props.label}</div>
      </div>
    );
  }
}

export default Eyebrow;

import firebase from '../../firebase/Firebase';

export const createUserWithEmailAndPassword = async (email, password) => {
    return await firebase.auth.createUserWithEmailAndPassword(email, password);
}


export const createUser = async (user) => {
    return await firebase.auth.createUser(user);
}

import { put, call, all, takeLatest } from 'redux-saga/effects';
import { ActionTypes } from './jobseniorities.actions';
import { getDropwizard } from '../Api'

const basePath = "job-seniorities";

export const getJobSeniorities = function* (action) {
  try {
    const response = yield call(getDropwizard, basePath);
    yield put({ type: ActionTypes.GET_JOB_SENIORITIES_SUCCESS, seniorities: response.data });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: ActionTypes.GET_JOB_SENIORITIES_FAILURE, error });
  }
}

function* jobseniorities() {
  yield all([
    takeLatest(ActionTypes.GET_JOB_SENIORITIES, getJobSeniorities)
  ]);
}

export default jobseniorities;

import { ActionTypes } from './packagecategories.actions';

const initialState = {
    errorMessage: "",
    categories: []
};

const PackageCategoriesReducer = (state = initialState, action) => {
    switch (action.type) {
      case ActionTypes.GET_PACKAGE_CATEGORIES_SUCCESS:
      console.log(action)
        return { ...state, categories: action.categories};
      default:
          return state;
    }
};

export default PackageCategoriesReducer;

import moment from 'moment';

export const generateDateFormat = (date) => {
  return moment(date).format("MMMM Do");
}

export const generateFullDateFormat = (date) => {
  return moment(date).format("MMMM Do, YYYY");
}

export const generateTimeFormat = (date) => {
  return moment(date).format('h:mm a');
}

export const generateDateTimeFormat = (date) => {
  return date.toLocaleDateString() + " " + date.toLocaleTimeString();
}

import React, { Component } from 'react';
import Button from 'components/new/Button';
import DotsLeft from '../assets/resources-dots-left.svg';
import DotsRight from '../assets/resources-dots-right.svg';
import ProductPhotoSponsorships from '../assets/product-photo-sponsorships.png';
import { Routes } from 'constants/Routes';
import './resourceshero.css';

class ResourcesHero extends Component {
  static defaultProps = {
    className: ""
  }
  render(){
    let { onCTA, header, description, ctaLabel } = this.props;

    return(
      <div className={`d-flex flex-column align-items-center justify-content-center resources-hero-container ${this.props.className}`}>
        <div className="resources-hero-wrapper container-fluid row">
          <div className="col-lg-5 col-12 resources-hero-text">
            <div style={{height: '100%'}} className="d-flex align-items-center justify-content-center">
              <div>
                <div style={{marginBottom: '15px'}} className="header-1-white">{header}</div>
                <div>
                  <Button
                    type="tertiary"
                    size="sm"
                    onClick={onCTA.bind(this)}
                    label={ctaLabel}/>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-7 col-12">
            <img src={ProductPhotoSponsorships} style={{zIndex: '2', position: 'relative', width: '100%'}} />
          </div>
        </div>
        <img className="resources-dots-left" src={DotsLeft} />
        <img className="resources-dots-right" src={DotsRight} />
      </div>
    )
  }
}

export default ResourcesHero;

import { put, call, all, takeLatest } from 'redux-saga/effects';
import { ActionTypes } from './verify.actions';
import { getDropwizard } from '../Api'

const basePath = "verify"

export const verifyEmailAndToken = function* (action) {
  let { email, token } = action.payload;
  try {
    const response = yield call(getDropwizard, `${basePath}?token=${token}&email=${email}`);
    if (response.status >= 200 && response.status < 300) {
      yield put({ type: ActionTypes.VERIFY_EMAIL_SUCCESS, success: true });
    } else {
      throw response;
    }
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: ActionTypes.VERIFY_EMAIL_FAILURE, error: true, success: false });
  }
}

export const resend = function* (action) {
  let { token, email } = action.payload;
  try {
    const response = yield call(getDropwizard, `${basePath}/resend?token=${token}&email=${email}`);

    if (response.status >= 200 && response.status < 300) {
      yield put({ type: ActionTypes.RESEND_VERIFY_EMAIL_SUCCESS, success: true, email: response.data.email, token: response.data.token });
    } else {
      throw response;
    }
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: ActionTypes.RESEND_VERIFY_EMAIL_FAILURE, error: true, success: false });
  }
}

function* verify() {
  yield all([
    takeLatest(ActionTypes.VERIFY_EMAIL, verifyEmailAndToken),
    takeLatest(ActionTypes.RESEND_VERIFY_EMAIL, resend)
  ]);
}

export default verify;

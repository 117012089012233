import { ActionTypes } from './forgotpassword.actions';

const initialState = {
    errorMessage: "",
    session: null
};

const ForgotPasswordReducer = (state = initialState, action) => {
    switch (action.type) {
      case ActionTypes.FORGOT_PASSWORD_SUCCESS:
        return { ...state, success: action.success};
      default:
          return state;
    }
};

export default ForgotPasswordReducer;

import { put, call, all, takeLatest } from 'redux-saga/effects';
import { ActionTypes } from './availableplacements.actions';
import { post } from '../Api'

const basePath = "availableplacements"

export const postAvailablePlacements = function* (action) {
  const { payload: { requests } } = action;
  try {
    const response = yield call(post, basePath, requests);
    const json = response.json();
    yield put({ type: ActionTypes.POST_AVAILABLE_PLACEMENTS_SUCCESS, json });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: ActionTypes.POST_SPONSORSHIP_REQUESTS_FAILURE, error });
  }
}

function* availableplacements() {
  yield all([
    takeLatest(ActionTypes.POST_AVAILABLE_PLACEMENTS, postAvailablePlacements)
  ]);
}

export default availableplacements;

import React, { Component } from 'react';
import Wrapper from 'components/Wrapper';
import Tab from 'common/Tab';
import TabGroup from 'common/TabGroup';
import { Routes } from 'constants/Routes';
import Document from 'assets/icons/document@3x.png';

import './settings.css';

const tabs = [
  { alt: "Document", icon: Document, label: "Profile", route: Routes.SETTINGS_PROFILE }
]

class Settings extends Component {
  onClickTab(route) {
    this.props.history.push(route);
  }

  render() {
    let { history } = this.props;
    return (
        <div className="container">
          <TabGroup>
            {
              tabs.map(({alt, icon, label, route}, index)=>{
                return (
                  <Tab
                    isCompleteVisible={false}
                    alt={alt}
                    key={index}
                    icon={icon}
                    label={label}
                    history={history}
                    route={route}
                    onClick={this.onClickTab.bind(this, route)} />
                  )
              })
            }
          </TabGroup>
          <Wrapper style={{position: 'relative'}}>
            {this.props.children}
          </Wrapper>
        </div>
    );
  }
}

export default Settings;

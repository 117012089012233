import { put, call, all, takeLatest } from 'redux-saga/effects';
import { ActionTypes } from './users.actions';
import { get, post, getDropwizard } from '../Api'
import firebase from '../../firebase/Firebase';

const basePath = "users";

export const getUser = function* (action) {
  try {

    const response = yield call(getDropwizard, basePath, "?user_id=" + firebase.auth.currentUser.uid);
    if (response.status >= 200 && response.status < 300) {
      yield put({ type: ActionTypes.GET_USER_SUCCESS, payload: response.data });
    } else {
      throw response;
    }
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: ActionTypes.GET_USER_FAILURE, error });
  }
}

export const postUsers = function* (action) {
  const { payload: { user } } = action;
  try {
    const response = yield call(post, basePath, user);
    yield put({ type: ActionTypes.POST_USERS_SUCCESS, response });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: ActionTypes.POST_USERS_FAILURE, error });
  }
}

export const getUserProfile = function* (action) {
  const { payload: { id } } = action;
  try {
    const response = yield call(get(basePath + "/profile"), id);
    const data = yield response.json();
    yield put({ type: ActionTypes.GET_USER_PROFILE_SUCCESS, data });
  } catch (error) {
    yield put({ type: ActionTypes.GET_USER_PROFILE_FAILURE, error });
  }
}

export const postUserProfile = function* (action) {
  const { payload: { id, request } } = action;
  try {
    const response = yield call(post(basePath + "/profile"), request);
    const data = yield response.json();
    yield put({ type: ActionTypes.POST_USER_PROFILE_SUCCESS, data });
  } catch (error) {
    yield put({ type: ActionTypes.POST_USER_PROFILE_FAILURE, error });
  }
}


function* users() {
  yield all([
    takeLatest(ActionTypes.POST_USERS, postUsers),
    takeLatest(ActionTypes.GET_USER, getUser),
    takeLatest(ActionTypes.GET_USER_PROFILE, getUserProfile),
    takeLatest(ActionTypes.POST_USER_PROFILE, postUserProfile)
  ]);
}

export default users;

import React, { Component } from 'react';
import TextField from 'components/TextField';
import Checkbox from 'components/Checkbox';
import Pill from 'components/Pill';
import PillWrapper from 'components/PillWrapper';
import CheckboxWrapper from 'components/CheckboxWrapper';
import Select from 'components/Select';
import AutocompleteEntryGroup from 'common/AutocompleteEntryGroup';
import Places from 'common/Places';
import Autocomplete from 'components/Autocomplete';
import FormGroup from 'components/FormGroup';
import { addAudienceProfileAttribute, removeAudienceProfileAttribute, setAudienceProfileName } from 'services/audienceprofile/audienceprofile.actions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getJobFunctions } from 'services/jobfunctions/jobfunctions.actions';
import { getJobSeniorities } from 'services/jobseniorities/jobseniorities.actions';
import { searchOccupations, setOccupations } from 'services/occupations/occupations.actions';
import { searchIndustries, setIndustries } from 'services/industries/industries.actions';
import './audienceprofile.css';


class AudienceProfile extends Component {
  static defaultProps = {
    isAudienceNameVisible: true,
    style: {},
    isNameFilled: true
  }

  constructor(props) {
    super(props);
    console.log("Audience profile props", props)
    this.state = {
      name: {
        value: props.name,
        name: "Year cannot be empty.",
        error: false
      },
      occupation: {
        value: "",
        error: false
      },
      industry: {
        value: "",
        error: false
      },
      isRadiusVisible: false
    }
  }

  componentDidMount() {
    this.props.getJobFunctions();
    this.props.getJobSeniorities();
  }

  onClickAgeRange(option) {
    let attribute = this.createAttribute("Age Range", option, option);
    let filteredArray = this.props.attributes.filter(({ type, value }) => attribute.type === type && attribute.value === value);

    if (filteredArray.length === 0) {
      this.props.addAudienceProfileAttribute(attribute);
    } else {
      this.props.removeAudienceProfileAttribute(attribute);
    }
  }

  createAttribute(type, value, data) {
    let attribute = {
      type,
      value,
      data
    };
    return attribute;
  }

  onAutocompleteOptionSelection(selected) {
    this.setState({
      place_id: selected[0].place_id
    });
    this.props.addAudienceProfileAttribute(this.createAttribute("Location", selected[0].description, selected[0].placeId));


  }

  setAgeRangeChecked(ageRange) {
    let filteredArray = this.props.attributes.filter(({ type, value }) => "Age Range" === type && ageRange === value);
    if (filteredArray.length > 0) {
      return true;
    }
    return false;
  }

  toggleRadiusInput() {
    let { isRadiusVisible } = this.state;
    this.setState({
      isRadiusVisible: !isRadiusVisible
    })
  }

  setAgeRangeUI() {
    let ageRanges = ["18-24", "25-34", "35-44", "45-54", "55-64", "65+"];

    return (
      <div>
        <label className="body-copy-bold-tertiary">Age ranges (Select all that apply)</label>
        <CheckboxWrapper style={{width: '100%'}}>
          {
            ageRanges.map((option, index) => {
              return (
                <Checkbox style={{width: '100%'}} className="col-sm-6 col-12" key={index} checked={this.setAgeRangeChecked(option)} label={option} onClick={this.onClickAgeRange.bind(this, option)} value={option} />
              )
            })
          }
          </CheckboxWrapper>
      </div>
    );
  }

  onChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  onChangeName(event) {
    this.setState({
      name: {
        ...this.state.name,
        value: event.target.value
      }
    })
    this.props.setAudienceProfileName(event.target.value);
  }

  /***********/
  onChangeAutocomplete(search) {
    this.setState({
      occupation: {
        value: search,
        error: false
      }
    })
    this.props.searchOccupations(search);
  }

  onMouseDownAutocompleteOption(value) {
    let attribute = this.createAttribute("Occupation", value, value);
    this.props.addAudienceProfileAttribute(attribute);

    this.setState({
      occupation: {
        value: "",
        error: false
      }
    }, ()=>{
      this.props.setOccupations([]);
    });
  }

  onBlurAutocomplete() {
    this.setState({
      occupation: {
        value: "",
        error: false
      }
    }, ()=>{
      this.props.setOccupations([]);
    });
  }

  onChangeAutocompleteIndustries(search) {
    this.setState({
      industry: {
        value: search,
        error: false
      }
    })
    this.props.searchIndustries(search);
  }

  onMouseDownAutocompleteOptionIndustries(value) {
    let attribute = this.createAttribute("Industry", value, value);
    this.props.addAudienceProfileAttribute(attribute);

    this.setState({
      industry: {
        value: "",
        error: false
      }
    }, ()=>{
      this.props.setIndustries([]);
    });
  }

  onClickAddIndustry() {
    if (!this.state.industry.value) {
      this.setState({
        industry: {
          value: this.state.industry.value,
          error: true
        }
      });
      return;
    }

    let attribute = this.createAttribute("Industry",  this.state.industry.value,  this.state.industry.value);

    this.props.addAudienceProfileAttribute(attribute);

    this.setState({
      industry: {
        value: "",
        error: false
      }
    }, ()=>{
      this.props.setIndustries([]);
    });
  }

  onChangeSelect(category, id, value) {
    console.log(category, id, value)
    let attribute = this.createAttribute(category, value, id);
    this.props.addAudienceProfileAttribute(attribute);
  }


  onBlurAutocompleteIndustries() {
    this.setState({
      industry: {
        value: "",
        error: false
      }
    }, ()=>{
      this.props.setIndustries([]);
    });
  }

  /***********/
  setAudienceProfileNameUI() {
    if (this.props.isAudienceNameVisible) {
      return (
        <div>
          <TextField
            size="full"
            name="name"
            error={!this.props.isNameFilled}
            errorMessage="Audience name cannot be empty."
            fontStyle="tertiary"
            value={this.state.name.value}
            placeholder='Audience Name'
            label="Audience Name"
            onChange={this.onChangeName.bind(this)} />
        </div>
      )
    } else {
      return (
        null
      );
    }
  }

  onClickAddOccupation() {
    if (!this.state.occupation.value) {
      this.setState({
        occupation: {
          value: this.state.occupation.value,
          error: true
        }
      });
      return;
    }

    let attribute = this.createAttribute("Occupation", this.state.occupation.value, this.state.occupation.value);

    this.props.addAudienceProfileAttribute(attribute);

    this.setState({
      occupation: {
        value: "",
        error: false
      }
    }, ()=>{
      this.props.setOccupations([]);
    });
  }

  render() {
    let { isRadiusVisible, occupation, industry } = this.state;

    let categories = ["Marketing", "Advertising", "Design", "Technology", "Media", "Finance"];

    let results = this.props.occupations.map((result) => result.occupation);

    let resultsIndustries = this.props.industries.map((result) => result.industry);

    if (results.length === 0 && this.state.occupation.value.length > 2) {
      results.push(this.state.occupation.value); //Add
    }

    if (resultsIndustries.length === 0 && this.state.industry.value.length > 2) {
      resultsIndustries.push(this.state.industry.value); //Add
    }

    let jobfunctions = this.props.jobfunctions.map(({id, job_function}) => {
      return {value: id, label: job_function};
    });

    let jobseniorities = this.props.jobseniorities.map(({id, seniority}) => {
      return {value: id, label: seniority};
    });


    return (
      <div className="audienceprofile-container">
        {this.setAudienceProfileNameUI()}
        <div>
          {this.setAgeRangeUI()}
        </div>
        <div>
          <Places
            isAddressAllowed={false}
            isGeopoliticalRegionAllowed={true}
            fontStyle="tertiary"
            label="Locations"
            onOptionSelection={this.onAutocompleteOptionSelection.bind(this)}
            placeholder="Enter a location" />
          <div style={{display: 'none'}}>
            <div className="d-flex flex-row audienceprofile-distance">
              <Checkbox style={{marginBottom: '15px'}} checked={isRadiusVisible} label={"Specify Radius"} onClick={this.toggleRadiusInput.bind(this)} />
              <TextField
                className={isRadiusVisible ? "" : "audienceprofile-distance-hidden"}
                size="sm"
                placeholder='Distance from'
                onChange={this.onChange.bind(this)} />
            </div>
          </div>
        </div>
        <AutocompleteEntryGroup
          fontStyle="tertiary"
          label="Industries"
          placeholder="Enter industries"
          name="Industries"
          errorMessage="Industry cannot be an empty string."
          buttonLabel="Add"
          value={this.state.industry.value}
          error={this.state.industry.error}
          onClickAddEntry={this.onClickAddIndustry.bind(this)}
          onChange={this.onChangeAutocompleteIndustries.bind(this)}
          results={resultsIndustries}
          onMouseDownOption={this.onMouseDownAutocompleteOptionIndustries.bind(this)}
          onBlur={this.onBlurAutocompleteIndustries.bind(this)}
          size="full"/>
        <FormGroup>
          <Select
            label="Job Function"
            onChange={this.onChangeSelect.bind(this, "Job Function")}
            placeholder="Job Function"
            options={jobfunctions} />
          <Select
            label="Job Seniority"
            onChange={this.onChangeSelect.bind(this, "Job Seniority")}
            placeholder="Job Seniority"
            options={jobseniorities} />
        </FormGroup>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    attributes: state.audienceprofile.attributes,
    occupations: state.occupations.occupations,
    industries: state.industries.industries,
    name: state.audienceprofile.name,
    jobfunctions: state.jobfunctions.functions,
    jobseniorities: state.jobseniorities.seniorities
  };
};

const mapDispatchToProps = dispatcher => (
    bindActionCreators({
        addAudienceProfileAttribute,
        removeAudienceProfileAttribute,
        setAudienceProfileName,
        searchOccupations,
        setOccupations,
        searchIndustries,
        setIndustries,
        getJobFunctions,
        getJobSeniorities
    }, dispatcher)
);

export default connect(mapStateToProps, mapDispatchToProps)(AudienceProfile);

import { put, call, all, takeLatest } from 'redux-saga/effects';
import { ActionTypes } from './eventpackageoffer.actions';
import { post } from '../Api'

const basePath = "event-package-offers"

export const postEventPackageOffer = function* (action) {
  const { payload: { event_package_offer } } = action;
  try {
    const response = yield call(post, basePath, event_package_offer);
    yield put({ type: ActionTypes.POST_EVENT_PACKAGE_OFFER_SUCCESS, response });
  } catch (error) {
    yield put({ type: ActionTypes.POST_EVENT_PACKAGE_OFFER_FAILURE, error });
  }
}

function* eventpackageoffer() {
  yield all([
    takeLatest(ActionTypes.POST_EVENT_PACKAGE_OFFER, postEventPackageOffer)  ]);
}

export default eventpackageoffer;

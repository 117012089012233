import React, { Component } from 'react';
import Section from './components/Section';
import logo from './assets/logo.svg';
import streetsigns from './assets/streetsigns.png';
import crossroads from './assets/crossroads.png';
import thumbs_up from './assets/thumbs_up.png';
import tag from './assets/tag.png';
import dot from './assets/dot.png';
import floatingdot from './assets/floatingdot.png';
import disc from './assets/disc.png';
import floatingimg from './assets/floatingimg.png';
import { Routes } from 'constants/Routes';
import './Sponsors.css'

class Sponsors extends Component {
  goTo(path) {
    this.props.history.push(path);
  }

  render() {
    return (
      <div className="home-sponsors-dots">
        <div className="container home-sponsors-container">
          <div className="home-sponsors-header d-flex flex-column align-items-start">
            <div className="sponsors-label header-2-secondary">Event Organizers</div>
            <div style={{marginBottom: '15px'}} className="body-copy-bold-quintary">Sponsorship sales made easy</div>
            <div className="body-copy-white">We’ve streamlined proposals and negotiations to make it easy for you to connect and transact with new partners. Speed up the sales process and increase exposure for your sponsorships.</div>
          </div>
          <div className="home-sponsors-header d-flex flex-column align-items-start">
            <div className="sponsors-label header-2-secondary">Sponsors</div>
            <div style={{marginBottom: '15px'}} className="body-copy-bold-quintary">Sponsorship strategy customized for your brand</div>
            <div className="body-copy-white">We’ve curated event recommendations for you based on your sponsor preferences. See packages from credible event organizers and know what your sponsorships are worth. </div>
          </div>
          <div style={{width: '100%', maxWidth: '600px'}} className="d-flex flex-column align-items-end">
            <div onClick={this.goTo.bind(this, Routes.LEARN_MORE_EVENT_ORGANIZER)} className="body-copy-bold-quintary restage-link" style={{marginBottom: '15px'}}>Learn more as an event organizer</div>
            <div onClick={this.goTo.bind(this, Routes.LEARN_MORE_SPONSOR)} className="body-copy-bold-quintary restage-link" style={{marginBottom: '15px'}}>Learn more as a sponsor</div>
          </div>
        </div>
        <img className="sponsors-floating-dot" src={floatingdot} />
        <img className="sponsors-floating-another-dot" src={dot} />
        <img className="sponsors-floating-img" src={floatingimg} />
        <img className="sponsors-floating-disc" src={disc} />
      </div>
    );
  }
}

export default Sponsors;

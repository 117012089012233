import React, { Component } from 'react';
import { Routes } from 'constants/Routes';
import Button from 'components/new/Button';

import './herowithimage.css';

class HeroWithImage extends Component {
  static defaultProps = {
    orientation: '',
    imageClassName: '',
    buttonType: "primary"
  }

  getDescriptionClass() {
    let classNames = ["pagesegment-description"];
    let { background } = this.props;

    if (background === "purple") {
      classNames.push("body-copy-white")
    } else {
      classNames.push("body-copy-tertiary")
    }

    return classNames.join(" ");
  }

  render(){
    let { header, description, img, onCTA, ctaLabel, buttonType } = this.props;

    return(
      <div className={`d-flex flex-column align-items-center justify-content-center herowithimage-container ${this.props.className}`}>
        <div className="container">
            <div className="d-flex flex-column flex-lg-row">
              <div style={{zIndex: 1}} className="hero-section-override col-lg-6 col-12 d-flex flex-column align-items-start justify-content-center">
                <div style={{marginBottom: '25px'}}>
                  <div style={{marginBottom: '15px'}}className="herowithimage-header header-2-brand">{header}</div>
                  <div className="herowithimage-description header-3-tertiary">{description}</div>
                </div>
                <Button
                  type={buttonType}
                  size="sm"
                  onClick={onCTA.bind(this)}
                  label={ctaLabel}/>
              </div>
              <div className="hero-section-override herowithimage-image-container col-lg-6 col-12 d-flex align-items-center justify-content-center">
                <img className="pagesegment-image" src={img} />
              </div>
            </div>
        </div>
        {this.props.children}
      </div>
    )
  }
}

export default HeroWithImage;

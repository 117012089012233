export const ActionTypes = {
    SEARCH_PLACEMENTS: 'SEARCH_PLACEMENTS',
    SEARCH_PLACEMENTS_SUCCESS: 'SEARCH_PLACEMENTS_SUCCESS',
    SEARCH_PLACEMENTS_FAILURE: 'SEARCH_PLACEMENTS_FAILURE',
    GET_PLACEMENTS: 'GET_PLACEMENTS_TYPES',
    GET_PLACEMENTS_SUCCESS: 'GET_PLACEMENTS_SUCCESS',
    GET_PLACEMENTS_FAILURE: 'GET_PLACEMENTS_FAILURE',
    SET_PLACEMENTS: 'SET_PLACEMENTS',
    SET_PLACEMENTS_SUCCESS: 'SET_PLACEMENTS_SUCCESS',
    SET_PLACEMENTS_FAILURE: 'SET_PLACEMENTS_FAILURE'
};

export const searchPlacements = (query, type = null) => ({
    type: ActionTypes.SEARCH_PLACEMENTS,
    payload: {
      query,
      type
    }
});

export const setPlacements = (placements) => {
  return {
      type: ActionTypes.SET_PLACEMENTS,
      payload: {
         placements
      }
  };
};

export const getPlacements = () => ({
    type: ActionTypes.GET_PLACEMENTS_SUCCESS,
});

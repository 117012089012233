import React, { Component } from 'react';
import TextField from 'components/TextField';
import Button from 'components/new/Button';
import auth from 'auth/Auth';
import { Routes } from 'constants/Routes';
import './login.css';

class LoginForm extends Component {
    render() {
      let { email, password } = this.props;

      return (
          <div className="container d-flex align-items-center justify-content-center flex-column">
            <div style={{marginBottom: '65px'}} className="header-2-brand">Log In</div>
            <div className="loginform-container d-flex align-items-center justify-content-center flex-column">
              <TextField
                name="email"
                placeholder='E-mail'
                errorMessage="Please enter your e-mail."
                value={email.value}
                error={email.error}
                onChange={this.props.onChange.bind(this)} />
              <TextField
                placeholder='Password'
                name="password"
                type="password"
                errorMessage="Password must be at least 6 characters."
                value={password.value}
                error={password.error}
                onChange={this.props.onChange.bind(this)} />
              {this.props.error ? <div className="body-copy-error-wrapper d-flex justify-content-center"><span className="body-copy-error-small body-cooy-error-container">{this.props.errorMessage}</span></div> : null}
            </div>
          </div>
      );
    }
}

export default LoginForm;

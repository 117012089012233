import React, { Component } from 'react';
import Wrapper from 'components/Wrapper';
import TextField from 'components/TextField';
import Calendar from 'components/Calendar';
import RadioGroup from 'components/RadioGroup';
import FormGroup from 'components/FormGroup';
import Detail from 'common/Detail';
import Form from 'components/Form';
import './assets.css';

class AssetUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: {
        logo: true,
        video: false
      }
    }
  }
  onChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  };

  _handleOnChangeLogoDueDate(value) {
    this.setState({
      logoduedate: new Date(value.format("YYYY-MM-DDTHH:mm:ssZ"))
    });
  }

  _handleOnChangeVideoDueDate(value) {
    this.setState({
      videoduedate: new Date(value.format("YYYY-MM-DDTHH:mm:ssZ"))
    });
  }

  setSelected(id) {
    this.setState({
      selected: id
    });

    let type = {...this.state.type};

    for (let key in type) {
      if (key === id) {
        type[key] = true;
      } else {
        type[key] = false;
      }
    }

    this.setState({
      type: {...type}
    })


  }

  setAssetOptionsUI() {
    let items = [
      {
        id: 'logo',
        type: 'Logo'
      },
      {
        id: 'video',
        type: 'Video'
      }
    ];

    return (
      <RadioGroup items={items} default="logo" onSelected={this.setSelected.bind(this)}/>
    )
  }

  setVisibility(visible) {
    if (!visible) {
      return {display: 'none'};
    }
    return {};
  }

  render() {
    return (
      <Wrapper className="container">
        <Detail justification="start" style={{marginBottom: '25px', textAlign: 'Start'}} header="Add Your Asset Requirements" />
        <Form className="assets-container">
          <div style={{marginBottom: '25px'}} className="body-copy-tertiary">Select your asset option:</div>
          {this.setAssetOptionsUI()}
          <div style={{marginBottom: '25px'}} className="body-copy-tertiary">Add details:</div>
          <div style={this.setVisibility(this.state.type.logo)} className="assets-container">
            <FormGroup>
              <TextField
                fontStyle="tertiary"
                name="height"
                onChange={this.onChange.bind(this)}
                label="Logo Height"
                size="full" />
              <TextField
                fontStyle="tertiary"
                name="width"
                onChange={this.onChange.bind(this)}
                label="Logo Width"
                size="full" />
            </FormGroup>
            <FormGroup>
              <Calendar
                timeFormat={false}
                fontStyle="tertiary"
                onChange={this._handleOnChangeLogoDueDate.bind(this)}
                label="Logo Due Date"
                size="full"/>
            </FormGroup>
          </div>
          <div style={this.setVisibility(this.state.type.video)} className="assets-container">
            <FormGroup>
              <TextField
                fontStyle="tertiary"
                name="url"
                onChange={this.onChange.bind(this)}
                label="Video URL"
                size="full" />
              <Calendar
                timeFormat={false}
                fontStyle="tertiary"
                onChange={this._handleOnChangeVideoDueDate.bind(this)}
                label="Video Due Date"
                size="full"/>
            </FormGroup>
          </div>
        </Form>
      </Wrapper>
    );
  }
}


export default AssetUpload;

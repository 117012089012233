import React, { Component } from 'react';
import Wrapper from 'components/Wrapper';
import ButtonGroup from 'components/ButtonGroup';
import Form from 'components/Form';
import Button from 'components/new/Button';
import FormGroup from 'components/FormGroup';
import Eyebrow from 'components/Eyebrow';
import TextField from 'components/TextField';
import Detail from 'common/Detail';
import Autocomplete from 'components/Autocomplete';
import { Routes } from 'constants/Routes';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { searchPlacements, setPlacements } from 'services/placements/placements.actions';

import { postAvailablePlacements } from 'services/availableplacements/availableplacements.actions';

import './eventplacements.css';

class EventPlacements extends Component {
  static defaultProps = {

  }

  constructor(props) {
    super(props);
    this.state = {
      placement: "",
      placements:[],
      packages: [],
      currentPackage: {
        placements: []
      },
      budget: "",
      amount: "",
      packageName: "",
      packageValue: "",
      numberOfPackages: ""
    }
  }

  goToAudience() {
    this.props.history.push(Routes.EVENT_AUDIENCE);
  }

  goToSponsorshipRequests() {
    this.props.postAvailablePlacements(this.state.available);
    this.props.history.push(Routes.REQUEST_SPONSORSHIP);
  }

  /***********/
  _handleChangeAutocomplete(search) {
    this.setState({
      placement: search
    })
    this.props.searchPlacements(search);
  }

  _handleMouseDownAutocompleteOption(value) {
    let placement = value;
    this.setState({
      placement
    }, ()=>{
      this.props.setPlacements([]);
    });

  }

  _handleOnBlurAutocomplete() {
    this.setState({
      placement: ''
    }, () => {
      this.props.setPlacements([]);
    });
  }
  /***********/

  _handleOnChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  setAvailablePlacementsUI() {
    if (this.state.placements.length === 0) {
      return (<div className="body-copy-tertiary-small" style={{marginTop: '25px'}}>No placements added</div>);
    } else {
      return (
        <div style={{marginTop: '25px'}}>
          {
            this.state.placements.map((item, index)=>{
              let {
                placement,
                budget,
                amount
              } = item;

              return (

                <div className="body-copy-tertiary-small" key={index}>
                  <div>{placement}</div>
                  <div>{budget}</div>
                  <div>{amount}</div>
                </div>
              )
            })
          }
        </div>
      );
    }
  }


  _handleOnClickAddPlacement() {
      console.log(this.state);
      let {
        placements,
        placement,
        budget,
        amount
      } = this.state;

      let item = {
        placement,
        budget,
        amount
      };

      placements.push(item);
      this.setState({
        availabe: placements
      })
  }


  render() {
    let results = this.props.placements.map((result)=>{
      if (result.placement != null) {
        return result.placement;
      }
    });

    if (results.length === 0 && this.state.placement.length > 2) {
      results.push(this.state.placement); //Add
    }

    return (
      <Form>
        <div className="div placements-container">
          <Detail justification="start" style={{marginBottom: '25px', textAlign: 'Start'}} header="Create Your Placements" subHeader="Step 3 of 5: Create your placements"/>
          <div style={{marginBottom: '50px'}}>
            <div style={{marginBottom: '50px'}} className="eventpackages-container">
              <FormGroup>
                <Autocomplete
                  fontStyle="tertiary"
                  label="Placement"
                  placeholder="Enter placement"
                  name="placement"
                  onChange={this._handleChangeAutocomplete.bind(this)}
                  results={results}
                  onMouseDownOption={this._handleMouseDownAutocompleteOption.bind(this)}
                  onBlur={this._handleOnBlurAutocomplete.bind(this)}
                  size="full"/>
              </FormGroup>
              <FormGroup>
                <TextField
                  fontStyle="tertiary"
                  size="full"
                  name="budget"
                  label="Budget"
                  placeholder='Budget'
                  onChange={this._handleOnChange.bind(this)} />
                <TextField
                  fontStyle="tertiary"
                  size="full"
                  name="amount"
                  label="# Avaliable"
                  placeholder='# Of Avaliable Placements'
                  onChange={this._handleOnChange.bind(this)} />
              </FormGroup>
            </div>
            <Button label="Add Placement" onClick={this._handleOnClickAddPlacement.bind(this)} />
          </div>
          <div style={{marginBottom: '50px'}}>
            <Eyebrow label="Placements" />
          {this.setAvailablePlacementsUI()}
          </div>
          <ButtonGroup
            labelPrimary="Next: Described Your Audience"
            labelSecondary="Back to sponsorship requests"
            onClickPrimary={this.goToAudience.bind(this)}
            onClickSecondary={this.goToSponsorshipRequests.bind(this)} />
        </div>
      </Form>
    );
  }
}

const mapStateToProps = (state) => {

  return {
    available: state.availableplacements.available,
    placements: state.placements.placements,
    currentEvent: state.events.currentEvent
  };
};

const mapDispatchToProps = dispatcher => (
    bindActionCreators({
      searchPlacements,
      setPlacements,
      postAvailablePlacements
    }, dispatcher)
);

export default connect(mapStateToProps, mapDispatchToProps)(EventPlacements);

import React, { Component } from 'react';
import './Section.css';

class Section extends Component {
  render() {
    return (
      <div className="sponsors-section-container">
        <div style={{marginBottom: '20px'}} className="d-flex justify-content-start">
          <img className="sponsors-section-img" src={this.props.src} alt={this.props.alt}/>
        </div>
        <div className="sponsors-section-description body-copy-white">
          {this.props.text}
        </div>
      </div>
    );
  }
}

export default Section;

import React, { Component } from 'react';
import Pill from 'components/Pill';
import PillWrapper from 'components/PillWrapper'
import { removeAudienceProfileAttribute } from 'services/audienceprofile/audienceprofile.actions';
import './pillscontainer.css';

class PillsContainer extends Component {
  static defaultProps = {
    label: "Audience Attributes",
    emptyStateMessage: "No attributes added",
    style: {}
  }
  setPills() {
    let { emptyStateMessage, values, onClickRemove } = this.props;

    if (values.length === 0) return (<Pill
      isCloseVisible={false}
      label={emptyStateMessage} />);

    return values.map((item, index)=>
      <Pill
        key={index}
        label={item}
        onClickRemove={onClickRemove.bind(this, item)}/>)
  }

  render() {
    let { style, label } = this.props;
    return (
      <div style={{...style}} className="pillscontainer-attributes-wrapper">
        <div className="body-copy-bold-tertiary" style={{marginBottom: '15px'}}>{label}</div>
        <PillWrapper className="pillscontainer-attributes-container" defaultLabel="Add Filters">
          {this.setPills()}
        </PillWrapper>
      </div>
    );
  }
}

export default PillsContainer;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import EventPoster from 'assets/placeholder.jpg'
import Button from 'components/new/Button';
import './blogtile.css';

class BlogTile extends Component {
  static propTypes = {
    label: PropTypes.string
  }

  static defaultProps = {

  }


  setFooterUI () {
    let { wordcount, category } = this.props;

    return (
      <div className="blogtile-footer d-flex justify-content-start">
        <span className="body-copy-bold-tertiary">{category}</span>&nbsp;|&nbsp;<span className="body-copy-tertiary">{Math.ceil(wordcount/200)} min read</span>
      </div>
    )
  }

  render() {
    let { title, author, date, onClick, headerurl } = this.props;
    let style = {
      backgroundImage:  `url(${headerurl})`
    }

    return (
      <div onClick={onClick.bind(this)} className="d-flex flex-column justify-content-between blogtile-container">
        <div className="d-flex flex-column flex-shrink-0">
          <div className="eventtile-poster-container">
            <div className="eventtile-poster" style={style} />
          </div>
          <div className="eventtile-content d-flex flex-column">
            <div>
              <div className="header-2-tertiary">{title}</div>
            </div>
          </div>
        </div>
        {this.setFooterUI()}
      </div>
    );
  }
}

export default BlogTile;

import { ActionTypes } from './shares.actions';

const initialState = {
    success: null,
    messages: [],
    warnings: [],
    inviteUrl: ""
};

const SharesReducer = (state = initialState, action) => {
    switch (action.type) {
      case ActionTypes.SHARE_EVENT:
        return { ...state, success: null, warnings: [], messages: [], inviteUrl: "" };
      case ActionTypes.SHARE_EVENT_SUCCESS:
        let { messages, warnings, url } = action.response;
        return { ...state, success: true, warnings, messages, inviteUrl: url };
      case ActionTypes.SHARE_EVENT_FAILURE:
        return { ...state, success: false };
      default:
          return state;
    }
};

export default SharesReducer;

export const ActionTypes = {
    POST_PORTFOLIO_OBJECTIVE: 'POST_PORTFOLIO_OBJECTIVE',
    POST_PORTFOLIO_OBJECTIVE_SUCCESS: 'POST_PORTFOLIO_OBJECTIVE_SUCCESS',
    POST_PORTFOLIO_OBJECTIVE_FAILURE: 'POST_PORTFOLIO_OBJECTIVE_FAILURE',
    SET_PORTFOLIO_OBJECTIVE: 'SET_PORTFOLIO_OBJECTIVE'
};

export const postPortfolioObjective = (objective, portfolio_id, portfolio_name) => ({
    type: ActionTypes.POST_PORTFOLIO_OBJECTIVE,
    payload: {
      objective,
      portfolio_id,
      portfolio_name
    }
});



export const setPortfolioObjective = (objective) => ({
    type: ActionTypes.SET_PORTFOLIO_OBJECTIVE,
    payload: {
      objective
    }
});

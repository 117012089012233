import { ActionTypes } from './sponsorships.actions';

const initialState = {
    errorMessage: "",
    sponsorships: []
};

const SponsorshipsReducer = (state = initialState, action) => {
    switch (action.type) {
      case ActionTypes.POST_SPONSORSHIP_SUCCESS:
        return { ...state};
      case ActionTypes.POST_SPONSORSHIP_FAILURE:
        return { ...state};
      default:
          return state;
    }
};

export default SponsorshipsReducer;

import { put, call, all, takeLatest } from 'redux-saga/effects';
import { ActionTypes } from './sponsorshiprequests.actions';
import { post } from '../Api'

const basePath = "sponsorshiprequests"

export const postSponsorshipRequest = function* (action) {
  const { payload: { request } } = action;
  try {
    const response = yield call(post, basePath, request);
    const json = response.json();
    yield put({ type: ActionTypes.POST_SPONSORSHIP_REQUEST_SUCCESS, json });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: ActionTypes.POST_SPONSORSHIP_REQUEST_FAILURE, error });
  }
}

export const postSponsorshipRequests = function* (action) {
  const { payload: { requests } } = action;
  try {
    const response = yield call(post, basePath, requests);
    const json = response.json();
    yield put({ type: ActionTypes.POST_SPONSORSHIP_REQUESTS_SUCCESS, json });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: ActionTypes.POST_SPONSORSHIP_REQUESTS_FAILURE, error });
  }
}

function* sponsorshiprequests() {
  yield all([
    takeLatest(ActionTypes.POST_SPONSORSHIP_REQUEST, postSponsorshipRequest),
    takeLatest(ActionTypes.POST_SPONSORSHIP_REQUESTS, postSponsorshipRequests)
  ]);
}

export default sponsorshiprequests;

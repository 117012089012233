import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Close from 'assets/icons/Close';
import { Modal as AntdModal } from 'antd';
import './Modal.css';


class Modal extends Component {
  static propTypes = {
    onClickClose: PropTypes.func.isRequired
  }

  static defaultProps = {
      visible: false,
      className: "",
      center: false,
      size: "medium",
      width: "100%",
      closeColor: "#333333",
      centered: false
  }

  appendHeader() {
    if (this.props.appendHeader) {
      return this.props.appendHeader();
    }
    return null;
  }

  appendFooter() {
    if (this.props.appendFooter) {
      return (
        <div className="restage-modal-footer d-flex align-items-center justify-content-start">
          {this.props.appendFooter()}
        </div>
      )
    }
    return null;
  }

  setWrapClassName() {
    let classNames = [];
    if (this.props.centered && this.props.visible) {
      classNames.push("d-flex");
      classNames.push("align-items-center");
      classNames.push("justify-content-center");
    }

    if (this.props.size === 'full') {
      classNames.push("restage-antd-modal-wrapper-full");
    }

    if (this.props.size === 'large') {
      classNames.push("restage-antd-modal-wrapper-lg");
     }

    if (this.props.size === 'small') {
      classNames.push("restage-antd-modal-wrapper");
    }

    return classNames.join(" ");
  }

  setClassName() {
    let classNames = ["container"]

    if (this.props.className) classNames.push(this.props.className);

    if (this.props.size === 'large') {
      classNames.push("restage-main-modal-lg");
    } else if (this.props.size === 'small') {
      classNames.push("restage-main-modal-sm");
    } else if (this.props.size === 'xs') {
      classNames.push("restage-main-modal-xs");
    } else if (this.props.size === 'full') {
      classNames.push("restage-main-modal-full");
      classNames = classNames.filter((name) => name !== "container")
    } else {
      classNames.push("restage-main-modal");
    }

    if (this.props.center === true) {
      classNames.push("align-items-center");
      classNames.push("d-flex");
    }
    return classNames.join(" ");
  }

  getSvg(size, closeColor) {
    return (
      <svg width={size} height={size} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M18.09 16.4L30.79 3.45001C31.3364 2.85397 31.312 1.93212 30.7349 1.36579C30.1578 0.799453 29.2357 0.792476 28.65 1.35001L15.97 14.28L2.77003 1.33001C2.39212 0.933069 1.82782 0.77383 1.29816 0.914674C0.768501 1.05552 0.357822 1.47402 0.226996 2.00624C0.0961704 2.53846 0.266027 3.09966 0.670028 3.47001L13.85 16.4L0.670028 29.33C0.112493 29.9156 0.11947 30.8378 0.685804 31.4149C1.25214 31.992 2.17399 32.0164 2.77003 31.47L15.97 18.52L28.65 31.45C29.2357 32.0075 30.1578 32.0006 30.7349 31.4342C31.312 30.8679 31.3364 29.9461 30.79 29.35L18.09 16.4Z" fill={closeColor}/>
      </svg>
    )
  }

  getCloseIcon() {
    let size = 20
    let { closeColor } = this.props;
    let svg = this.getSvg(size, closeColor);

    if (closeColor === '#BDBDBD') {
      size = 20;
    }

    if (this.props.backgroundCloseColor === 'gray') {
      return (
        <div className="modal-close-background modal-close-background-dark-gray">
          {svg}
        </div>
      )
    }

    return svg;
  }

  render() {
    return (
      <AntdModal
        closeIcon={this.getCloseIcon()}
        wrapClassName={this.setWrapClassName()}
        className={this.setClassName()}
        width={this.props.width}
        visible={this.props.visible}
        footer={this.appendFooter()}
        onCancel={this.props.onClickClose.bind(this, false)}
        afterClose={this.props.onClickClose.bind(this, false)}
      >
        {this.props.children}
      </AntdModal>

    );
  }
}

export default Modal;

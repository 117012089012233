import React, { Component } from 'react';
import Button from 'components/new/Button';
import Checkbox from 'components/Checkbox';
import './placement.css';

class Placement extends Component {
  constructor(props) {
    super(props);

  }

  _handleOnClickPlacement() {
    if (this.props.onClickToggleCart !== null) {
      this.props.onClickToggleCart();
    }
  }

  render() {

    return (
      <div className="d-flex flex-row justify-content-between placement-container align-items-center">
        <div className="d-flex align-items-start flex-column">
          <div className="header-1-tertiary">{new Intl.NumberFormat('us-US', { style: 'currency', currency: 'USD' }).format(this.props.value)}</div>
          <div style={{textAlign: 'start'}} className="body-copy-tertiary">{this.props.type}</div>
          <div className="body-copy-tertiary-small">{this.props.remaining} available</div>
        </div>
        <div>
          <Checkbox checked={this.props.added} onClick={this._handleOnClickPlacement.bind(this)} />
          {/*<Button label={this.props.added ? "Remove From Cart" : "Add To Cart"} />*/}
        </div>
      </div>
    );
  }
}

export default Placement;

export const ActionTypes = {
    SEARCH_OCCUPATIONS: 'SEARCH_OCCUPATIONS',
    SEARCH_OCCUPATIONS_SUCCESS: 'SEARCH_OCCUPATIONS_SUCCESS',
    SEARCH_OCCUPATIONS_FAILURE: 'SEARCH_OCCUPATIONS_FAILURE',
    SET_OCCUPATIONS: 'SET_OCCUPATIONS',
    SET_OCCUPATIONS_SUCCESS: 'SET_OCCUPATIONS_SUCCESS',
    SET_OCCUPATIONS_FAILURE: 'SET_OCCUPATIONS_FAILURE'
};

export const searchOccupations = (query) => ({
    type: ActionTypes.SEARCH_OCCUPATIONS,
    payload: {
      query
    }
});

export const setOccupations = (occupations) => {
  return {
      type: ActionTypes.SET_OCCUPATIONS,
      payload: {
         occupations
      }
  };
};

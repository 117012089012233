import { put, call, all, takeLatest } from 'redux-saga/effects';
import { ActionTypes } from './eventgenders.actions';
import { putRequest } from '../Api'
const basePath = "event-genders";

export const putEventGenders = function* (action) {
  const { payload: { data } } = action;

  try {
    const response = yield call(putRequest, basePath, data);
    yield put({ type: ActionTypes.PUT_EVENT_GENDERS_SUCCESS, eventgenders: response.data });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: ActionTypes.PUT_EVENT_GENDERS_FAILURE, error });
  }
}

function* eventgenders() {
  yield all([
    takeLatest(ActionTypes.PUT_EVENT_GENDERS, putEventGenders)
  ]);
}

export default eventgenders;

import { ActionTypes } from './events.actions';

const initialState = {
  errorMessage: "",
  events: [],
  currentUserEvents: [],
  currentEvent: {
    event:  {
      name: "",
      description: "",
      startdate: null,
      enddate: null,
      place_id: "",
      tagline: "",
      website: "",
      expected_attendees: "",
      location: {}
    }
  },
  decision: {
    event_id: 0,
    value: false
  },
  isEventDetailsPageValid: false,
  redirectToLogin: false
};

const EventsReducer = (state = initialState, action) => {
    switch (action.type) {
      case ActionTypes.GET_EVENT_SUCCESS:
        return { ...state, currentEvent: {...action.data}};
      case ActionTypes.GET_EVENT_WITH_TOKEN_SUCCESS:
          return { ...state, currentEvent: {...action.data}};
      case ActionTypes.GET_EVENT_WITH_TOKEN_FAILURE:
          return { ...state, redirectToLogin: true};
      case ActionTypes.GET_EVENT_FAILURE:
        return { ...state};
      case ActionTypes.GET_EVENTS_SUCCESS:
        return { ...state, currentUserEvents: action.data};
      case ActionTypes.GET_EVENTS_FAILURE:
        return { ...state};
      case ActionTypes.SEARCH_EVENTS_SUCCESS:
        return { ...state, events: action.data};
      case ActionTypes.SEARCH_EVENTS_FAILURE:
        return { ...state};
      case ActionTypes.POST_EVENTS_SUCCESS:
        return { ...state, errorMessage: "" , currentEvent: { event: { ...action.json} } };
      case ActionTypes.POST_EVENTS_FAILURE:
        return { ...state, errorMessage: "" };
      case ActionTypes.PUT_EVENT_SUCCESS:
        return { ...state, currentEvent: action.response };
      case ActionTypes.PUBLISH_EVENT_SUCCESS:
        return { ...state, errorMessage: "" };
      case ActionTypes.PUBLISH_EVENT_FAILURE:
        return { ...state, errorMessage: "" };
      case ActionTypes.SET_CURRENT_EVENT:
        return { ...state, currentEvent: action.payload.currentEvent};
      case ActionTypes.SET_EVENT_DETAILS_PAGE_STATE:
          return { ...state,isEventDetailsPageValid: action.payload.isValid };
      case ActionTypes.POST_EVENT_DECISION_SUCCESS:
            return { ...state,
              decision: {
                event_id: action.payload.event_id,
                value:  true
              }
            };
      case ActionTypes.POST_EVENT_DECISION_FAILURE:
              return { ...state };
      default:
          return state;
    }
};

export default EventsReducer;

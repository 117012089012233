import React from 'react';
import Modal from 'components/Modal';
import Checkbox from 'components/Checkbox';
import TextField from 'components/TextField';
import ButtonGroup from 'components/ButtonGroup';
import RadioGroup from 'components/RadioGroup';
import Detail from 'common/Detail';
import { isFilled } from 'common/helpers/validators';
import './respond-to-offer-modal.css';

const options = [
  {id: "in-person", type: "In-person meeting"},
  {id: "call", type: "Conference Call"}
];

class RespondToOfferModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: "in-person",
      message: {
        value: "",
        error: false
      }
    }
  }

  setSelected(id) {
    this.setState({
      selected: id
    });
  }

  validate() {
    let fields = new Set();

    fields.add(this.isFieldValid(isFilled, "message"));

    return !fields.has(false);
  }

  onChange(event) {
    this.setState({ [event.target.name]: {
      value: event.target.value,
      error: this.state[event.target.name].error
      }
    })
  }

  render() {
    if (this.props.offer === null) return null;

    let { offer, visible } = this.props;
    let { brand_user, event_package } = offer;
    let { first_name, last_name, organization } = brand_user;
    let { description, name } = event_package;
    let { message } = this.state;

    return (
      <Modal onClickClose={this.props.onClickClose.bind(this)} visible={visible} appendFooter={() => {
        return(
          <ButtonGroup
            labelPrimary={"Submit"}
            labelSecondary={"Cancel"}
            onClickPrimary={this.props.sendNegotiationsEmail.bind(this, offer.id, message.value)}
            onClickSecondary={this.props.onClickClose.bind(this)} />
        )
      }}>
        <div style={{paddingTop: '30px'}} className="negotiations-modal-body">
          <Detail style={{marginBottom: '25px'}} justification="start" header="Respond to Request" subHeader="Congratulations on receiving a request! Keep the conversation going by sending them a message." />
          <div className="d-flex flex-row" style={{paddingTop: '20px'}}>
            <div>
              <div className="manage-avatar manage-them d-flex justify-content-center align-items-center">
                <div className="manage-label">{first_name[0]}{last_name[0]}</div>
              </div>
            </div>
            <div className="manage-message">
              <div className="body-copy-bold-orange">{first_name} {last_name}</div>
              <div className="body-copy-tertiary">{organization.name}</div>
            </div>
          </div>
          <div className="body-copy-tertiary" style={{marginTop: '25px', marginBottom: '15px'}}>Type a message below for {first_name} and when you submit, Restage will send you an e-mail to intro both you and {first_name}.</div>
          {/*<div style={{marginTop: '25px', marginBottom: '15px'}} className="body-copy-bold-tertiary">Schedule a meeting:</div>

          <RadioGroup
            justification="vertical"
            style={{marginTop: '10px', marginBottom: '25px'}}
            defaultId={this.state.selected}
            items={options}
            onSelected={this.setSelected.bind(this)}/>*/}
          <TextField
            fontStyle="tertiary"
            name="message"
            value={message.value}
            error={message.error}
            errorMessage="Description cannot be empty."
            isTextArea={true}
            onChange={this.onChange.bind(this)}
            placeholder="Leave a message for the event organizer"
            label="Message"
            size="full" />
        </div>
      </Modal>
    );
  }
}

export default RespondToOfferModal;

import React, { Component } from 'react';
import Button from 'components/new/Button';
import './PortfolioTile.css';

class PortfolioTile extends Component {
  _handleOnClickView() {
    if (this.props.onClick !== null) {
      this.props.onClick();
    }
  }

  render() {
    let { name , budget } = this.props
    return (
      <div className="portfoliotile-container d-flex flex-md-row flex-column justify-content-between align-items-md-center">
        <div>
          <div className="header-1-tertiary" style={{marginBottom: '15px'}}>{name || "NULL"}</div>
          <div className="body-copy-tertiary">{`Total Budget: ${budget || "NULL"}`}</div>
        </div>
        <div>
          <Button type="tertiary" label="View" size="md" onClick={this._handleOnClickView.bind(this)} />
        </div>
      </div>
    );
  }
}

export default PortfolioTile;

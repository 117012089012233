export const ActionTypes = {
    POST_USERS: 'POST_USERS',
    POST_USERS_SUCCESS: 'POST_USERS_SUCCESS',
    POST_USERS_FAILURE: 'POST_USERS_FAILURE',
    GET_USER: 'GET_USER',
    GET_USER_SUCCESS: 'GET_USER_SUCCESS',
    GET_USER_FAILURE: 'GET_USER_FAILURE',
    SET_CURRENT_USER: 'SET_CURRENT_USER',
    GET_USER_PROFILE: 'GET_USER_PROFILE',
    GET_USER_PROFILE_SUCCESS: 'GET_USER_PROFILE_SUCCESS',
    GET_USER_PROFILE_FAILURE: 'GET_USER_PROFILE_FAILURE',
    POST_USER_PROFILE: 'POST_USER_PROFILE',
    POST_USER_PROFILE_SUCCESS: 'POST_USER_PROFILE_SUCCESS',
    POST_USER_PROFILE_FAILURE: 'POST_USER_PROFILE_FAILURE'
};

export const postUsers = (user) => ({
      type: ActionTypes.POST_USERS,
      payload: {
        user
      }
  });

export const getUser = () => ({
      type: ActionTypes.GET_USER
  });

export const setCurrentUser = (currentUser) => {
  return {
      type: ActionTypes.SET_CURRENT_USER,
      payload: {
        currentUser
      }
  };
}

export const getUserProfile = (id) => ({
    type: ActionTypes.GET_USER_PROFILE,
    payload: {
      id
    }
});

export const postUserProfile = (id, profile) => ({
    type: ActionTypes.POST_USER_PROFILE,
    payload: {
      id,
      profile
    }
});

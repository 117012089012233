import React, { Component } from 'react';
import AudienceProfile from 'common/audienceprofile.component';
import AudienceProfileAttributes from 'common/audienceprofile-attributes.component';
import ButtonGroup from 'components/ButtonGroup';
import Form from 'components/Form';
import Detail from 'common/Detail';
import { isFilled } from 'common/helpers/validators';
import { Routes } from 'constants/Routes';
import './eventaudience.css';

class EventAudience extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isNameFilled: true,
      isAttributeFilled: true
    }
  }

  addSidePanel() {
    return (
      <div style={{height: '100%'}}>
        <AudienceProfileAttributes style={{height: '100%'}}/>
      </div>
    )
  }

  componentDidMount() {
    if (!this.props.isEventDetailsPageValid) {
      this.props.history.push(Routes.EVENT_DETAILS);
    }
  }

  componentDidUpdate(prevProps) {
    if ((prevProps.location.pathname === this.props.location.pathname) && (this.props.event_audience_profile !== prevProps.event_audience_profile)) {
        this.props.history.push(Routes.EVENT_PACKAGES);
        return;
    }
  }

  goToEventDetails() {
    this.props.history.push(Routes.EVENT_DETAILS);
  }

  getEventAudienceProfileData() {
    let eap = {};

    eap.name = this.props.name;
    eap.event_id = this.props.event.event.id;
    eap.audience_attributes = this.props.attributes;

    return eap;
  }

  validate() {
    let fields = new Set();

    let isNameFilled = isFilled(this.props.name);
    let isAttributeFilled = (this.props.attributes.length > 0 ? true : false);

    if (!isNameFilled || !isAttributeFilled) {
      this.setState({
        isNameFilled,
        isAttributeFilled
      });
      return;
    } else {
      this.setState({
        isNameFilled,
        isAttributeFilled: true
      });
    }

    return !fields.has(false);
  }

  onClickSubmitEventAudience() {
    if (!this.validate()) return;
    this.props.setEventAudiencePage(true);
    let data = this.getEventAudienceProfileData();
    if (this.props.event_audience_profile === null) {
      this.props.postEventAudienceProfile(data);
    } else {
      this.props.putEventAudienceProfile(data);
    }
  }

  render() {
    let { isNameFilled } = this.state;
    return (
      <div>
        <Form addSidePanel={this.addSidePanel.bind(this)}>
          <Detail
            justification="start"
            style={{marginBottom: '25px', textAlign: 'Start'}}
            header="Audience"
            subHeader="Define your target audience for the event. The audience attributes selected will be shown in the right panel."
          />
          <AudienceProfile
            isNameFilled={isNameFilled}
          />
        </Form>
        <div style={{marginTop: '50px'}}>
          {!this.state.isAttributeFilled ? <div style={{alignSelf: 'stretch', position: "relative"}}><span className="body-copy-error-xs-small" style={{textAlign: 'center', position: "absolute", color: 'red'}}>Please specifiy at least one attribute.</span></div> : null}
          <div style={{float: 'right', marginBottom: '10px'}} className="body-copy-tertiary">Step 2 of 4</div>
          <ButtonGroup
            labelPrimary="NEXT"
            labelSecondary="BACK"
            onClickPrimary={this.onClickSubmitEventAudience.bind(this)}
            onClickSecondary={this.goToEventDetails.bind(this)} />
        </div>
      </div>
    );
  }
}

export default EventAudience;

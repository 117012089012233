import { ActionTypes } from './blog.actions';

const initialState = {
    posts: [],
    post: null
};

const BlogReducer = (state = initialState, action) => {
    switch (action.type) {
      case ActionTypes.GET_BLOG_POSTS_SUCCESS:
        return { ...state, posts: action.json};
      case ActionTypes.GET_BLOG_POST_SUCCESS:
        return { ...state, post: action.json};
      default:
          return state;
    }
};

export default BlogReducer;

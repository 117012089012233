import { ActionTypes } from './portfolioplacementpreferences.actions';

const initialState = {
    preferences: null
};

const PortfolioPlacementPreferencesReducer = (state = initialState, action) => {
    switch (action.type) {
      case ActionTypes.POST_PORTFOLIO_PLACEMENT_PREFERENCES_SUCCESS:
        return { ...state, preferences: action.data };
        case ActionTypes.PUT_PORTFOLIO_PLACEMENT_PREFERENCES_SUCCESS:
          return { ...state, preferences: action.data };
      default:
        return state;
    }
};

export default PortfolioPlacementPreferencesReducer;

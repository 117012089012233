export const ActionTypes = {
    ADD_AUDIENCE_PROFILE_ATTIRIBUTE: 'ADD_AUDIENCE_PROFILE_ATTIRIBUTE',
    REMOVE_AUDIENCE_PROFILE_ATTIRIBUTE: 'REMOVE_AUDIENCE_PROFILE_ATTIRIBUTE',
    SET_AUDIENCE_PROFILE_NAME: 'SET_AUDIENCE_PROFILE_NAME'
};

export const addAudienceProfileAttribute = (attribute) => ({
    type: ActionTypes.ADD_AUDIENCE_PROFILE_ATTIRIBUTE,
    payload: {
      attribute
    }
});

export const removeAudienceProfileAttribute = (attribute) => ({
    type: ActionTypes.REMOVE_AUDIENCE_PROFILE_ATTIRIBUTE,
    payload: {
      attribute
    }
});

export const setAudienceProfileName = (name) => ({
    type: ActionTypes.SET_AUDIENCE_PROFILE_NAME,
    payload: {
      name
    }
});

import React, { Component } from 'react';
import Wrapper from 'components/Wrapper';
import Eyebrow from 'components/Eyebrow';
import Detail from 'common/Detail';
import Divider from 'common/Divider.component';
import Button from 'components/new/Button';
import TabGroup from 'common/TabGroup';
import Tab from 'common/Tab';
import Expand from 'common/expand.component';
import Accordion from 'components/Accordion';
import { Routes } from 'constants/Routes';
import { Redirect } from "react-router-dom";
import { isNumeric } from 'common/helpers/validators';
import { Menu, Dropdown } from 'antd';
import { Table } from 'antd';
import { getEvents } from 'services/events/events.actions';
import { generateCurrencyFormat } from 'common/helpers/currency';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import './events.css';

const actions = (id)=>{
  return (
    <Menu>
      <Menu.Item key="0">
        <a className="button-text-bold" href={`/admin/events/${id}/review`}>Review Event</a>
      </Menu.Item>
    </Menu>
  );
}

class AdminEvents extends Component {
  componentWillMount() {
    this.props.getEvents();

  }

  getActions(accepted, event_id) {
    if (accepted !== null) return null;
    return (
      <div>
        <Dropdown overlay={actions(event_id)} trigger={['click']}>
          <Button label="Review Event" type="primary" onClick={(()=>{})} stopPropagation={true} />
        </Dropdown>
      </div>
    )
  }

  setEventsUI() {
    if (!this.props.events) return null;

    let panels = [];

    return this.props.events.map((event, index) => {
      let { name, location, accepted } = event;

      return (
          <div key={index} className="admin-events-panel d-flex flex-row justify-content-between align-items-center">
            <div className="d-flex flex-column">
              <div className='body-copy-bold-tertiary'>{name}</div>
              {/*<div>
                <span className="body-copy-bold-tertiary">Organizer: </span>
                <span className="body-copy-tertiary">{First} {}</span>
              </div>
              <div>
                <span className="body-copy-bold-tertiary">E-mail: </span>
                <span className="body-copy-tertiary-link">First Name</span>
              </div>*/}
              <div>
                <span className="body-copy-bold-tertiary">Location: </span>
                <span className="body-copy-tertiary">{location.description}</span>
              </div>
            </div>
            {this.getActions(accepted, event.id)}
          </div>
      )
    });
  }

  goToPath() {
    this.props.history.push('/events/' + this.props.event.event.id)
  }

  render() {
    if (this.props.events === undefined || this.props.events.length === 0) return null;


    return (
      <div style={{paddingTop: '30px'}} className="container">
        <div style={{paddingBottom: '30px'}}>
          <div style={{marginBottom: "25px"}} className="d-flex flex-row justify-content-between">
            <div>
              <Detail justification="start" header="Events" />
            </div>
          </div>
          <Divider />
          <div className="admin-events-panel-container">
            {this.setEventsUI()}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    events: state.events.currentUserEvents,
  };
};

const mapDispatchToProps = dispatcher => (
    bindActionCreators({
      getEvents
    }, dispatcher)
);

export default connect(mapStateToProps, mapDispatchToProps)(AdminEvents);

import React, { Component } from 'react';
import Button from 'components/new/Button';
import { Routes } from 'constants/Routes';
import './hero.css';

class Hero extends Component {
  static defaultProps = {
    className: ""
  }
  render(){
    let { onCTA, header, description, ctaLabel } = this.props;

    return(
      <div className={`d-flex flex-column align-items-center justify-content-center learnmore-hero-container ${this.props.className}`}>
        <div className="container">
          <div className="header-1-purple">{header}</div>
          <div className="header-3-tertiary">{description}</div>
          <div className="learnmore-hero-button-container d-flex justify-content-center">
            <Button
              type="tertiary"
              size="sm"
              onClick={onCTA.bind(this)}
              label={ctaLabel}/>
          </div>
        </div>
      </div>
    )
  }
}

export default Hero;

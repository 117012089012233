import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Detail extends Component {
  render() {
    let { label, value } = this.props;
    return (
      <div>
        <div style={{marginBottom: '10px'}} className="body-copy-bold-tertiary">{label}</div>
        <div className="body-copy-tertiary">{value}</div>
      </div>
    );
  }
}

export default Detail;

import React, { Component } from 'react'
import { Slider, Rail, Handles, Tracks, Ticks } from 'react-compound-slider'
import { SliderRail, Handle, Track, Tick } from './components' // example render components - source below

const sliderStyle = {
  position: 'relative',
  width: '100%',
}

const defaultValues = [0, 0]

class CompoundSlider extends Component {
  static defaultProps = {
    values: defaultValues.slice(),
    updates: defaultValues.slice(),
    domain: [0, 30000]
  }

  constructor(props) {
    super(props);
    this.state = {
      domain: props.domain,
      values: props.defaultValues.slice(),
      update: props.defaultValues.slice(),
      reversed: false,
    }
  }

  onUpdate(update) {
    this.setState({ update })
  }

  onChange(values) {
    let { onChange } = this.props;
    this.setState({ values });

    if (onChange) {
      onChange(values);
    }
  }

  setDomain(domain) {
    this.setState({ domain })
  }

  toggleReverse() {
    this.setState(prev => ({ reversed: !prev.reversed }))
  }

  render() {
    const {
      state: { domain, values, update, reversed },
    } = this

    let { label } = this.props;

    return (
      <div style={{ paddingBottom: '50px', width: '100%' }}>
        <label style={{marginBottom: '25px'}} className="body-copy-bold-tertiary">{label}</label>
        <Slider
          mode={1}
          step={1}
          domain={domain}
          reversed={reversed}
          rootStyle={sliderStyle}
          onUpdate={this.onUpdate.bind(this)}
          onChange={this.onChange.bind(this)}
          values={values}
        >
          <Rail>
            {({ getRailProps }) => <SliderRail getRailProps={getRailProps} />}
          </Rail>
          <Handles>
            {({ handles, getHandleProps }) => (
              <div className="slider-handles">
                {handles.map(handle => (
                  <Handle
                    key={handle.id}
                    handle={handle}
                    domain={domain}
                    getHandleProps={getHandleProps}
                  />
                ))}
              </div>
            )}
          </Handles>
          <Tracks left={false} right={false}>
            {({ tracks, getTrackProps }) => (
              <div className="slider-tracks">
                {tracks.map(({ id, source, target }) => (
                  <Track
                    key={id}
                    source={source}
                    target={target}
                    getTrackProps={getTrackProps}
                  />
                ))}
              </div>
            )}
          </Tracks>
          {/*<Ticks count={10}>
            {({ ticks }) => (
              <div className="slider-ticks">
                {ticks.map(tick => (
                  <Tick key={tick.id} tick={tick} count={ticks.length} />
                ))}
              </div>
            )}
          </Ticks>*/}
        </Slider>
      </div>
    )
  }
}

export default CompoundSlider

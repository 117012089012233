export const ActionTypes = {
    SEARCH_INDUSTRIES: 'SEARCH_INDUSTRIES',
    SEARCH_INDUSTRIES_SUCCESS: 'SEARCH_INDUSTRIES_SUCCESS',
    SEARCH_INDUSTRIES_FAILURE: 'SEARCH_INDUSTRIES_FAILURE',
    SET_INDUSTRIES: 'SET_INDUSTRIES',
    SET_INDUSTRIES_SUCCESS: 'SET_INDUSTRIES_SUCCESS',
    SET_INDUSTRIES_FAILURE: 'SET_INDUSTRIES_FAILURE'
};

export const searchIndustries = (query) => ({
    type: ActionTypes.SEARCH_INDUSTRIES,
    payload: {
      query
    }
});

export const setIndustries = (industries) => {
  return {
      type: ActionTypes.SET_INDUSTRIES,
      payload: {
         industries
      }
  };
};

import React, { Component } from 'react';
import Plus from 'assets/icons/black/plus.png';
import Minus from 'assets/icons/black/minus.png';
import './AccordionPanel.css';

class AccordionPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false
    }
  }

  onClickToggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  getIcon() {

  }

  render() {
    let { isOpen } = this.state;

    let bodyClassName = ["accordionpanel-body"];

    let headerClassName = ["body-copy-tertiary", "accordionpanel-header", "d-flex", "flex-row"]

    let icon = Minus;

    if (!isOpen) {
      bodyClassName.push("accordion-closed");
      icon = Plus;
    } else {
      headerClassName.push("accordion-open");
    }

    return (
      <div className="accordionpanel-container">
        <div onClick={this.onClickToggle.bind(this)} className={headerClassName.join(" ")}>
          <div className="accordionpanel-icon-container">
            <img className="accordionpanel-icon" src={icon} />
          </div>
          <div>
            {this.props.label}
          </div>
        </div>
        <div className={bodyClassName.join(" ")}>
          {this.props.content()}
        </div>
      </div>
    );
  }
}

export default AccordionPanel;

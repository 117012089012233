import React, { Component } from 'react';
import Expand from 'common/expand.component';
import Checkbox from 'components/Checkbox';
import AutocompleteEntryGroup from 'common/AutocompleteEntryGroup';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { addAudienceProfileAttribute, removeAudienceProfileAttribute } from 'services/audienceprofile/audienceprofile.actions';
import { searchOccupations, setOccupations } from 'services/occupations/occupations.actions';
import { searchIndustries, setIndustries } from 'services/industries/industries.actions';
import { searchEvents } from 'services/events/events.actions';
import './SearchFilters.css';

class SearchFilters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAgeRangeFilterOpen: false,
      isOccupationFilterOpen: false,
      isIndustryFilterOpen: false,
      occupation: {
        value: "",
        error: false
      },
      industry: {
        value: "",
        error: false
      },
    }
  }

  generateSearchPayload() {
    let payload = {};
    console.log("this.props.attributes", this.props.attributes);
    this.props.attributes.map((item)=>{
      let type = "";

      if (item.type === "Age Range") {
        type = "ageRanges";
      }

      if (item.type === "Occupation") {
        type = "occupations";
      }

      if (item.type === "Industry") {
        type = "industries";
      }

      if (!payload[type]) {
        payload[type] = [];
      }
      payload[type].push(item.value);
    })
    console.log("payload", payload)
    return payload;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.attributes.length != this.props.attributes.length) {
      console.log("uh ha ha ", this.generateSearchPayload())
      this.props.searchEvents(this.generateSearchPayload());
    }
  }

  toggleFilter(filterName) {
    let filters = ["isAgeRangeFilterOpen", "isOccupationFilterOpen", "isIndustryFilterOpen"];
    filters.forEach((filter) => {
      if (filter !== filterName) {
        this.setState({
          [filter]: false
        })
      } else {
        this.setState({
          [filterName]: !this.state[filterName]
        })
      }
    })
  }

  setAgeRangeFilter() {
    let ageRanges = ["18-24", "25-34", "35-44", "45-54", "55-64", "65+"];

    return (
      <Expand expandChildrenContainerClassName="searchfilters-agerange" onToggle={this.toggleFilter.bind(this, "isAgeRangeFilterOpen")} label="Age Range" isOpen={this.state.isAgeRangeFilterOpen}>
        {
          ageRanges.map((option, index) => {
            return (
              <Checkbox style={{width: '100%'}} key={index} checked={this.setAgeRangeChecked(option)} label={option} onClick={this.onClickAgeRange.bind(this, option)} value={option} />
            )
          })
        }
      </Expand>
    );
  }

  setAgeRangeChecked(ageRange) {
    let filteredArray = this.props.attributes.filter(({ type, value }) => "Age Range" === type && ageRange === value);
    if (filteredArray.length > 0) {
      return true;
    }
    return false;
  }

  onClickAgeRange(option) {
    let attribute = this.createAttribute("Age Range", option, option);

    let filteredArray = this.props.attributes.filter(({ type, value }) => attribute.type === type && attribute.value === value);

    if (filteredArray.length === 0) {
      this.props.addAudienceProfileAttribute(attribute);
    } else {
      this.props.removeAudienceProfileAttribute(attribute);
    }
  }

  setOccupationsFilter() {
    let results = this.props.occupations.map((result) => result.occupation);
    if (results.length === 0 && this.state.occupation.value.length > 2) {
      results.push(this.state.occupation.value); //Add
    }

    return (
      <Expand onToggle={this.toggleFilter.bind(this, "isOccupationFilterOpen")} label="Occupation" isOpen={this.state.isOccupationFilterOpen}>
        <AutocompleteEntryGroup
          fontStyle="tertiary"
          label="Occupations"
          justification="vertical"
          placeholder="Enter occupations"
          name="occupations"
          errorMessage="Occupation cannot be an empty string."
          buttonLabel="Add"
          value={this.state.occupation.value}
          error={this.state.occupation.error}
          onClickAddEntry={this.onClickAddOccupation.bind(this)}
          onChange={this.onChangeAutocompleteOccupation.bind(this)}
          results={results}
          onMouseDownOption={this.onMouseDownAutocompleteOption.bind(this)}
          onBlur={this.onBlurAutocompleteOccupation.bind(this)}
          size="full"/>
      </Expand>
    );
  }

  onBlurAutocompleteOccupation() {
    this.setState({
      occupation: {
        value: "",
        error: false
      }
    }, ()=>{
      this.props.setOccupations([]);
    });
  }

  onMouseDownAutocompleteOption(value) {
    let attribute = this.createAttribute("Occupation", value, value);

    this.props.addAudienceProfileAttribute(attribute);

    this.setState({
      occupation: {
        value: "",
        error: false
      }
    }, ()=>{
      this.props.setOccupations([]);
    });
  }


  onChangeAutocompleteOccupation(search) {
    this.setState({
      occupation: {
        value: search,
        error: false
      }
    })
    this.props.searchOccupations(search);
  }

  onClickAddOccupation() {
    if (!this.state.occupation.value) {
      this.setState({
        occupation: {
          value: this.state.occupation.value,
          error: true
        }
      });
      return;
    }

    let attribute = this.createAttribute("Occupation", this.state.occupation.value, this.state.occupation.value);

    this.props.addAudienceProfileAttribute(attribute);

    this.setState({
      occupation: {
        value: "",
        error: false
      }
    }, ()=>{
      this.props.setOccupations([]);
    });
  }

  createAttribute(type, value, data) {
    let attribute = {
      type,
      value,
      data
    };
    return attribute;
  }


  onChangeAutocompleteIndustries(search) {
    this.setState({
      industry: {
        value: search,
        error: false
      }
    })
    this.props.searchIndustries(search);
  }

  onMouseDownAutocompleteOptionIndustries(value) {

    let attribute = this.createAttribute("Industry", value, value);

    this.props.addAudienceProfileAttribute(attribute);

    this.setState({
      industry: {
        value: "",
        error: false
      }
    }, ()=>{
      this.props.setIndustries([]);
    });
  }

  onClickAddIndustry() {
    if (!this.state.industry.value) {
      this.setState({
        industry: {
          value: this.state.industry.value,
          error: true
        }
      });
      return;
    }

    let attribute = this.createAttribute("Industry",  this.state.industry.value,  this.state.industry.value);

    this.props.addAudienceProfileAttribute(attribute);

    this.setState({
      industry: {
        value: "",
        error: false
      }
    }, ()=>{
      this.props.setIndustries([]);
    });
  }

  onBlurAutocompleteIndustries() {
    this.setState({
      industry: {
        value: "",
        error: false
      }
    }, ()=>{
      this.props.setIndustries([]);
    });
  }

  setIndustriesFilter() {
    let resultsIndustries = this.props.industries.map((result) => result.industry);

    if (resultsIndustries.length === 0 && this.state.industry.value.length > 2) {
      resultsIndustries.push(this.state.industry.value); //Add
    }

    return (
      <Expand onToggle={this.toggleFilter.bind(this, "isIndustryFilterOpen")} label="Industry" isOpen={this.state.isIndustryFilterOpen}>
        <AutocompleteEntryGroup
          fontStyle="tertiary"
          label="Industries"
          placeholder="Enter industries"
          name="Industries"
          justification="vertical"
          errorMessage="Industry cannot be an empty string."
          buttonLabel="Add"
          value={this.state.industry.value}
          error={this.state.industry.error}
          onClickAddEntry={this.onClickAddIndustry.bind(this)}
          onChange={this.onChangeAutocompleteIndustries.bind(this)}
          results={resultsIndustries}
          onMouseDownOption={this.onMouseDownAutocompleteOptionIndustries.bind(this)}
          onBlur={this.onBlurAutocompleteIndustries.bind(this)}
          size="full"/>
      </Expand>
    );
  }

  render() {
    return (
      <div className="searchfilters-container d-flex flex-wrap flex-md-row flex-column">
        {this.setAgeRangeFilter()}
        {this.setOccupationsFilter()}
        {this.setIndustriesFilter()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    attributes: state.audienceprofile.attributes,
    occupations: state.occupations.occupations,
    industries: state.industries.industries,
  };
};

const mapDispatchToProps = dispatcher => (
    bindActionCreators({
      addAudienceProfileAttribute,
      removeAudienceProfileAttribute,
      searchOccupations,
      setOccupations,
      searchIndustries,
      setIndustries,
      searchEvents
    }, dispatcher)
);

export default connect(mapStateToProps, mapDispatchToProps)(SearchFilters);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './Detail.css';
import './text.css';

class Detail extends Component {
  static propTypes = {
    label: PropTypes.string
  }

  static defaultProps = {
    type: 'brand',
    justification: 'center',
    style: {}
  }

  setDescription() {
    let { description } = this.props;

    if (description) {
      let style = {marginTop: '15px', ...this.setJusification()}
      return (<div style={style} className={this.setSubHeaderStyle()}>{description}</div>)
    }

    return (null);
  }

  setHeaderStyle() {
    if (this.props.type === 'tertiary') {
      return "header-2-tertiary";
    }

    if (this.props.type === 'header-3-tertiary') {
      return "header-3-tertiary";
    }

    if (this.props.type === 'header-3-purple') {
      return "header-3-purple";
    }

    if (this.props.type === 'header-4-purple') {
      return "header-4-purple";
    }

    if (this.props.type === 'brand') {
      return "header-2-brand";
    }

    if (this.props.type === 'body-copy-tertiary') {
      return "body-copy-tertiary";
    }

    if (this.props.type === 'body-copy-bold-tertiary') {
      return "body-copy-bold-tertiary";
    }

    if (this.props.type === 'body-copy-bold-brand') {
      return "body-copy-bold-tertiary";
    }
  }

  setSubHeaderStyle() {
    if (this.props.type === 'primary') {
      return "body-copy-primary";
    }

    if (this.props.type === 'header-3-tertiary') {
      return "body-copy-tertiary";
    }

    if (this.props.type === 'header-3-purple') {
      return "body-copy-tertiary";
    }

    if (this.props.type === 'header-4-purple') {
      return "body-copy-tertiary";
    }

    if (this.props.type === 'body-copy-tertiary') {
      return "body-copy-tertiary-small";
    }

    if (this.props.type === 'tertiary') {
      return "body-copy-tertiary";
    }

    if (this.props.type === 'brand') {
      return "body-copy-tertiary";
    }

    if (this.props.type === 'body-copy-bold-tertiary') {
      return "body-copy-tertiary";
    }

    if (this.props.type === 'body-copy-bold-brand') {
      return "body-copy-tertiary";
    }
  }

  setJusification() {
    if (this.props.justification === 'center') {
      return {textAlign: 'center'}
    }

    if (this.props.justification === 'start') {
      return {textAlign: 'start'}
    }
  }

  render() {
    let { header, subHeader, children } = this.props;
    let style = {};

    if (this.props.style !== null) {
      style = this.props.style;
    }

    return (
      <div style={style}>
        <div style={this.setJusification()} className={this.setHeaderStyle()}>{header}</div>
        <div style={this.setJusification()} className={this.setSubHeaderStyle()}>{subHeader || children}</div>
        {this.setDescription()}
      </div>
    );
  }
}

export default Detail;

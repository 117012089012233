import React, { Component } from 'react';
import Button from 'components/new/Button';
import Image from 'components/Image';
import Modal from 'components/Modal';
import Detail from 'common/Detail';
import TextField from 'components/TextField';
import Confirmation from '../hero/assets/profile.svg';
import validator from 'validator';
import { signUpDemo, reset, setEmail } from 'services/sponsornewslettersignup/sponsornewslettersignup.actions';
import { Routes } from 'constants/Routes';
import './Signup.css';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

class Signup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      success: false,
      email: {
        value: "",
        error: false,
        errorMessage: ""
      },
      modalVisible: false
    };
  }
/*
  componentDidUpdate(prevProps) {
    if (this.props.isDemoSignupSuccessful) {
      if (this.state.email.value) {
        notification.success({
          message: 'Thank you for signing up!',
          description: `An email has been sent to ${this.state.email.value}`
        });
      }

      this.props.reset();
      return;
    }

    if (this.props.error) {
      this.setState({
        email:  {
          value: this.state.email.value,
          error: true,
          errorMessage: this.props.errorMessage
        }
      }, () => {
        this.props.reset();
      });
    }
  }*/

  handleOnChange(event) {
    this.setState({
      email: {
        value: event.target.value,
        error: false
      }
    });
  }

  isEmailValid() {
    return validator.isEmail(this.state.email.value);
  }

  handleOnClick() {
    if (!this.isEmailValid()) {
      this.setState({
        email: {
          error: true,
          value: this.state.email.value,
          errorMessage: "Please enter a valid e-mail address"
        }
      });
      return;
    }
    this.props.setEmail(this.state.email.value);
    window.fbq('track', 'StartRegistration');
    this.props.history.push(Routes.LEARN_MORE_SIGNUP);
    //this.props.signUpDemo(this.state.email.value)
  }

  setModalVisible(visible) {
    this.setState({
      modalVisible: visible
    })
  }

  render() {
    let { email, modalVisible } = this.state;
    let { className } = this.props;

    let containerClassName = ["d-flex", "flex-column"]
    if (className) {
      containerClassName.push(className)
    }
    return (
        <div className={containerClassName.join(" ")}>
          <div className="d-flex flex-column align-items-center">
            <div className="d-flex flex-sm-row flex-column justify-content-start" style={{width: '100%'}}>
              <div className="d-flex flex-column align-items-start">
                <TextField
                  className="signup-textfield"
                  size="full"
                  onChange={this.handleOnChange.bind(this)}
                  placeholder="Enter your work e-mail"
                  value={email.value} />
              </div>
              <Button
                size="sm"
                type="tertiary"
                className="signup-button button-text"
                onClick={this.handleOnClick.bind(this)}
                label='REQUEST DEMO'/>
            </div>
            {email.error ? <div className="body-copy-error-wrapper d-flex" style={{width: '100%'}}><span style={{color: '#F8F8F8'}} className="signup-error-message body-copy-error-small body-copy-error-container">{email.errorMessage}</span></div> : null}
          </div>
          <Modal onClickClose={this.setModalVisible.bind(this, false)} visible={modalVisible}>
            <Image className="signup-image" src={Confirmation} />
            <Detail style={{marginBottom: '50px'}}header="Thank You!!!" subHeader="You are signed up to our newsletter!!!" />
            <Button size="lg" label="Close" type="tertiary" onClick={()=>this.setState({modalVisible: false})}/>
          </Modal>

        </div>
      );
  }
}

const mapStateToProps = (state) => ({
    isDemoSignupSuccessful: state.sponsornewslettersignup.isDemoSignupSuccessful,
    error: state.sponsornewslettersignup.demoSignupError,
    errorMessage: state.sponsornewslettersignup.demoSignupErrorMessage
  });


const mapDispatchToProps = dispatcher => (
    bindActionCreators({
      signUpDemo,
      reset,
      setEmail
    }, dispatcher)
);

export default connect(mapStateToProps, mapDispatchToProps)(Signup);

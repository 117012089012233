import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './Row.css';

class Row extends Component {
  static defaultProps = {
    style: {},
    inject: false
  }

  getValue() {
    let { value, inject } = this.props;
    if (inject) {
      return value();
    }
    return value;
  }
  
  render() {
    let {
      style,
      label
    } = this.props;

    return (
      <div style={style} className="d-flex">
        <span className="row-container body-copy-bold-tertiary">{label}:&nbsp;</span>
        <span className="body-copy-tertiary">{this.getValue()}</span>
      </div>
    );
  }
}

export default Row;

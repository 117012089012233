export const ActionTypes = {
    ADD_ATTRIBUTE: 'ADD_ATTRIBUTE',
    ADD_ATTRIBUTE_SUCCESS: 'ADD_ATTRIBUTE_SUCCESS',
    REMOVE_ATTRIBUTE: 'REMOVE_ATTRIBUTE',
    REMOVE_ATTRIBUTE_SUCCESS: 'REMOVE_ATTRIBUTE_SUCCESS'
};

export const addAttribute = (attribute) => {
  return {
    type: ActionTypes.ADD_ATTRIBUTE,
    payload: {
      attribute
    }
  }
};

export const removeAttribute = (attribute) => {
  return {
    type: ActionTypes.REMOVE_ATTRIBUTE,
    payload: {
      attribute
    }
  }
};

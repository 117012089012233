import { ActionTypes } from './availableplacements.actions';

const initialState = {
    errorMessage: "",
    available: []
};

const AvailablePlacementsReducer = (state = initialState, action) => {
    switch (action.type) {
      case ActionTypes.POST_AVAILABLE_PLACEMENTS_SUCCESS:
        return { ...state};
      case ActionTypes.POST_AVAILABLE_PLACEMENTS_FAILURE:
          return { ...state};
      default:
          return state;
    }
};

export default AvailablePlacementsReducer;

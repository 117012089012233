export const ActionTypes = {
    DELETE_EVENT_ASSET: 'DELETE_EVENT_ASSET',
    DELETE_EVENT_ASSET_SUCCESS: 'DELETE_EVENT_ASSET_SUCCESS',
    DELETE_EVENT_ASSET_FAILURE: 'DELETE_EVENT_ASSET_FAILURE',
    POST_EVENT_ASSETS: 'POST_EVENT_ASSETS',
    POST_EVENT_ASSETS_SUCCESS: 'POST_EVENT_ASSETS_SUCCESS',
    POST_EVENT_ASSETS_FAILURE: 'POST_EVENT_ASSETS_FAILURE',
    POST_EVENT_ASSET: 'POST_EVENT_ASSET',
    POST_EVENT_ASSET_SUCCESS: 'POST_EVENT_ASSET_SUCCESS',
    POST_EVENT_ASSET_FAILURE: 'POST_EVENT_ASSET_FAILURE',
    ADD_EVENT_ASSET: 'ADD_EVENT_ASSET',
    REMOVE_EVENT_ASSET: 'REMOVE_EVENT_ASSET'
  }

export const postEventAssets = (event_assets) => ({
    type: ActionTypes.POST_EVENT_ASSETS,
    payload: {
      event_assets
    }
});

export const addEventAsset = (asset) => ({
  type: ActionTypes.ADD_EVENT_ASSET,
  payload: {
    asset
  }
});

export const removeEventAsset = (asset) => ({
  type: ActionTypes.REMOVE_EVENT_ASSET,
  payload: {
    asset
  }
});

export const deleteEventAsset = (id) => ({
  type: ActionTypes.DELETE_EVENT_ASSET,
  payload: {
    id
  }
})

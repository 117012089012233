import React, { Component } from 'react';
import { Routes } from 'constants/Routes';
import GetStartedPanel from 'common/getstartedpanel.component';
import Button from 'components/new/Button';
import ButtonGroup from 'components/ButtonGroup';
import Portfolio from 'assets/portfolio@2x.png';
import PortfolioTile from './components/PortfolioTile';
import Detail from 'common/Detail';
import Divider from 'common/Divider.component';
import { connect } from 'react-redux';
import { setCurrentPortfolio } from 'services/portfolios/portfolios.actions';
import { bindActionCreators } from 'redux';
import { getPortfolios } from 'services/portfolios/portfolios.actions';
import './sponsorhome.css';

const {
  PORTFOLIO_OBJECTIVES
} = Routes;

class SponsorHome extends Component {
  static defaultProps = {
    events: []
  }

  constructor(props) {
    super(props);
    this.state = {

    }
  }

  componentDidMount() {
    this.props.getPortfolios();
    console.log(this.props.portfolios)
  }

  goTo(path) {
    this.props.history.push(path);
  }

  goToPortfolio(portfolio) {
    this.props.setCurrentPortfolio(portfolio);
    this.props.history.push("/portfolio/" + portfolio.id);
  }
  /*<div className="d-flex justify-content-center flex-column align-items-center">
    <div className="sponsorhome-container">
      <img style={{maxWidth: '400px', width: '100%'}} src={Portfolio} />
    </div>
    <div style={{marginTop: '45px'}}>
      <Button size="lg" type="tertiary" onClick={this.goToSponsorObjectives.bind(this)} label="Get Started: Create Your First Portfolio"/>
    </div>
  </div>*/

  getSponsorHomeUI() {
    if (this.props.portfolios.length === 0) {
      return (
        <div className="d-flex align-items-center justify-content-center flex-column">
          <GetStartedPanel
            title="Create Your First Portfolio"
            items={[
              "Select your sponsorship objective.",
              "Create a target audience profile.",
              "Set your sponsorship preferences.",
              "Submit your portfolio to get customized recommendations."
            ]}
          />
          <div>
            <Button size="md" type="tertiary" onClick={this.goTo.bind(this, PORTFOLIO_OBJECTIVES)} label="CREATE PORTFOLIO"/>
          </div>
        </div>
      );
    } else {
      return (
        <div className="sponsorhome-container d-flex justify-content-center flex-column align-items-center">
        {
          this.props.portfolios.map((item, index) => {
            return <PortfolioTile key={index} name={item.name} budget={100} onClick={this.goToPortfolio.bind(this, item)} />
          })
        }
        </div>
      )
    }
  }

  getCreatePorfolioButton() {
    if (this.props.portfolios.length === 0) return (null);
    return (
      <div>
        <Button size="md" type="tertiary" onClick={this.goTo.bind(this, PORTFOLIO_OBJECTIVES)} label="CREATE PORTFOLIO"/>
      </div>
    )
  }

  render() {
    if (!this.props.userData) {
      return (null);
    }

    let {first_name, last_name} = this.props.userData;

    return (
      <div className="sponsorhome-container container">
        <div className="d-flex flex-column">
          <div className="d-flex flex-row justify-content-between">
            <Detail justification="start" style={{textAlign: 'Start', marginBottom: '25px'}} header="My Portfolios"/>
            {this.getCreatePorfolioButton()}
          </div>
          <Divider  style={{marginBottom: '25px'}}/>
        </div>
        {this.getSponsorHomeUI()}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    portfolios: state.portfolios.portfolios,
    user: state.users.currentUser,
    userData: state.users.user
  };
};

const mapDispatchToProps = dispatcher => (
    bindActionCreators({
        getPortfolios,
        setCurrentPortfolio,
    }, dispatcher)
);

export default connect(mapStateToProps, mapDispatchToProps)(SponsorHome);

import validator from 'validator';

export const isSignUpValid = (email, password, firstName, lastName) => {
  let errorMessage = "";
  let error = false;

  if (firstName.length === 0) {
    errorMessage = "Please enter your first name.";
    error = true;
  }
  if (lastName.length === 0) {
    errorMessage = "Please enter your last name.";
    error = true;
  }
  if (!validator.isEmail(email)) {
    errorMessage = "Please enter a valid e-mail.";
    error = true;
  }
  if (password.length < 6 ) {
    errorMessage = "Password must be at least 6 characters.";
    error = true;
  }
  return {error, errorMessage}
}

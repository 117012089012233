export const ActionTypes = {
    GET_SUGGESTIONS: 'GET_SUGGESTIONS',
    GET_SUGGESTIONS_SUCCESS: 'GET_SUGGESTIONS_SUCCESS',
    GET_SUGGESTIONS_FAILURE: 'GET_SUGGESTIONS_FAILURE',
    SET_SUGGESTIONS: 'SET_SUGGESTIONS',
    SET_SUGGESTIONS_SUCCESS: 'SET_SUGGESTIONS_SUCCESS',
    SET_SUGGESTIONS_FAILURE: 'SET_SUGGESTIONS_FAILURE'
};

export const getSuggestions = (query, isAddressAllowed = true, isGeopoliticalRegionAllowed = true) => ({
    type: ActionTypes.GET_SUGGESTIONS,
    payload: {
      query,
      isAddressAllowed,
      isGeopoliticalRegionAllowed
    }
});

export const setSuggestions = (suggestions) => {
  return {
      type: ActionTypes.SET_SUGGESTIONS,
      payload: {
        data: suggestions
      }
  };
};

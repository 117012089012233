import { ActionTypes } from './firebase.actions';

const initialState = {
    firebase: {}
};

const FirebaseReducer = (state = initialState, action) => {
    switch (action.type) {
      case ActionTypes.SET_FIREBASE:
        return { ...state };
      case ActionTypes.SET_FIREBASE_SUCCESS:
        return { ...state, firebase: action.data};
      default:
        return state;
    }
};

export default FirebaseReducer;

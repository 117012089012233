import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';


class Test extends Component {
  constructor(props) {
    super(props);
    this.state = {
      results: []
    }
  }


  componentDidMount() {
    const { id } = this.props.match.params;
    fetch("http://localhost:4000/score", {
      method: 'POST',
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json"
      },
      redirect: "follow",
      referrer: "no-referrer",
      body: JSON.stringify({
        "portfolio_id": id
      })
    }).then((response) => {
      return response.json();
    }).then((response) => {
      this.setState({
        results: response
      })
    });
  }

  getAttributes(attributes) {
    let items = [];
    if (attributes.length > 0) {
      items = attributes.map((attribute)=> attribute.value)
      return items.join(", ");
    }
    return 'None'
  }


  render() {
    return (
      <div style={{width: '100%'}}>
        <div className="d-flex justify-content-around align-items-center" style={{width: '100%', marginBottom: '50px', marginTop: '50px'}} >
          <div className="col-4 d-flex justify-content-center">Event</div>
          <div className="col-4 d-flex justify-content-center">Portfolio</div>
          <div className="col-4 d-flex justify-content-center">Score</div>
        </div>
        {
          this.state.results.map((result)=> {
            let { event, portfolio, score } = result;

            return (
              <div className="d-flex justify-content-around" style={{width: '100%', marginBottom: '50px'}} >
                <div className="col-4 d-flex justify-content-center">
                  <div className="d-flex flex-column">
                    <div>Event ID: {event.id}</div>
                    <div>Age ranges: {this.getAttributes(event.age_groups)}</div>
                    <div>Occupations: {this.getAttributes(event.occupations)}</div>
                    <div>Interests: {this.getAttributes(event.interests)}</div>
                    {/*<div>Industries: {this.getAttributes(event.industries)}</div>*/}
                  </div>
                </div>
                <div className="col-4 d-flex justify-content-center">
                  <div className="d-flex flex-column">
                    <div>Portfolio ID: {portfolio.id}</div>
                    <div>Age ranges: {this.getAttributes(portfolio.age_groups)}</div>
                    <div>Occupations: {this.getAttributes(portfolio.occupations)}</div>
                    <div>Interests: {this.getAttributes(portfolio.interests)}</div>
                    {/*<div>Industries: {this.getAttributes(event.industries)}</div>*/}
                  </div>
                </div>
                <div className="col-4 d-flex justify-content-center">{score}</div>
              </div>
            )
          })
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {

  };
};

const mapDispatchToProps = dispatcher => (
    bindActionCreators({

    }, dispatcher)
);

export default connect(mapStateToProps, mapDispatchToProps)(Test);

import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/firestore';

const config = {
  apiKey: "AIzaSyCCPbLYD2uG5PsH10X2HcKwdxtNi3EUL9Y",
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: "208874115133"
};

class Firebase {
  constructor() {
    app.initializeApp(config);

    this.db = app.database();
    this.auth = app.auth();
    this.firestore = app.firestore();
    this.idToken = null;
    this.isAuthenticated = false;

    this.googleProvider = new app.auth.GoogleAuthProvider();
    this.facebookProvider = new app.auth.FacebookAuthProvider();
    this.twitterProvider = new app.auth.TwitterAuthProvider();
  }

  db() {
    return this.db;
  }

  doPostLeadGenerationEmail(email) {
    return this.db.ref("waitlist_emails").push({
      email
    });
  }

  createUserWithEmailAndPassword(email, password) {
    return this.auth.createUserWithEmailAndPassword(email, password);
  }

  createUser(user) {
    return this.auth.createUser(user);
  }

  signInWithEmailAndPassword(email, password) {
    return this.auth.signInWithEmailAndPassword(email, password);
  }


  signInWithGoogle() {
    this.auth.signInWithPopup(this.googleProvider);
  }

  signInWithFacebook() {
    this.auth.signInWithPopup(this.facebookProvider);
  }

  signInWithTwitter() {
    this.auth.signInWithPopup(this.twitterProvider);
  }

  signOut() {
    return this.auth.signOut();
  }

  sendPasswordResetEmail(email) {
    return this.auth.sendPasswordResetEmail(email);
  }

  updatePassword(password) {
    this.auth.currentUser.updatePassword(password);
  }

  user(uid) {
    return this.firestore.collection('users').doc(uid).get();
  }

  users() {
    return this.firestore.collection('users');
  }

  setIsAuthenticated(isAuthenticated) {
    this.isAuthenticated = isAuthenticated;
  }

  isUserAuthenticated() {
    return this.isAuthenticated;
  }

  onAuthUserListener(next, fallback) {
    this.auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        this.setIsAuthenticated(true);
        this.user(authUser.uid)
          .then((response) => {
            const dbUser = response.data();
            // default empty roles
            if (!dbUser.roles) {
              dbUser.roles = [];
            }

            // merge auth and db user
            authUser = {
              uid: authUser.uid,
              email: authUser.email,
              emailVerified: authUser.emailVerified,
              lastName: authUser.lastName,
              firstName: authUser.firstName,
              providerData: authUser.providerData,
              ...dbUser,
            };

            next(authUser);
          }).catch((error)=>{});
      } else {
        fallback();
      }
    });
  }
}

export default new Firebase();

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import RadioButton from 'components/RadioButton'
import './RadioGroup.css';


//Add keyboard actions
class RadioGroup extends Component {
  static defaultProps = {
    default: null,
    defaultId: null,
    justification: "horizontal",
    style: {}
  }

  constructor(props) {
    super(props);
    let boxes = {};
    let checked = null;
    props.items.map((item)=>{
      let selected = false;

      if (props.defaultId === item.id) {
        selected = true;
        checked = props.defaultId;
      }

      boxes[item.id] = selected;
    })

    this.state = {
      checked: checked,
      boxes: {...boxes}
    }
  }

  componentDidMount() {
    if (this.props.default) {
      this.setChecked(this.props.default);
    }
  }

  setChecked(id) {


    let boxes = {...this.state.boxes};

    if (this.state.checked) {
      if (this.state.checked !== id) {
        boxes[this.state.checked] = false;
      }
    }

    boxes[id] = true;


    this.setState({
      boxes: {...boxes},
      checked: id
    });

    this.props.onSelected(id);
  }

  onKeyDown() {

  }

  setJusification() {
    if (this.props.justification === 'horizontal') {
      return "radiogroup-container-horizonal d-flex";
    }
    return "radiogroup-container-vertical";
  }

  render() {
    let { error, errorMessage } = this.props;
    return (
      <div style={this.props.style}>
        <div onKeyDown={this.onKeyDown.bind(this)} role="radiogroup" className={this.setJusification()} tabIndex="0">
          {
            this.props.items.map((item, index) => {
              let checkbox = <RadioButton key={index} checked={this.state.boxes[item.id]} description={item.description} label={item.type} onClick={this.setChecked.bind(this, item.id)} value={item.type} />
              return checkbox;
            })
          }
        </div>
        <div style={{paddingTop: '5px'}} className={error ? "textfield-error-container" : "textfield-hidden" }>
          <div className="body-copy-error-xs-small">{errorMessage}</div>
        </div>
      </div>
    );
  }
}

export default RadioGroup;

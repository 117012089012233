import React, { Component } from 'react';
import './CheckboxWrapper.css';

class CheckboxWrapper extends Component {
  static defaultProps = {
    style: {}
  }
  render() {
    return (
      <div style={this.props.style} className="checkbox-wrapper d-flex flex-wrap">
        {this.props.children}
      </div>
    );
  }
}

export default CheckboxWrapper;

import React, { Component } from 'react';
import Plus from 'assets/icons/plus.svg';
import Autocomplete from 'components/Autocomplete';
import FormGroup from 'components/FormGroup';
import Button from 'components/new/Button';
import PropTypes from 'prop-types';

import './AutocompleteEntryGroup.css';

class AutocompleteEntryGroup extends Component {
  static defaultProps = {
    errorMessage: "",
    style: {},
    buttonLabel: "Add",
    size: "full",
    justification: 'horizontal'
  }

  constructor(props) {
    super(props);
    this.state = {
      value: "",
    }
  }
  _handleOnClickAddEntry() {

    if (this.props.onClickAddEntry !== null) {
      this.props.onClickAddEntry();
    }
    this.setState({
      value: ''
    })
  }

  getLabel() {
    let className = "";

    if (this.props.label == null || this.props.label == "") {
      return;
    }

    if (this.props.fontStyle === "primary") {
      className = "body-copy-bold-primary";
    } else if (this.props.fontStyle === "secondary") {
      className = "body-copy-bold-secondary";
    } else if (this.props.fontStyle === "tertiary") {
      className = "body-copy-bold-tertiary";
    }

    return (
      <label className={className}>{this.props.label}</label>
    );
  }

  onChange(event) {
    this.props.onChange();

    this.setState({
      value: event
    })
  }

  setJusification() {
    if (this.props.justification === 'vertical') {
      return "autocomplete-entry-group-container d-flex flex-column autocomplete-entry-group-container-vertical";
    }
    return "autocomplete-entry-group-container d-flex flex-column-reverse flex-lg-row";
  }

  render() {
    let { style, value, fontStyle, placeholder, errorMessage, error, name, onChange, results, onMouseDownOption, onBlur, size} = this.props

    return (
      <div style={{width: '100%'}}>
        {this.getLabel()}
        <div className={this.setJusification()}>
          <Autocomplete
            value={value}
            resetOnSelect={true}
            fontStyle={fontStyle}
            placeholder={placeholder}
            name={name}
            errorMessage={errorMessage}
            error={error}
            onChange={onChange.bind(this)}
            onClickAddEntry={this._handleOnClickAddEntry.bind(this)}
            results={results}
            onMouseDownOption={onMouseDownOption.bind(this)}
            onBlur={onBlur.bind(this)}
            size={size}/>
          <Button
            icon={Plus}
            className="autocomplete-entry-group-button"
            label={this.props.buttonLabel}
            type="tertiary"
            onClick={this._handleOnClickAddEntry.bind(this)} />
        </div>
      </div>
    );
  }
}

export default AutocompleteEntryGroup;

import React, { Component } from 'react';
import Detail from 'common/Detail';
import Divider from 'common/Divider.component';
import { Menu, Dropdown } from 'antd';
import Button from 'components/new/Button';
import { Routes } from 'constants/Routes';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { isNumeric } from 'common/helpers/validators';
import { withRouter } from "react-router-dom";
import { getEvent } from 'services/events/events.actions';
import DownArrow from 'assets/down-arrow-white-small.svg';
import './eventdashboard.css';

class EventDashboard extends Component {
  componentDidMount() {
    const { id } = this.props.match.params;

    if (id === undefined || !isNumeric(id)) {
      this.props.history.push(Routes.ORGANIZER_HOME);
      return;
    }

    this.props.getEvent(this.props.user.id, id);
  }

  componentDidUpdate(prevProps) {

  }

  goToPath(path) {
    this.props.history.push(path);
  }

  getMenu(id) {
    return (
      <Menu>
        <Menu.Item key="0">
          <div className="button-text-bold" style={{textAlign: 'center'}} onClick={this.goToPath.bind(this, `/events/${id}/dashboard/edit`)}>EDIT EVENT</div>
        </Menu.Item>
        <Menu.Item key="1">
          <div className="button-text-bold" style={{textAlign: 'center'}} onClick={this.goToPath.bind(this, `/events/${id}/dashboard/view`)}>VIEW EVENT</div>
        </Menu.Item>
        <Menu.Item key="2">
          <div className="button-text-bold" style={{textAlign: 'center'}} onClick={this.goToPath.bind(this, `/events/${id}/dashboard/manage`)}>MANAGE EVENT</div>
        </Menu.Item>
      </Menu>
    );
  }

  getButtonLabel() {
    console.log(this.props.location.pathname)
    if (this.props.location.pathname.includes("manage")) {
      return  "MANAGE EVENT";
    }

    if (this.props.location.pathname.includes("edit")) {
      return  "EDIT EVENT";
    }

    if (this.props.location.pathname.includes("view")) {
      return  "VIEW EVENT";
    }

  }

  render() {

    if (!this.props.event.event.id) return null;
    let { id } = this.props.event.event;

    return (
        <div className="container">
          <div className="d-flex flex-row justify-content-between align-items-center">
            <Detail justification="start" style={{textAlign: 'Start', marginBottom: '25px', marginTop: '30px'}} header={this.props.event.event.name} />
            {/*}<Button
              label="VIEW EVENT"
              style="tertiary"
              onClick={(()=>{})}
              stopPropagation={true} />*/}
            <div>
              <Dropdown overlay={this.getMenu(id)} trigger={['click']}>
                <Button
                  iconOrientation="reverse"
                  icon={DownArrow}
                  iconStyles={{height: '9px'}}
                  label={this.getButtonLabel()}
                  type="tertiary"
                  onClick={(()=>{})}
                  stopPropagation={true} />
              </Dropdown>
            </div>
          </div>
          <Divider />
          {this.props.children}
        </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    event: state.events.currentEvent,
    user: state.users.user
  };
};

const mapDispatchToProps = dispatcher => (
    bindActionCreators({
        getEvent
    }, dispatcher)
);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EventDashboard));

import React, { Component } from 'react';
import Wrapper from 'components/Wrapper';
import Eyebrow from 'components/Eyebrow';
import Detail from 'common/Detail';
import Button from 'components/new/Button';
import TextField from 'components/TextField';
import FormGroup from 'components/FormGroup';
import { Table } from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { get } from 'services/Api'

const columns = [
  {
    title: 'URL',
    dataIndex: 'url',
    key: 'url'
  },
  {
    title: 'Title',
    dataIndex: 'title',
    key: 'title'
  },
  {
    title: 'Snippet',
    dataIndex: 'snippet',
    key: 'snippet'
  },
  {
    title: 'Search Term',
    dataIndex: 'searchterm',
    key: 'searchterm'
  },
  {
    title: 'S3 PDF URL',
    dataIndex: 's3PDFURL',
    key: 's3PDFURL'
  }

];

class PDFs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      search: "Las Vegas Convention Center"
    }
  }
  async componentWillMount() {
    this.search();
  }

  search() {
    let { search } = this.state;
    get("search/pdfs", "?query="+ search).then((response)=> {
      return response.json();
    }).then((response) => {
      let data = response.map((item, index) => {

        let url = item.conference_prospectus_download_url;
        let s3PDFURL = item.conference_prospectus_download_s3PDFURL;
        let snippet = item.conference_prospectus_download_snippet || url;
        let search_term = item.conference_prospectus_download_search_term || "MANUAL PULL";
        let title = item.conference_prospectus_download_title || "MANUAL PULL";

        return {
          url: <a href={url}>{title}</a>,
          title: title,
          key: "table-" + index,
          snippet: snippet,
          searchterm: search_term,
          s3PDFURL: <a href={s3PDFURL}>{title}</a>,
        };
      });

      this.setState({
        data
      })
    });
  }

  _handleOnChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  render() {
    let { data } = this.state;

    return (
      <Wrapper className="container">
      <div className="d-flex flex-row justify-content-between">
        <Detail justification="start" header="Search PDFs" subHeader="PDFs" />
        <div style={{width: '50%'}}>
          <FormGroup>
            <TextField
              size="full"
              name="search"
              value={this.state.search}
              placeholder='Search'
              onChange={this._handleOnChange.bind(this)} />
            <Button
              type="primary"
              label="Search"
              onClick={this.search.bind(this)} />
          </FormGroup>
        </div>
      </div>
        <div style={{marginTop: '25px'}}>
          <Eyebrow label="PDFs" />
          <Table dataSource={data} columns={columns} />
        </div>
      </Wrapper>
    );
  }
}

const mapStateToProps = (state) => {
  return {

  };
};

const mapDispatchToProps = dispatcher => (
    bindActionCreators({

    }, dispatcher)
);

export default connect(mapStateToProps, mapDispatchToProps)(PDFs);

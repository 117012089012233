import { ActionTypes } from './cart.actions';

const initialState = {
    errorMessage: "",
    items: [],
    package: null
};

const CartReducer = (state = initialState, action) => {
    let contains = [];
    switch (action.type) {
      case ActionTypes.ADD_TO_CART:

        contains = state.items.filter(({ id }) => action.item.id === id);
        let items = [];

        if (contains.length > 0) {
          items = [...state.items];
        } else {
          items = [...state.items, action.item]
        }
        return {
          ...state,
          items
        };
      case ActionTypes.ADD_PACKAGE_TO_CART:
        console.log(action);
        return {
          ...state,
          package: action.payload.item
        };
      case ActionTypes.REMOVE_PACKAGE_FROM_CART:

        return {
          ...state,
          package: null
        };
      case ActionTypes.REMOVE_FROM_CART:
        let newItems = state.items.filter(({ id }) => !(action.item.id === id));

        return {
          ...state,
          items: [...newItems]
        };
      default:
          return state;
    }
};

export default CartReducer;

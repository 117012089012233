import React, { Component } from 'react';
import LearnMorePageSegment from './components/pagesegment.component';
import ResourcesHero from './components/resourceshero.component';
import Subscribe from './components/subscribe.component';
import Button from 'components/new/Button';
import Detail from 'common/Detail';
import Accordion from 'components/Accordion';
import BlogTile from './components/blogtile.component';
import Footer from './components/footer.component';
import { getBlogPosts } from 'services/blog/blog.actions';
import { Routes } from 'constants/Routes';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import './resources.css';

const FAQs = [
  {
    question: "How does Restage work?",
    answer: () => {
      return (
        <div>
          A: Restage helps you find the right partners for your events using
          data from your event and data provided by brands to make introductions relevant to both parties.
        </div>
      )
    }
  },
  {
    question: "How does Restage find sponsors for my event? ",
    answer: () => {
      return (
        <div>
          A: Once your event page is approved, we recommend your event to sponsors interested in audiences like yours. Sponsors can visit your event page to see the information you’ve shared.
          <br/>
          <br/>
          Once you upload your event details and your event is approved, we use your event's audience profile to match with brands and their audience profiles. If there is a match, the brand is notified
          of your event and the available sponsorships.
          <br/>
          <br/>
          Whether or not a match is made, brands can still find your event using search filters.
        </div>
      )
    }
  },
  {
    question: "How much does Restage cost?",
    answer: () => {
      return (
        <div>
           A: Event organizers can list events on Restage for free.
           We make money by charging a commission on sponsorships you get through us. Restage is always free to sponsors.
        </div>)
    }
  },
  {
    question: "How do I share my event information with sponsors?",
    answer: () => {
      return (
        <div>
          A: Share event details, audience info, and sponsorship opportunities with sponsors on your event page.
          You can make your event pages as comprehensive as you would like as well as link to your website.        </div>
      )
    }
  },
  {
    question: "How do I find relevant events?",
    answer: () => {
      return (
        <div>
          A: Restage helps you find the right partners for your brand by using data from events on our platform and data about what you're looking for to make introductions relevant to both parties.
        </div>)
    }
  }/*,
  {
    question: "When can I negotiate with event organizers?",
    answer: () => {
      return (
        <div>
          A: When an event is added to the platform that meets your brand preferences, you'll be notified, at which point you can search through their sponsorship opportunities
          and select a sponsorship of your choosing to submit an offer to.
          <br/>
          <br/>
          You can search from events on our platform at any time.
        </div>
      )
    }
  }*/
]

class Resources extends Component {
  getFAQs() {
    let panels = [];

    FAQs.map((faq, index) => {
      let { question, answer } = faq;

      let content = () => {
        return (
          <div className='body-copy-tertiary' key={index}>{answer()}</div>
        )
      }

      let panel = {
        label: `Q: ${question}`,
        content
      }

      panels.push(panel);
    });

    return (
      <Accordion
        className="learnmore-resources-accordion"
        panelClassName="d-flex justify-content-center flex-column learnmore-resources-accordion-panel"
        type="purple"
        panels={panels} />
    )
  }

  componentDidMount() {
    this.props.getBlogPosts();
  }

  goToPost(slug) {
    this.props.history.push(`/blog/${slug}`)
  }

  goToPath(path) {
    this.props.history.push(path);
  }

  openCalendly() {
    window.open("https://www.calendly.com/kiamber", "_blank",)
  }

  render(){
    return(
      <div>
        <div id="product-demo">
          <ResourcesHero
            ctaLabel="Book A Demo"
            header="Get ready to meet your new Sponsorships Manager"
            onCTA={this.openCalendly.bind(this)}/>
        </div>
        <div id="blog" className="learnmore-resources-container">
          <div className="container">
            <div style={{marginBottom: '50px'}} className="header-2-purple">Recent Blog Posts</div>
            <div className="learnmore-resources-parts">
              <div className="d-flex flex-wrap blogposts-container">
                {
                  this.props.posts.map((post, index)=>{
                    let { title, author, date, slug, content, headerurl, wordcount, category } = post;
                    console.log(wordcount)
                    return(
                      <BlogTile
                        category={category}
                        key={index}
                        wordcount={wordcount}
                        title={title}
                        headerurl={headerurl}
                        onClick={this.goToPost.bind(this, slug)}
                      />
                    )
                  })
                }
                <Subscribe />
              </div>
            </div>
          </div>
        </div>
        <div id="faqs" className="learnmore-resources-container">
          <div className="container">
            <div className="d-flex justify-content-center" style={{marginBottom: '50px'}}>
              <span className="header-2-purple">Frequently Asked Questions</span>
            </div>
            {this.getFAQs()}
          </div>
        </div>
        <Footer
          background="purple"
          description="Streamline your event sponsorship sales"
          ctaLabel="Create An Event">
          <div className="d-flex flex-sm-row resources-ctas flex-column">
            <div className="d-flex align-items-center resources-create-cta"><Button label="CREATE EVENT" size="sm" type="primary" onClick={this.goToPath.bind(this, Routes.ORGANIZER_ONBOARDING_INTRO)}  /></div>
            <div className="d-flex align-items-center"><Button style={{marginRight: '15px'}} label="BOOK A DEMO" size="sm" type="tertiary" onClick={this.openCalendly.bind(this)}  /></div>
          </div>
        </Footer>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    posts: state.blog.posts,
  };
};

const mapDispatchToProps = dispatcher => (
    bindActionCreators({
      getBlogPosts,
    }, dispatcher)
);

export default connect(mapStateToProps, mapDispatchToProps)(Resources);

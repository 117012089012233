import React, { Component } from 'react';
import { getBlogPosts } from 'services/blog/blog.actions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import './blog.css';

class Blog extends Component {
  componentDidMount() {
    this.props.getBlogPosts()
  }

  goToPost(slug) {
    this.props.history.push(`blog/${slug}`)
  }

  render(){
    return(
      <div>
        {
          this.props.posts.map((post)=>{
            let { title, author, date, slug } = post;
            return(
              <div className="blog-tile" onClick={this.goToPost.bind(this, slug)}>
                <div>{title}</div>
                <div>{author}</div>
                <div>{date}</div>
              </div>
            )
          })
        }
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    posts: state.blog.posts
  };
};

const mapDispatchToProps = dispatcher => (
    bindActionCreators({
      getBlogPosts
    }, dispatcher)
);

export default connect(mapStateToProps, mapDispatchToProps)(Blog);
/*
<meta data-rh="true" charset="utf-8"><meta data-rh="true" name="viewport" content="width=device-width,minimum-scale=1,initial-scale=1">    <meta data-rh="true" name="theme-color" content="#000000">
   <meta data-rh="true" name="twitter:app:name:iphone" content="Medium">
   <meta data-rh="true" name="twitter:app:id:iphone" content="828256236">
   <meta data-rh="true" property="al:ios:app_name" content="Medium">
   <meta data-rh="true" property="al:ios:app_store_id" content="828256236">
   <meta data-rh="true" property="al:android:package" content="com.medium.reader">
   <meta data-rh="true" property="fb:app_id" content="542599432471018">
   <meta data-rh="true" property="og:site_name" content="Medium">
   <meta data-rh="true" property="og:type" content="article">
   <meta data-rh="true" property="article:published_time" content="2020-01-13T15:41:27.851Z">
   <meta data-rh="true" name="title" content="How to create sponsorship packages sponsors will love">
   <meta data-rh="true" property="og:title" content="How to create sponsorship packages sponsors will love">
   <meta data-rh="true" property="twitter:title" content="How to create sponsorship packages sponsors will love">
   <meta data-rh="true" name="twitter:site" content="@restagehq">
   <meta data-rh="true" name="twitter:app:url:iphone" content="medium://p/442b34d5986b">
   <meta data-rh="true" property="al:android:url" content="medium://p/442b34d5986b">
   <meta data-rh="true" property="al:ios:url" content="medium://p/442b34d5986b">
   <meta data-rh="true" property="al:android:app_name" content="Medium">
   <meta data-rh="true" name="description" content="Involve sponsors in creating sponsorship packages they want. Ask sponsors these three questions to better incorporate their needs and objectives.">
   <meta data-rh="true" property="og:description" content="Involve sponsors in creating sponsorship packages they want. Ask sponsors these three questions to better incorporate their objectives."><meta data-rh="true" property="twitter:description" content="Involve sponsors in creating sponsorship packages they want. Ask sponsors these three questions to better incorporate their objectives.">
   <meta data-rh="true" property="og:url" content="https://medium.com/restage/how-to-create-sponsorship-packages-sponsors-will-love-442b34d5986b">
   <meta data-rh="true" property="al:web:url" content="https://medium.com/restage/how-to-create-sponsorship-packages-sponsors-will-love-442b34d5986b">
   <meta data-rh="true" property="og:image" content="https://miro.medium.com/max/1200/1*-Cgfb60xJwTZy7f2FFmPLw.png"><meta data-rh="true" name="twitter:image:src" content="https://miro.medium.com/max/1200/1*-Cgfb60xJwTZy7f2FFmPLw.png">
   <meta data-rh="true" name="twitter:card" content="summary_large_image">
   <meta data-rh="true" property="article:author" content="https://medium.com/@alejandro_restage">
   <meta data-rh="true" name="author" content="Alejandro Luperon">
   <meta data-rh="true" name="robots" content="index,follow">
   <meta data-rh="true" name="referrer" content="unsafe-url">
   <meta data-rh="true" name="twitter:label1" value="Reading time">
   <meta data-rh="true" name="twitter:data1" value="5 min read">
   <meta data-rh="true" name="parsely-post-id" content="442b34d5986b">*/

import React, { Component } from 'react';
import TextField from 'components/TextField';
import Detail from 'common/Detail';
import Image from 'components/Image';
import Button from 'components/new/Button';
import Divider from 'common/Divider.component';
import Modal from 'components/Modal';
import Mailbox from 'assets/mailbox.svg';
import Team from 'assets/team.svg';
import Select from 'components/Select';
import { notification } from 'antd';
import { createUser } from 'services/signup/signup.actions';
import { resend } from 'services/verify/verify.actions';
import { isFilled, isEmailValid, isPasswordValid } from 'common/helpers/validators';
import { Routes } from 'constants/Routes';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import './createaccount.css';

const ROLES = [
  {value: "Event Organizer", label: "Event Organizer"},
  {value: "Sponsor", label: "Sponsor"}
]

class CreateAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalVisible: false,
      firstname: {
        value: "",
        error: false
      },
      lastname: {
        value: "",
        error: false
      },
      email: {
        value: "",
        error: false
      },
      password: {
        value: "",
        error: false
      },
      company: {
        value: "",
        error: false
      },
      role: {
        value: "",
        error: false
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.success !== prevProps.success) {
      this.setModalVisible(true);
      return;
    }

    if ((this.props.resentToken !== prevProps.resentToken) && this.props.resendsuccess && this.props.success) {
      notification.success({
        message:  `Another verification e-mail has been sent to ${this.props.email}.`,
      });
    }
  }

  goToPath(path) {
    this.props.history.push(path)
  }

  validate() {
    let fields = new Set();

    //TODO: State can probably be updated all at once with these objects
    //Maybe isFieldValue could return new state?
    fields.add(this.isFieldValid(isFilled, "firstname"));
    fields.add(this.isFieldValid(isFilled, "lastname"));
    fields.add(this.isFieldValid(isEmailValid, "email"));
    fields.add(this.isFieldValid(isFilled, "company"));
    fields.add(this.isFieldValid(isFilled, "role"));
    fields.add(this.isFieldValid(isPasswordValid, "password"));

    /*if (this.state.url.value.length > 0) {
      fields.add(this.isFieldValid(isURL, "url"));
    } else {
      this.setState({
        url: {
          value: "",
          error: false
        }
      });
    }*/

    return !fields.has(false);
  }

  closeModal() {
    this.props.history.push(Routes.LOG_IN);
    this.setState({modalVisible: false});
  }

  isFieldValid(validator, key) {
    let isValid = validator(this.state[key].value);

    this.setState({
      [key]: {
        value: this.state[key].value,
        error: !isValid
      }
    });

    return isValid;
  }

  onChange(event) {
    this.setState({ [event.target.name]: {
      value: event.target.value,
      error: this.state[event.target.name].error
      }
    })
  }

  onClickResendEmail() {
    this.props.resend(this.props.token, this.props.email);
  }

  onChangeRole(value) {
    console.log(value);
    this.setState({ "role": {
      value,
      error: false
      }
    })
  }

  onClickCreate() {
    let {
      firstname,
      lastname,
      email,
      password,
      company,
      role
    } = this.state;

    if (!this.validate()) return;

    this.props.createUser({
      firstName: firstname.value,
      lastName: lastname.value,
      email: email.value,
      password: password.value,
      organizationName: company.value,
      role: role.value
      //organizationUrl: this.state.url.value
    });
  }

  setModalVisible(visible) {
    this.setState({
      modalVisible: visible
    })
  }

  render() {
    let {firstname,
      lastname,
      email,
      company,
      password,
      role,
      modalVisible
    } = this.state;

    return (
      <div className="create-wrapper container">
        <div className="create-form d-flex flex-md-row flex-column-reverse">
          <div className="create-container col-md-6 col-12">
            <Detail justification="start" style={{textAlign: 'Start', marginBottom: '25px'}} header="Create an account"/>
            <Divider  style={{marginBottom: '25px'}}/>
            <TextField
              fontStyle="tertiary"
              name="firstname"
              value={firstname.value}
              placeholder="Enter your first name"
              errorMessage="First name cannot be empty."
              error={firstname.error}
              onChange={this.onChange.bind(this)}
              label="First Name"
              size="full" />
            <TextField
              fontStyle="tertiary"
              name="lastname"
              error={lastname.error}
              errorMessage="Last name cannot be empty."
              placeholder="Enter your last name"
              value={lastname.value}
              onChange={this.onChange.bind(this)}
              label="Last Name"
              size="full" />
            <TextField
              fontStyle="tertiary"
              name="email"
              error={email.error}
              errorMessage="Please enter a valid e-mail."
              value={email.value}
              placeholder="Enter your work e-mail address"
              onChange={this.onChange.bind(this)}
              label="Work Email Address"
              size="full" />
            <TextField
              fontStyle="tertiary"
              name="password"
              type="password"
              error={password.error}
              errorMessage="Password must be at least 6 characters."
              placeholder="Enter your password"
              value={password.value}
              onChange={this.onChange.bind(this)}
              label="Password"
              size="full" />
            <TextField
              fontStyle="tertiary"
              name="company"
              error={company.error}
              errorMessage="Company name cannot be empty."
              placeholder="Enter your company name"
              value={company.value}
              onChange={this.onChange.bind(this)}
              label="Company Name"
              size="full" />
            {/*<TextField
              fontStyle="tertiary"
              name="url"
              error={url.error}
              optional={true}
              errorMessage="Please enter a valid url."
              placeholder="Enter your company url"
              value={url.value}
              onChange={this.onChange.bind(this)}
              label="Company Website"
              size="full" />*/}

            <Select
              options={ROLES}
              name="role"
              error={role.error}
              errorMessage="Please select a role"
              placeholder="Select a role"
              value={role.value}
              onChange={this.onChangeRole.bind(this)}
              label="Role"
              size="full" />
              <div>
                <Button
                  type="tertiary"
                  style={{float: 'right'}}
                  size="sm"
                  onClick={this.onClickCreate.bind(this)}
                  label='CREATE'/>
              </div>
            {this.props.error ? <div className="d-flex justify-content-start" style={{width: '100%', position: "relative", paddingBottom: '25px', paddingTop: '25px'}}><span style={{textAlign: 'center', position: "absolute", color: 'red'}}>{this.props.errorMessage}</span></div> : null}
            </div>
            <div className="col-md-6 col-12 create-content create-image" >
              <div className="d-flex flex-column justify-content-center align-items-center" style={{ height: '100%', width: '100%'}}>
                <img alt="" src={Team} style={{width: '100%'}}/>
              </div>
            </div>
          </div>
          <Modal
            onClickClose={this.closeModal.bind(this)}
            visible={modalVisible}>
            <div className="d-flex align-items-center flex-column" style={{padding: '24px', backgroundColor: 'white'}}>
              <Image className="create-confirmation-image" src={Mailbox} />
              <Detail style={{marginBottom: '50px'}}header="Verify your email to start using Restage.">
                Check your email to get started. Click <span onClick={this.onClickResendEmail.bind(this)} className="body-copy-tertiary-link">here</span> if you need to send the email again.
               </Detail>
              <Button size="lg" label="LOG IN" type="tertiary" onClick={this.closeModal.bind(this)}/>
            </div>
          </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    errorMessage: state.signUp.errorMessage,
    error: state.signUp.error,
    success: state.signUp.success,
    token: state.signUp.token,
    email: state.signUp.email,
    resendsuccess: state.verify.resendsuccess,
    resentToken: state.verify.resentToken
  };
};

const mapDispatchToProps = dispatcher => (
    bindActionCreators({
      createUser,
      resend
    }, dispatcher)
);

export default connect(mapStateToProps, mapDispatchToProps)(CreateAccount);

import { put, all, takeLatest } from 'redux-saga/effects';
import { ActionTypes } from './auth.actions';
import { ActionTypes as UserActionTypes} from 'services/users/users.actions';
import firebase from '../../firebase/Firebase';

export const userLogin = function* (action) {
  const { payload: { email, password } } = action;
  try {

    const response = yield firebase.auth.signInWithEmailAndPassword(email, password);

    if (!response.user.emailVerified) {
      let response = { message: "E-mail is not verified" };
      throw response;
    } else {

      yield all([
        put({ type: ActionTypes.USER_LOGIN_SUCCESS, data: response.user }),
        put({ type: UserActionTypes.GET_USER })
      ])
    }
  } catch (error) {
    yield firebase.signOut();

    if (error.code) {
      error.message = 'Incorrect e-mail/password combination.'
    }
    // dispatch a failure action to the store with the error
    yield put({ type: ActionTypes.USER_LOGIN_FAILURE, error: true, errorMessage: error.message });
  }
}

export const userLogout = function* (action) {
  try {
    yield firebase.signOut();
    firebase.setIsAuthenticated(false);
    yield put({ type: ActionTypes.USER_LOGOUT_SUCCESS});
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: ActionTypes.USER_LOGOUT_FAILURE, errorMessage: error.message });
  }
}

function* auth() {
  yield all([
    takeLatest(ActionTypes.USER_LOGIN, userLogin),
    takeLatest(ActionTypes.USER_LOGOUT, userLogout)
  ]);
}

export default auth;

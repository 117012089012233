import React, { Component } from 'react';
import TextField from 'components/TextField';
import Button from 'components/new/Button';
import Wrapper from 'components/Wrapper';
import { Routes } from 'constants/Routes';
import auth from 'auth/Auth';
import { userLogin } from 'services/auth/auth.actions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { isEmailValid , isPasswordValid} from 'common/helpers/validators';
import './login.css';

const {
  ORGANIZER_HOME,
  SPONSOR_HOME
} = Routes;

class LogIn extends Component {
    constructor(props) {
      super(props);
      this.state = {
        email: {
          value: "",
          error: false
        },
        password: {
          value: "",
          error: false
        }
      }
    }

    validate() {
      let fields = new Set();
      fields.add(this.isFieldValid(isEmailValid, "email"));
      fields.add(this.isFieldValid(isPasswordValid, "password"));
      return !fields.has(false);
    }

    isFieldValid(validator, key) {
      let isValid = validator(this.state[key].value);

      this.setState({
        [key]: {
          value: this.state[key].value,
          error: !isValid
        }
      });

      return isValid;
    }

    componentWillMount() {
      if (this.props.user) {
        this.props.history.push(ORGANIZER_HOME);
      }
    }

    componentDidUpdate() {
      let { user, success, history } = this.props;
      let route = ORGANIZER_HOME
      if (success) {
        //if (user.user_type_id == 3) route = SPONSOR_HOME;
        history.push(route);
      }
    }

    onSubmit(event) {
      event.preventDefault();
      let isValid = this.validate();
      if (!isValid) return;

      const { email, password } = this.state;
      this.props.userLogin(email.value, password.value)
    }

    onChange(event) {
      this.setState({ [event.target.name]: {
        value: event.target.value,
        error: this.state[event.target.name].error
        }
      })
    }

    render() {
      const { email, password } = this.state;

      return (
        <Wrapper>
          <form onSubmit={this.onSubmit.bind(this)} style={{height: '100%'}} className="container d-flex align-items-center justify-content-center flex-column">
            <div style={{marginBottom: '65px'}} className="header-2-brand">Log In</div>
            <div className="login-container d-flex align-items-center justify-content-center flex-column">
              <TextField
                name="email"
                placeholder='E-mail'
                errorMessage="Please enter your e-mail."
                value={email.value}
                error={email.error}
                onChange={this.onChange.bind(this)} />
              <TextField
                placeholder='Password'
                name="password"
                type="password"
                errorMessage="Password must be at least 6 characters."
                value={password.value}
                error={password.error}
                onChange={this.onChange.bind(this)} />
              <Button buttonType="submit" type="tertiary" label="Log In" size="lg" onClick={()=>{}}/>
              <div className="body-copy-tertiary">Don't have an account? <a href={Routes.ONBOARDING_CREATE_ACCOUNT} className="body-copy-tertiary-link">Create an account</a> to get started.</div>
              {/*<a href="/forgot-password" className="body-copy-tertiary-link">Forgot your password?</a>*/}
              {this.props.error ? <div className="body-copy-error-wrapper d-flex justify-content-center"><span className="body-copy-error-small body-cooy-error-container">{this.props.errorMessage}</span></div> : null}
            </div>
          </form>
        </Wrapper>
      );
    }
}

const mapStateToProps = (state) => ({
    errorMessage: state.auth.errorMessage,
    error: state.auth.error,
    user: state.users.currentUser,
    success: state.auth.success
  });

const mapDispatchToProps = dispatcher => (
    bindActionCreators({
        userLogin
    }, dispatcher)
);

export default connect(mapStateToProps, mapDispatchToProps)(LogIn);

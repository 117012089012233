import { ActionTypes } from './eventsponsorshipoffer.actions';

const initialState = {
    errorMessage: "",
};

const EventSponsorshipOfferReducer = (state = initialState, action) => {
    switch (action.type) {
      case ActionTypes.POST_EVENT_SPONSORSHIP_OFFER_SUCCESS:
        return { ...state};
      default:
          return state;
    }
};

export default EventSponsorshipOfferReducer;

import React, { Component } from 'react';
import Button from 'components/new/Button';
import ButtonGroup from 'components/ButtonGroup';
import Modal from 'components/Modal';
import Divider from 'common/Divider.component';
import TextField from 'components/TextField';
import Eyebrow from 'components/Eyebrow';
import RadioGroup from 'components/RadioGroup';
import Checkbox from 'components/Checkbox';
import Form from 'components/Form';
import Document from 'assets/icons/document@3x.png';
import User from 'assets/user@3x.png';
import Placement from '../components/placement.component';
import Tab from 'common/Tab';
import TabGroup from 'common/TabGroup';
import AutocompleteEntryGroup from 'common/AutocompleteEntryGroup';
import CheckboxWrapper from 'components/CheckboxWrapper';
import { searchPlacements, setPlacements } from 'services/placements/placements.actions';

import { generateCurrencyFormat } from 'common/helpers/currency';
import { addToCart, removeFromCart, addPackageToCart, removePackageFromCart } from 'services/cart/cart.actions';
import { postEventSponsorshipOffer } from 'services/eventsponsorshipoffer/eventsponsorshipoffer.actions';
import { Routes } from 'constants/Routes';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import './footer.css';


import { postEventPackageOffer } from 'services/eventpackageoffer/eventpackageoffer.actions';
/** NEED COPY TO COMMPUNCATE CUSTOMIZATION*/
class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      placement: {
        value: null,
        error: false
      },
      message: {
        value: "",
        error: false
      },
      placements: [],
      modalVisible: false,
      selected: null,
      packages: {},
      tabs: {
        packages: true,
        placements: false
      }
    }
  }

  onChange(event) {
    console.log(event)
    this.setState({ [event.target.name]: {
      value: event.target.value,
      error: this.state[event.target.name].error
      }
    })
  }

  onClickAddPlacement() {
    let { placement, placements } = this.state;
    placements.push(placement.value);

    this.setState({
      placements,
      placement: {
        value: null,
        error: false
      }
    }, () => {
      this.props.setPlacements([]);
    });
  }

  /***********/
  onChangeAutocomplete(search) {
    this.setState({
      placement: {
        value: search,
        error: false
      }
    });
    this.props.searchPlacements(search);
  }

  onMouseDownAutocompleteOption(value) {
    let placement = value;
    this.setState({
      placement: {
        value,
        error: false,
      }
    }, ()=>{
      this.props.setPlacements([]);
      this.onClickAddPlacement();
    });

  }

  onBlurAutocomplete() {
    this.props.setPlacements([]);
  }
  /***********/

  setSelectedPackage() {
    let results = this.props.placements.map((result)=>{
      if (result.placement !== null) {
        return result.placement;
      }
    });

    let { placement, message } = this.state;

    if (this.state.selected) {
      return (
        <div>
          <div className="body-copy-tertiary" style={{textAlign: 'start',marginTop: '15px', marginBottom: '15px'}}>{this.state.selected.name} <b>({generateCurrencyFormat(this.state.selected.cost)})</b></div>
          {this.setCustomizedPackageUI()}
          <div style={{marginTop: '15px'}}>
            <AutocompleteEntryGroup
              justification="vertical"
              fontStyle="tertiary"
              label="Add additional Desired Placement"
              placeholder="Enter placement"
              name="placement"
              value={placement.value}
              errorMessage="Placement cannot be an empty string."
              onClickAddEntry={this.onClickAddPlacement.bind(this)}
              onChange={this.onChangeAutocomplete.bind(this)}
              results={results}
              onMouseDownOption={this.onMouseDownAutocompleteOption.bind(this)}
              onBlur={this.onBlurAutocomplete.bind(this)}
              size="full"/>
            </div>
            <div style={{marginTop: '15px', marginBottom: '15px'}}>
              <TextField
                fontStyle="tertiary"
                name="message"
                value={message.value}
                errorMessage="Description cannot be empty."
                isTextArea={true}
                onChange={this.onChange.bind(this)}
                label="Leave a message for the event organizer"
                label="Leave a message for the event organizer"
                size="full" />
            </div>
        </div>

      )
    } else {
      return (
        <div style={{textAlign: 'start', marginTop: '15px'}} className="body-copy-tertiary">No package selected</div>
      )
    }
  }

  setSelectedPlacements() {
    if (this.props.items.length === 0) {
      return (
        <div style={{textAlign: 'start', marginTop: '15px'}} className="body-copy-tertiary">No placements selected</div>
      )
    } else {
      let totalItems = 0;
      let totalCost = 0;
      return (
        <div className='footer-modal-selected-placements'>
          {
            this.props.items.map((item, index) => {
              totalItems++;
              totalCost = totalCost + item.value;
              return (
                <div key={index} className="d-flex flex-row justify-content-between">
                  <div className="footer-modal-selected-placement col-6 body-copy-tertiary" style={{textAlign: 'start'}}>{item.type}</div>
                  <div className="footer-modal-selected-placement col-6 body-copy-tertiary d-flex justify-content-end">{generateCurrencyFormat(item.value)}</div>
                </div>
              )
            })
          }
          {/*<Divider />
          <div className="d-flex flex-row justify-content-between">
            <div className="body-copy-tertiary">Total items: {totalItems}</div>
            <div className="body-copy-tertiary-small">Total: {new Intl.NumberFormat('us-US', { style: 'currency', currency: 'USD' }).format(totalCost)}</div>
          </div>*/}
          <div style={{marginTop: '15px', marginBottom: '15px'}}>
            <TextField
              fontStyle="tertiary"
              name="message"
              isTextArea={true}
              onChange={this.onChange.bind(this)}
              label="Leave a message for the event organizer"
              size="full" />
          </div>
        </div>
      )
    }
  }

  addSidePanel() {
    return (
      <div className="footer-modal-side-panel">
        <div>
          <Eyebrow label="Packages" />
          <div style={{marginTop: '15px'}}>{this.setSelectedPackage()}</div>
        </div>
        {/*<div style={{marginTop: '45px'}}>
          <Eyebrow label="Placements" />
          <div style={{marginTop: '15px'}}>{this.setSelectedPlacements()}</div>
        </div>*/}
      </div>
    )
  }


  setModalVisible(visible) {
    this.setState({
      modalVisible: visible
    })
  }

  goToCart() {
    this.setModalVisible(true);
  }

  activate(current) {
    let { tabs } = this.state;

    let update = {};

    for (let tab in tabs) {
      if (current === tab) {
        update[tab] = true;
      } else {
        update[tab] = false;
      }
    }

    this.setState({
      tabs: {...update}
    });
  }

  setPackagesUI() {
    return  (
      <div style={{width: '100%', height: '100%'}}>
        <Form split="48" wrapperClassName="checkout-modal-form-wrapper" sidePanelClassName="checkout-modal-footer-sidepanel" addSidePanel={this.addSidePanel.bind(this)} className="footer-purchase">
          <div>
            {this.setCartUI()}
          </div>
        </Form>
      </div>
    );
  }

  onClickToggleCartItem(placement) {
    let filteredArray = this.props.items.filter((item) => item.type === placement.type);

    if (filteredArray.length === 0) {
      this.props.addToCart(placement);
    } else {
      this.props.removeFromCart(placement);
    }
  }

  setCartItemChecked(placement) {
    let filteredArray = this.props.items.filter((item) => item.type === placement.type);
    if (filteredArray.length > 0) {
      return true;
    }
    return false;
  }

  setPlacementsUI() {
    let eventplacements = [
      {"id": 1, "type": "Publicity in your online marketing", "amount": 10, "remaining": 4, "value": 300},
      {"id": 2, "type": "Logo placement on lanyard", "amount": 1, "remaining": 4, "value": 300},
      {"id": 3, "type": "Logo placement on flyer", "amount": 10, "remaining": 4, "value": 300},
      {"id": 4, "type": "Logo placement on event website", "amount": 5, "remaining": 4, "value": 300}
    ];

    return  (
      <div className="footer-placements">
        {
          eventplacements.map((placement, index) => {
            return <Placement added={this.setCartItemChecked(placement)} onClickToggleCart={this.onClickToggleCartItem.bind(this, placement)} key={index} type={placement.type} remaining={placement.remaining} value={placement.value}/>
          })
        }
      </div>
    );
  }

  //postEventSponsorshipOffer() {
  postEventPackageOffer() {
    if (this.props.package === null) {
      return;
    }
    /*let eventSponsorshipOffer = {
      portfolio_id: 1,
      event_id: 1,
      message: "Hi world",
      packages: this.props.items
    }*/
    let eventPackageOffer = {
      //portfolio_id: 1,
      user_id: this.props.user.uid,
      event_id: this.props.event.id,
      message: "Hi world",
      package_id: this.props.package.id,
      package: this.props.package
    }
    this.props.postEventPackageOffer(eventPackageOffer)
    //this.props.postEventSponsorshipOffer(eventSponsorshipOffer);


    this.props.history.push(Routes.EVENT_CHECKOUT_ACTIVATION);
  }

  setSelected(id) {

    if (this.state.selected) {
      this.props.removePackageFromCart(this.state.selected);
    }

    let selected = this.props.event.packages.filter((item)=>{
      console.log(item);
      if(id === item.id) {
        return item;
      }
    })

    this.setState({
      selected: selected[0]
    });

    this.props.addPackageToCart(selected[0]);
    console.log(this.props.items);
  }

  setPlacementChecked(placement) {
    let filteredArray = this.state.placements.filter((item) => placement.id === item.id);
    if (filteredArray.length > 0) {
      return true;
    }
    return false;
  }

  onClickPlacement(placement) {



    let newPlacements = [];
    let filteredArray = this.state.placements.filter((item) => item.id === placement.id);

    if (filteredArray.length === 0) {
      newPlacements = [...this.state.placements, placement]
    } else {
      newPlacements = this.state.placements.filter((item) => !(item.id === placement.id));
    }
    this.setState({
      placements: newPlacements
    })
  }

  setCustomizedPackageUI() {

    if (!this.state.selected) return (null);

    return (
      <div>
        <CheckboxWrapper>
          {
            this.state.selected.placements.map((placement, index)=>{
              return (<Checkbox className="col-12" key={index} checked={this.setPlacementChecked(placement)} label={placement.placement} onClick={this.onClickPlacement.bind(this, placement)} value={placement} />)
            })
          }
          {/*
            this.state.placements.map((placement, index)=>{
              return (<Checkbox className="col-12" key={index} checked={this.setPlacementChecked(placement)} label={placement.placement} onClick={this.onClickPlacement.bind(this, placement)} value={placement} />)
            })
          */}
        </CheckboxWrapper>
      </div>
    )
  }

  setCartUI() {
    if (!this.props.event) {
      return null;
    }
    let { packages } = this.props.event;

    let available = {};

    return (
      <div>
        <div style={{marginBottom: '25px'}}>
          <div style={{marginBottom: '10px'}} className="header-2-tertiary">Available Packages</div>
          <div className="body-copy-tertiary">Select a package to begin customizing.</div>
        </div>
        <Divider type="brand secondary"/>
        <div>
          {
             packages.map((item)=>{
              available[item.id] = item;
              let placements = item.placements.map((placement)=>placement.placement).join(", ")

              /*return ({
                id: item.id,
                type: `${item.name} (${generateCurrencyFormat(item.cost)})`,
                description: `Placements: ${placements}`
              })*/
              return (
                <div>
                  <div style={{paddingTop: '20px', paddingBottom: '20px'}} className="d-flex justify-content-between">
                    <div>
                      <div style={{marginBottom: '10px'}} className="body-copy-bold-tertiary">{item.name}</div>
                      <div className="body-copy-tertiary">{generateCurrencyFormat(item.cost)}</div>
                    </div>
                    <Button label="CHOOSE" type="tertiary" size="xs" onClick={this.setSelected.bind(this, item.id)} />
                  </div>
                  <Divider type="brand secondary"/>
                </div>
              )
            })
          }
        </div>
      </div>
    )
  }


  render() {
    return (
      <div className="cart-footer-container">
        <div className="container d-flex justify-content-end align-items-center cart-footer">
          <Button label="SPONSOR" type="primary" onClick={this.goToCart.bind(this)} />
        </div>
        <Modal
          appendHeader={
            ()=>{
              return (
                <TabGroup>
                  <Tab alt="Packages" isCompleteVisible={false} icon={Document} label="Packages" selected={this.state.tabs["packages"]} onClick={this.activate.bind(this, "packages")}/>
                  <Tab alt="Placements" isCompleteVisible={false} icon={User} label="Placements" selected={this.state.tabs["placements"]} onClick={this.activate.bind(this, "placements")}/>
                </TabGroup>
              )
            }
          }
          appendFooter={
            ()=>{
              return (
                <div className="col-12 d-flex justify-content-end">
                  {/*<Button disabled={(this.state.selected ? false: true)} label="Purchase" type="primary" onClick={this.postEventSponsorshipOffer.bind(this)} />*/}
                  <Button disabled={(this.state.selected ? false: true)} label="PURCHASE" type="primary" onClick={this.postEventPackageOffer.bind(this)} />

                </div>
              )
              /*return (
                <Button label="Purchase" type="primary" onClick={(() => {})} />
              )*/
            }
          }
          onClickClose={this.setModalVisible.bind(this, false)}
          visible={this.state.modalVisible}>
            {this.setPackagesUI()}
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    event: state.events.currentEvent,
    items: state.cart.items,
    package: state.cart.package,
    user: state.users.currentUser,
    placements: state.placements.placements,
  };
};


const mapDispatchToProps = dispatcher => (
    bindActionCreators({
      addToCart,
      removeFromCart,
      addPackageToCart,
      removePackageFromCart,
      postEventSponsorshipOffer,
      postEventPackageOffer,
      searchPlacements,
      setPlacements
    }, dispatcher)
);


export default connect(mapStateToProps, mapDispatchToProps)(Footer);

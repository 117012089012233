import React, { Component } from 'react';
import Disc from 'assets/icons/Disc.png';

import './listitem.css';

class ListItem extends Component {
  render() {
    let { item, expand, itemClassName } = this.props;

    if (itemClassName.length === 0) {
      itemClassName = "body-copy-tertiary-small";
    }

    let className = ["d-flex", "flex-row", "col-12", "listitem", "align-items-start"]

    console.log(itemClassName);

    if (!expand) {
      className.push("col-sm-6");
    }



    return (
      <div className={className.join(" ")}>
        <div><img alt="Bullet in bulleted list." src={Disc} /></div>
        <div className={`${itemClassName} d-flex align-items-center`}>{item}</div>
      </div>
    );
  }
}

export default ListItem;

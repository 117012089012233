import React, { Component } from 'react';
import Detail from './components/Detail';
import './Services.css';
import vine from './assets/vine.png';
import dots from './assets/dots.png';

class Services extends Component {
  render() {
    return (
      <div className="services-container">
        <div className="services-wrapper d-flex align-items-center flex-column">
        <div className="services-header">Here's How It Works</div>
          <div className="services-description d-flex flex-md-row flex-column">
            <div className="services-left services-details d-flex flex-column justify-content-sm-around">
              <Detail detail="Create Sponsorship Opportunities"
                justification="end"
                description="Add details to launch event quickly" />
              <Detail detail="Target Custom Audiences"
               justification="end"
                description="Set KPIs for profitability, retention, attendee experience, and more"/>
            </div>
            <div className="services-vine-container d-flex flex-row justify-content-center">
              <img className="services-vine" src={vine} />
            </div>
            <div className="services-right services-bottom services-details d-flex flex-column justify-content-sm-around">
              <Detail detail="Connect with Partners"
              justification="start"
                description="Make venue space, food, and giveaways available for sponsorship." />
              <Detail detail="Sell Sponsorships Online"
              justification="start"
                description="Connect with others to share experience and collaborate "
                />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Services;

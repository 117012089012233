import React, { Component } from 'react';
import './Form.css';
import { connect } from 'react-redux';

class Form extends Component {
  static defaultProps = {
    isReverse: false,
    target: '',
    sidePanelClassName: "",
    wrapperClassName: "",
    style: {},
    split: "84"
  }
  getSidePanel() {
    let { addSidePanel, className, sidePanelClassName, split } = this.props;
    if (!addSidePanel) return;
    let classNames;

    if (split === "66") {
      classNames = ["restage-side-panel", "col-12", "col-md-6", "col-lg-6"];
    } else if (split === "48") {
      classNames = ["restage-side-panel", "col-12", "col-md-8", "col-lg-8"];
    }
    else {
      classNames = ["restage-side-panel", "col-12", "col-md-4", "col-lg-4"];
    }

    classNames = (className !== null ? classNames.concat(className) : classNames);
    classNames = (sidePanelClassName !== null ? classNames.concat(sidePanelClassName) : classNames);
    return (
      <div className={classNames.join(" ")}>
        {addSidePanel()}
      </div>
    )
  }

  render() {
    let { children, className, isReverse, split} = this.props;
    let innerClassName;


    if (split === "66") {
      innerClassName = ["restage-form", "col-md-6", "col-12", "col-lg-6"];
    } else if (split === '48'){
      innerClassName = ["restage-form", "col-md-4", "col-12", "col-lg-4"];;
    } else {
      innerClassName = ["restage-form", "col-md-8", "col-12", "col-lg-8"];;
    }

    let containerClassName = ["d-flex", "flex-column-reverse"];
    containerClassName.push(this.props.wrapperClassName);

    if (isReverse) {
      containerClassName.push("flex-md-row-reverse");
    } else {
      containerClassName.push("flex-md-row");
    }

    innerClassName = (className !== null ? innerClassName.concat(className) : innerClassName);

    return (
      <div style={this.props.style} className={containerClassName.join(" ")}>
        <div className={innerClassName.join(" ")}>
          {children}
        </div>
        {this.getSidePanel()}
      </div>
    );
  }

}

export default Form;

export const ActionTypes = {
  GET_EVENTS: 'GET_EVENTS',
  GET_EVENTS_SUCCESS: 'GET_EVENTS_SUCCESS',
  GET_EVENTS_FAILURE: 'GET_EVENTS_FAILURE',
  PUT_EVENT: 'PUT_EVENT',
  PUT_EVENT_SUCCESS: 'PUT_EVENT_SUCCESS',
  PUT_EVENT_FAILURE: 'PUT_EVENT_FAILURE',
  SEARCH_EVENTS: 'SEARCH_EVENTS',
  SEARCH_EVENTS_SUCCESS: 'SEARCH_EVENTS_SUCCESS',
  SEARCH_EVENTS_FAILURE: 'SEARCH_EVENTS_FAILURE',
  SEARCH_EVENTS_V: 'SEARCH_EVENTS_V',
  SEARCH_EVENTS_V_SUCCESS: 'SEARCH_EVENTS_V_SUCCESS',
  SEARCH_EVENTS_V_FAILURE: 'SEARCH_EVENTS_V_FAILURE',
  POST_EVENTS: 'POST_EVENTS',
  POST_EVENTS_SUCCESS: 'POST_EVENTS_SUCCESS',
  POST_EVENTS_FAILURE: 'POST_EVENTS_FAILURE',
  SET_CURRENT_EVENT: 'SET_CURRENT_EVENT',
  GET_EVENT: 'GET_EVENT',
  GET_EVENT_SUCCESS: 'GET_EVENT_SUCCESS',
  GET_EVENT_FAILURE: 'GET_EVENT_FAILURE',
  GET_EVENT_WITH_TOKEN: 'GET_EVENT_WITH_TOKEN',
  GET_EVENT_WITH_TOKEN_SUCCESS: 'GET_EVENT_WITH_TOKEN_SUCCESS',
  GET_EVENT_WITH_TOKEN_FAILURE: 'GET_EVENT_WITH_TOKEN_FAILURE',
  PUBLISH_EVENT: 'PUBLISH_EVENT',
  PUBLISH_EVENT_SUCCESS: 'PUBLISH_EVENT_SUCCESS',
  PUBLISH_EVENT_FAILURE: 'PUBLISH_EVENT_FAILURE',
  POST_EVENT_DECISION: 'POST_EVENT_DECISION',
  POST_EVENT_DECISION_SUCCESS: 'POST_EVENT_DECISION_SUCCESS',
  POST_EVENT_DECISION_FAILURE: 'POST_EVENT_DECISION_FAILURE',
  SET_EVENT_DETAILS_PAGE_STATE: 'SET_EVENT_DETAILS_PAGE_STATE'
};

export const getEvent = (user_id, event_id) => ({
    type: ActionTypes.GET_EVENT,
    payload: {
      user_id,
      event_id
    }
});

export const getEventWithToken = (event_id, token) => ({
    type: ActionTypes.GET_EVENT_WITH_TOKEN,
    payload: {
      event_id,
      token
    }
});

export const getEvents = (user_id) => ({
    type: ActionTypes.GET_EVENTS,
    payload: {
      user_id
    }
});

export const searchEvents = (filters) => ({
    type: ActionTypes.SEARCH_EVENTS,
    payload: {
      filters
    }
});

export const postEvent = (data) => ({
    type: ActionTypes.POST_EVENTS,
    payload: {
      data
    }
});

export const postEventDecision = (event_id, decision) => ({
    type: ActionTypes.POST_EVENT_DECISION,
    payload: {
      event_id,
      decision
    }
});

export const putEvent = (event) => ({
    type: ActionTypes.PUT_EVENT,
    payload: {
      event
    }
});

export const publish = (id) => ({
  type: ActionTypes.PUBLISH_EVENT,
  payload: {
    id
  }
})


export const setCurrentEvent = (currentEvent) => ({
    type: ActionTypes.SET_CURRENT_EVENT,
    payload: {
      currentEvent
    }
});


export const setEventDetailsPage = (isValid) => ({
  type: ActionTypes.SET_EVENT_DETAILS_PAGE_STATE,
  payload: {
    isValid
  }
})

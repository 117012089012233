import React, { Component } from 'react';
import { Switch, BrowserRouter as Router, Route, withRouter } from "react-router-dom";
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import EventDashboard from '../screens/event/dashboard/eventdashboard.screen';
import ManageEvent from '../screens/event/dashboard/manage/manage.screen';
import ViewEvent from '../screens/event/dashboard/view/view.screen';
import EditEvent from '../screens/event/dashboard/edit/edit.screen';
import ProtectedRoute from 'common/ProtectedRoute.component';
import { Routes } from 'constants/Routes';

const routes = [
  { path: Routes.MANAGE_EVENT, Component: ManageEvent },
  { path: Routes.EDIT_EVENT, Component: EditEvent },
  { path: Routes.VIEW_EVENT, Component: ViewEvent },
]

class EventDashboardRoutes extends Component {
  render() {
    return (
        <Route path={Routes.EVENT_DASHBOARD} children={({match, location})=>{
          if (match !== null && !match.isExact) {
            return (
              <EventDashboard>
                <Switch>
                  {routes.map(({ path, Component }) => (
                    <ProtectedRoute key={path} path={path} component={Component}/>
                  ))}
                </Switch>
              </EventDashboard>
            );
          } else {
            return null;
          }
        }}
        />
      )
  }
}

export default withRouter(EventDashboardRoutes);

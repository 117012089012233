import React, { Component } from 'react';
import AudienceProfile from 'common/audienceprofile.component';
import Tab from 'common/Tab';
import TabGroup from 'common/TabGroup';
import Clipboard from 'assets/icons/clipboard@3x.png';
import CheckboxWrapper from 'components/CheckboxWrapper';
import Checkbox from 'components/Checkbox';
import Tag from 'assets/tag@3x.png';
import Document from 'assets/icons/document@3x.png';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import './sidepanel.css';

const tabs = [
  { label: "Audience", icon: Clipboard },
  { label: "Dates", icon: Tag },
  { label: "Category", icon: Document }
]

class SidePanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabs: {
        audience: true,
        dates: false,
        category: false
      }
    }
  }

  activate(current) {
    let { tabs } = this.state;

    let update = {};

    for (let tab in tabs) {
      if (current === tab) {
        update[tab] = true;
      } else {
        update[tab] = false;
      }
    }

    this.setState({
      tabs: {...update}
    });
  }

  onClickCategory(category) {

  }

  setCategoryChecked(category) {
    return false;
  }


  setPanelUI() {
    let { audience, category } = this.state.tabs;

    if (audience) {
      return (
        <AudienceProfile />
      )
    }

    if (category) {
      return this.setCategoryUI();
    }
  }

  setCategoryUI() {
    let categories = ["Auto, Boat & Air", "Business", "Charity & Causes", "Community", "Family & Education", "Fashion", "Film & Media", "Food & Drink", "Government", "Health", "Hobbies", "Holiday", "Home & Lifestyle", "Music", "Performing & Visual Arts", "School Activities", "Science & Tech", "Spirituality", "Sports & Fitness", "Travel & Outdoor", "Other"];

    return (
      <div>
        <label className="body-copy-tertiary">Categories (select all that apply):</label>
        <CheckboxWrapper>
          {
            categories.map((option, index) => {
              return (
                <Checkbox className="col-sm-6 col-12" key={index} checked={this.setCategoryChecked(option)} label={option} onClick={this.onClickCategory.bind(this, option)} value={option} />
              )
            })
          }
          </CheckboxWrapper>
      </div>
    );
  }

  render() {
    return (
      <div style={{width: '100%'}}>
        <div className="search-sidepanel-tabgroup-wrapper">
          <TabGroup>
            {
              tabs.map(({ label, icon }, index)=>{
                return (
                  <Tab
                    isCompleteVisible={false}
                    icon={icon}
                    key={index}
                    label={label}
                    selected={this.state.tabs[label.toLowerCase()]}
                    onClick={this.activate.bind(this, label.toLowerCase())} />
                  )
              })
            }
          </TabGroup>
        </div>
        <div className="search-sidepanel-audience-container">
          {this.setPanelUI()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {

  };
};

const mapDispatchToProps = dispatcher => (
    bindActionCreators({
      //attributes: state.audienceprofile.attributes,
    }, dispatcher)
);

export default connect(mapStateToProps, mapDispatchToProps)(SidePanel);

import React, { Component } from 'react';
import Pill from 'components/Pill';
import { generatePercentFormat } from 'common/helpers/percent';
import { generateNumberFormat } from 'common/helpers/number';
import './eventaudiencetab.css';

const KEYS = {
  "Age Range": "Age Ranges",
  "Location": "Locations",
  "Occupation": "Occupations",
  "Industry": "Industries",
  "Job Function": "Job Function",
  "Job Seniority": "Seniority Level"
}

class EventAudienceTab extends Component {
  setAudienceProfile(audienceAttributes) {
    let buffer = [];
    let items = [];
    let index = 0;
    let label = "";
    let map = new Map();

    for (let key in audienceAttributes) {
      let attributes = audienceAttributes[key];
      items = attributes.map((attribute, i) => <Pill key={i} isCloseVisible={false} label={attribute.value} />);
      map.set(key, items)
    }

    map.forEach((value, key) => {
      label = key;

      if (KEYS[key]) {
          label = KEYS[key];
      }

      buffer.push(
        <div key={index}>
          <div style={{marginBottom: '10px'}} className="body-copy-bold-tertiary">{label}</div>
          <div className="d-flex flex-wrap event-details-pill-wrapper">{value}</div>
        </div>
      )
      index++;
    })

    return (
      <div className="event-details-audience-attributes">
        <div style={{marginBottom: '25px'}} className="header-3-tertiary">Audience Profile</div>
        {buffer}
      </div>
    );
  }

  setEventGender() {
    if (this.props.eventGender === null) return null;

    let { male, female, non_binary} = this.props.eventGender;
    return (
      <div>
        <div style={{marginBottom: '25px'}} className="header-3-tertiary">Gender Breakdown</div>
        <div className="event-audience-tab-genders d-flex flex-lg-row flex-column justify-content-between">
          <div style={{paddingLeft: '0px', paddingRight: '0px'}}>
            <span style={{marginRight: '20px'}} className="body-copy-bold-tertiary">Male</span>
            <span className="body-copy-brand-secondary">{generatePercentFormat(male/100)}</span>
          </div>
          <div style={{paddingLeft: '0px', paddingRight: '0px'}}>
            <span style={{marginRight: '20px'}} className="body-copy-bold-tertiary">Female</span>
            <span className="body-copy-brand-secondary">{generatePercentFormat(female/100)}</span>
          </div>
          <div style={{paddingLeft: '0px', paddingRight: '0px'}}>
            <span style={{marginRight: '20px'}} className="body-copy-bold-tertiary">Non-binary</span>
            <span className="body-copy-brand-secondary">{generatePercentFormat(non_binary/100)}</span>
          </div>
        </div>
      </div>
    )
  }

  setEventAttendances() {
    let { eventAttendances } = this.props;
    if (eventAttendances === null || eventAttendances.length === 0) return null;

    let max = eventAttendances[0].attendance;

    for (let i = 1, len = eventAttendances.length; i < len; i++) {
      let v = eventAttendances[i].attendance;
      max = (v > max) ? v : max;
    }

    return (
      <div>
        <div style={{marginBottom: '25px'}} className="header-3-tertiary">Previous Years Attendance</div>
        <div style={{height: '300px'}} className="d-flex event-audience-tab-attendees">
          {
            this.props.eventAttendances.map((eventAttendance, index) => {
              let { year, attendance } = eventAttendance;
              let height = (100 * attendance/max);
              console.log("height", height)
              return (
                <div key={index} className="d-flex flex-column align-items-center justify-content-end">
                  <div style={{width: '36px', height: '80%', backgroundColor: '#F8A59A', height: `calc(${height}% - 48px)`}} />
                    <div className="d-flex flex-column align-items-center">
                    <div className="body-copy-bold-tertiary">{generateNumberFormat(attendance)}</div>
                    <div className="body-copy-brand-secondary">{year}</div>
                  </div>
                </div>
               )
            })
          }
        </div>
      </div>

    )
  }

  render() {
    return (
      <div className="event-details-panel event-audience-tab-details">
        <div>
          <div style={{marginBottom: '10px'}} className="body-copy-bold-tertiary">Expected Audience Size</div>
          <div className="body-copy-tertiary">{generateNumberFormat(this.props.expected_attendees)}</div>
        </div>
        {this.setEventAttendances()}
        {this.setAudienceProfile(this.props.audience_attributes)}
        {this.setEventGender()}
      </div>
    );
  }
}

export default EventAudienceTab;

export const ActionTypes = {
    VERIFY_EMAIL: 'VERIFY_EMAIL',
    VERIFY_EMAIL_SUCCESS: 'VERIFY_EMAIL_SUCCESS',
    VERIFY_EMAIL_FAILURE: 'VERIFY_EMAIL_FAILURE',
    RESEND_VERIFY_EMAIL: 'RESEND_VERIFY_EMAIL',
    RESEND_VERIFY_EMAIL_SUCCESS: 'RESEND_VERIFY_EMAIL_SUCCESS',
    RESEND_VERIFY_EMAIL_FAILURE: 'RESEND_VERIFY_EMAIL_FAILURE',
};

export const verifyEmailAndToken = (email, token) => ({
    type: ActionTypes.VERIFY_EMAIL,
    payload: {
      email,
      token
    }
});


export const resend = (token, email) => ({
    type: ActionTypes.RESEND_VERIFY_EMAIL,
    payload: {
      token,
      email
    }
});

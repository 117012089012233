export const ActionTypes = {
    ADD_PLACEMENT: 'ADD_PLACEMENT',
    ADD_PLACEMENT_SUCCESS: 'ADD_PLACEMENT_SUCCESS',
    REMOVE_PLACEMENT: 'REMOVE_PLACEMENT',
    REMOVE_PLACEMENT_SUCCESS: 'REMOVE_PLACEMENT_SUCCESS',
    POST_PORTFOLIO_DESIRED_PLACEMENTS: 'POST_PORTFOLIO_DESIRED_PLACEMENTS',
    POST_PORTFOLIO_DESIRED_PLACEMENTS_SUCCESS: 'POST_PORTFOLIO_DESIRED_PLACEMENTS_SUCCESS',
    POST_PORTFOLIO_DESIRED_PLACEMENTS_FAILURE: 'POST_PORTFOLIO_DESIRED_PLACEMENTS_FAILURE'
};

export const addPlacement = (placement) => {
  return {
    type: ActionTypes.ADD_PLACEMENT,
    payload: {
      placement
    }
  }
};

export const postPortfolioDesiredPlacements = (placements) => {
  return {
    type: ActionTypes.POST_PORTFOLIO_DESIRED_PLACEMENTS,
    payload: {
      placements
    }
  }
};

export const removePlacement = (placement) => {
  return {
    type: ActionTypes.REMOVE_PLACEMENT,
    payload: {
      placement
    }
  }
};

import React, { Component } from 'react';
import Form from 'components/Form';
import Button from 'components/new/Button';
import Header from './components/header.component';
import SidePanel from './components/sidepanel.component';
import SearchFilters from './components/SearchFilters.component';
import EventTile from 'common/eventtile.component';
import Expand from 'common/expand.component';
import EventTilePrototype from 'common/eventtile-prototype.component';
import Divider from 'common/Divider.component';
import AudienceProfileAttributes from 'common/audienceprofile-attributes.component';
import { searchEvents, setCurrentEvent } from 'services/events/events.actions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import './search.css';

class Search extends Component {
  static defaultProps = {
    events: []
  }

  constructor(props) {
    super(props);
    this.state = {
      events: []
    }
  }

  componentDidMount() {
    this.props.searchEvents();
  }

  goToEvent(event) {
    this.props.history.push("/events/" + event.id);
  }

  getEvents() {
    if (!this.props.events || this.props.events.length === 0) {
      return (
        <div style={{paddingBottom: '60px'}} className="d-flex justify-content-center flex-column align-items-center">
          <div className="eventshome-emptystate d-flex flex-column">
            <div style={{width: '100%'}} className="d-flex justify-content-center">
              <div className="eventshome-emptystate-header header-2-orange">Add an event to the Restage platform</div>
            </div>
            <div className="eventshome-emptystate-instructions">
              <div className="d-flex flex-row">
                <div className="eventshome-emptystate-label body-copy-bold-tertiary">1</div>
                <div className="body-copy-tertiary">Give us the basic details to get started.</div>
              </div>
              <div className="d-flex flex-row">
                <div className="eventshome-emptystate-label body-copy-bold-tertiary">2</div>
                <div className="body-copy-tertiary">Tell us about your audience to improve recommendations. </div>
              </div>
              <div className="d-flex flex-row">
                <div className="eventshome-emptystate-label body-copy-bold-tertiary">3</div>
                <div className="body-copy-tertiary">List your available sponsorships for sponsors to see. </div>
              </div>
              <div className="d-flex flex-row">
                <div className="eventshome-emptystate-label body-copy-bold-tertiary">4</div>
                <div className="body-copy-tertiary">Submit the event for review and publishing.</div>
              </div>
            </div>
          </div>
          <div>
            <Button size="md" type="tertiary" onClick={(()=>{})} label="CREATE EVENT"/>
          </div>
        </div>
      );
    }

    if (this.props.events.name && this.props.events.name === 'ResponseError') return null;
    return (
      <div className="search-events d-flex flex-wrap">

        {
          this.props.events.map((item, index)=>{
            let { event, location } = item;
            let { startdate, enddate, name, assets, description} = event;

            let start = new Date(startdate);
            let end = new Date(enddate);
            let poster;
            if (assets) {
              if (assets.length > 0) {
                poster = assets[0];
              }
            }

            return (
              <EventTile
                description={description}
                score={100}
                key={index}
                poster={poster}
                onClick={this.goToEvent.bind(this, event)}
                name={name}
                startdate={start.toLocaleDateString()}
                enddate={end.toLocaleDateString()}
                formatted_address={location.description} />
            );
          })
        }
      </div>
    )
  }

  render() {
    return (
      <div>
        <div style={{paddingBottom: '60px'}} className="container search-wrapper">
          <div style={{marginBottom: '30px'}} className="header-2-brand">Search Events</div>
          <AudienceProfileAttributes />
          <Divider style={{marginBottom: '15px'}} type="brand secondary" />
          <SearchFilters />
          <Divider style={{marginBottom: '30px'}} type="brand secondary" />
          <div className="search-container">
              {this.getEvents()}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    events: state.events.events
  };
};

const mapDispatchToProps = dispatcher => (
    bindActionCreators({
        searchEvents,
        setCurrentEvent
    }, dispatcher)
);

export default connect(mapStateToProps, mapDispatchToProps)(Search);

import { ActionTypes } from './jobfunctions.actions';

const initialState = {
    errorMessage: "",
    functions: []
};

const JobFunctionsReducer = (state = initialState, action) => {
    switch (action.type) {
      case ActionTypes.GET_JOB_FUNCTIONS_SUCCESS:
        return { ...state, functions: action.functions};
      default:
          return state;
    }
};

export default JobFunctionsReducer;

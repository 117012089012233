import { ActionTypes } from './audienceprofile.actions';

const initialState = {
    errorMessage: "",
    attributes: [],
    name: ""
};

const AudienceProfileReducer = (state = initialState, action) => {
    switch (action.type) {
      case ActionTypes.ADD_AUDIENCE_PROFILE_ATTIRIBUTE:
        let { attribute } = action.payload;
        let contains = state.attributes.filter(({ type, value }) => attribute.type === type && attribute.value === value);
        let attributes = [];

        if (contains.length > 0) {
          attributes = [...state.attributes];
        } else {
          attributes = [...state.attributes, attribute]
        }
        return {
          ...state,
          attributes
        };
      case ActionTypes.REMOVE_AUDIENCE_PROFILE_ATTIRIBUTE:
        attribute = action.payload.attribute;
        let newAttributes = state.attributes.filter(({ type, value }) => !(attribute.type === type && attribute.value === value));

        return {
          ...state,
          attributes: [...newAttributes]
        };
      case ActionTypes.SET_AUDIENCE_PROFILE_NAME:
        return {...state, name: action.payload.name};
      default:
          return state;
    }
};

export default AudienceProfileReducer;

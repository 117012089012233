import React, { Component } from 'react';
import Detail from 'common/Detail';
import Image from 'components/Image';
import Button from 'components/new/Button';
import Divider from 'common/Divider.component';
import ProfileCard from 'assets/profile-card.svg';
import { Routes } from 'constants/Routes';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import './intro.css';

class Intro extends Component {
  goToPath(path) {
    this.props.history.push(path)
  }

  render() {
    return (
      <div className="intro-wrapper container d-flex flex-fill flex-column">
{/*flex-md-fill*/}
        <div className="intro-form d-flex flex-md-row flex-column-reverse ">

            <div className="intro-container col-md-6 col-12 d-flex flex-column justify-content-between">
              <div>
                <Detail justification="start" style={{textAlign: 'Start', marginBottom: '25px'}} header="Here's an overview of the process"/>
                <Divider style={{ marginBottom: '25px'}}/>
                <div className="intro-details">
                  <div className="d-flex flex-row">
                    <div>
                      <div className="intro-circle header-2-secondary d-flex align-items-center justify-content-center">1</div>
                    </div>
                    <div>
                      <div className="header-2-brand">Create an account</div>
                      <div className="body-copy-tertiary">Find the right partners for events.</div>
                    </div>
                  </div>
                  <div className="d-flex flex-row">
                    <div>
                      <div className="intro-circle header-2-secondary d-flex align-items-center justify-content-center">2</div>
                    </div>
                    <div>
                      <div className="header-2-brand">Create an event</div>
                      <div className="body-copy-tertiary">List your sponsorships on Restage to connect with sponsors.</div>
                    </div>
                  </div>
                  <div className="d-flex flex-row">
                    <div className="">
                      <div className="intro-circle header-2-secondary d-flex align-items-center justify-content-center">3</div>
                    </div>
                    <div>
                      <div className="header-2-brand">Submit for review</div>
                      <div className="body-copy-tertiary">Someone from Restage will review your event page. If it meets our quality standards, you'll get published in our recommendations!</div>
                    </div>
                  </div>
                  <Detail justification="start" header="" description=""/>
                  <Detail justification="start" header="" description=""/>
                  <Detail justification="start" header="" description="."/>
                </div>
                {/*<List
                  expand={true}
                  justification="vertical"
                  items={[
                    "Access to one central location for creating events, negoations, and tracking available sponsorships.",
                    "Leverage the Restage network of sponsors and increase the visibility around your event.",
                    "Define your event audience and get curated recommendations to find the partnerships right for you.",
                    "Accept, decline, or modify package offers from brands."
                  ]} />*/}
                </div>
              <div>
                <Button
                  type="primary"
                  style={{float: 'right'}}
                  size="sm"
                  onClick={this.goToPath.bind(this, Routes.ONBOARDING_CREATE_ACCOUNT)}
                  label='GET STARTED'/>
              </div>
            </div>
            <div className="col-md-6 col-12 intro-content intro-image" >
              <div className="d-flex flex-column justify-content-start align-items-center" style={{ height: '100%', width: '100%'}}>
                  <img alt="Account" src={ProfileCard} style={{width: '100%'}}/>
              </div>
              {/*<div className="d-flex flex-column justify-content-center align-items-center" style={{padding: '20px', height: '100%', width: '100%', borderRadius: '4px', backgroundColor: '#FFFFFF'}}>
                  <img src={Community} style={{width: '60%'}} />
              </div>*/}
            </div>
        </div>

      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {

  };
};

const mapDispatchToProps = dispatcher => (
    bindActionCreators({

    }, dispatcher)
);

export default connect(mapStateToProps, mapDispatchToProps)(Intro);

import { combineReducers } from 'redux';
import AuthReducer from 'services/auth/auth.reducer';
import EventsReducer from 'services/events/events.reducer';
import EventAssetsReducer from 'services/eventassets/eventassets.reducer';
import UsersReducer from 'services/users/users.reducer';
import PlacesReducer from 'services/places/places.reducer';
import SignUpReducer from 'services/signup/signup.reducer';
import FirebaseReducer from './firebase/firebase.reducer';
import SponsorshipsReducer from 'services/sponsorships/sponsorships.reducer';
import SponsorshipRequestsReducer from 'services/sponsorshiprequests/sponsorshiprequests.reducer';
import SponsorNewsLetterSignupReducer from 'services/sponsornewslettersignup/sponsornewslettersignup.reducer';
import NewsletterSignupReducer from 'services/newslettersignup/newslettersignup.reducer';
import TapReducer from './services/tap/tap.reducer';
import AudienceProfileReducer from './services/audienceprofile/audienceprofile.reducer';
import EventSponsorsReducer from './services/eventsponsors/eventsponsors.reducer';
import AvailablePlacementsReducer from './services/availableplacements/availableplacements.reducer';
import CartReducer from './services/cart/cart.reducer';
import MessageReducer from './services/messages/messages.reducer';
import PortfoliosReducer from './services/portfolios/portfolios.reducer';
import PortfolioObjectivesReducer from './services/portfolioobjectives/portfolioobjectives.reducer';
import PortfolioAudienceProfileReducer from './services/portfolioaudienceprofile/portfolioaudienceprofile.reducer';
import EventAudienceProfileReducer from './services/eventaudienceprofile/eventaudienceprofile.reducer';
import EventPackageOfferReducer from './services/eventpackageoffer/eventpackageoffer.reducer';
import PortfolioDesiredPlacementsReducer from './services/portfoliodesiredplacements/portfoliodesiredplacements.reducer';
import PortfolioPlacementPreferencesReducer from './services/portfolioplacementpreferences/portfolioplacementpreferences.reducer';
import EventSponsorshipOfferReducer from './services/eventsponsorshipoffer/eventsponsorshipoffer.reducer';
import EventAttendancesReducer from './services/eventattendances/eventattendances.reducer';
import EventGendersReducer from './services/eventgenders/eventgenders.reducer';
import ForgotPasswordReducer from './services/forgotpassword/forgotpassword.reducer';
import OccupationsReducer from './services/occupations/occupations.reducer';
import IndustriesReducer from './services/industries/industries.reducer';
import PlacementsReducer from './services/placements/placements.reducer';
import PackageCategoriesReducer from './services/packagecategories/packagecategories.reducer';
import PlacementTypesReducer from './services/placementtypes/placementtypes.reducer';
import PackageTypesReducer from './services/packagetypes/packagetypes.reducer';
import JobSenioritiesReducer from './services/jobseniorities/jobseniorities.reducer';
import JobFunctionsReducer from './services/jobfunctions/jobfunctions.reducer';
import PackagesReducer from './services/packages/packages.reducer';
import VerifyReducer from './services/verify/verify.reducer';
import NegotiationsReducer from './services/negotiations/negotiations.reducer';
import BlogReducer from './services/blog/blog.reducer';
import AccountModalReducer from './services/accountmodal/accountmodal.reducer';
import SponsorPreferencesReducer from './services/sponsorpreferences/sponsorpreferences.reducer';
import SharesReducer from './services/shares/shares.reducer';

export default combineReducers({
  accountmodal: AccountModalReducer,
  auth: AuthReducer,
  blog: BlogReducer,
  events: EventsReducer,
  eventassets: EventAssetsReducer,
  firebase: FirebaseReducer,
  users: UsersReducer,
  signUp: SignUpReducer,
  places: PlacesReducer,
  sponsorships: SponsorshipsReducer,
  sponsorshiprequests: SponsorshipRequestsReducer,
  sponsornewslettersignup: SponsorNewsLetterSignupReducer,
  newslettersignup: NewsletterSignupReducer,
  tap: TapReducer,
  audienceprofile: AudienceProfileReducer,
  portfolios: PortfoliosReducer,
  portfolioaudienceprofile: PortfolioAudienceProfileReducer,
  eventaudienceprofile: EventAudienceProfileReducer,
  portfoliodesiredplacements: PortfolioDesiredPlacementsReducer,
  portfolioplacementpreferences: PortfolioPlacementPreferencesReducer,
  availableplacements: AvailablePlacementsReducer,
  packagecategories: PackageCategoriesReducer,
  packagetypes: PackageTypesReducer,
  jobfunctions: JobFunctionsReducer,
  jobseniorities: JobSenioritiesReducer,
  cart: CartReducer,
  eventsponsors: EventSponsorsReducer,
  eventsponsorshipoffer: EventSponsorshipOfferReducer,
  eventpackageoffer: EventPackageOfferReducer,
  forgotpassword: ForgotPasswordReducer,
  occupations: OccupationsReducer,
  industries: IndustriesReducer,
  placements: PlacementsReducer,
  packages: PackagesReducer,
  verify: VerifyReducer,
  messages: MessageReducer,
  negotiations: NegotiationsReducer,
  placementtypes: PlacementTypesReducer,
  eventattendances: EventAttendancesReducer,
  eventgenders: EventGendersReducer,
  sponsorpreferences: SponsorPreferencesReducer,
  shares: SharesReducer,
  portfolioobjectives: PortfolioObjectivesReducer
});

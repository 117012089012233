export const ActionTypes = {
    SET_SAVED_PACKAGES: 'SET_SAVED_PACKAGES',
    POST_PACKAGES: 'POST_PACKAGES',
    POST_PACKAGES_SUCCESS: 'POST_PACKAGES_SUCCESS',
    POST_PACKAGES_FAILURE: 'POST_PACKAGES_FAILURE',
    PUT_PACKAGES: 'PUT_PACKAGES',
    PUT_PACKAGES_SUCCESS: 'PUT_PACKAGES_SUCCESS',
    PUT_PACKAGES_FAILURE: 'PUT_PACKAGES_FAILURE',
    CLEAR_PACKAGES: 'CLEAR_PACKAGES',
    ADD_PACKAGE: 'ADD_PACKAGE',
    REMOVE_PACKAGE: 'REMOVE_PACKAGE',
    SET_EVENT_PACKAGES_PAGE_STATE: 'SET_EVENT_PACKAGES_PAGE_STATE'
};

export const setSavedPackages = (packages) => ({
  type: ActionTypes.SET_SAVED_PACKAGES,
  payload: {
    savedPackages: packages
  }
});

export const postPackages = (packages) => ({
  type: ActionTypes.POST_PACKAGES,
  payload: {
    packages
  }
});

export const putPackages = (packages) => ({
  type: ActionTypes.PUT_PACKAGES,
  payload: {
    packages
  }
})

export const addPackage = (item) => ({
  type: ActionTypes.ADD_PACKAGE,
  payload: {
    item
  }
});

export const clearPackages = (item) => ({
  type: ActionTypes.CLEAR_PACKAGES
});

export const removePackage = (item) => ({
  type: ActionTypes.REMOVE_PACKAGE,
  payload: {
    item
  }
});

export const setEventPackagesPage = (isValid) => ({
  type: ActionTypes.SET_EVENT_PACKAGES_PAGE_STATE,
  payload: {
    isValid
  }
})

import React, { Component } from 'react';
import { Switch, BrowserRouter as Router, Route, withRouter } from "react-router-dom";
import { CSSTransition } from 'react-transition-group'
import Profile from '../screens/settings/profile/profile.screen';
import Settings from '../screens/settings/settings.screen';
import ProtectedRoute from 'common/ProtectedRoute.component';
import { Routes } from 'constants/Routes';

class SettingsModule extends Component {
  render() {
    return (
      <Route path={Routes.SETTINGS} children={({match})=>{
        if (match !== null && !match.isExact) {
          return (
            <Settings history={this.props.history}>
                <ProtectedRoute exact path={Routes.SETTINGS_PROFILE} component={Profile} />
            </Settings>
          );
        } else {
          return null;
        }
      }}/>
    )
  }
}

export default withRouter(SettingsModule);

/*<Route path={Routes.EVENTS_CREATE} children={({match})=>{
  if (match !== null && !match.isExact) {
    return (
      <CreateEvent>
          <Route exact path={Routes.EVENT_DETAILS}>
              <CSSTransition
                in={match !== null}
                timeout={300}
                classNames="my-node"
                unmountOnExit
              >
                <div className="my-node">
                  <EventDetails />
                </div>
              </CSSTransition>
          </Route>
          <Route exact path={Routes.REQUEST_SPONSORSHIP}>
            <CSSTransition
              in={match !== null}
              timeout={300}
              classNames="my-node"
              unmountOnExit
            >
              <RequestSponsorship />
            </CSSTransition>
          </Route>
          <Route exact path={Routes.EVENT_PLACEMENTS}>
            <CSSTransition
              in={match !== null}
              timeout={300}
              classNames="my-node"
              unmountOnExit
            >
              <div className="my-node">
                <EventPlacements />
              </div>
            </CSSTransition>
          </Route>
          <Route exact path={Routes.EVENT_AUDIENCE}>
            <CSSTransition
              in={match !== null}
              classNames="my-node"
              timeout={300}
              unmountOnExit
            >
              <div className="my-node">
                <EventAudience />
              </div>
            </CSSTransition>
          </Route>
          <Route exact path={Routes.EVENT_PACKAGES} component={EventPackages} />
          <Route exact path={Routes.EVENT_AUDIENCE} component={EventAudience} />
          <Route exact path={Routes.EVENT_REVIEW} component={EventReview} />
      </CreateEvent>
    );
  } else {
    return null;
  }
}}
/>*/


/*

  render() {
    return (
        <Route path={Routes.EVENTS_CREATE} children={({match})=>{
          if (match !== null && !match.isExact) {
            return (
              <CreateEvent history={this.props.history}>
                {routes.map(({ path, Component }) => (
                  <Route key={path} exact path={path}>
                    {(result) => (
                      <CSSTransition
                        in={result.match != null}
                        timeout={0}
                        unmountOnExit
                      >
                        <Component history={this.props.history}/>
                      </CSSTransition>
                    )}
                  </Route>
                ))}
              </CreateEvent>
            );
          } else {
            return null;
          }
        }}
        />
      )
  }*/

export const ActionTypes = {
    POST_PORTFOLIO_AUDIENCE_PROFILE: 'POST_PORTFOLIO_AUDIENCE_PROFILE',
    POST_PORTFOLIO_AUDIENCE_PROFILE_SUCCESS: 'POST_PORTFOLIO_AUDIENCE_PROFILE_SUCCESS',
    POST_PORTFOLIO_AUDIENCE_PROFILE_FAILURE: 'POST_PORTFOLIO_AUDIENCE_PROFILE_FAILURE',
    PUT_PORTFOLIO_AUDIENCE_PROFILE: 'PUT_PORTFOLIO_AUDIENCE_PROFILE',
    PUT_PORTFOLIO_AUDIENCE_PROFILE_SUCCESS: 'PUT_PORTFOLIO_AUDIENCE_PROFILE_SUCCESS',
    PUT_PORTFOLIO_AUDIENCE_PROFILE_FAILURE: 'PUT_PORTFOLIO_AUDIENCE_PROFILE_FAILURE'
  }

export const postPortfolioAudienceProfile = (portfolio_audience_profile) => {
  return {
      type: ActionTypes.POST_PORTFOLIO_AUDIENCE_PROFILE,
      payload: {
        portfolio_audience_profile
      }
  }
};

export const putPortfolioAudienceProfile = (portfolio_audience_profile) => {
  return {
      type: ActionTypes.PUT_PORTFOLIO_AUDIENCE_PROFILE,
      payload: {
        portfolio_audience_profile
      }
  }
};

import React, { Component } from 'react';
import Wrapper from 'components/Wrapper';
import Eyebrow from 'components/Eyebrow';
import Detail from 'common/Detail';
import Button from 'components/new/Button';
import TabGroup from 'common/TabGroup';
import Tab from 'common/Tab';
import Expand from 'common/expand.component';
import Accordion from 'components/Accordion';
import { Routes } from 'constants/Routes';
import { Redirect } from "react-router-dom";
import Divider from 'common/Divider.component';
import RespondToOfferModal from './components/respond-to-offer-modal.component';
import { sendNegotiationsEmail } from 'services/negotiations/negotiations.actions';
import { notification } from 'antd';
import { isNumeric } from 'common/helpers/validators';
import { Menu, Dropdown } from 'antd';
import { Table } from 'antd';
import { generateCurrencyFormat } from 'common/helpers/currency';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import './manage.css';

const tabs = [
  { label: "Offers" },
  //{ label: "Recommendations" }
]

class ManageEvent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      selectedOffer: null,
      isRespondToModalVisible: false,
      tabs: {
        offers: true,
        recommendations: false
      }
    }

  }

  componentDidUpdate(prevProps) {
    if (!prevProps.eventPackageOfferIdResponses.includes(this.state.selectedOffer.id) && this.props.eventPackageOfferIdResponses.includes(this.state.selectedOffer.id)) {
      notification.success({
        message: `An e-mail has been sent to both you and ${this.state.selectedOffer.brand_user.first_name}`
      })
      this.setState({
        isRespondToModalVisible: false
      })
    }
  }

  activate(current) {
    let { tabs } = this.state;

    let update = {};

    for (let tab in tabs) {
      if (current === tab) {
        update[tab] = true;
      } else {
        update[tab] = false;
      }
    }

    this.setState({
      tabs: {...update}
    });
  }

  setModalVisible(isVisible) {
    this.setState({
      isRespondToModalVisible: isVisible
    })
  }

  openRespondToOfferModal(offer) {
    this.setState({
      selectedOffer: offer,
      isRespondToModalVisible: true
    })
  }

  isAdded(isAdded) {
    if (isAdded) {
        return (
          <div>
            <div className="body-copy-white-semibold" style={{backgroundColor: '#eb5b49', paddingLeft: '15px', paddingRight: '15px', paddingTop: '10px', paddingBottom: '10px', borderRadius: '4px'}}>ADDED</div>
          </div>
        )
    }
    return null;
  }

  isRemoved(isRemoved) {
    if (isRemoved) {
        return (
          <div className="body-copy-white-semibold" style={{backgroundColor: '#eb5b49', paddingLeft: '15px', paddingRight: '15px', paddingTop: '10px', paddingBottom: '10px', borderRadius: '4px'}}>REMOVED</div>
        )
    }
    return null;
  }

  isModified(cost, originalcost) {
    if (cost !== originalcost) {
        return (
          <div className="body-copy-white-semibold" style={{backgroundColor: '#eb5b49', paddingLeft: '15px', paddingRight: '15px', paddingTop: '10px', paddingBottom: '10px', borderRadius: '4px'}}>MODIFIED</div>
        )
    }
    return null;
  }

  setOffersTab(offers) {
    if (!this.state.tabs.offers) return null;
    if (!offers) return;
    let panels = [];

    offers.map((offer, index) => {
      let { placements, event_package, brand_user, created_at, room } = offer;
      let dateCreated = new Date(created_at);
      let { organization, first_name, last_name, email  } = brand_user;

      let getNote = () =>{
        if (room.messages.length > 0) {
          return (
            <div style={{marginTop: '50px'}}>
              <div style={{marginBottom: '8px'}} className="body-copy-bold-tertiary">Message: </div>
              <div className="body-copy-tertiary">
                {room.messages[0].message}
              </div>
            </div>
          )
        }
        return (
          null
        )
      }

      let requestedPlacements = placements.map((item)=>item.placement.placement);
      let originalPlacements = event_package.placements.map((item)=>item.placement.placement);

      placements = placements.map((item) => {
        if (!originalPlacements.includes(item.placement.placement)) {
          item.added = true;
        }
        return item;
      });

      let removedPlacements = event_package.placements.filter((item) => {
        return !requestedPlacements.includes(item.placement.placement);
      });

      let content = () => {

        return (
          <div style={{paddingLeft: '24px'}}className="d-flex flex-sm-row flex-column">
            <div className="d-flex flex-row">
              <div>
                <div className="manage-avatar manage-them d-flex justify-content-center align-items-center">
                  <div className="manage-label">{first_name[0]}{last_name[0]}</div>
                </div>
              </div>
              <div className="manage-message">
                <div className="body-copy-bold-orange">{first_name} {last_name}</div>
                <div className="body-copy-tertiary">{organization.name}</div>
                {/*<div className="body-copy-tertiary">{email}</div>*/}
              </div>
            </div>
            <div style={{width: '100%'}} className="d-flex flex-column manage-offer-details" key={index}>
              <div style={{marginBottom: '25px'}} className="body-copy-bold-brand">Package Info</div>
              <div>
                <div>
                  <Divider type="brand" />
                  <div className="align-items-center d-flex justify-content-between">
                    <div style={{paddingTop: '20px', paddingBottom: '20px'}} className='body-copy-tertiary'>Price: {generateCurrencyFormat(offer.offer)}</div>
                    {this.isModified(offer.offer, event_package.cost)}
                  </div>
                  <Divider type="brand" />
                </div>
                {
                  placements.map((item, index)=> {
                    return (
                      <div key={index}>
                        <div className="align-items-center d-flex justify-content-between">
                          <div style={{paddingTop: '20px', paddingBottom: '20px'}} key={index} className='body-copy-tertiary'>{item.placement.placement}</div>
                          {this.isAdded(item.added)}
                        </div>
                        <Divider type="brand" />
                      </div>
                    )
                  })
                }
                {
                  removedPlacements.map((item, index)=> {
                    return(
                      <div key={index}>
                        <div className="align-items-center d-flex justify-content-between">
                          <div style={{paddingTop: '20px', paddingBottom: '20px'}} key={index} className='body-copy-tertiary'>{item.placement.placement}</div>
                          {this.isRemoved(true)}
                        </div>
                        <Divider type="brand" />
                      </div>
                    )
                  })
                }
              </div>
              {getNote()}
              <div className="d-flex justify-content-center" style={{width: '100%', marginTop: '50px', paddingBottom: '30px'}}>
                <Button label="Respond" type="tertiary" onClick={this.openRespondToOfferModal.bind(this, offer)} stopPropagation={true} />
              </div>
            </div>
          </div>
        )
      }

      let panel = {
        label: (
          <div className="d-flex flex-row justify-content-between">
            <div style={{width: '100%'}} className="manage-offer-header d-flex flex-row flex-wrap justify-content-between align-items-center">
              <div className="d-flex flex-column col-sm-3">
                <span className="body-copy-bold-tertiary">Rep: </span>
                <span className="body-copy-tertiary">{first_name} {last_name}</span>
              </div>
              <div className="d-flex flex-column col-sm-3">
                <span className="body-copy-bold-tertiary">Company: </span>
                <span className="body-copy-tertiary">{organization.name}</span>
              </div>
              <div className="d-flex flex-column col-sm-3">
                <span className="body-copy-bold-tertiary">Package: </span>
                <span className="body-copy-tertiary">{event_package.name}</span>
              </div>
              {/*<div className="d-flex flex-column">
                <span className="body-copy-bold-tertiary">E-mail: </span>
                <span className="body-copy-tertiary-link">{email}</span>
              </div>*/}
              <div className="d-flex flex-column col-sm-3">
                <span className="body-copy-bold-tertiary">Created: </span>
                <span className="body-copy-tertiary">{dateCreated.toLocaleDateString()}</span>
              </div>
            </div>
            {/*<div>
              <Dropdown overlay={actions(offer.event_id)} trigger={['click']}>
                <Button label="Manage Event" type="primary" onClick={(()=>{})} stopPropagation={true} />
              </Dropdown>
            </div>*/}
          </div>
        ),
        content
      }

      panels.push(panel);
    });

    return (
      <div>
        <Accordion style={{borderTop: '3px solid black'}} panels={panels} />
      </div>
    )
  }

  setRecommendationsTab(recommendations) {
    if (!this.state.tabs.recommendations) return null;

    if (!recommendations) {
      return (null);
    }

    if (recommendations.length === 0) {
      return (null);
    }

    return (
      <div style={{marginTop: '25px'}} className="portfolioreview-details">
        <div className="portfolio-recommendations-container">
          {
            recommendations.map((item) => {
              return (
                <div className="portfolio-recommendation-row d-flex flex-row body-copy-tertiary">
                  <div className="col-6">Portfolio: <a href={`/portfolio/${item.portfolio_id}`} target="_blank">{item.portfolio_id}</a></div>
                  <div className="col-6">Score: {item.score.toFixed(2)}</div>
                </div>
              )
            })
          }
        </div>
      </div>
    )
  }

  goToRespondToOffer() {

  }

  goToPath() {
    this.props.history.push('/events/' + this.props.event.event.id)
  }

  getEventPageButton() {
    if (this.props.event.event.accepted) {
      return (
        <div>
          <Button
            type="primary"
            label="VIEW EVENT PAGE"
            onClick={this.goToPath.bind(this)} />
        </div>
      )
    }
    return  (null);
  }

  getEventStatus() {
    let status = ""
    console.log(this.props.event.event)
    if (!("accepted" in this.props.event.event)) {
      status = "Under Review";
    }

    if (this.props.event.event.accepted === false) {
      status = "Not accepted";
    }

    if (this.props.event.event.accepted) {
      status = "Accepted";
    }

    return (<span>{status}</span>);
  }

  render() {

    if (!this.props.event.event.id) return null;

    let { offers, recommendations} = this.props.event;

    return (
      <div style={{paddingBottom: '30px'}}>
        <div>
          <TabGroup>
            {
              tabs.map(({ label, icon }, index)=>{
                return (
                  <Tab
                    type="bold"
                    isCompleteVisible={false}
                    icon={icon}
                    key={index}
                    label={label}
                    selected={this.state.tabs[label.toLowerCase()]}
                    onClick={this.activate.bind(this, label.toLowerCase())} />
                  )
              })
            }
          </TabGroup>
        </div>
        <div>
          {this.setOffersTab(offers)}
          {this.setRecommendationsTab(recommendations)}
        </div>
        <RespondToOfferModal
          sendNegotiationsEmail={this.props.sendNegotiationsEmail.bind(this, )}
          onClickClose={this.setModalVisible.bind(this, false)}
          onClickPrimary={this.setModalVisible.bind(this, false)}
          offer={this.state.selectedOffer}
          visible={this.state.isRespondToModalVisible}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  console.log(state)
  return {
    event: state.events.currentEvent,
    eventPackageOfferIdResponses: state.negotiations.eventPackageOfferIdResponses,
  };
};

const mapDispatchToProps = dispatcher => (
    bindActionCreators({
      sendNegotiationsEmail
    }, dispatcher)
);
export default connect(mapStateToProps, mapDispatchToProps)(ManageEvent);

import React, { Component } from 'react';
import Detail from './Detail';
import Button from 'components/new/Button';
import ButtonGroup from 'components/ButtonGroup';
import Form from 'components/Form';
import Modal from 'components/Modal';
import TextField from 'components/TextField';
import CheckboxWrapper from 'components/CheckboxWrapper';
import Checkbox from 'components/Checkbox';
import Divider from 'common/Divider.component';
import AutocompleteEntryGroup from 'common/AutocompleteEntryGroup';
import CurrencyFormat from 'react-currency-format';
import { min } from 'common/helpers/validators';
import { Routes } from 'constants/Routes';
import { searchPlacements, setPlacements } from 'services/placements/placements.actions';
import { setAccountModalVisibility } from 'services/accountmodal/accountmodal.actions';
import { postEventPackageOffer } from 'services/eventpackageoffer/eventpackageoffer.actions';
import { bindActionCreators } from 'redux';
import { generateCurrencyFormat } from 'common/helpers/currency';
import { connect } from 'react-redux';
import './CustomizePackageModal.css';

class CustomizePackageModal extends Component {
  constructor(props) {
    super(props);
    let cost = "";

    this.state = {
      placement: {
        value: null,
        error: false
      },
      message: {
        value: "",
        error: false
      },
      offer: {
        value: cost,
        quantity: cost,
        error: false,
        errorMessage: "Minimum cost is $5,000."
      },
      originalcost: {
        value: cost,
        quantity: cost
      },
      createdAnAccount: false,
      placement: "",
      placements: [],
      modifyPackage: false,
      isPackageOfferSent: false,
      isCustomPlacementDropdownVisible: false
    }
  }

  onValueChange(values) {
    const {formattedValue, value} = values;
    this.setState({ offer: {
      ...this.state.offer,
      value: formattedValue,
      quantity: value,
      }
    }, ()=> {
      this.setState({ offer: {
        ...this.state.offer,
        error: !this.isCostMin(min, 5000, "offer", "Package value must be at least $5,000."),
        errorMessage: this.state["offer"].errorMessage
        }
      });
    });
  }

  setPackageOfferSentModalVisible(isVisible) {
    this.setState({
      isPackageOfferSent: isVisible
    }, ()=> {
      this.props.onClickClose();
    })
  }

  onChange(event) {
    this.setState({ [event.target.name]: {
      value: event.target.value,
      error: this.state[event.target.name].error
      }
    })
  }

  componentDidUpdate(prevProps) {
    if (this.props.accountModalVisibility && !prevProps.accountModalVisibility) {
      this.setState({
        createdAnAccount: true
      })
    }

    if (!prevProps.user && this.props.user && this.state.createdAnAccount) {
      let eventPackageOffer = {
        brand_user_id: this.props.user.id,
        event_id: this.props.event.event.id,
        message: this.state.message.value,
        event_package_id: this.props.selectedPackage.id,
        offer: this.state.offer.quantity,
        placements: [...this.state.placements.filter((placement)=>placement.checked).map((placement)=>placement.placement.placement)],

      }
      this.props.postEventPackageOffer(eventPackageOffer);
      this.setPackageOfferSentModalVisible(true);
    }

    if (this.props.selectedPackage === null && prevProps.selectedPackage === null) return null;
    if ((prevProps.selectedPackage === null && this.props.selectedPackage !== null) || (this.props.selectedPackage.id !== prevProps.selectedPackage.id)) {
      let placements = [...this.props.selectedPackage.placements];
      placements.map((placement) => placement.checked = true)
      this.setState({
        placements
      }, () => {

      });

      let cost = this.props.selectedPackage.cost;

      this.setState({
        offer: {
          value: cost,
          quantity: cost,
          error: false,
          errorMessage: "Minimum cost is $5,000."
        },
        originalcost: {
          value: cost,
          quantity: cost
        }
      })
    }
  }

  isCostMin(validator, value, key, errorMessage) {
    this.setState({
      [key]: {
        quantity: this.state[key].quantity,
        value: this.state[key].value,
        error: !validator(this.state[key].quantity, value),
        errorMessage: errorMessage
      }
    });

    return validator(this.state[key].quantity, value);
  }

  postEventPackageOffer() {
    if (!this.props.user) {
      this.props.setAccountModalVisibility(true);
      return;
    }


    let eventPackageOffer = {
      brand_user_id: this.props.user.id,
      event_id: this.props.event.event.id,
      message: this.state.message.value,
      event_package_id: this.props.selectedPackage.id,
      offer: this.state.offer.quantity,
      placements: [...this.state.placements.filter((placement)=>placement.checked).map((placement)=>placement.placement.placement)],

    }
    this.props.postEventPackageOffer(eventPackageOffer)
    //this.props.history.push(Routes.EVENT_CHECKOUT_ACTIVATION);
    this.setPackageOfferSentModalVisible(true);
  }


  onClickAddPlacement() {
    let { placement, placements } = this.state;
    placements.push({
      placement: { placement: placement.value },
      checked: true
    });

    this.setState({
      placements,
      isCustomPlacementDropdownVisible:  false,
      placement: {
        value: null,
        error: false
      }
    }, () => {
      this.props.setPlacements([]);
    });
  }

  /***********/
  onChangeAutocomplete(search) {
    this.setState({
      placement: {
        value: search,
        error: false
      }
    });
    this.props.searchPlacements(search);
  }

  onMouseDownAutocompleteOption(value) {
    let placement = value;
    this.setState({
      isCustomPlacementDropdownVisible:  false,
      placement: {
        value,
        error: false,
      }
    }, ()=>{
      this.props.setPlacements([]);
      this.onClickAddPlacement();
    });
  }

  onBlurAutocomplete() {
    this.props.setPlacements([]);
  }

  setCustomizedPackageUI() {

    return (
      <div>
        <CheckboxWrapper>
          {
            this.state.placements.map((placement, index)=>{
              return (<Checkbox className="col-12" key={index} checked={this.setPlacementChecked(placement)} label={placement.placement.placement || placement} onClick={this.onClickPlacement.bind(this, placement)} value={placement.placement} />)
            })
          }
        </CheckboxWrapper>
      </div>
    )
  }

  onClickPlacement(placement) {
    let newPlacements = [];

    let match = this.state.placements.filter((item) => item.id === placement.id || item.placement.placement === placement.placement.placement);

    if (!match[0].checked) {
      newPlacements = this.state.placements.map((item) => {
        if(item.id === placement.id) {
          item.checked = true;
          return item;
        }
        return item;
      })

    } else {
      newPlacements = this.state.placements.map((item) => {
        if(item.id === placement.id) {
          item.checked = false;
          return item;
        }
        return item;
      });
    }

    this.setState({
      placements: [...newPlacements]
    }, () => {
      console.log(this.state.placements)
    })
  }

  setPlacementChecked(placement) {
    return placement.checked;
  }

  setPackageModification() {
    this.setState({
      modifyPackage: !this.state.modifyPackage
    });
  }

  setSelectedPackage() {
    let { selectedPackage } = this.props;
    let { message, offer, originalcost } = this.state;
    let { name, limit, placements, description } = selectedPackage;
      return (
        <div>
          <div className="body-copy-tertiary" style={{textAlign: 'start', marginBottom: '15px'}}>
            <div style={{marginBottom: '10px'}} className="header-2-brand">{name}</div>
            <div className="body-copy-tertiary">
              Customize the package before sending your request to the event organizer. Deselect placements you’re not interested in. Add custom placements you want to negotiate for. Suggest a price modification if you wish. 
            </div>
            {/*<div className>{generateCurrencyFormat(cost)}</div>*/}
          </div>
          <div style={{display: 'none', marginTop: '15px', marginBottom: '15px'}}>
            <Divider type="brand secondary" />
            <Checkbox style={{marginTop: '15px', marginBottom: '15px'}} className="col-12" checked={this.state.modifyPackage} label="Modify package" onClick={this.setPackageModification.bind(this)} value="" />
            <Divider type="brand secondary" />
          </div>
          {this.setCustomizedPackageUI()}
          {this.showCustomPlacementDropdown()}
          <Divider style={{marginTop: '15px', marginBottom: '15px'}} type="brand" />
          <div className="customize-package-modal-form">
            <div>
              <div className="d-flex flex-row align-items-end">
                <CurrencyFormat
                  thousandSeparator={true}
                  prefix='$'
                  customInput={TextField}
                  fontStyle="tertiary"
                  size="md"
                  name="offer"
                  value={offer.quantity}
                  error={offer.error}
                  errorMessage={offer.errorMessage}
                  label="Price"
                  placeholder='Value'
                  onValueChange={this.onValueChange.bind(this)} />
                <span style={{marginLeft: '10px'}} className="body-copy-tertiary">{`${generateCurrencyFormat(this.state.originalcost.value)} original price`}</span>
              </div>
            </div>
            <TextField
              fontStyle="tertiary"
              name="message"
              value={message.value}
              errorMessage="Description cannot be empty."
              isTextArea={true}
              onChange={this.onChange.bind(this)}
              placeholder="Leave a message for the event organizer"
              label="Leave a message"
              size="full" />
          </div>
        </div>
      )
  }

  addCustomPlacement() {
    this.setState({
      isCustomPlacementDropdownVisible: true
    })
  }

  reset() {
    this.setState({
      placements: [],
      selectedPackage: null
    }, () =>{
      this.props.onClickClose()
    })
  }

  showCustomPlacementDropdown() {
    if (!this.state.isCustomPlacementDropdownVisible) {
      return (
        <div style={{marginTop: '30px',  marginBottom: '30px', cursor: 'pointer'}} onClick={this.addCustomPlacement.bind(this)} className="body-copy-secondary">+ Add Custom Placement</div>
      )
    }
    let { placement, message } = this.state;

    let results = this.props.placements.map((result)=>{
      if (result.placement !== null) {
        return result.placement;
      }
    });

    return (
      <div style={{marginTop: '15px'}}>
        <AutocompleteEntryGroup
          fontStyle="tertiary"
          label="Add additional Desired Placement"
          placeholder="Enter placement"
          name="placement"
          value={placement.value}
          errorMessage="Placement cannot be an empty string."
          onClickAddEntry={this.onClickAddPlacement.bind(this)}
          onChange={this.onChangeAutocomplete.bind(this)}
          results={results}
          onMouseDownOption={this.onMouseDownAutocompleteOption.bind(this)}
          onBlur={this.onBlurAutocomplete.bind(this)}
          size="full"/>
        </div>
    )
  }

  render() {
    if (this.props.selectedPackage === null) return null
    return (
      <div>
        <Modal
          closeColor="#BDBDBD"
          appendFooter={
            ()=>{
              return (
                <div className="col-12 d-flex flex-md-row flex-column justify-content-between">
                  <Button className="" label="RESET" type="primary-border" onClick={this.reset.bind(this)} />
                  <ButtonGroup
                    labelPrimary="REQUEST"
                    labelSecondary="CANCEL"
                    onClickPrimary={this.postEventPackageOffer.bind(this)}
                    onClickSecondary={this.props.onClickClose.bind(this, false)} />
                </div>
              )
            }
          }
          onClickClose={this.props.onClickClose.bind(this, false)}
          visible={this.props.isModalVisible}>
            <div className="customize-package-modal-container">
              {this.setSelectedPackage()}
            </div>
        </Modal>
        <Modal
          closeColor="#BDBDBD"
          size="xs"
          appendFooter={
            ()=>{
              return (
                <div className="col-12 d-flex justify-content-end">
                  <Button label="CLOSE" type="primary" onClick={this.setPackageOfferSentModalVisible.bind(this, false)} />
                </div>
              )
            }
          }
          onClickClose={this.setPackageOfferSentModalVisible.bind(this, false)}
          visible={this.state.isPackageOfferSent}>
            <div className="customize-package-modal-container">
              <div style={{marginBottom: '20px'}} className="header-2-purple">Request Sent!</div>
              <div className="body-copy-tertiary">We have notified the event contributor of your request.</div>
            </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    placements: state.placements.placements,
    user: state.users.user,
    event: state.events.currentEvent,
    accountModalVisibility: state.accountmodal.visibility
  };
};

const mapDispatchToProps = dispatcher => (
    bindActionCreators({
      postEventPackageOffer,
      searchPlacements,
      setPlacements,
      setAccountModalVisibility
    }, dispatcher)
);

export default connect(mapStateToProps, mapDispatchToProps)(CustomizePackageModal);

import React, { Component } from 'react';
import Instagram from 'assets/instagram.svg';
import Linkedin from 'assets/linkedin.svg';
import Youtube from 'assets/youtube.svg';
import Twitter from 'assets/twitter.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './postmediamobile.css';

const URLS = {
  TWITTER: "https://twitter.com/restagehq",
  LINKEDIN: "https://www.linkedin.com/company/restage",
  INSTAGRAM: "https://www.instagram.com/restagehq",
  YOUTUBE: "https://www.youtube.com"
}

class PostMediaMobile extends Component {
  static defaultProps = {
      style: {},
      className: ""
  }

  onClickIcon(url) {
    window.open(url, "_blank",)
  }

  render(){
    let { TWITTER, LINKEDIN, INSTAGRAM, YOUTUBE } = URLS;

    return (
      <div className="postmediamobile-container">
        <div className="postmediamobile-icons d-flex flex-row justify-content-center">
        <FontAwesomeIcon onClick={this.onClickIcon.bind(this, TWITTER)} size="3x" className="postmediamobile-icon" icon={["fab", "twitter"]} />
        <FontAwesomeIcon onClick={this.onClickIcon.bind(this, LINKEDIN)} size="3x" className="postmediamobile-icon" icon={["fab", "linkedin"]} />
        {/*<FontAwesomeIcon onClick={this.onClickIcon.bind(this, YOUTUBE)} size="3x" className="postmediamobile-icon" icon={["fab", "youtube"]} />*/}
        <FontAwesomeIcon onClick={this.onClickIcon.bind(this, INSTAGRAM)} size="3x" className="postmediamobile-icon" icon={["fab", "instagram"]} />
        </div>
      </div>
    )
  }
}

export default PostMediaMobile;

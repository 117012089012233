export const Routes = {
  LANDING: '/',
  SIGN_UP: '/signup',

  LOG_IN: '/login',
  FORGOT_PASSWORD: '/forgot-password',

  VERIFY: '/verify',
  RESEND_VERIFICATION: '/resend',

  SETTINGS: '/settings',
  SETTINGS_PROFILE: '/settings/profile',

  ONBOARDING_GET_STARTED: '/onboarding/get-started',

  CHAT_ROOM: '/messages/room',

  ONBOARDING_CREATE_ACCOUNT: '/onboarding/create-account',
  ORGANIZER_ONBOARDING_INTRO: '/onboarding/intro',

  PORTFOLIO_CREATE: '/portfolio/create',
  PORTFOLIO_OBJECTIVES: '/portfolio/create/objectives',
  PORTFOLIO_PREFERENCES: '/portfolio/create/preferences',
  PORTFOLIO_PLACEMENTS: '/portfolio/create/placements',
  PORTFOLIO_AUDIENCE: '/portfolio/create/audience',
  PORTFOLIO_SPONSORSET: '/portfolio/create/sponsorset',
  PORTFOLIO_REVIEW: '/portfolio/create/review',

  REQUEST_SPONSORSHIP: '/events/create/sponsorships',
  EVENTS_CREATE: '/events/create',
  EVENT_DETAILS: '/events/create/details',
  EVENT_PLACEMENTS: '/events/create/placements',
  EVENT_PACKAGES: '/events/create/packages',
  EVENT_AUDIENCE: '/events/create/audience',
  EVENT_REVIEW: '/events/create/review',

  ORGANIZER_HOME: '/home/organizer',
  EVENT_VIEW: '/event/view',
  EVENT: '/events/:id',
  BLOG_POST: '/blog/:id',
  BLOG: '/blog',
  EVENTS: '/events',

  EVENT_DASHBOARD: '/events/:id/dashboard',
  MANAGE_EVENT: '/events/:id/dashboard/manage',
  EDIT_EVENT: '/events/:id/dashboard/edit',
  VIEW_EVENT: '/events/:id/dashboard/view',

  PORTFOLIO: '/portfolio/:id',
  EVENT_CHECKOUT: '/event/sponsor/',
  EVENT_CHECKOUT_PACKAGES: '/events/:id/packages',
  EVENT_CHECKOUT_PLACEMENTS: '/events/:id/placements',
  EVENT_CHECKOUT_ASSETS: '/events/:id/checkout/assets',
  EVENT_CHECKOUT_ACTIVATION: '/events/:id/checkout/activation',
  EVENT_CHECKOUT_LEGAL: '/events/:id/checkout/legal',
  EVENT_CHECKOUT_REVIEW: '/events/:id/checkout/review',
  EVENT_CHECKOUT_PAYMENT: '/events/:id/checkout/payment',

  EVENTS_SEARCH: '/events',
  SPONSOR_HOME: '/home/sponsor',

  UPLOAD: '/upload',
  PRIVACY_POLICY: '/privacypolicy',

  TEST: '/test/:id',

  PDF: '/admin/pdfs',

  LEARN_MORE_EVENT_ORGANIZER: '/learn-more/event-organizer',
  LEARN_MORE_SPONSOR: '/learn-more/sponsor',
  LEARN_MORE_RESOURCES: '/learn-more/resources',
  LEARN_MORE_RESOURCES_PRODUCT_DEMO: '/learn-more/resources#product-demo',
  LEARN_MORE_RESOURCES_BLOG: '/learn-more/resources#blog',
  LEARN_MORE_RESOURCES_FAQS: '/learn-more/resources#faqs',

  EVENT_SPONSORS: '/admin/event-sponsors',
  EVENT_RANKINGS: '/admin/event-rankings',
  EVENT_RANKINGS_DIVISION: '/admin/event-rankings-division',
  SPONSOR_RANKINGS: '/admin/sponsor-rankings',
  ADMIN_EVENT_REVIEW: '/admin/events/:id/review',
  ADMIN_EVENTS: '/admin/events',
}

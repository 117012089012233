import { ActionTypes } from './occupations.actions';

const initialState = {
    errorMessage: "",
    occupations: []
};

const OccupationsReducer = (state = initialState, action) => {
    switch (action.type) {
      case ActionTypes.SEARCH_OCCUPATIONS_SUCCESS:
        return { ...state, occupations: action.data};
        case ActionTypes.SET_OCCUPATIONS_SUCCESS:
          return { ...state, occupations: action.data};
      default:
          return state;
    }
};

export default OccupationsReducer;

export const ActionTypes = {
    SET_FIREBASE: 'SET_FIREBASE',
    SET_FIREBASE_SUCCESS: 'SET_FIREBASE_SUCCESS',
    SET_FIREBASE_FAILURE: 'SET_FIREBASE_FAILURE'
};

export const setFirebase = (firebase) => ({
    type: ActionTypes.SET_FIREBASE,
    payload: {
        firebase
    },
});

import { put, call, all, takeLatest } from 'redux-saga/effects';
import { ActionTypes } from './industries.actions';
import { post } from '../Api'
const basePath = "industries";

export const searchIndustries = function* (action) {
  const { payload: { query } } = action;
  try {
    const response = yield call(post, basePath + "/search", { query });
    const json = yield response.json();
    yield put({ type: ActionTypes.SEARCH_INDUSTRIES_SUCCESS, data: json });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: ActionTypes.SEARCH_INDUSTRIES_FAILURE, error });
  }
}

function* industries() {
  yield all([
    takeLatest(ActionTypes.SEARCH_INDUSTRIES, searchIndustries),
  ]);
}

export default industries;

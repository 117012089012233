import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Close from 'assets/icons/Close';
import './Pill.css';

class Pill extends Component {
  static defaultProps = {
    isCloseVisible: true
  }

  constructor(props) {
    super(props);
    this.state = {

    }
  }

  onClickRemove() {
    if (this.props.onClickRemove != null) {
        this.props.onClickRemove();
    }
  }

  getClose() {
    if (!this.props.isCloseVisible) {
      return;
    }
    return (
      <div className="d-flex align-items-center">
        <Close className="pill-close" classNameX="pill-close-x" onClick={this.onClickRemove.bind(this)} />
      </div>
    );
  }

  render() {

    return (
      <div>
        <div className="pill-container">
          <div className="d-flex flex-row">
            <div className="body-copy-primary-small">{this.props.label}</div>
            {this.getClose()}
          </div>
        </div>
      </div>
    );
  }
}

export default Pill;

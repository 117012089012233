import React, { Component } from 'react';
import TextField from 'components/TextField';
import Detail from 'common/Detail';
import Button from 'components/new/Button';
import Divider from 'common/Divider.component';
import Community from 'assets/community.svg';
import { isFilled, isEmailValid } from 'common/helpers/validators';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import './getstarted.css';


class GetStarted extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstname: {
        value: "",
        error: false
      },
      lastname: {
        value: null,
        error: false
      },
      email: {
        value: props.email,
        error: false
      },
      password: {
        value: null,
        error: false
      },
      company: {
        value: "",
        error: false
      },
      url: {
        value: "",
        error: false
      },
    }
  }

  validate() {
    let isFormValid = false;
    let fields = new Set();

    fields.add(this.isFieldValid(isFilled, "firstname"));
    fields.add(this.isFieldValid(isFilled, "lastname"));
    fields.add(this.isFieldValid(isEmailValid, "email"));
    fields.add(this.isFieldValid(isFilled, "company"));

    return !fields.has(false);
  }

  isFieldValid(validator, key) {
    let isValid = validator(this.state[key].value);

    this.setState({
      [key]: {
        value: this.state[key].value,
        error: !isValid
      }
    });

    return isValid;
  }

  componentDidUpdate() {

  }

  closeModal() {
    this.props.history.push('/');
    this.setState({modalVisible: false});
  }

  onChange(event) {
    this.setState({ [event.target.name]: {
      value: event.target.value,
      error: this.state[event.target.name].error
      }
    })
  }

  handleOnClick() {
    if (!this.validate()) return;
    /*this.props.signUpNewsletter({
      place_id: this.state.place_id.value,
      representative_name: this.state.name.value,
      representative_email: this.state.email.value,
      representative_company: this.state.company.value,
      due_date: this.state.dueDate.value,
      objectives: this.state.objectives.value,
      user_type: this.state.selected,
      event_types: this.state.type.value
    });*/
  }

  render() {
    let {firstname,
      lastname,
      email,
      company,
      password,
      url
    } = this.state;

    let {
      error,
      errorMessage
    } = this.props;

    return (
      <div className="getstarted-wrapper container">
        <Detail justification="start" style={{textAlign: 'Start', marginBottom: '25px'}} header="My Events"/>
        <Divider />
        <div className="getstarted-form d-flex flex-md-row flex-column-reverse">
          <div className="getstarted-container col-md-6 col-12">
            <TextField
              fontStyle="tertiary"
              name="firstname"
              value={firstname.value}
              placeholder="Enter your first name"
              errorMessage="First name cannot be empty."
              error={firstname.error}
              onChange={this.onChange.bind(this)}
              label="First Name"
              size="full" />
            <TextField
              fontStyle="tertiary"
              name="lastname"
              error={lastname.error}
              errorMessage="Last name cannot be empty."
              placeholder="Enter your last name"
              value={lastname.value}
              onChange={this.onChange.bind(this)}
              label="Last Name"
              size="full" />
            <TextField
              fontStyle="tertiary"
              name="email"
              error={email.error}
              errorMessage="Please enter a valid e-mail."
              value={email.value}
              placeholder="Enter your work e-mail address"
              onChange={this.onChange.bind(this)}
              label="Work Email Address"
              size="full" />
            <TextField
              key="eo-2"
              fontStyle="tertiary"
              name="company"
              error={company.error}
              errorMessage="Company name cannot be empty."
              placeholder="Enter your company name"
              value={company.value}
              onChange={this.onChange.bind(this)}
              label="Company Name"
              size="full" />
            <TextField
              key="eo-2"
              fontStyle="tertiary"
              name="url"
              error={url.error}
              optional={true}
              errorMessage="Please enter a valid url."
              placeholder="Enter your company url"
              value={company.value}
              onChange={this.onChange.bind(this)}
              label="Company Website"
              size="full" />
            <TextField
              key="eo-2"
              fontStyle="tertiary"
              name="password"
              type="password"
              error={password.error}
              errorMessage="Password cannot be empty."
              placeholder="Enter your password"
              value={password.value}
              onChange={this.onChange.bind(this)}
              label="Password"
              size="full" />
              <div className="learnmore-signup-footer">
                <Button
                  type="primary"
                  size="sm"
                  onClick={this.handleOnClick.bind(this)}
                  label='GET STARTED'/>
              </div>
            </div>
            <div className="col-md-6 col-12 getstarted-content" >
              <div className="d-flex flex-column justify-content-between" style={{padding: '20px', height: '100%', width: '100%', borderRadius: '4px', backgroundColor: '#FFFFFF'}}>
                <div className="getstarted-reasons">
                  <div className="header-2-brand">Create your first event</div>
                  <div>Get your events in front of the right audience.
                  Get your events in front of the right audience.
                  Get your events in front of the right audience.
                  Get your events in front of the right audience.
                  Get your events in front of the right audience.
                  Get your events in front of the right audience.
                  Get your events in front of the right audience.
                  Get your events in front of the right audience.</div>
                </div>
                <div className="d-flex justify-content-center">
                  <img src={Community} style={{width: '60%'}} />
                </div>
              </div>
            </div>
          </div>

            {/*<div style={{position: "relative"}}>
              {error ? <div className="body-copy-error-wrapper"><span className="signup-error-message body-copy-error-small body-copy-error-container">{errorMessage}</span></div> : null}
            </div>*/}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {

  };
};

const mapDispatchToProps = dispatcher => (
    bindActionCreators({

    }, dispatcher)
);

export default connect(mapStateToProps, mapDispatchToProps)(GetStarted);

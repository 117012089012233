export const ActionTypes = {
    ADD_SPONSOR_PREFERENCE: 'ADD_SPONSOR_PREFERENCE',
    REMOVE_SPONSOR_PREFERENCE: 'REMOVE_SPONSOR_PREFERENCE'
};

export const addSponsorPreference = (preference) => ({
    type: ActionTypes.ADD_SPONSOR_PREFERENCE,
    payload: {
      preference
    }
});

export const removeSponsorPreference = (preference) => ({
    type: ActionTypes.REMOVE_SPONSOR_PREFERENCE,
    payload: {
      preference
    }
});

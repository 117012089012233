import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Eyebrow from 'components/Eyebrow';
import Button from 'components/new/Button';
import { removeEventAsset } from 'services/eventassets/eventassets.actions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import './sidepanel.css';

class SidePanel extends Component {
  initialize() {
    let { assets } = this.props;

    if (assets.length === 0) {
      return (<div className="body-copy-tertiary-small eventdetails-sidepanel-container">No assets added</div>)
    } else {
      return (
        <div className="eventdetails-tilepanel">
          {
            assets.map((asset, index) => {
              return (
                <div key={index} className="eventdetails-tile-container">
                  {this.displayAsset(asset)}
                  <div className="d-flex eventdetails-tile-footer flex-row justify-content-between flex-wrap">
                    <div style={{ marginBottom: '10px', wordNreak: 'break-word' }} className="eventdetails-sidepanel-image body-copy-tertiary-small">{asset.get("filename")}</div>
                    <Button label="Remove" size="xs" onClick={this.removeEventAsset.bind(this, asset)} />
                  </div>
                </div>
              )
            })
          }
        </div>
      )
    }
  }

  displayAsset(asset) {
    if (asset.get("asset_type_id") == 1 || asset.get("asset_type_id") == 3)  {
      return (
        <div>
          <img style={{width: '100%'}} src={URL.createObjectURL(asset.get("file"))} />
        </div>
      )
    } else {
      return (null)
    }
  }

  removeEventAsset(asset) {
    this.props.removeEventAsset(asset);
  }

  render() {
    return (
      <div>
        <div className="body-copy-bold-tertiary">Event Assets</div>
        {this.initialize()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {

  };
};

const mapDispatchToProps = dispatcher => (
    bindActionCreators({
        removeEventAsset
    }, dispatcher)
);

export default connect(mapStateToProps, mapDispatchToProps)(SidePanel);

import { put, call, all, takeLatest } from 'redux-saga/effects';
import { ActionTypes } from './signup.actions';
import { ActionTypes as UserActionTypes} from 'services/users/users.actions';
import { isSignUpValid } from './validators';
import { Routes } from 'constants/Routes';
import { createUserWithEmailAndPassword, createUser } from './signup.api';
import { post } from '../Api'

export const signUp = function* (action) {
  /*
  .createUser({
    email: 'user@example.com',
    emailVerified: false,
    phoneNumber: '+11234567890',
    password: 'secretPassword',
    displayName: 'John Doe',
    photoURL: 'http://www.example.com/12345678/photo.png',
    disabled: false
  })
  */
  const { payload: { email, password, firstName, lastName, user, history } } = action;
  let isValid = isSignUpValid(email, password, firstName, lastName);

  if (isValid.error) {
    yield put({ type: ActionTypes.SIGNUP_FAILURE, error: true, errorMessage: isValid.errorMessage });
    return;
  }

  try {
    const response = yield call(createUserWithEmailAndPassword, email, password);
    user.id = response.user.uid;
    user.firstName = firstName;
    user.lastName = lastName;
    yield put({ type: ActionTypes.SIGNUP_SUCCESS, data: user });
    yield put({ type: UserActionTypes.POST_USERS, payload: {user: user }});
    history.push(Routes.LANDING)
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: ActionTypes.SIGNUP_FAILURE, errorMessage: error.message });
  }
}

export const createAccount = function* (action) {
  const { payload: { user } } = action;
  let payload = {
    email: user.email,
    emailVerified: false,
    password: user.password,
    displayName: `${user.firstName} ${user.lastName}`,
    disabled: false
  }

  try {
    const response = yield call(createUser, payload);
    delete user.password
    user.id = response.user.uid;

    yield put({ type: ActionTypes.CREATE_USER_ACCOUNT_SUCCESS, data: user });
    yield put({ type: ActionTypes.CREATE_USER, payload: { user }});
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: ActionTypes.CREATE_USER_ACCOUNT_FAILURE, errorMessage: error.message });
  }
}

export const postUser = function* (action) {
  const { payload: { user } } = action;

  try {
    const response = yield call(post, "signup", user);
    if (response.status >= 200 && response.status < 300) {
      const data = yield response.json();

      yield put({ type: ActionTypes.CREATE_USER_SUCCESS, payload: { user }, token: data.token, email: data.email  });
    } else {
      throw response.body;
    }

  } catch (error) {
    // dispatch a failure action to the store with the error
    //console.log(error)
    yield put({ type: ActionTypes.CREATE_USER_FAILURE, errorMessage:"This e-mail is already signed up for an account.", error: true });
  }
}

function* signUpSagas() {
  yield all([
    takeLatest(ActionTypes.SIGNUP, signUp),
    takeLatest(ActionTypes.CREATE_USER_ACCOUNT, createAccount),
    takeLatest(ActionTypes.CREATE_USER, postUser)
  ]);
}

export default signUpSagas;

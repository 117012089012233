import React, { Component } from 'react';
import  Divider from 'common/Divider.component';
import './TabGroup.css';

class TabGroup extends Component {
  render() {
    return (
      <div className="d-flex flex-column" style={{width: '100%'}}>
        <div className="d-flex flex-row tabgroup-container flex-wrap justify-content-between justify-content-sm-start">
          {this.props.children}
        </div>
        <Divider />
      </div>
    );
  }
}

export default TabGroup;

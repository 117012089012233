export const ActionTypes = {
    POST_EVENT_AUDIENCE_PROFILE: 'POST_EVENT_AUDIENCE_PROFILE',
    POST_EVENT_AUDIENCE_PROFILE_SUCCESS: 'POST_EVENT_AUDIENCE_PROFILE_SUCCESS',
    POST_EVENT_AUDIENCE_PROFILE_FAILURE: 'POST_EVENT_AUDIENCE_PROFILE_FAILURE',
    PUT_EVENT_AUDIENCE_PROFILE: 'PUT_EVENT_AUDIENCE_PROFILE',
    PUT_EVENT_AUDIENCE_PROFILE_SUCCESS: 'PUT_EVENT_AUDIENCE_PROFILE_SUCCESS',
    PUT_EVENT_AUDIENCE_PROFILE_FAILURE: 'PUT_EVENT_AUDIENCE_PROFILE_FAILURE',
    SET_EVENT_AUDIENCE_PAGE_STATE: 'SET_EVENT_AUDIENCE_PAGE_STATE'
  }

export const postEventAudienceProfile = (event_audience_profile) => {
  return {
      type: ActionTypes.POST_EVENT_AUDIENCE_PROFILE,
      payload: {
        event_audience_profile
      }
  }
};

export const putEventAudienceProfile = (event_audience_profile) => {
  return {
      type: ActionTypes.PUT_EVENT_AUDIENCE_PROFILE,
      payload: {
        event_audience_profile
      }
  }
};

export const setEventAudiencePage = (isValid) => ({
    type: ActionTypes.SET_EVENT_AUDIENCE_PAGE_STATE,
    payload: {
      isValid
    }
});

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Eyebrow from 'components/Eyebrow'
import Button from 'components/new/Button';
import List from 'common/list.component';
import Accordion from 'components/Accordion';
import Close from 'assets/icons/close.svg';
import { generateCurrencyFormat } from 'common/helpers/currency';
import './sidepanel.css';

class SidePanel extends Component {
  onClickEditPackage(item) {
    this.props.onClickEditPackage(item)
  }

  onClickRemovePackage(item) {
    this.props.onClickRemovePackage(item)
  }

  initialize() {
    let { packages, savedPackages } = this.props;

    if (packages.length === 0) {
      return (<div className="body-copy-tertiary-small eventpackages-sidepanel-container">No packages added</div>)
    }
    else {
      let panels = [];
      packages.map((item, index) => {
        let { name, cost, limit, placements } = item;

        let content = () => {
          return (
            <div key={index}>
              <div className="d-flex flex-column body-copy-tertiary-small">
                <div>Value: {generateCurrencyFormat(cost)}</div>
                <div>Limit: {limit}</div>
                <div>Placements:</div>
                <List expand={true} items={placements} emptyMessage="No placements" />
              </div>
              <div style={{ marginTop: '5px'}}>
                <Button label="Edit" onClick={this.onClickEditPackage.bind(this, item)} size="xs" />
              </div>
            </div>
          )
        }

        let panel = {
          label: this.getLabel(name, item),
          content
        }

        panels.push(panel);
      });

      return (
        <div className="eventpackages-sidepanel-container">
          <Accordion className="eventpackages-sidepanel-container" panels={panels} />
        </div>
      )
    }
  }

  getLabel(name, item) {
    return (
      <div className="d-flex justify-content-between align-items-center">
        <div>{name}</div>
        <div>
          <Button
            icon={Close}
            type="transparent"
            onClick={this.onClickRemovePackage.bind(this, item)}
            stopPropagation={true} />
        </div>
      </div>
    )
  }

  render() {
    return (
      <div>
        <Eyebrow label="Packages" />
        {this.initialize()}
      </div>
    );
  }
}

export default SidePanel;

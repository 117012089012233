import { ActionTypes } from './industries.actions';

const initialState = {
    errorMessage: "",
    industries: []
};

const IndustriesReducer = (state = initialState, action) => {
    switch (action.type) {
      case ActionTypes.SEARCH_INDUSTRIES_SUCCESS:
        return { ...state, industries: action.data};
        case ActionTypes.SET_INDUSTRIES:
          return { ...state, industries: action.payload.industries};
      default:
          return state;
    }
};

export default IndustriesReducer;

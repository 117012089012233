import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './FormGroup.css';

class FormGroup extends Component {
  static defaultProps = {
    justification: 'end',
    style: {}
  }

  constructor(props) {
    super(props);
  }

  setJustification() {
    if (this.props.justification === 'end') {

    } else {

    }
  }

  render() {
    return (
      <div style={this.props.style} className="flex-md-row d-flex justify-content-end restage-form-group flex-column">
        {this.props.children}
      </div>
    );
  }
}

export default FormGroup;

import { ActionTypes } from './eventgenders.actions';

const initialState = {
    eventgenders: null
};

const EventGendersReducer = (state = initialState, action) => {
    switch (action.type) {
      case ActionTypes.PUT_EVENT_GENDERS_SUCCESS:
        return { ...state, eventgenders: action.eventgenders };
      default:
          return state;
    }
};

export default EventGendersReducer;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './RadioButton.css';

class RadioButton extends Component {
  static defaultProps = {
    checked: false
  }

  onClick() {
    if (this.props.onClick != null) {
      this.props.onClick();
    }
  }

  render() {
    let style = (this.props.style === null ? {} : this.props.style);
    let className = ["d-flex", "flex-row", "align-items-center", "radiobutton-container"];

    return (
      <div style={style} role="radio" name={this.props.value} aria-labelledby={this.props.label} onClick={this.onClick.bind(this)} aria-checked={this.props.checked} tabIndex="0" className="radiobutton-container">
        <div className="d-flex flex-row align-items-center">
          <div>
            <div className="radiobutton-outer d-flex align-items-center justify-content-center">
              <div className={(this.props.checked ? "radiobutton-inner checkbox-open" : "radiobutton-inner")} />
            </div>
          </div>
          <label onClick={this.onClick.bind(this)} className={`radiobutton-label ${this.props.description ? "body-copy-tertiary-semibold" : "body-copy-tertiary"}`}>{this.props.label}</label>
        </div>
        <div style={{textAlign: 'start'}} className="radiobutton-description body-copy-tertiary-small">{this.props.description}</div>
      </div>
    );
  }
}

export default RadioButton;

import React, { Component } from 'react';
import './Wrapper.css';

class Wrapper extends Component {
  static defaultProps = {
    style: {}
  }
  render() {
    let className=["restage-wrapper"];

    className = (this.props.className !== null ? className.concat(this.props.className) : className);

    return (
      <div style={this.props.style} className={className.join(" ")}>
        {this.props.children}
      </div>
    );
  }
}


export default Wrapper;

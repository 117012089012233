import { ActionTypes } from './portfolioaudienceprofile.actions';

const initialState = {
    errorMessage: "",
    portfolio_audience_profile: null
};

const PortfolioAudienceProfileReducer = (state = initialState, action) => {
    switch (action.type) {
      case ActionTypes.POST_PORTFOLIO_AUDIENCE_PROFILE_SUCCESS:
        return { ...state, portfolio_audience_profile: action.portfolio_audience_profile };
      case ActionTypes.PUT_PORTFOLIO_AUDIENCE_PROFILE_SUCCESS:
        return { ...state, portfolio_audience_profile: action.portfolio_audience_profile };
      default:
        return state;
    }
};

export default PortfolioAudienceProfileReducer;

export const ActionTypes = {
    ADD_TO_CART: 'ADD_TO_CART',
    ADD_PACKAGE_TO_CART: 'ADD_PACKAGE_TO_CART',
    REMOVE_FROM_CART: 'REMOVE_FROM_CART',
    REMOVE_PACKAGE_FROM_CART: 'REMOVE_PACKAGE_FROM_CART'
};

export const addToCart = (item) => ({
    type: ActionTypes.ADD_TO_CART,
    payload: {
      item
    }
});

export const addPackageToCart = (item) => ({
    type: ActionTypes.ADD_PACKAGE_TO_CART,
    payload: {
      item
    }
});

export const removeFromCart = (item) => ({
    type: ActionTypes.REMOVE_FROM_CART,
    payload: {
      item
    }
});

export const removePackageFromCart = (item) => ({
    type: ActionTypes.REMOVE_PACKAGE_FROM_CART,
    payload: {
      item
    }
});

import { ActionTypes } from './eventpackageoffer.actions';

const initialState = {
    errorMessage: ""
};

const EventPackageOfferReducer = (state = initialState, action) => {
    switch (action.type) {
      case ActionTypes.POST_EVENT_PACKAGE_OFFER_SUCCESS:
        return { ...state };
      default:
          return state;
    }
};

export default EventPackageOfferReducer;

import { ActionTypes } from './negotiations.actions';

const initialState = {
  eventPackageOfferIdResponses: []
};

const NegotiationsReducer = (state = initialState, action) => {
    switch (action.type) {
      case ActionTypes.SEND_NEGOTIATIONS_EMAIL_SUCCESS:
        console.log("action", action)
        return { ...state, eventPackageOfferIdResponses: [...state.eventPackageOfferIdResponses, action.eventPackageOfferId]};
      default:
        return state;
    }
};

export default NegotiationsReducer;

import { put, call, all, takeLatest } from 'redux-saga/effects';
import { ActionTypes } from './portfolioplacementpreferences.actions';
import { post, putRequest } from '../Api'

const basePath = "portfolio-placement-preferences"

export const postPortfolioPlacementPreferences = function* (action) {
  const { payload: { portfolio_placement_preferences } } = action;
  console.log(portfolio_placement_preferences)
  try {
    const response = yield call(post, basePath, portfolio_placement_preferences);
    const data = yield response.json();
  console.log(portfolio_placement_preferences)
    yield put({ type: ActionTypes.POST_PORTFOLIO_PLACEMENT_PREFERENCES_SUCCESS, data });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: ActionTypes.POST_PORTFOLIO_PLACEMENT_PREFERENCES_FAILURE, error });
  }
}

export const putPortfolioPlacementPreferences = function* (action) {
  const { payload: { portfolio_placement_preferences } } = action;
  console.log(portfolio_placement_preferences)

  try {
    const response = yield call(putRequest, basePath, portfolio_placement_preferences);
    yield put({ type: ActionTypes.PUT_PORTFOLIO_PLACEMENT_PREFERENCES_SUCCESS, data: response.data });
  } catch (error) {
    yield put({ type: ActionTypes.PUT_PORTFOLIO_PLACEMENT_PREFERENCES_FAILURE, error });
  }
}


function* portfolioplacementpreferences() {
  yield all([
    takeLatest(ActionTypes.POST_PORTFOLIO_PLACEMENT_PREFERENCES, postPortfolioPlacementPreferences),
    takeLatest(ActionTypes.PUT_PORTFOLIO_PLACEMENT_PREFERENCES, putPortfolioPlacementPreferences)
  ]);
}

export default portfolioplacementpreferences;

import { put, call, all, takeLatest } from 'redux-saga/effects';
import { ActionTypes } from './eventsponsorshipoffer.actions';
import { get, post } from '../Api'

const basePath = "event-sponsorship-offers"

export const postEventSponsorshipOffer = function* (action) {
  const { payload: { eventsponsorshipoffer } } = action;
  try {
    const response = yield call(post, basePath, eventsponsorshipoffer);
    console.log("eventsponsorshipoffer", eventsponsorshipoffer);
    const json = yield response.json();
    yield put({ type: ActionTypes.POST_EVENT_SPONSORSHIP_OFFER_SUCCESS, json });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: ActionTypes.POST_EVENT_SPONSORSHIP_OFFER_FAILURE, error });
  }
}

function* eventsponsorshipoffers() {
  yield all([
    takeLatest(ActionTypes.POST_EVENT_SPONSORSHIP_OFFER, postEventSponsorshipOffer)
  ]);
}

export default eventsponsorshipoffers;

import React, { useState } from 'react';
import Modal from 'components/Modal';
import Checkbox from 'components/Checkbox';
import TextField from 'components/TextField';
import ButtonGroup from 'components/ButtonGroup';

import './negotiations.css';

export const NegotiationScreen = ({ packageName, packageDescription }) => {  
  const isNegotiationText = "Negotiate Package";
  const priceLabel = "Price";
  const notesLabel = "Notes";
  const requestText = "Request";
  const cancelText = "Cancel";

  const [isNegotiation, setIsNegotiation] = useState(true);

  const onIsNegotiationToggle = () => {
    setIsNegotiation(!isNegotiation);
  }

  return (
    <Modal onClickClose={() => {}} visible={true} width={800}>
      <div className="negotiations-modal-body">
        <div className="negotiations-package-name">{packageName}</div>
        <div className="negotiations-package-description">{packageDescription}</div>
        <Checkbox className="negotiations-negotiate-pacakge" checked={isNegotiation} label={isNegotiationText} onClick={onIsNegotiationToggle} />
        <div className="negotiations-divider" />
        <div className="negotiations-sub-container">
          <TextField
            fontStyle="tertiary"
            isTextArea={false}
            onChange={() => {}}
            label={priceLabel}
            size="md" 
          />
        </div>
        <div className="negotiations-divider" />
        <div className="negotiations-sub-container">
          <TextField
            fontStyle="tertiary"
            isTextArea={true}
            onChange={() => {}}
            label={notesLabel}
            size="full" 
          />
        </div>
      </div>
      <div className="negotiations-button-group">
        <ButtonGroup
          labelPrimary={requestText}
          labelSecondary={cancelText}
          onClickPrimary={(() => {})}
          onClickSecondary={(() => {})} 
        />
      </div>
    </Modal>
  );
}

export default NegotiationScreen;
import { ActionTypes } from './places.actions';

const initialState = {
    errorMessage: "",
    suggestions: []
};

const PlacesReducer = (state = initialState, action) => {
    switch (action.type) {
      case ActionTypes.GET_SUGGESTIONS_SUCCESS:
        return { ...state, suggestions: action.data};
      case ActionTypes.GET_SUGGESTIONS_FAILURE:
        return { ...state};
      case ActionTypes.SET_SUGGESTIONS:
        return { ...state, suggestions: action.payload.data};
      default:
          return state;
    }
};

export default PlacesReducer;

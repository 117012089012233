import React, { Component } from 'react';
import Wrapper from 'components/Wrapper';
import Eyebrow from 'components/Eyebrow';
import Detail from 'common/Detail';
import Button from 'components/new/Button';
import TextField from 'components/TextField';
import FormGroup from 'components/FormGroup';
import { Table } from 'antd';
import { getEvent } from 'services/events/events.actions';
import { generateCurrencyFormat } from 'common/helpers/currency';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

const columns = [
  {
    title: 'Event Name',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: 'Probability',
    dataIndex: 'ranking',
    key: 'ranking'
  },
  {
    title: 'Event URL',
    dataIndex: 'eventURL',
    key: 'eventURL'
  }
];

class EventRankingsDivision extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: []
    }
  }

  componentWillMount() {

      fetch("https://api.restagex.com/event-rankings-division", {
      //fetch("http://localhost:9000/event-rankings", {
      method: "GET",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json"
      },
      redirect: "follow",
      referrer: "no-referrer"
    }).then((response) => {
      return response.json();
    }).then((data) => {
      let events = []
      data.map((item, index) => {
        let { name, ranking, eventURL } = item;
        events.push({
          name,
          ranking,
          eventURL: <a key={index} href={eventURL} target="_blank" rel="noopener noreferrer">{eventURL}</a>
        })
      });
      this.setState({
        data: events
      })
    });
  }

  _handleOnChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  render() {
    let { data } = this.state;

    return (
      <Wrapper className="container">
      <div className="d-flex flex-row justify-content-between">
        <Detail justification="start" header="Event Rankings" subHeader="Event Rankings" />
      </div>
        <div style={{marginTop: '25px'}}>
          <Eyebrow label="Event Rankings" />
          <Table dataSource={data} columns={columns} />
        </div>
      </Wrapper>
    );
  }
}

const mapStateToProps = (state) => {
  return {

  };
};

const mapDispatchToProps = dispatcher => (
    bindActionCreators({

    }, dispatcher)
);

export default connect(mapStateToProps, mapDispatchToProps)(EventRankingsDivision);

import React, { Component } from 'react';
import Button from 'components/new/Button';
import { Routes } from 'constants/Routes';
import { withRouter } from "react-router-dom";
import './footer.css';

class Footer extends Component {
  static defaultProps = {
    topheader: "Ready to get started?",
    bottomheader: "Get in touch or create an account."
  }

  getBackgroundClass() {
    let { background } = this.props;
    let classNames = ["footer-container"];

    if (background === "purple") {
      classNames.push("footer-container-purple");
    } else if (background === 'pink') {
      classNames.push("footer-container-pink");
    } else {
      classNames.push("footer-container-white");
    }

    return classNames.join(" ")
  }

  getDescriptionClass() {
    let classNames = ["learnmore-footer-description"];
    let { background } = this.props;

    if (background === "purple") {
      classNames.push("header-1-white")
    } else if (background === "pink") {
      classNames.push("header-1-purple")
    } else {
      classNames.push("header-1-tertiary")
    }

    return classNames.join(" ");
  }

  getButtonType() {
    if (this.props.background === 'purple') {
      return "primary";
    }
    return "tertiary";
  }

  openAngelList() {
    window.open("https://www.angel.co/company/restage/jobs", "_blank",)
  }

  goTo(path) {
    console.log(this.props.history)
    console.log(path)
    this.props.history.push(path)
  }

  contact() {
    window.open("mailto:hello@restagex.com");
  }

  render() {
    let { description, onCTA, ctaLabel, topheader, bottomheader } = this.props;

    return(
      <div className={this.getBackgroundClass()}>
        <div className="container">
          <div className="footer-main">
           <div className="d-flex flex-lg-row flex-column justify-content-between" style={{marginBottom: '35px'}}>
            <div>
              <div className="header-2-white">{topheader}</div>
              <div className="header-2-white">{bottomheader}</div>
            </div>
            {this.props.children}
           </div>
           <div className="d-flex flex-sm-row flex-column">
             <div className="footer-top-row d-flex flex-column">
               <div className="body-copy-bold-white">Company</div>
               <div onClick={this.openAngelList.bind(this)} rel="noopener noreferrer" className="link-undecorated body-copy-white">Jobs</div>
               <div onClick={this.goTo.bind(this, Routes.LEARN_MORE_RESOURCES_BLOG)} className="link-undecorated body-copy-white">Blog</div>
             </div>
             <div className="d-flex flex-column">
               <div className="body-copy-bold-white">Resources</div>
               <div className="d-flex flex-row">
                  <div style={{marginRight: '45px'}}>
                    <div onClick={this.contact.bind(this)} className="link-undecorated body-copy-white">Contact</div>
                    <div onClick={this.goTo.bind(this, Routes.PRIVACY_POLICY)} className="link-undecorated body-copy-white">Privacy & Terms</div>
                  </div>
                  <div>
                    <div onClick={this.goTo.bind(this, Routes.LEARN_MORE_EVENT_ORGANIZER)} className="link-undecorated body-copy-white">Event Organizer</div>
                    <div onClick={this.goTo.bind(this, Routes.LEARN_MORE_SPONSOR)} className="link-undecorated body-copy-white">Sponsor</div>
                  </div>
                </div>
             </div>
           </div>
          </div>
        </div>
        <div style={{marginTop: '25px'}} className="d-flex flex-row justify-content-center body-copy-bold-white">© Restage Experiences 2020</div>
      </div>
    )
  }
}

export default withRouter(Footer);

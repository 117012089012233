import { ActionTypes } from './verify.actions';

const initialState = {
    success: null,
    resendsuccess: null,
    error: false,
    email: "",
    resentToken: ""
};

const VerifyReducer = (state = initialState, action) => {
    switch (action.type) {
      case ActionTypes.VERIFY_EMAIL_SUCCESS:
        return { ...state, success: true };
      case ActionTypes.VERIFY_EMAIL_FAILURE:
        return { ...state, success: false};
      case ActionTypes.RESEND_VERIFY_EMAIL_SUCCESS:
        return { ...state, resendsuccess: true, resentToken: action.token, email: action.email };
      case ActionTypes.RESEND_VERIFY_EMAIL_FAILURE:
        return { ...state, resendsuccess: false };
      case ActionTypes.RESEND_VERIFY_EMAIL:
        return { ...state, resendsuccess: null };
      default:
        return state;
    }
};

export default VerifyReducer;

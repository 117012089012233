import { ActionTypes } from './sponsorpreferences.actions';

const initialState = {
    errorMessage: "",
    preferences: [],
    name: ""
};

const SponsorPreferencesReducer = (state = initialState, action) => {
    switch (action.type) {
      case ActionTypes.ADD_SPONSOR_PREFERENCE:
        let { preference } = action.payload;
        console.log(preference)
        let contains = state.preferences.filter((item) => item.placement === preference.placement && item.type === preference.type);
        let preferences = [];

        if (contains.length > 0) {
          preferences = [...state.preferences];
        } else {
          preferences = [...state.preferences, preference]
        }
        return {
          ...state,
          preferences
        };
      case ActionTypes.REMOVE_SPONSOR_PREFERENCE:
        preference = action.payload.preference;
        console.log(preference)
        console.log(state.preferences)
        let newPreferences = state.preferences.filter((item) => !(`${item.type}: ${item.placement}` === preference));

        return {
          ...state,
          preferences: [...newPreferences]
        };
      default:
          return state;
    }
};

export default SponsorPreferencesReducer;

import { put, call, all, takeLatest } from 'redux-saga/effects';
import { ActionTypes } from './messages.actions';
import { post, getDropwizard } from '../Api'

const basePath = "messages"

export const postMessage = function* (action) {
  const { payload: { message, room_id, sender_user_id } } = action;
  try {
    const response = yield call(post, basePath, {message, room_id, sender_user_id});
    yield put({ type: ActionTypes.POST_MESSAGE_SUCCESS, response });
  } catch (error) {
    yield put({ type: ActionTypes.POST_MESSAGE_FAILURE, error });
  }
}

export const getMessages = function* (action) {
  const { payload: { room_id } } = action;
  try {
    const response = yield call(post, `${basePath}?room_id=${room_id}`);
    yield put({ type: ActionTypes.GET_MESSAGES_SUCCESS, response });
  } catch (error) {
    yield put({ type: ActionTypes.GET_MESSAGES_FAILURE, error });
  }
}

function* messages() {
  yield all([
    takeLatest(ActionTypes.POST_MESSAGE, postMessage)  ]);
}

export default messages;
